import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';

import { useFetchQuote } from 'src/graphql/tosa/queries/useFetchQuote/useFetchQuote';
import type {
  DiscountTypeEnum,
  FetchLeadQuery,
  FetchQuoteQuery,
  FetchQuoteQueryVariables,
  ShippingAddressInput,
} from 'src/graphql/tosa.types';
import { ProductLineType } from 'src/graphql/tosa.types';
import type { UseShippingAddressReturn } from '../state/useShippingAddress';
import { useShippingAddress } from '../state/useShippingAddress';
import type { ApolloError } from '@apollo/client';
import { isHandledQuoteTaxError } from './utils/isHandledQuoteTaxError';

interface FetchStatelessQuoteArgs {
  discountCode: string | null;
  discountType: DiscountTypeEnum | null;
  lead: FetchLeadQuery['lead'];
}

interface StatelessFreshQuoteContextReturn {
  fetchQuote(this: void, args: FetchStatelessQuoteArgs): Promise<void>;
  quote: FetchQuoteQuery['fetchQuote'] | null;
  loading: boolean;
  error: ApolloError | undefined;
}

const StatelessFreshQuoteContext =
  createContext<StatelessFreshQuoteContextReturn>({
    fetchQuote: async () => {},
    quote: null,
    loading: false,
    error: undefined,
  });

export function StatelessFreshQuoteProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [fetchQuoteQuery, { loading, error }] = useFetchQuote();
  const shippingAddress = useShippingAddress();

  const latestFetchTimestamp = useRef<number>(Date.now());
  const [quote, setQuote] = useState<FetchQuoteQuery['fetchQuote'] | null>(
    null
  );

  const fetchQuote = useCallback(
    async ({ discountCode, discountType, lead }: FetchStatelessQuoteArgs) => {
      const arePetsValid =
        lead && allPetsHaveRecommendedCaloriesAndRecipes(lead.pets);

      if (!arePetsValid) {
        throw new Error('Not all pets have recommended calories or recipes');
      }

      const pets = lead.pets
        .map(pet => {
          const mixingPlanRatio =
            petMixingPlanRatiosState.getIndividualPetRatio(pet.name);

          return {
            name: pet.name,
            requestedCalories: pet.recommendedCalories || 0,
            selection: {
              diy: null,
              fresh: {
                productLine: ProductLineType.Fresh,
                options: {
                  mixingPlanRatio,
                  recipes: pet.selection?.fresh?.options.recipes || [],
                },
              },
            },
          };
        })
        .filter(pet => pet.selection.fresh.options.mixingPlanRatio !== 0);

      const shippingZip = shippingAddress.zipCode;
      if (!shippingZip) {
        throw new Error('No shipping zip code provided');
      }

      const shippingAddressForFetchQuote =
        getShippingAddressForFetchQuote(shippingAddress);

      const selection: FetchQuoteQueryVariables['selection'] =
        lead.selection?.treats && lead.selection?.treats?.length > 0
          ? {
              treats: lead.selection?.treats?.map(treat => ({
                name: treat.name,
                quantity: treat.quantity,
                size: treat.size,
              })),
            }
          : null;

      const fetchTimestamp = Date.now();
      const result = await fetchQuoteQuery({
        variables: {
          pets,
          shippingZip,
          discountCode,
          discountType,
          shippingAddress: shippingAddressForFetchQuote,
          selection,
        },
      });

      if (result.error) {
        if (isHandledQuoteTaxError(result.error)) {
          // NOTE: This early return will leave the previously fetched quote in place,
          // which is not ideal. However, this prevents the entire page from failing
          // in the case that a tax rate cannot be fetched due to a zip/state mismatch.
          return;
        } else {
          throw result.error;
        }
      }

      if (!result.data?.fetchQuote && !loading) {
        throw new Error('No quote data returned');
      }

      // Sometimes quotes can be fetched in rapid succession and received out
      // of order; we compare fetchTimestamp against
      // latestFetchTimestamp.current to see if the incoming quote is also the
      // most-recently requested one.
      if (fetchTimestamp > latestFetchTimestamp.current && result.data) {
        setQuote(result.data.fetchQuote);
        latestFetchTimestamp.current = fetchTimestamp;
      }
    },
    [fetchQuoteQuery, shippingAddress, loading]
  );

  return (
    <StatelessFreshQuoteContext.Provider
      value={{
        fetchQuote,
        quote,
        loading,
        error,
      }}
    >
      {children}
    </StatelessFreshQuoteContext.Provider>
  );
}

function useStatelessFreshQuoteContext() {
  return useContext(StatelessFreshQuoteContext);
}

export function useFetchStatelessFreshQuote() {
  return useStatelessFreshQuoteContext().fetchQuote;
}

export function useStatelessFreshQuote() {
  return {
    quote: useStatelessFreshQuoteContext().quote,
    loading: useStatelessFreshQuoteContext().loading,
    error: useStatelessFreshQuoteContext().error,
  };
}

const allPetsHaveRecommendedCaloriesAndRecipes = (
  pets: FetchLeadQuery['lead']['pets']
) => {
  return pets.every(
    pet =>
      pet.recommendedCalories &&
      pet.selection?.fresh?.options.recipes &&
      pet.selection?.fresh?.options.recipes.length > 0
  );
};

function getShippingAddressForFetchQuote(
  shippingAddress: UseShippingAddressReturn
): ShippingAddressInput | null {
  const isShippingAddressValid =
    getIsShippingAddressValidInput(shippingAddress);

  return isShippingAddressValid ? shippingAddress : null;
}

function getIsShippingAddressValidInput(
  shippingAddress: UseShippingAddressReturn
): shippingAddress is ShippingAddressInput {
  return Boolean(
    shippingAddress?.addressLine1 &&
      shippingAddress?.city &&
      shippingAddress?.stateAbbr &&
      shippingAddress?.zipCode &&
      shippingAddress?.country
  );
}
