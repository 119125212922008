import { datadogLogs } from '@datadog/browser-logs';
import { config } from '../config';
import type { TfdLoggerConfig } from '../config';

/**
 * Initialize the logger for future use.  Must be called before any log requests
 * are made.
 */
export function loggerInit(initConfig: TfdLoggerConfig) {
  if (config.isSet) {
    return;
  }
  config.setConfig(initConfig);

  if (config.datadog?.enabled) {
    datadogLogs.init({
      ...config.datadog,
      site: 'datadoghq.com',
    });
  }
}
