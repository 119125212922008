/* eslint-env browser */
import './asset-public-path';
import 'core-js/stable';
import 'core-js/modules/esnext.string.match-all';
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Logger, loggerInit } from '@farmersdog/logger';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { polyfillSmoothScroll, ToastProvider } from '@farmersdog/corgi';
import ApolloProvider from 'src/graphql/Apollo/Provider';

import Application from 'src/components/Application';
import configureStore from 'src/store/configureStore';
import { loadRequestAttributesFromWindowCache } from 'src/server/utils/loadRequestAttributesFromWindowCache';
import deviceType, { DeviceTypeInjector } from 'src/utils/deviceType';
import {
  ScreenProvider,
  CorgiScreenProvider,
  CorgiV2ScreenProvider,
} from 'src/screen';

import { anonymousId } from '@farmersdog/lead-browser-storage';
import localStorageUser from 'src/utils/localStorageUser';

import { SplitProvider } from 'src/abTesting/SplitProvider.tsx';
import config from 'src/config';
import { WINDOW_SPLIT_TREATMENTS } from './server/constants';
import { SSRAnonymousExperimentsProvider } from './server/context/experimentsContext';

// Enable smooth scrolling behavior on Safari (Slideshow, Carousel, etc.)
polyfillSmoothScroll();
loggerInit({
  level: config('log.level'),
  datadog: {
    enabled: config('datadogRum.enabled'),
    clientToken: config('datadogRum.clientToken'),
    applicationId: config('datadogRum.applicationId'),
    service: 'website',
    env: config('app.env'),
    version: config('app.version'),
    trackUserInteractions: false,
    // Set to false, RUM will collect these
    forwardErrorsToLogs: false,
  },
});

const windowState = window.__INITIAL_STATE__;
const initialStateWithRequestAttributes = loadRequestAttributesFromWindowCache({
  initialState: windowState,
});
const initialState = localStorageUser.init(initialStateWithRequestAttributes);

const ssrAnonymousExperiments = window[WINDOW_SPLIT_TREATMENTS] || {};

const store = configureStore({ initialState });

const didCreateNewAnonymousId = anonymousId.createIfNotExists();
if (didCreateNewAnonymousId) {
  const log = new Logger('app:client');
  log.warn('New anonymousId created via client', {
    anonymousId: anonymousId.get(),
  });
}

anonymousId.syncCookieWithLocalStorage();

window.__STORE__ = store;

deviceType.initialize(window.navigator.userAgent);

const rootElement = document.getElementById('tfd-root');

createRoot(rootElement).render(
  <BrowserRouter>
    <HelmetProvider>
      <Provider store={store}>
        <ApolloProvider>
          <SplitProvider>
            <DeviceTypeInjector>
              <ScreenProvider platform={deviceType.platform}>
                <CorgiScreenProvider platform={deviceType.platform}>
                  <CorgiV2ScreenProvider platform={deviceType.platform}>
                    <ToastProvider>
                      <SSRAnonymousExperimentsProvider
                        experiments={ssrAnonymousExperiments}
                      >
                        <Application />
                      </SSRAnonymousExperimentsProvider>
                    </ToastProvider>
                  </CorgiV2ScreenProvider>
                </CorgiScreenProvider>
              </ScreenProvider>
            </DeviceTypeInjector>
          </SplitProvider>
        </ApolloProvider>
      </Provider>
    </HelmetProvider>
  </BrowserRouter>
);
