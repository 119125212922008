import { Grid, Section, useViewport } from '@farmersdog/corgi-x';

import { HeroIconItemsMobile, HeroImage, HeroTitle } from '../../components';
import { useTrackedSectionRef } from '../../../../../../hooks/useTrackedSections';
import { HomepageRedesignSections } from '../../../CtaTrackerV2';

import { heroOptimizedVariantSources } from './assets';
import styles from './OptimizedHero.module.css';

export const OptimizedHero = () => {
  const viewport = useViewport();
  const isMobile = viewport.xs && !viewport.lg;

  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.Hero);

  return (
    <>
      <Section
        aria-label="Homepage Hero"
        hSpacing={{ xs: 'md', md: 'xxl' }}
        className={styles.pageHeroWrapper}
        sectionRef={sectionRef}
      >
        <Grid className={styles.heroContent}>
          <HeroTitle />
          <HeroIconItemsMobile />
        </Grid>
      </Section>
      <HeroImage
        objectPosition={isMobile ? '50% 15%' : 'right top'}
        sources={heroOptimizedVariantSources}
      />
    </>
  );
};
