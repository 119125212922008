const HEALTH_CHECK_USER_AGENTS = [
  'Amazon-Route53-Health-Check-Service',
  'kube-probe',
  'Datadog/Synthetics',
];

const INTERNAL_SAAS_USER_AGENTS = ['Slackbot', 'FullStoryBot'];

const NETWORKING_USER_AGENTS = ['NetworkingExtension'];

const USER_AGENTS_TO_SKIP_SPLIT = [
  ...HEALTH_CHECK_USER_AGENTS,
  ...INTERNAL_SAAS_USER_AGENTS,
  ...NETWORKING_USER_AGENTS,
];

export function getShouldSkipSplitForUserAgent(
  userAgent: string | undefined
): boolean {
  return (
    !!userAgent &&
    USER_AGENTS_TO_SKIP_SPLIT.some(agent =>
      userAgent.toLowerCase().includes(agent.toLowerCase())
    )
  );
}
