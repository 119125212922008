import {
  PATH_OFFER_TERMS,
  PATH_REFERRAL_TERMS,
} from '@farmersdog/constants/paths';
import type { TextProps } from '@farmersdog/corgi-x';
import { ReferrerType } from '@farmersdog/coupons';
import { Logger } from '@farmersdog/logger';

import { FeatureName } from '../../../../utils';

import styles from './DiscountBanner.module.css';

import type { DiscountBannerInnerProps } from './DiscountBannerInner';
import type { Experiments } from '../../../../types';

interface DiscountBannerPropsWithCopyRequired extends DiscountBannerInnerProps {
  copy: string;
}

export const nameToken = '~~name~~';
export const discountAmountToken = '~~discountAmount~~';
const DEFAULT_BANNER_COPY = `Special offer: ${discountAmountToken}% off your first box!`;
const INACTIVE_SPLIT_TREATMENTS = ['off', 'control'];
const SURPRISE_DISCOUNT_PCODE = 'surprisediscount100v2hmjku';

interface BannerOverride {
  bannerCopy?: string;
  bannerLinkCopy?: string;
  bannerLinkPath?: string;
}

interface DiscountOverrides {
  globalDiscountOverride?: BannerOverride;
  tosaDiscountBannerCopyOverride?: BannerOverride;
}

interface DeriveBannerPropsArgs {
  experiments: Experiments;
  discountAmount: number;
  referrerName?: string;
  couponCode?: string;
  isRecipesPage: boolean;
  referrerType: ReferrerType | undefined;
}

export function deriveBannerProps({
  experiments,
  discountAmount,
  referrerName,
  couponCode,
  isRecipesPage,
  referrerType,
}: DeriveBannerPropsArgs): DiscountBannerInnerProps {
  if (!getAreExperimentsReady(experiments)) {
    return {};
  }

  const { globalDiscountOverride, tosaDiscountBannerCopyOverride } =
    getDiscountOverrides({ experiments });

  const { copy, linkPath, linkCopy, className, color } = getBannerProps({
    globalDiscountOverride,
    tosaDiscountBannerCopyOverride,
    couponCode,
    discountAmount,
    isRecipesPage,
    referrerType,
  });

  const bannerCopyWithTokensReplaced = replaceTokens({
    copy,
    discountAmount,
    referrerName,
  });

  return {
    copy: bannerCopyWithTokensReplaced,
    linkPath,
    linkCopy,
    className,
    color,
  };
}

interface GetBannerPropsArgs {
  globalDiscountOverride: BannerOverride | undefined;
  tosaDiscountBannerCopyOverride: BannerOverride | undefined;
  couponCode?: string;
  isRecipesPage: boolean;
  discountAmount: number;
  referrerType: ReferrerType | undefined;
}

function getBannerProps({
  globalDiscountOverride,
  tosaDiscountBannerCopyOverride,
  couponCode,
  isRecipesPage,
  discountAmount,
  referrerType,
}: GetBannerPropsArgs): DiscountBannerPropsWithCopyRequired {
  if (globalDiscountOverride) {
    return {
      copy: globalDiscountOverride.bannerCopy || DEFAULT_BANNER_COPY,
      linkPath: globalDiscountOverride.bannerLinkPath,
      linkCopy: globalDiscountOverride.bannerLinkCopy,
    };
  }

  if (couponCode === SURPRISE_DISCOUNT_PCODE) {
    return getSurpriseDiscountBannerProps({ isRecipesPage, discountAmount });
  }

  if (tosaDiscountBannerCopyOverride) {
    return {
      copy: tosaDiscountBannerCopyOverride.bannerCopy || DEFAULT_BANNER_COPY,
      linkPath: tosaDiscountBannerCopyOverride.bannerLinkPath,
      linkCopy: tosaDiscountBannerCopyOverride.bannerLinkCopy,
    };
  }

  if (discountAmount === 100 && referrerType === ReferrerType.User) {
    return {
      copy: '~~name~~ has gifted you 100% off your first box! 🎉',
      linkCopy: 'See Offer Terms',
      linkPath: PATH_REFERRAL_TERMS,
    };
  }

  return {
    copy: DEFAULT_BANNER_COPY,
    linkPath: undefined,
    linkCopy: undefined,
  };
}

export function replaceTokens({
  copy,
  discountAmount,
  referrerName,
}: {
  copy: string;
  discountAmount: number;
  referrerName?: string;
}) {
  const hasNoNameForNameToken = !referrerName && copy.includes(nameToken);
  if (hasNoNameForNameToken) {
    const log = new Logger('TOSA:DiscountBanner');
    log.warn('No name provided for name token in banner copy', {
      referrerName,
      discountAmount,
      copy,
    });

    return DEFAULT_BANNER_COPY.replace(
      discountAmountToken,
      String(discountAmount)
    );
  }

  const withDiscountAmountReplaced = copy.replace(
    discountAmountToken,
    String(discountAmount)
  );

  // We know if there's a name token, there's also a name here
  return withDiscountAmountReplaced.replace(nameToken, referrerName ?? '');
}

interface GetSurpriseDiscountBannerPropsArgs {
  isRecipesPage: boolean;
  discountAmount: number;
}
export function getSurpriseDiscountBannerProps({
  isRecipesPage,
  discountAmount,
}: GetSurpriseDiscountBannerPropsArgs): DiscountBannerPropsWithCopyRequired {
  const isFreeTrial = discountAmount === 100;
  const shouldUseSurpriseDiscountRecipePageBannerStyles =
    isRecipesPage && isFreeTrial;

  const className = shouldUseSurpriseDiscountRecipePageBannerStyles
    ? styles.surpriseDiscountBanner
    : undefined;

  const color: TextProps['color'] =
    shouldUseSurpriseDiscountRecipePageBannerStyles ? 'Kale3' : 'White';

  const copy: string = isFreeTrial
    ? 'Surprise! 🎉 Get a free first box, while supplies last. '
    : replaceTokens({ copy: DEFAULT_BANNER_COPY, discountAmount });

  const linkCopy = isFreeTrial ? 'Offer Terms' : undefined;
  const linkPath = isFreeTrial ? PATH_OFFER_TERMS : undefined;

  return {
    className,
    color,
    copy,
    linkCopy,
    linkPath,
  };
}

function getDiscountOverrides({
  experiments,
}: {
  experiments: Experiments;
}): DiscountOverrides {
  // Global Discount Override
  const {
    config: globalDiscountExperimentConfig,
    treatment: globalDiscountTreatment,
  } = experiments[FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE] || {};

  const isGlobalDiscountOverrideOn = !INACTIVE_SPLIT_TREATMENTS.includes(
    globalDiscountTreatment ?? ''
  );

  const globalDiscountOverride = isGlobalDiscountOverrideOn
    ? (globalDiscountExperimentConfig as BannerOverride)
    : undefined;

  // Tosa Discount Banner Copy Override
  const {
    config: tosaDiscountBannerCopyExperimentConfig,
    treatment: tosaDiscountBannerCopyTreatment,
  } = experiments[FeatureName.CVR_TOSA_DISCOUNT_BANNER_COPY] || {};

  const isTosaDiscountBannerCopyOverrideOn =
    !INACTIVE_SPLIT_TREATMENTS.includes(tosaDiscountBannerCopyTreatment ?? '');

  const tosaDiscountBannerCopyOverride = isTosaDiscountBannerCopyOverrideOn
    ? (tosaDiscountBannerCopyExperimentConfig as BannerOverride)
    : undefined;

  return { globalDiscountOverride, tosaDiscountBannerCopyOverride };
}

function getAreExperimentsReady(experiments: Experiments) {
  return (
    experiments &&
    Object.values(experiments).length > 0 &&
    Object.values(experiments).some(experiment => experiment?.isReady)
  );
}
