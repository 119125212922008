import eventNames from 'src/analytics/eventNames';
import track from 'src/analytics/track';
import { SubscriptionType } from 'src/graphql/types';

/**
 * A tracking event that is used to mark a customer clicking the Signup button from the DIY homepage.
 *
 * @param requestAttributes - `requestAttributes` from the redux store, includes things like IP address and utm_source
 */

export interface TrackDiyHomepageSuccessArgs {
  requestAttributes: Record<string, unknown>;
}

export function trackDiyHomepageSuccess({
  requestAttributes,
}: TrackDiyHomepageSuccessArgs) {
  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Homepage+-+Success
  track(eventNames.homepage_success, {
    ...requestAttributes,
    product_line: SubscriptionType.Diy,
    label: SubscriptionType.Diy,
  });
}
