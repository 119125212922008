import {
  WeightManagementMobileSources,
  PickyEatingMobileSources,
  SeniorCareMobileSources,
  PuppyCareMobileSources,
} from './assets/drawer';

import {
  PickyEatingSources,
  PuppyCareSources,
  SeniorCareSources,
  WeightManagementSources,
} from './assets/modal';

export enum BenefitCardVariant {
  WeightManagement = 'Weight Management',
  PickyEating = 'Picky Eating',
  SeniorCare = 'Senior Care',
  PuppyCare = 'Puppy Care',
}

export interface Sources {
  webP: string;
  jpg: string;
}

export interface BenefitInformation {
  sources: Sources;
  mobileSources: Sources;
  title: string;
  benefits: string[];
  testimonial: {
    quote: string;
    author: string;
  };
}

//TODO: add the images for the other benefits

const weightManagement: BenefitInformation = {
  sources: {
    webP: WeightManagementSources.webP,
    jpg: WeightManagementSources.jpg,
  },
  mobileSources: {
    webP: WeightManagementMobileSources.webP,
    jpg: WeightManagementMobileSources.jpg,
  },
  title: 'Fresh pre-portioned food helps dogs maintain a healthy weight.',
  benefits: [
    'Can support longevity—dogs at a healthy weight can live up to 2.5 years longer.',
    'Can help support healthy joints.',
    'Can also increase your dog’s energy and activity levels.',
  ],
  testimonial: {
    quote:
      'Emmett got down to his ideal body weight and regained his puppy-like disposition. I want my best friend’s remaining years to be meaningful, pain free, and packed full of fun and I truly believe The Farmer’s Dog has given Emmett more time on this earth to do so.',
    author: 'Emmett’s person',
  },
};

const pickyEating: BenefitInformation = {
  sources: {
    webP: PickyEatingSources.webP,
    jpg: PickyEatingSources.jpg,
  },
  mobileSources: {
    webP: PickyEatingMobileSources.webP,
    jpg: PickyEatingMobileSources.jpg,
  },
  title: 'Still searching for the right food for your picky dog?',
  benefits: [
    'Our food is gently cooked to make it highly palatable and easy to digest.',
    'Picky dogs love the taste of our food.',
    'Real humans are standing by to support 24/7.',
  ],
  testimonial: {
    quote:
      'She has never been ready to eat, it was always a struggle. Now, Allie is right there to eat when her bowl touches the floor.',
    author: 'Allie’s person',
  },
};

const seniorCare: BenefitInformation = {
  sources: {
    webP: SeniorCareSources.webP,
    jpg: SeniorCareSources.jpg,
  },
  mobileSources: {
    webP: SeniorCareMobileSources.webP,
    jpg: SeniorCareMobileSources.jpg,
  },
  title:
    'Our food is soft, easy on the digestive system, and loaded with benefits for senior dogs.',
  benefits: [
    'Can help support healthy joints.',
    'Can aid in maintaining muscle mass by providing high-quality protein.',
    'Can contribute to an increase in activity levels.',
  ],
  testimonial: {
    quote:
      'At 14 we didn’t expect her to change much...within a couple weeks we noticed her skin had improved and she began to play more.',
    author: 'Lucy’s person',
  },
};

const puppyCare: BenefitInformation = {
  sources: {
    webP: PuppyCareSources.webP,
    jpg: PuppyCareSources.jpg,
  },
  mobileSources: {
    webP: PuppyCareMobileSources.webP,
    jpg: PuppyCareMobileSources.jpg,
  },
  title: 'A fresh food plan that grows with your growing puppy.',
  benefits: [
    'Complete and balanced to help support immunity.',
    'Help maintaining ideal weight during growth and adulthood.',
    'Plans you can easily adjust to fit their growing needs.',
  ],
  testimonial: {
    quote:
      'Thor is 7 months old. Quick benefits transitioning to The Farmer’s Dog, his poops becoming solid, having more energy and his hair/fur seems more vibrant.',
    author: 'Thor’s person',
  },
};

export const benefitDataMap = {
  [BenefitCardVariant.WeightManagement]: weightManagement,
  [BenefitCardVariant.PickyEating]: pickyEating,
  [BenefitCardVariant.SeniorCare]: seniorCare,
  [BenefitCardVariant.PuppyCare]: puppyCare,
};
