import { useDispatch } from 'react-redux';

import { useFeature } from 'src/abTesting';
import { PLX_CHEWS_ENABLED } from 'src/abTesting/features';
import { logout } from 'src/actions/auth';
import { useCorgiViewport } from 'src/screen';

import { trackLogoutFrontendSuccess } from '@/account/app/analytics';
import type { UseCustomerAccountData } from '@/account/app/hooks/useCustomerAccountData';
import { useDisplayDesktopNav } from '@/account/app/hooks/useDisplayDesktopNav';
import { getNavigation } from '@/account/app/utils/getNavigation/getNavigation';

export function useCustomerAccountNavigation(data: UseCustomerAccountData) {
  const dispatch = useDispatch();
  const showDesktopNav = useDisplayDesktopNav();

  const isMobile = useCorgiViewport({
    xs: true,
    lg: !showDesktopNav,
  });

  const showTreatsAndChews = useFeature(PLX_CHEWS_ENABLED).treatment === 'on';

  const callLogout = () => {
    dispatch(logout());
    trackLogoutFrontendSuccess();
  };

  return getNavigation(data, callLogout, {
    isMobile,
    showTreatsAndChews,
  });
}
