import { getSnapchatScript } from '@farmersdog/pixels';

import { SdkLoaderWithFallbackScript } from 'src/components/Script/SdkLoaderWithFallbackScript';
import { PIXEL_IDS } from './settings';

const { sdkLoader, fallbackScript } = getSnapchatScript(PIXEL_IDS);

export const SnapchatScript = () => {
  return (
    <SdkLoaderWithFallbackScript
      sdkLoader={sdkLoader}
      fallbackScript={fallbackScript}
    />
  );
};
