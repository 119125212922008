import {
  mobileLovedByHumans,
  desktopLovedByHumans,
} from '../../../../../AssetPreloader/assets/loved-by-humans';

import type { MobileAndDesktopSources } from '../../../../../AssetPreloader/assets/shared';

interface HeroConfigReturn {
  title: JSX.Element;
  subtitle: JSX.Element;
  assets: MobileAndDesktopSources;
  alt: string;
  lowerBackground: boolean;
  cta: string;
}

const lovedByHumansTitle = (
  <>
    Supported by vets.
    <br />
    Loved by dog people.
  </>
);

const lovedByHumansSubtitle = (
  <>
    Gently cooked fresh food formulated by
    <br />
    board-certified nutritionists.
  </>
);

const lovedByHumans = {
  title: lovedByHumansTitle,
  subtitle: lovedByHumansSubtitle,
};

export const LovedByHumansConfig: HeroConfigReturn = {
  title: lovedByHumans.title,
  subtitle: lovedByHumans.subtitle,
  assets: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  alt: 'Dog looking at a bowl of food',
  lowerBackground: true,
  cta: 'Shop Fresh',
};

// ########################################################

export const CtaOnly50OffConfig: HeroConfigReturn = {
  ...LovedByHumansConfig,
  cta: 'Get 50% Off',
};

export const CtaOnlySeePlansAndPricingConfig: HeroConfigReturn = {
  ...LovedByHumansConfig,
  cta: 'See Plans & Pricing',
};
