import { track as analyticsPackageTrack } from '@farmersdog/analytics';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

import sharedProperties from './sharedAttributes';

const log = new Logger('app:analytics');

export type TrackEventProperties =
  | Record<string, unknown>
  | Record<string, unknown>[];

/**
 * Track an event via analytics.
 *
 * This function is a wrapper of Segment's `track()`: see
 * https://segment.com/docs/spec/track/ for more details.
 *
 * @param event - Name of the action that a user has performed.
 * @param eventProperties - Free-form dictionary of properties
 * @param eventContext - A not-so-clear argument that populates the
 *                          `context` object in Segment's payload. It is used by
 *                          Impact Radius event.
 */
function track<T extends TrackEventProperties>(
  event: string,
  eventProperties?: T,
  eventContext?: Record<string, unknown>
) {
  // Add app version to both properties and context
  const properties = {
    ...eventProperties,
    ...sharedProperties,
  };

  log.debug(`Tracking event "${event}" with properties`, properties);

  const page = browserOnly(
    window => ({ path: window.location.pathname }),
    () => undefined
  );

  const context = {
    ...eventContext,
    app: { version: String(sharedProperties.appVersion) },
    page,
  };

  const logUndefinedTrackFunction = () => {
    log.debug(
      `Could not track event because 'analytics.track' is not defined`,
      {
        event,
      }
    );
  };

  analyticsPackageTrack(event, properties, context, logUndefinedTrackFunction);
}

export default track;
