import type { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import type { ValidationError } from 'src/errors';

// Actions
export enum ActionType {
  SetCardHolder = 'SIGNUP_SET_CARD_HOLDER',
  SetBillingAsShipping = 'SIGNUP_SET_BILLING_AS_SHIPPING',
  SetValidationError = 'SIGNUP_SET_VALIDATION_ERROR',
  SetChangedStartDate = 'SIGNUP_SET_CHANGED_START_DATE',
}

export interface SetCardHolderAction {
  type: ActionType.SetCardHolder;
  payload: string;
}
export interface SetBillingAsShippingAction {
  type: ActionType.SetBillingAsShipping;
  payload: boolean;
}
export interface SetValidationErrorAction {
  type: ActionType.SetValidationError;
  payload: Partial<CheckoutValidationErrors>;
}
export interface SetChangedStartDateAction {
  type: ActionType.SetChangedStartDate;
  payload: string | null;
}

export interface CheckoutValidationErrors {
  userAccount: Array<ValidationError> | null;
  shippingAddress: Array<ValidationError> | null;
  billingAddress: Array<ValidationError> | null;
  card: Array<ValidationError> | null;
  startDate: ValidationError | null;
  hasAcceptedTermsAndPrivacyPolicy: ValidationError | null;
}

export interface CheckoutState {
  billingAsShipping: boolean | null;
  cardHolder: string | null;
  changedStartDate: string | null;
  validationErrors: CheckoutValidationErrors;
}

export interface HandleStripeTokenEvent {
  shippingAddress: {
    addressLine: Array<string>;
    postalCode: string;
    city: string;
    region: string;
  };

  token: {
    id: PaymentRequestTokenEvent['token']['id'];
    card: {
      address_line1: string;
      address_line2: string;
      address_zip: string;
      address_city: string;
      address_state: string;
    };
  };
  payerPhone: string;
  payerName: string;
  walletName: PaymentRequestTokenEvent['walletName'];
  complete: PaymentRequestTokenEvent['complete'];
}
