import {
  CgsChewLength,
  CgsChewSize,
  SnapsTreatProtein,
  SnapsTreatSize,
} from '@/account/graphql/cgs/types';

import webpChewsLonger from './chews-longer-in-the-box.webp';
import webpChewsShorter from './chews-shorter-in-the-box.webp';
import webpChickenRegular from './snaps-chicken-in-the-box.webp';
import webpChickenLarge from './snaps-chicken-large-in-the-box.webp';
import webpPorkRegular from './snaps-pork-in-the-box.webp';
import webpPorkLarge from './snaps-pork-large-in-the-box.webp';
import webpTurkeyRegular from './snaps-turkey-in-the-box.webp';
import webpTurkeyLarge from './snaps-turkey-large-in-the-box.webp';

/**
 * protein
 */
type InTheBoxSources = Record<SnapsTreatProtein, string[]>;

/**
 * packageSize
 */
type ImageSourceByPackageSize = Record<SnapsTreatSize, InTheBoxSources>;

export const inTheBoxAssets: ImageSourceByPackageSize = {
  [SnapsTreatSize.Regular]: {
    [SnapsTreatProtein.Chicken]: [webpChickenRegular],
    [SnapsTreatProtein.Pork]: [webpPorkRegular],
    [SnapsTreatProtein.Turkey]: [webpTurkeyRegular],
  },
  [SnapsTreatSize.Large]: {
    [SnapsTreatProtein.Chicken]: [webpChickenLarge],
    [SnapsTreatProtein.Pork]: [webpPorkLarge],
    [SnapsTreatProtein.Turkey]: [webpTurkeyLarge],
  },
};

type ChewsInTheBoxSources = Record<CgsChewLength, string[]>;

type ChewsImageSourceByPackageSize = Record<CgsChewSize, ChewsInTheBoxSources>;

export const chewsInTheBoxAssets: ChewsImageSourceByPackageSize = {
  [CgsChewSize.Regular]: {
    [CgsChewLength.Shorter]: [webpChewsShorter],
    [CgsChewLength.Longer]: [webpChewsLonger],
  },
};
