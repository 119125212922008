import { track } from 'src/analytics';
import eventNames from '../eventNames';

export interface TrackModuleViewedArgs {
  moduleLevel: 1 | 2;
  moduleName: string;
  moduleIndex?: number;
  moduleLocation?: number;
}

export function trackModuleViewed({
  moduleLevel,
  moduleName,
  moduleIndex,
  moduleLocation,
}: TrackModuleViewedArgs) {
  track(eventNames.module_viewed, {
    moduleLevel,
    moduleName,
    moduleIndex,
    moduleLocation,
  });
}
