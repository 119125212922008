import { useCallback, useMemo } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { useSignupUpdatedAssets } from '../../..//hooks';
import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupCardSelectors } from '../../../hooks/experiments/useSignupCardsSelectors';
import { useLabel } from '../../../hooks/useLabel';
import { RadioSelectorGroup } from '../base';
import { CardRadioGroup } from '../base/CardRadioGroup';
import { getIconAltTag } from '../base/RadioSelectorGroup/utils';

import {
  eatingStyleAssets,
  EatingStyleAssets,
  eatingStyleNewAssets,
  cardEatingStyleAssets,
} from './assets';
import styles from './EatingStyleInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { Value } from '../base/CardRadioGroup';

export function EatingStyleInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature } = props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const eatingStyleField = `${NodeNames.EatingStyle}-${position}` as const;
  const currentValue = getValues(eatingStyleField) ?? null;
  const { frontLabel } = useLabel({ node, getValues });

  const options =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });
  const { shouldShowSignupCardSelectors } = useSignupCardSelectors({
    useFeature,
  });

  const imageAssets = isSignupUpdatedAssetsOn
    ? eatingStyleNewAssets
    : eatingStyleAssets;

  const cardGroupOptions = useMemo(() => {
    return (
      node.input?.options?.map(({ name, value, description }, index) => ({
        heading: name,
        value,
        description,
        imageSources: cardEatingStyleAssets.large[index],
        imageAlt: getIconAltTag(value),
      })) ?? []
    );
  }, [node.input?.options]);

  const cardGroupOnChange = useCallback(
    (value: Value) => {
      const event = {
        target: {
          name: node.name,
          value,
        },
      };
      void registerProps.onChange(event);
    },
    [registerProps, node.name]
  );

  return (
    <>
      <span>{frontLabel}</span>
      {shouldShowSignupCardSelectors ? (
        <div className={styles.wrapper}>
          <CardRadioGroup
            options={cardGroupOptions}
            name={registerProps.name}
            currentValue={currentValue}
            onChange={cardGroupOnChange}
            groupDescription="Select the eating style closest to your dog's."
          />
        </div>
      ) : (
        <RadioSelectorGroup
          registerProps={registerProps}
          options={options}
          icons={imageAssets}
          defaultMobileIconIndex={EatingStyleAssets.GoodEater}
          legend="Eating Style"
          currentValue={currentValue}
          defaultDescription={
            currentValue ? '' : "Select the eating style closest to your dog's."
          }
        />
      )}
    </>
  );
}
