import config from 'src/config';

import { local } from 'src/utils/browserStorage';
import calculateProgress from 'src/reducers/signup/utils/calculateProgress';

const buildNumberKey = '__website_user_validation__';
const userKey = '__website_user_object__';
const currentBuild = config('app.build');

const clear = () => {
  local.removeItem(userKey);
  local.removeItem(buildNumberKey);
};

const get = () => {
  const prevBuild = local.getItem(buildNumberKey);

  if (currentBuild !== prevBuild) {
    clear();
    return undefined;
  }

  const storedUser = local.getItem(userKey);

  try {
    return JSON.parse(storedUser);
  } catch {
    clear();
    return undefined;
  }
};

const init = initialState => {
  const storedUser = get();

  if (!storedUser) {
    return initialState;
  }

  const initalObj = { ...initialState };
  initalObj.signup.user = storedUser;
  initalObj.signup.pets.numberOfPets = storedUser.pets.length;
  initalObj.signup.progress = calculateProgress(initalObj.signup);

  return initalObj;
};

const set = user => {
  try {
    const stringified = JSON.stringify(user);
    local.setItem(userKey, stringified);
    local.setItem(buildNumberKey, currentBuild);
  } catch {
    clear();
  }
};

export default { init, get, set, clear };
