import {
  beefAndCarrots,
  beefAndRice,
  beefAndSweetPotato,
  beefAndBeefLiver,
  beefAndRiceForPuppies,
  chickenAndRice,
  chickenAndVegetables,
  chickenAndRiceForPuppies,
  chickenAndVegetablesForPuppies,
  chickenRiceAndChickpeas,
  chickenCouscousAndApples,
  chickenCouscousAndApplesForPuppies,
  lowFatBeefAndSweetPotato,
  lowFatChickenAndRice,
  lowFatTilapiaAndSweetPotato,
  porkSweetPotatoAndRice,
  porkRiceAndVegetablesForPuppies,
  tilapiaRiceAndVegetables,
  turkeyAndChickpeas,
  turkeyAndRice,
  turkeyAndRiceForPuppies,
  turkeyAndVegetables,
  turkeyAndSweetPotatoes,
  turkeyAndVegetablesForPuppies,
} from './recipes';
import type { DiyRecipe } from './types';

export const launchRecipes: DiyRecipe[] = [
  beefAndCarrots,
  beefAndRice,
  beefAndSweetPotato,
  beefAndBeefLiver,
  beefAndRiceForPuppies,
  chickenAndRice,
  chickenAndVegetables,
  chickenAndRiceForPuppies,
  chickenAndVegetablesForPuppies,
  chickenRiceAndChickpeas,
  chickenCouscousAndApples,
  chickenCouscousAndApplesForPuppies,
  turkeyAndRice,
  turkeyAndVegetables,
  turkeyAndChickpeas,
  turkeyAndSweetPotatoes,
  turkeyAndVegetablesForPuppies,
  turkeyAndRiceForPuppies,
  porkSweetPotatoAndRice,
  porkRiceAndVegetablesForPuppies,
  tilapiaRiceAndVegetables,
  lowFatBeefAndSweetPotato,
  lowFatChickenAndRice,
  lowFatTilapiaAndSweetPotato,
];

export const getAllRecipes = () => launchRecipes;
