import type { DiyRecipe } from '../types';
import { PrimaryProtein, RecipesCategory } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const lowFatTilapiaAndSweetPotato: DiyRecipe = {
  name: 'Low-Fat Tilapia & Sweet Potato',
  id: 'low-fat-tilapia-&-sweet-potato',
  primaryProtein: PrimaryProtein.Tilapia,
  category: RecipesCategory.LowFat,
  ingredients: [
    {
      name: 'tilapia fillets (skinless)',
      quantity: 1000,
      unit: 'g',
      alternativeQuantity: 2.2,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 700,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'lb',
    },
    {
      name: 'cooked chickpeas',
      quantity: 625,
      unit: 'g',
      alternativeQuantity: 1.4,
      alternativeUnit: 'lb',
    },
    {
      name: 'spinach, chopped',
      quantity: 175,
      unit: 'g',
      alternativeQuantity: 0.39,
      alternativeUnit: 'lb',
    },
    {
      name: 'cauliflower, cut into 1/4 inch dice',
      quantity: 175,
      unit: 'g',
      alternativeQuantity: 0.39,
      alternativeUnit: 'lb',
    },
    {
      name: 'canned pumpkin, without salt',
      quantity: 123,
      unit: 'g',
      alternativeQuantity: 0.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'salmon oil',
      quantity: 6.8,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'sunflower oil',
      quantity: 6.8,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  cookTime: '40 mins',
  ingredientSummary:
    'Tilapia Fillets, Sweet Potato, Chickpeas, Spinach, Cauliflower, Canned Pumpkin, DIY Nutrient Mix, Salmon Oil, Sunflower oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Place the tilapia fillets in the pot and cook, flipping periodically. Break into smaller pieces and cook until the tilapia is totally opaque.  Remove tilapia from the pot, double check and remove any remaining pin bones, and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Add the sweet potato, chickpeas, spinach, cauliflower, sunflower oil, and canned pumpkin. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 3',
      description: 'Add the tilapia to the pot and stir to evenly distribute.',
    },
    {
      name: 'Step 4',
      description:
        'Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 145°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 5',
      description:
        'Remove the pot from the heat and add salmon oil. Then, sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
};
