import { useCallback, useEffect, useRef, useState } from 'react';

import { browserOnly } from '@farmersdog/utils';

import { useSafeOptions } from '@farmersdog/corgi-x';
import type {
  NativeWistiaOptions,
  NativeWistiaPlayer,
  WistiaEventHandlers,
  WistiaPlayer,
  WistiaWindow,
} from './types';
import './wistia-overrides.css';
import {
  trackVideoCompleted,
  trackVideoPaused,
  trackVideoPlayed,
} from './tracking';

/**
 * Initializes the Wistia API and returns a Wistia Player instance. To display
 * the video, use a `WistiaElement` in the page to display the video.
 */
export function useWistiaPlayer(
  /** The Wistia ID of the video to load (from the video URL) */
  wistiaId: string,
  /** Options to pass to the Wistia API */
  options: NativeWistiaOptions = {},
  /** Event handlers to register with the Wistia API */
  handlers: WistiaEventHandlers = {},
  /** The page module the video is rendered in */
  moduleName: string,
  /** The video's title as displayed on the page */
  videoTag?: string
): WistiaPlayer {
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoName, setVideoName] = useState<string | undefined>();
  const [wistia, setWistia] = useState<NativeWistiaPlayer | undefined>();

  // Ensures that the options are safe to pass to the Wistia API
  // If these options changes after initialization, the Wistia API will not work.
  const safeOptions = useSafeOptions<NativeWistiaOptions>(options);
  const safeHandlers = useSafeOptions<WistiaEventHandlers>(handlers);

  /**
   * Used to fix an accessibility issue when a dialog element is shown before
   * the video is playing
   *
   * @see https://app.shortcut.com/farmersdog/story/121472
   */
  const getWistiaDialogElement = useCallback(() => {
    return document.getElementById(
      `wistia-${wistiaId}-1_popover_container`
    ) as HTMLDialogElement;
  }, [wistiaId]);

  // Stores the event handlers in a ref to avoid re-rendering
  const refCallbacks = useRef({ onReady: safeHandlers?.onReady });
  useEffect(() => {
    refCallbacks.current = { onReady: safeHandlers?.onReady };
  }, [safeHandlers?.onReady, wistiaId]);

  // Initializes the Wistia API
  // See https://wistia.com/support/developers/async-embeds#using-the-wistia-library-asynchronously
  useEffect(() => {
    return browserOnly(window => {
      const wistiaWindow = window as WistiaWindow;

      wistiaWindow.wistiaInitQueue = wistiaWindow.wistiaInitQueue ?? [];
      wistiaWindow.wistiaInitQueue.push(
        (wistiaPlayerAPI: NativeWistiaPlayer) => {
          // Options must be applied to wistia pre embed
          wistiaPlayerAPI.options(wistiaId, {
            preload: 'none',
            playSuspendedOffScreen: true,
            playsinline: false,
            controlsVisibleOnLoad: false,
            ...safeOptions,
            // The following options are hard coded to support customizations
            playButton: false,
          });
        }
      );

      wistiaWindow._wq = wistiaWindow._wq ?? [];
      const queue = {
        id: wistiaId,
        onReady: (api: NativeWistiaPlayer) => {
          setWistia(api);
          if (api.state() !== 'playing' && options.popover) {
            getWistiaDialogElement()?.setAttribute('aria-hidden', 'true');
          }
          setVideoName(api.name());
          setIsLoading(false);
          setIsPlaying(api.state() === 'playing');

          refCallbacks.current?.onReady?.(api);
        },
      };
      wistiaWindow._wq.push(queue);
      wistiaWindow._wq.push({
        id: '_all',
        onReady: video => {
          video.bind('play', () => {
            const allVideos = wistiaWindow.Wistia.api.all();
            for (let i = 0; i < allVideos.length; i++) {
              if (allVideos[i].hashedId() !== video.hashedId()) {
                allVideos[i].pause();
              }
            }
          });
        },
      });
    });
  }, [
    getWistiaDialogElement,
    options.popover,
    refCallbacks,
    safeOptions,
    wistiaId,
  ]);

  // Registers playback event callbacks
  useEffect(() => {
    if (!wistia) {
      return;
    }

    const trackArgs = [{ moduleName, videoTag: videoTag }, wistia] as const;

    const handlePlay = () => {
      trackVideoPlayed(...trackArgs);
      setIsPlaying(true);
      safeHandlers.onPlay?.(wistia);
    };

    const handlePause = () => {
      trackVideoPaused(...trackArgs);
      setIsPlaying(false);
      safeHandlers.onPause?.(wistia);
    };

    const handleEnd = () => {
      trackVideoCompleted(...trackArgs);
      setIsPlaying(false);
      safeHandlers.onEnd?.(wistia);
    };

    const handlePopoverShow = () => {
      getWistiaDialogElement()?.setAttribute('aria-hidden', 'false');
    };
    const handlePopoverHide = () => {
      getWistiaDialogElement()?.setAttribute('aria-hidden', 'true');
    };

    wistia.bind('play', handlePlay);
    wistia.bind('pause', handlePause);
    wistia.bind('end', handleEnd);
    wistia.bind('popovershow', handlePopoverShow);
    wistia.bind('popoverhide', handlePopoverHide);

    return () => {
      wistia.unbind('play', handlePlay);
      wistia.unbind('pause', handlePause);
      wistia.unbind('end', handleEnd);
      wistia.unbind('popovershow', handlePopoverShow);
      wistia.unbind('popoverhide', handlePopoverHide);
    };
  }, [
    wistia,
    safeHandlers,
    videoName,
    wistiaId,
    getWistiaDialogElement,
    moduleName,
    videoTag,
  ]);

  const wistiaPlayer: WistiaPlayer = {
    isLoading,
    isPlaying,
    videoName,
    play: () => wistia?.play(),
    pause: () => wistia?.pause(),
    time: (seconds: number) => wistia?.time(seconds),
  };
  return wistiaPlayer;
}
