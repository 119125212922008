import {
  PreloadLink,
  PreloadLinkAs,
} from '@farmersdog/tosa/src/components/shared/PreloadLink';

import { useHomepageHeroExperiment } from 'src/hooks/useHomepageHeroExperiment';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { generateSourcesForPreloader } from 'src/pages/Home/components/AssetPreloader/assets/shared';

import { getSourcesForHomepageHero } from './getSourcesForHomepage';

export const AssetPreloader = () => {
  const { treatment: homepageRedesignTreatment } = useShowHomepageRedesign();
  const { treatment: homepageHeroTreatment } = useHomepageHeroExperiment();
  const { desktopSources, mobileSources } = getSourcesForHomepageHero({
    homepageHeroTreatment,
    homepageRedesignTreatment,
  });
  const assetsForPreloader = generateSourcesForPreloader({
    mobileSources,
    desktopSources,
  });

  return (
    <>
      {assetsForPreloader.map(({ imageSrcSet, media }) => (
        <PreloadLink
          key={imageSrcSet}
          rel="preload"
          as={PreloadLinkAs.Image}
          imageSrcSet={imageSrcSet}
          media={media}
        />
      ))}
    </>
  );
};
