import {
  Turnstile,
  type TurnstileProps as TurnstileLibraryProps,
  type TurnstileInstance,
} from '@marsidev/react-turnstile';
import classNames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  type MutableRefObject,
} from 'react';

import { track } from '@farmersdog/analytics';
import { Logger } from '@farmersdog/logger';

import type AnalyticsEvents from 'src/analytics/eventNames';

import {
  getCaptchaResponseFromErrorCode,
  getTurnstileSiteKey,
} from '@/account/app/components/Turnstile/utils';

import styles from './TurnstileWidget.module.css';

type AnalyticsEvent = {
  [K in keyof typeof AnalyticsEvents]: (typeof AnalyticsEvents)[K];
}[keyof typeof AnalyticsEvents];

export type CaptchaResponse = {
  message?: string;
  pass: boolean;
};

export enum TurnstileAction {
  LOGIN = 'login',
  REQUEST_PASSWORD_RESET = 'request-password-reset',
}

export enum TurnstileState {
  EXPIRED = 'expired',
  FAILED = 'failed',
  PASSED = 'passed',
  UNKNOWN = 'unknown',
}

export type TurnstileProps = {
  /** The action code to send when verifying the request with Turnstile. */
  action: TurnstileAction;
  /** If true, the starting mode for the widget to interactive. */
  isInteractiveChallenge: boolean;
  /** A scoped state assignment method to pass the interactive state to the wrapping component. */
  setIsInteractiveChallenge: (value: boolean) => void;
  /** A scoped state assignment method to pass the error message to the wrapping component. */
  setCaptchaError: (error?: string) => boolean | void;
  /** A scoped state assignment method to pass the current widget state to the wrapping component. */
  setCaptchaState: (state: TurnstileState) => void;
  /** A scoped state assignment method to pass the authorization token to the wrapping component. */
  setCaptchaToken: (token: string) => void;
  /** A method called during the event handler for a successful response, after the state updates have been stored. */
  onSuccess?: () => void;
  /** An event handler called due to an error response, after the state updates have been stored. Only called if the widget cannot be reset, or it has reached maximum retries. */
  onError?: (code: string) => void;
  /** An event handler called if the authorization token expires before being used by the form. */
  onExpired?: () => void;
  /** An event handler called if the token validating the interactive challenge times out due to user inactivity. */
  onTimeout?: () => void;
  /** An event handler called if the widget enters interactive mode. */
  onEnterInteractive?: () => void;
  /** An event handler called if the widget leaves interactive mode. */
  onLeaveInteractive?: () => void;
  /** An analytics event to track after a successful response. */
  successEvent?: AnalyticsEvent;
  /** An analytics event to track after an error response. */
  errorEvent?: AnalyticsEvent;
  /** An analytics event to track after an authorization token expires. */
  expiredEvent?: AnalyticsEvent;
  /** An analytics event to track after a challenge times out. */
  timeoutEvent?: AnalyticsEvent;
  /** An analytics event to track after the mode changes to interactive. */
  enterInteractiveEvent?: AnalyticsEvent;
  /** An analytics event to track after the mode changes away from interactive. */
  leaveInteractiveEvent?: AnalyticsEvent;
};

const noop = () => void 0;
export const TURNSTILE_ATTEMPTS_COUNT_MAX = 3;
/**
 * Render the Turnstile library component
 */
export const TurnstileWidget = forwardRef<TurnstileInstance, TurnstileProps>(
  (
    {
      action,
      isInteractiveChallenge,
      setIsInteractiveChallenge,
      setCaptchaError,
      setCaptchaState,
      setCaptchaToken,
      onSuccess = noop,
      onError = noop,
      onExpired = noop,
      onTimeout = noop,
      onEnterInteractive = noop,
      onLeaveInteractive = noop,
      successEvent,
      errorEvent,
      timeoutEvent,
      expiredEvent,
      enterInteractiveEvent,
      leaveInteractiveEvent,
    },
    ref
  ) => {
    const SITE_KEY = getTurnstileSiteKey();
    const turnstileInstanceRef = ref as MutableRefObject<TurnstileInstance>;
    const logger = useMemo(
      () => new Logger('website:login:TurnstileWidget'),
      []
    );

    const attemptCount = useRef(1);

    const renderOptions: TurnstileLibraryProps['options'] = {
      action,
      appearance: 'interaction-only',
      theme: 'light',
    };

    useEffect(() => {
      if (!SITE_KEY || SITE_KEY.length === 0) {
        setCaptchaToken('');
        setCaptchaState(TurnstileState.UNKNOWN);
        setCaptchaError();
        logger.error('Invalid turnstile site key');
        return;
      }
    }, [setCaptchaToken, setCaptchaState, setCaptchaError, SITE_KEY, logger]);

    const trackTrigger = useCallback((eventName?: AnalyticsEvent) => {
      if (eventName) {
        track(eventName);
      }
    }, []);

    const handleSuccess = useCallback(
      (token: string) => {
        setCaptchaToken(token);
        setCaptchaState(TurnstileState.PASSED);
        setCaptchaError();

        onSuccess();
        trackTrigger(successEvent);
      },
      [
        setCaptchaError,
        setCaptchaState,
        setCaptchaToken,
        onSuccess,
        trackTrigger,
        successEvent,
      ]
    );

    const handleError = useCallback(
      (code: string = '') => {
        const { message, retry, reset } = getCaptchaResponseFromErrorCode(code);
        if (reset) {
          turnstileInstanceRef?.current?.reset();
          return;
        }

        if (retry && attemptCount.current < TURNSTILE_ATTEMPTS_COUNT_MAX) {
          attemptCount.current = (attemptCount.current ?? 1) + 1;
          turnstileInstanceRef?.current?.reset();
          return;
        }

        setCaptchaState(TurnstileState.FAILED);
        setCaptchaError(message);
        logger.error('Failed captcha challenge', {
          code,
          message,
        });

        onError(code);
        trackTrigger(errorEvent);
      },
      [
        turnstileInstanceRef,
        logger,
        onError,
        setCaptchaError,
        setCaptchaState,
        trackTrigger,
        errorEvent,
      ]
    );

    const handleExpired = useCallback(() => {
      onExpired();
      trackTrigger(expiredEvent);
    }, [onExpired, trackTrigger, expiredEvent]);

    const handleTimeout = useCallback(() => {
      onTimeout();
      trackTrigger(timeoutEvent);
    }, [onTimeout, trackTrigger, timeoutEvent]);

    const handleEnterInteractiveChallengeMode = useCallback(() => {
      setIsInteractiveChallenge(true);

      onEnterInteractive();
      trackTrigger(enterInteractiveEvent);
    }, [
      setIsInteractiveChallenge,
      onEnterInteractive,
      trackTrigger,
      enterInteractiveEvent,
    ]);

    const handleLeaveInteractiveChallengeMode = useCallback(() => {
      onLeaveInteractive();
      trackTrigger(leaveInteractiveEvent);
    }, [onLeaveInteractive, trackTrigger, leaveInteractiveEvent]);

    return (
      <Turnstile
        className={
          isInteractiveChallenge
            ? classNames(styles.turnstileWidget, styles.interactive)
            : styles.turnstileWidget
        }
        data-testid="turnstile-library-component"
        options={renderOptions}
        ref={turnstileInstanceRef}
        siteKey={SITE_KEY}
        onAfterInteractive={handleLeaveInteractiveChallengeMode}
        onBeforeInteractive={handleEnterInteractiveChallengeMode}
        onTimeout={handleTimeout}
        onSuccess={handleSuccess}
        onError={handleError}
        onExpire={handleExpired}
      />
    );
  }
);
