import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';

import { useToast } from '@farmersdog/corgi';
import { ContentLoader } from '@farmersdog/corgi-x';
import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';

import {
  trackRecipesClickBackToRecommendedPlan,
  trackRecipesClickContinueWithRecommendation,
  trackRecipesClickSelectYourOwnPlan,
} from '../../analytics';
import { standardBlueprintVersion } from '../../blueprint';
import { useRegisterLead, useUpdateLead } from '../../graphql/mutations';
import {
  type FetchLeadQuery,
  type FetchQuoteQuery,
  type PetProductFreshSelectionOptionsInput,
} from '../../graphql/types';
import {
  useSurpriseHigherDiscountExperiment,
  useThrowToErrorBoundary,
} from '../../hooks';
import { useHandleRecipesCompleted } from '../../hooks/useHandleRecipesCompleted';
import { getDiscountInput } from '../../hooks/useLeadState/utils/convertFormStateToLeadInput/getDiscountInput';
import { getProductLineInput } from '../../hooks/useLeadState/utils/convertFormStateToLeadInput/getProductLineInput';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import { scroller } from '../../utils';
import { handleStepCompleted } from '../../utils/handleStepCompleted';
import { toggleElementInArray } from '../../utils/toggleElementInArray';
import { TosaLayout } from '../layout';
import { isRecipesRoute } from '../NewTosaRouter/utils/getRecipesRoutes';
import { MultiRecipeModal } from '../RecipeModal/MultiRecipeModal';
import { SignUpHeaderNonTosaPage } from '../shared/SignUpHeaderNonTosaPage';

import {
  useFetchPetsAvailableRecipes,
  usePetNamesAndRecommendedCalories,
  useSurpriseHigherDiscountRecipesPage,
} from './hooks';
import styles from './RecipesPage.module.css';
import { MAX_SELECTED_RECIPES, RecipesSelection } from './RecipesSelection';
import { RecommendedPlan } from './RecommendedPlan';
import { checkLpfRecipeStatus } from './utils/checkLpfRecipeStatus';
import {
  createLeadInput,
  createUpdateLeadPetsPayload,
} from './utils/createLeadInput';
import { getSelectedAvailableRecipes } from './utils/getSelectedAvailableRecipes';

import type { OnTosaCompleted } from '../../hooks';
import type { UseFeatureHook } from '../../types';
import type { Pet, RouteProps } from '../NewTosaRouter/types';

export interface RecipesPageArgs extends RouteProps {
  useFeature: UseFeatureHook;
  onTosaCompleted: OnTosaCompleted;
  lead: FetchLeadQuery['lead'];
  petName: string;
}

// TODO - put a place holder pet name on here from TOSA props
function RecipesPage_({
  useFeature,
  onTosaCompleted,
  lead,
  petName,
  nextRoute,
}: Omit<RecipesPageArgs, 'previousRoute'>) {
  const { refetch } = usePetNamesAndRecommendedCalories();
  useEffect(() => {
    // We want to refetch recommended calories when the recipes page is loaded
    // so that if a customer goes back into the signup flow and changes their
    // xpets' details, we don't get now-stale data from the cache.
    //
    // The ideal solution here is making the pet data into inputs to the
    // `FetchPetsWithRecommendedCalories` query itself so that Apollo knows
    // when to refetch (as we do with the fetchQuote query).
    refetch();
  }, [refetch]);

  const currentPet = lead.pets.find(pet => pet.name === petName) as Pet;

  const { isRecipesPageEnabled, code } = useSurpriseHigherDiscountExperiment({
    useFeature,
  });

  useSurpriseHigherDiscountRecipesPage({
    shouldReplaceDiscount: isRecipesPageEnabled,
    code,
  });

  const [isCustomizingRecipes, setIsCustomizingRecipes] = useState<
    null | boolean
  >(null);
  const [currentPetRecipeSelection, setCurrentPetRecipeSelection] = useState<
    string[] | undefined
  >();

  const onRecipesCompleted = useHandleRecipesCompleted();
  const dispatchToast = useToast();

  const { availablePetRecipesMap } = useFetchPetsAvailableRecipes();
  const availableRecipes = availablePetRecipesMap?.[petName];
  const recommendedRecipes = useMemo(
    () =>
      availableRecipes && availableRecipes.filter(recipe => recipe.recommended),
    [availableRecipes]
  );

  const { isLpfRecipeEligible } = checkLpfRecipeStatus(availableRecipes || []);

  useEffect(() => {
    if (!availableRecipes) {
      return;
    }

    const availableSelectedRecipesNames = getSelectedAvailableRecipes({
      availableRecipes,
      currentSelection: currentPet.selection?.fresh?.options?.recipes ?? [],
    });

    setCurrentPetRecipeSelection(availableSelectedRecipesNames);
  }, [
    lead,
    availableRecipes,
    petName,
    currentPet.selection?.fresh?.options?.recipes,
  ]);

  useEffect(() => {
    // We only want to run this for the first setting of isCustomizingRecipes;
    // the user can control it thereafter
    if (
      !recommendedRecipes ||
      !currentPetRecipeSelection ||
      isCustomizingRecipes !== null
    ) {
      return;
    }

    const hasSelectionDistinctFromRecommendation =
      currentPetRecipeSelection.length > 0 &&
      !isEqual(
        new Set(recommendedRecipes.map(recipe => recipe.name)),
        new Set(currentPetRecipeSelection)
      );

    setIsCustomizingRecipes(hasSelectionDistinctFromRecommendation);
  }, [isCustomizingRecipes, recommendedRecipes, currentPetRecipeSelection]);

  useEffect(() => {
    // Ensure page scrolls to top when transitioning between recipes
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [isCustomizingRecipes]);

  const lpfRecipeNames = useMemo(
    () =>
      availableRecipes
        ? availableRecipes
            .filter(recipe => recipe.content.productLine === 'lpf')
            .map(recipe => recipe.name)
        : null,
    [availableRecipes]
  );

  useSetLpfProductEligibility({
    shouldPersistLpfEligibility: isLpfRecipeEligible,
    email: lead.email,
    lpfRecipeNames,
  });

  const handleSelectionChange = useCallback(
    (recipe: string) => {
      const newSelectedRecipes = toggleElementInArray(
        recipe,
        currentPetRecipeSelection ?? []
      );

      if (newSelectedRecipes.length > MAX_SELECTED_RECIPES) {
        dispatchToast({
          variant: 'neutral',
          children: `You have ${MAX_SELECTED_RECIPES} recipes selected. Please deselect a recipe before choosing another.`,
        });
      } else {
        setCurrentPetRecipeSelection(newSelectedRecipes);
      }
    },
    [currentPetRecipeSelection, dispatchToast]
  );

  const handleReturnToRecommendedPlan = useCallback(() => {
    setIsCustomizingRecipes(false);
    trackRecipesClickBackToRecommendedPlan();
  }, []);

  const handleCustomizePlan = useCallback(() => {
    setIsCustomizingRecipes(true);
    trackRecipesClickSelectYourOwnPlan();
  }, []);

  const [updateLead, updateLeadData] = useUpdateLead();
  const throwToErrorBoundary = useThrowToErrorBoundary();
  if (updateLeadData.error) {
    throwToErrorBoundary(updateLeadData.error);
  }

  const updateLeadRecipeSelection = useCallback(
    async (selection: PetProductFreshSelectionOptionsInput['recipes']) => {
      await updateLead({
        variables: {
          lead: {
            blueprintVersion: standardBlueprintVersion,
            pets: createUpdateLeadPetsPayload({ lead, petName, selection }),
            // TODO - https://app.shortcut.com/farmersdog/story/134051/update-lead-when-reconciling-coupon-cookie-and-lead-state
            // this won't be necessary once update lead is called during coupon reconciliation
            discount: getDiscountInput(),
            productLine: getProductLineInput(),
          },
        },
      });
    },
    [lead, petName, updateLead]
  );

  const finishRecipesPage = useCallback(
    ({
      quote,
      recipes,
    }: {
      quote: FetchQuoteQuery['fetchQuote'] | null;
      recipes: string[];
    }) => {
      petMixingPlanRatiosState.resetAllPetRatios();
      handleStepCompleted(lead);

      const isLastPet = petName === lead.pets[lead.pets.length - 1].name;
      onRecipesCompleted({
        lead,
        isLastPet,
        quote,
      });

      void updateLeadRecipeSelection(recipes);
    },
    [petName, lead, onRecipesCompleted, updateLeadRecipeSelection]
  );

  const handleRecommendedPlanSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      if (!recommendedRecipes) {
        return;
      }

      trackRecipesClickContinueWithRecommendation({
        recommendedRecipes,
      });

      finishRecipesPage({
        quote,
        recipes: recommendedRecipes.map(recipe => recipe.name),
      });
    },
    [finishRecipesPage, recommendedRecipes]
  );

  const handleCustomizeRecipesSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      if (!currentPetRecipeSelection) {
        return;
      }

      finishRecipesPage({ quote, recipes: currentPetRecipeSelection });
    },
    [finishRecipesPage, currentPetRecipeSelection]
  );

  const [registerLead, registerLeadData] = useRegisterLead();
  if (registerLeadData.error) {
    throwToErrorBoundary(registerLeadData.error);
  }

  const [hasOnTosaCompletedRun, setHasOnTosaCompletedRun] = useState(false);

  const shouldCallRegisterLeadBeforeNavigating =
    nextRoute && !isRecipesRoute(nextRoute);

  useEffect(() => {
    const updateLeadResponse = updateLeadData.data?.updateLead;

    if (shouldCallRegisterLeadBeforeNavigating && updateLeadResponse) {
      void (async function () {
        const { data } = await registerLead({
          variables: {
            lead: createLeadInput(updateLeadResponse),
            disableRecipesUpdate: true,
          },
        });

        if (!data) {
          throwToErrorBoundary(
            new Error(
              'Unexpected error in TOSA registration: no register lead data found'
            )
          );
          return;
        }

        await onTosaCompleted({
          registeredUserResponse: data.registerLead.user,
        });

        setHasOnTosaCompletedRun(true);
      })();
    }
  }, [
    shouldCallRegisterLeadBeforeNavigating,
    lead,
    onTosaCompleted,
    registerLead,
    throwToErrorBoundary,
    updateLeadData.data,
  ]);

  const isLoading =
    isCustomizingRecipes === null ||
    !availablePetRecipesMap ||
    !recommendedRecipes ||
    !currentPetRecipeSelection;

  if (isLoading) {
    return (
      <ContentLoader loading={true} height="100vh">
        <></>
      </ContentLoader>
    );
  }

  if (
    updateLeadData.data &&
    nextRoute &&
    (shouldCallRegisterLeadBeforeNavigating ? hasOnTosaCompletedRun : true)
  ) {
    return <Redirect to={nextRoute} push />;
  }

  const isSubmitting = updateLeadData.loading || registerLeadData.loading;

  return (
    <>
      <TosaLayout>
        {/* TODO: Move MultiRecipeModal into RecipesSelection, since that's
        where it can be opened */}
        <MultiRecipeModal recipes={recommendedRecipes} petName={petName} />
        {isCustomizingRecipes ? (
          <RecipesSelection
            returnToRecommendedPlan={handleReturnToRecommendedPlan}
            currentPetRecipeSelection={currentPetRecipeSelection}
            onSubmit={handleCustomizeRecipesSubmit}
            isFormSubmitting={isSubmitting}
            onChangeSelection={handleSelectionChange}
            petName={petName}
            pets={lead.pets}
            availablePetRecipesMap={availablePetRecipesMap}
          />
        ) : (
          <RecommendedPlan
            className={styles.recommendedPlan}
            customizePlan={handleCustomizePlan}
            onSubmit={handleRecommendedPlanSubmit}
            isFormSubmitting={isSubmitting}
            pets={lead.pets}
            petName={petName}
            availablePetRecipesMap={availablePetRecipesMap}
          />
        )}
      </TosaLayout>
    </>
  );
}

export function RecipesPage({
  useFeature,
  onTosaCompleted,
  lead,
  petName,
  previousRoute,
  nextRoute,
}: RecipesPageArgs) {
  return (
    <>
      <SignUpHeaderNonTosaPage previousSignupRoute={previousRoute} />
      <RecipesPage_
        petName={petName}
        lead={lead}
        useFeature={useFeature}
        onTosaCompleted={onTosaCompleted}
        nextRoute={nextRoute}
      />
    </>
  );
}
