import upsellCardImageJpgSmall from './upsell-card-image-sm.jpg';
import upsellCardImageWebpSmall from './upsell-card-image-sm.webp';
import upsellCardImageJpgLarge from './upsell-card-image-lg.jpg';
import upsellCardImageWebpLarge from './upsell-card-image-lg.webp';

export const imageSources = {
  mobileSources: {
    webP: upsellCardImageWebpSmall,
    jpg: upsellCardImageJpgSmall,
  },
  desktopSources: {
    webP: upsellCardImageWebpLarge,
    jpg: upsellCardImageJpgLarge,
  },
};
