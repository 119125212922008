import type { Request } from 'express';
import isEmpty from 'lodash/isEmpty';

import { Logger } from '@farmersdog/logger';

import { ValidationError } from 'src/errors';

import type {
  AnonymousExperimentFlag,
  AnonymousExperimentFlags,
} from './flags';
import { getExperimentFlagsForRequest } from './getExperimentFlagsForRequest';
import { getShouldSkipSplitForUserAgent } from './getShouldSkipSplitForUserAgent';
import { getClient } from './splitFactory';
import { getAttributes } from './getAttributes';

interface GetTreatmentsWithParsedConfigsArgs {
  request: Request;
}

export async function getTreatmentsWithParsedConfigs<
  F extends AnonymousExperimentFlag,
>({
  request,
}: GetTreatmentsWithParsedConfigsArgs): Promise<AnonymousExperimentFlags> {
  if (!request.anonymousId) {
    throw new ValidationError(
      'No anonymousId found on request in experimentsMiddleware'
    );
  }

  const shouldSkipSplitForUserAgent = getShouldSkipSplitForUserAgent(
    request.get('user-agent')
  );

  const anonymousExperiments = getExperimentFlagsForRequest({
    requestPath: request.path,
  });

  const shouldBypassSplit =
    shouldSkipSplitForUserAgent || isEmpty(anonymousExperiments);

  if (shouldBypassSplit) {
    return {} as AnonymousExperimentFlags;
  }

  const splitClient = await getClient();
  const attributes = getAttributes(request);
  const treatmentsWithConfig = splitClient.getTreatmentsWithConfig(
    request.anonymousId,
    anonymousExperiments,
    {
      ...attributes,
    }
  );

  return Object.keys(treatmentsWithConfig).reduce((acc, experiment) => {
    const result = treatmentsWithConfig[experiment];

    if (!result) {
      return acc;
    }

    const treatment =
      result.treatment as AnonymousExperimentFlags[F]['treatment'];

    const config = result.config
      ? (parseSplitConfig(
          result.config
        ) as AnonymousExperimentFlags[F]['config'])
      : undefined;

    return {
      ...acc,
      [experiment as F]: {
        treatment,
        config,
      },
    };
  }, {} as AnonymousExperimentFlags);
}

/**
 * Parses the configuration string from Split
 * @param configString - The configuration string from Split
 */
function parseSplitConfig(configString: string): unknown {
  try {
    return JSON.parse(configString) as unknown;
  } catch (error) {
    const log = new Logger('app:server:getTreatmentsWithParsedConfigs');
    log.error('Failed to parse Split config', { error, configString });
    return undefined;
  }
}
