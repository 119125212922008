import { getSnapchatPii } from './getSnapchatPii';
import { mountSnapchatEvent } from './mountSnapchatEvent';
import { SnapchatEvent } from './types';

interface MountSnapchatAddCartArgs {
  email?: string;
  eventId: string;
}

export async function mountSnapchatAddCart({
  email,
  eventId,
}: MountSnapchatAddCartArgs) {
  const properties = {
    ...(await getSnapchatPii(email)),
    event_id: eventId,
  };

  mountSnapchatEvent({ name: SnapchatEvent.AddCart, properties });
}
