export const makeRoom = {
  variants: {
    initial: { width: 1440 },
    animateLG: { width: 780 },
    animateXL: { width: 900 },
  },
  transition: {
    type: 'spring',
    stiffness: 100,
    damping: 15,
    delay: 1,
  },
};
