import { useCallback, useEffect, useState } from 'react';

export const useHasScrolled = (): boolean => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY === 0) {
      return;
    }

    setHasScrolled(true);
    window.removeEventListener('scroll', handleScroll);
  }, [setHasScrolled]);

  useEffect(() => {
    if (hasScrolled) {
      return;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled, handleScroll]);

  return hasScrolled;
};
