import { events, track } from 'src/analytics';

interface trackTreatsSkipFromModalProps {
  path: string;
  category: 'treats' | 'chews';
}

export function trackTreatsSkipFromModal({
  path,
  category,
}: trackTreatsSkipFromModalProps) {
  return track(events.treats_skip_from_modal, {
    path,
    category,
  });
}
