import classNames from 'classnames';
import round from 'lodash/round';
import { useMemo, type FC } from 'react';

import { ButtonBase, type ButtonBaseProps } from '@farmersdog/corgi-x';

import * as animations from './helpers/animations';
import { getScale } from './helpers/getScale';
import styles from './Slide.module.css';

export interface SlideProps extends ButtonBaseProps {
  /** Whether to toggle the wide view */
  isWide: boolean;
  /** The index of the slide */
  index: number;
  /** Whether to toggle the debug mode for this component */
  isDebug: boolean;
  /** The amount the slide is intersecting the parent element */
  intersectionRatio: number;
  /** Whether this is the active slide */
  active: boolean;
  /** Whether to grayscale inactive items */
  grayscaleInactiveItem?: boolean;
  /** Whether to omit the slide animation */
  omitAnimation?: boolean;
  /** Whether to disable user scrolling */
  disableUserScrolling?: boolean;
}

export const Slide: FC<SlideProps> = ({
  children,
  className,
  isWide = false,
  index,
  intersectionRatio,
  isDebug = false,
  active,
  grayscaleInactiveItem = false,
  omitAnimation = false,
  disableUserScrolling = false,
  ...props
}) => {
  const responsiveAnimation = !isWide ? animations.notWide : animations.wide;

  const style = useMemo(() => {
    const { minScale, maxScale, curve } = responsiveAnimation;

    const progress = curve(intersectionRatio);
    const scale = getScale(progress, minScale, maxScale);

    return { transform: `scale(${scale})` };
  }, [intersectionRatio, responsiveAnimation]);

  const showDebug = index === 2 && isDebug;

  return (
    <li
      className={classNames(styles.slide, className, {
        [styles['is-wide']]: isWide,
        [styles['is-not-wide']]: !isWide,
      })}
      tabIndex={disableUserScrolling ? -1 : undefined}
      onMouseDown={disableUserScrolling ? e => e.preventDefault() : undefined}
      onFocus={disableUserScrolling ? e => e.target.blur() : undefined}
    >
      <div
        className={classNames(
          styles['animation-reference'],
          showDebug && styles['animation-debug']
        )}
      >
        {showDebug &&
          `Animation reference intersection ${round(intersectionRatio, 2)}`}
      </div>
      <ButtonBase
        className={classNames(styles.button, {
          [styles['active']]: active,
          [styles['inactive']]: !active,
          [styles['inactive-grayscale']]: !active && grayscaleInactiveItem,
        })}
        style={omitAnimation ? {} : style}
        disabled={disableUserScrolling}
        tabIndex={disableUserScrolling ? -1 : undefined}
        {...props}
      >
        {children}
      </ButtonBase>
    </li>
  );
};
