import { useState, useEffect, useRef } from 'react';

import { Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import { getDeliveryEstimateText } from '../../utils';

import { Marquee } from '../Marquee';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';

import styles from './OrderNowMarquee.module.css';

export function OrderNowMarquee() {
  const [animate, setAnimate] = useState(false);

  const { treatment } = useShowHomepageRedesign();

  const deliveryText = getDeliveryEstimateText('mobile') as string;
  const marqueeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (marqueeRef.current) {
        const rect = marqueeRef.current.getBoundingClientRect();
        const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setAnimate(inView);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isCurrentFormatHero =
    treatment === HomepageEndToEndRedesignTreatments.CurrentFormat;

  const marqueeClassnames = classNames(styles.marqueeWrapper, {
    [styles.marqueeWrapperCurrentFormat]: isCurrentFormatHero,
  });

  return (
    <div ref={marqueeRef} className={marqueeClassnames}>
      <Marquee animate={animate}>
        <Text bold as="p" color="kale-2" vSpacing="none" leftSpacing="xl">
          Order now for delivery {deliveryText}
          <Text bold as="span" color="kale-2" leftSpacing="xl">
            Only at TheFarmersDog.com
          </Text>
        </Text>
      </Marquee>
    </div>
  );
}
