import { Quote } from '../../components';

import styles from './PressBarItem.module.css';

export const PressBarItem = ({ quote }: { quote: string }) => {
  return (
    <div className={styles.item}>
      <Quote text={quote} />
    </div>
  );
};
