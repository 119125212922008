import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { useToast } from '@farmersdog/corgi';

import { showError } from 'src/actions/ui';

import skipTreatsMutation from './SkipTreats.graphql';

import type {
  SkipTreatsModalOrdersUpdateSubscriptionMutationVariables,
  SkipTreatsModalOrdersUpdateSubscriptionMutation,
} from './SkipTreats.generated-types';

export interface UseSkipTreatsSubmit {
  submit: (
    input: SkipTreatsModalOrdersUpdateSubscriptionMutationVariables
  ) => Promise<void>;
  isSubmitting: boolean;
}

export function useSkipTreatsSubmit(displayName: string): UseSkipTreatsSubmit {
  const dispatch = useDispatch();
  const dispatchToast = useToast();

  const [callSubmit, { loading }] = useMutation<
    SkipTreatsModalOrdersUpdateSubscriptionMutation,
    SkipTreatsModalOrdersUpdateSubscriptionMutationVariables
  >(skipTreatsMutation, {
    onError: error => {
      dispatch(showError(error));
    },
    onCompleted: () => {
      dispatchToast({
        variant: 'positive',
        children: `Your next scheduled order will skip ${displayName.toLowerCase()}!`,
      });
    },
  });

  async function submit(
    input: SkipTreatsModalOrdersUpdateSubscriptionMutationVariables
  ) {
    await callSubmit({ variables: input });
  }

  return {
    submit,
    isSubmitting: loading,
  };
}
