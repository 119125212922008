import { PATH_SIGNUP, PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi-x';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';

import styles from './FAQ.module.css';

interface Question {
  question: string;
  answer: string | React.ReactNode;
}

export const faqItems: Question[] = [
  {
    question: 'What are the recipes and ingredients?',
    answer: (
      <>
        The Farmer’s Dog creates a variety of human-grade recipes that are
        simple in nature using different proteins and vegetables to account for
        dietary restrictions. Our board-certified veterinary nutritionists
        carefully formulate each recipe to be 100% complete and balanced
        according to AAFCO standards, and we use all human-grade ingredients and
        processes to do so.
        <br />
        <br />
        Every recipe has clean human-grade proteins, mixed with simple whole
        foods, and balanced with vitamins and minerals. That’s it. We never use
        feed-grade ingredients, and we don’t process our ingredients to be
        shelf-stable, ensuring your dog is getting the highest quality, most
        natural nutrients available. All of our ingredients are human-grade and
        sourced from reputable food suppliers, local farms, and other human food
        purveyors that meet human-grade standards.
        <br />
        <br />
        You can find the specific ingredient lists for the meal plan we
        recommend on the “Your Plan” section of{' '}
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.Faq}
        >
          <Link className={styles.link} to={PATH_SIGNUP_ME}>
            our meal plan questionnaire.
          </Link>
        </CtaTrackerV2>
      </>
    ),
  },
  {
    question: 'What does The Farmer’s Dog cost?',
    answer: (
      <>
        Every dog is different, and your exact plan and price options will
        depend on your dog(s) and their food needs, which factor in age, weight,
        activity, recipe choice, and more. Plans start around $2/day and include
        free shipping across a range of delivery options.
        <br />
        <br />
        To see your exact pricing options, simply fill out{' '}
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.Faq}
        >
          <Link to={PATH_SIGNUP}>our short questionnaire.</Link>
        </CtaTrackerV2>
      </>
    ),
  },
  {
    question: 'How do you determine my dogʼs portions?',
    answer:
      'We work closely with our on-staff vet nutritionists to determine the optimal caloric needs for different combinations of breed, age, activity level, and sensitivities. Certain dogs can metabolize faster than others, so some plans may need further adjusting. Our support team is standing by 24/7 to help update your plan and answer any questions.',
  },
  {
    question: 'Do I need to refrigerate the food?',
    answer:
      'Yes! Because it’s real food, made fresh, without excess processing. And like all perishable food, it’s best kept in the freezer until you’re ready to feed it to your dog. We include an easy feeding guide in your first box of food.',
  },
  {
    question: 'Do I need to cook the food?',
    answer:
      'Nope, our food arrives ready-to-serve with portions tailored to your dog that are indicated on every pack. You can serve our food directly from the fridge or add a touch of hot water if your dog prefers warm food.',
  },
  {
    question: 'How do shipments work? Do you ship nationwide?',
    answer: (
      <>
        We currently ship to 48 states (we’re working on Hawaii and Alaska).
        <br />
        <br />
        You’ll receive deliveries of freshly prepared food with plans tailored
        to your dog’s needs. Our food arrives frozen right to your door in
        convenient serving packs that make feeding easy. Our flexible and
        recurring deliveries ensure you never run out of food. You can adjust
        your order size and delivery schedule anytime.
      </>
    ),
  },
];
