import eventNames from 'src/analytics/eventNames';
import track from 'src/analytics/track';
import { SubscriptionType } from 'src/graphql/types';

/**
 * A tracking event that is used to mark a customer clicking the Signup button from the homepage.
 *
 * @param requestAttributes - `requestAttributes` from the redux store, includes things like IP address and utm_source
 * @param userId - The TFD user id
 * @param anonymousId - The anonymousId used by split and segment, pulled from store.splitio.anonymousId
 */

export interface TrackHomepageSuccessArgs {
  requestAttributes: Record<string, unknown>;
  location: string;
  ctaCopy: string;
}

export function trackHomepageSuccess({
  requestAttributes,
  location,
  ctaCopy,
}: TrackHomepageSuccessArgs) {
  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Homepage+-+Success
  track(eventNames.homepage_success, {
    ...requestAttributes,
    product_line: SubscriptionType.Fresh,
    label: SubscriptionType.Fresh,
    cta_location: location,
    cta_text: ctaCopy,
  });
}
