import classNames from 'classnames';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { Link } from '@farmersdog/corgi';
import { PageWidth, Picture, useViewport } from '@farmersdog/corgi-x';

import HomepageText from '../HomepageText';
import { HomepageTitle1 } from '../HomepageTitle';

import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { pawsWithPacks } from './assets';
import {
  CtaTrackerV2,
  HomepageSections,
} from '../../../HomepageRedesign/components/CtaTrackerV2';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

import styles from './SectionRecommended.module.css';

export const SectionRecommended = () => {
  const viewport = useViewport();
  const isMobile = viewport.xs && !viewport.lg;

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  const containerClassNames = classNames(styles.section, {
    [styles.accessibilityTheme]: isAccessibilityThemeOn,
  });

  const sectionRef = useTrackedSectionRef(HomepageSections.Recommend);

  return (
    <section className={containerClassNames} ref={sectionRef}>
      {isMobile && (
        <Picture
          loading="lazy"
          sources={pawsWithPacks}
          className={styles.backgroundImage}
          rootMargin="100%"
          alt="Reviews Background Image"
        />
      )}
      <PageWidth enabled={isMobile} className={styles.recommendedSection}>
        <div
          className={classNames(styles.box, {
            [styles.accessibilityTheme]: isAccessibilityThemeOn,
          })}
        >
          <HomepageTitle1 center className={styles.boxTitle}>
            See your dog’s recommended plan!
          </HomepageTitle1>
          <HomepageText>
            Get real, healthy food conveniently delivered with our personalized
            meal plans.
          </HomepageText>
          <CtaTrackerV2
            moduleLevel={1}
            moduleName={HomepageSections.Recommend}
            type="select"
          >
            <Link
              asButton
              variant="primary"
              to={getLinkToFreshSignup(PATH_SIGNUP_ME)}
              className={classNames(styles.button, {
                [styles.accessiblilityThemeCta]: isAccessibilityThemeOn,
              })}
            >
              Create Your Plan
            </Link>
          </CtaTrackerV2>
        </div>
      </PageWidth>
    </section>
  );
};
