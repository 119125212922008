import { Text } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';

import heroImages from './assets/images';
import styles from './Hero.module.scss';

function Hero() {
  return (
    <>
      <section className={styles.container}>
        <Picture
          sources={heroImages}
          alt="about-us-hero"
          className={styles.background}
        />
        <Text
          as="h1"
          variant="heading-52"
          color="white"
          bold
          className={styles.header}
        >
          For the love
          <br />
          of dogs.
        </Text>
      </section>
    </>
  );
}

export default Hero;
