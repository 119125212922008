import Page from 'src/components/Page';
import ErrorPage from 'src/errors/ErrorPage';
import { useAsyncModule } from 'src/utils/useAsyncModule';

import type { AuthenticatedRoutesModule } from './AuthenticatedRoutes';

export function LazyLoadedAuthenticatedRoutes() {
  const authenticatedRoutesModule = useAsyncModule<AuthenticatedRoutesModule>(
    () => import('./AuthenticatedRoutes')
  );

  if (authenticatedRoutesModule.error) {
    return <ErrorPage error={authenticatedRoutesModule.error} />;
  }

  if (authenticatedRoutesModule.loading || !authenticatedRoutesModule.called) {
    return <Page title="Loading…" loading />;
  }

  const AuthenticatedRoutes =
    authenticatedRoutesModule.data?.AuthenticatedRoutes;
  return <>{AuthenticatedRoutes && <AuthenticatedRoutes />}</>;
}
