import { track } from '@farmersdog/analytics';

import eventNames from 'src/analytics/eventNames';

export function trackPortionsModuleViewed(properties: {
  site: 'Main';
  module_name: 'Update Portions';
  flow: 'Reschedule' | 'Inventory';
  plan_context: {
    pet_id: number;
    current_portions: number | null;
  }[];
}) {
  return track(eventNames.module_viewed, properties);
}
