import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

export enum NEXTDOOR_EVENT_TYPES {
  init = 'init',
  track = 'track',
}

export type NextDoorMounter = (
  eventType: NEXTDOOR_EVENT_TYPES,
  eventName: NextDoorEvent | string,
  options?: { user_email_hash?: string }
) => void;

export enum NextDoorEvent {
  PAGEVIEW = 'PAGE_VIEW',
  CONVERSION = 'CONVERSION',
}

/** Mount a NextDoor pixel event */
function _mountNextDoorEvent(window: Window, eventName: NextDoorEvent) {
  if (window.ndp) {
    window.ndp(NEXTDOOR_EVENT_TYPES.track, eventName);
  }
}

export const mountNextDoorEvent = mountVendorWindowEvent(
  'NextDoor',
  _mountNextDoorEvent
);
