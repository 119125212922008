import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';
import { Text } from '@farmersdog/corgi-x';
import styles from './tilapiaRiceAndVegetables.module.css';

export const tilapiaRiceAndVegetables: DiyRecipe = {
  name: 'Tilapia, Rice & Vegetables',
  id: 'tilapia-rice-and-vegetables',
  primaryProtein: PrimaryProtein.Tilapia,
  ingredients: [
    {
      name: 'tilapia fillets, skinless, weighed raw',
      quantity: 908,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'lb',
    },
    {
      name: 'white rice, cooked',
      quantity: 870,
      unit: 'g',
      alternativeQuantity: 5.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, chopped or diced, weighed raw',
      quantity: 250,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, chopped or diced, weighed raw',
      quantity: 180,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'sunflower oil',
      quantity: 27,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'tablespoon',
    },
    {
      name: 'coconut oil',
      quantity: 27,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'tablespoon',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  cookTime: '40 mins',
  ingredientSummary:
    'Tilapia Fillets, White rice, Broccoli, Carrots, Sunflower oil, Coconut oil, DIY Nutrient Mix',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook the white rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description: (
        <Text variant="article-16" as="p">
          Place pot/skillet/dutch oven over medium to medium-high heat on the
          stovetop. Place the tilapia fillets in the pot and cook, flipping
          periodically. Break into smaller pieces and cook until the tilapia is
          totally opaque. Remove the tilapia from the pot, double check and
          remove any remaining pin bones, and set aside.{' '}
          <em className={styles.underline}>
            Retain all cooking liquid produced to include in the final recipe.
          </em>
        </Text>
      ),
    },
    {
      name: 'Step 3',
      description:
        'Add the coconut oil to the pan and add the carrots and broccoli. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 4',
      description:
        'Add the cooked tilapia, cooked rice, and sunflower oil into the pot with the cooked vegetables and coconut oil and stir to evenly distribute.',
    },
    {
      name: 'Step 5',
      description:
        'Using a food-safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 145°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
};
