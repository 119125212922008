import { browserOnly, hashSha } from '@farmersdog/utils';
import { PIXEL_ID as NEXTDOOR_PIXEL_ID } from './trackNextDoorEvent';
import { NEXTDOOR_EVENT_TYPES } from './nextDoorTypes';

export async function addViewThroughConversionData(userEmail?: string) {
  if (!userEmail) {
    return;
  }

  await browserOnly(async window => {
    const user_email_hash = await hashSha({ stringToHash: userEmail });

    if (window.ndp) {
      window.ndp(NEXTDOOR_EVENT_TYPES.init, NEXTDOOR_PIXEL_ID, {
        user_email_hash,
      });
    }
  });
}
