import { paths, QueryParameter } from '@farmersdog/constants';

export function getRecoverPasswordUrl(email: string | undefined) {
  if (!email) {
    return paths.PAW_PATH_PASSWORD_RESET;
  }
  return `${paths.PAW_PATH_PASSWORD_RESET}?${
    QueryParameter.Email
  }=${encodeURIComponent(email)}`;
}
