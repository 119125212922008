import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { motion } from 'motion/react';
import { useState } from 'react';
import { Grid, GridItem, Picture, Text } from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { ExtendedButton } from '../../../../components';

import {
  BowlArrowLottieJson,
  bowlMobileSources,
  bowlShadowMobileSources,
  PackArrowLottieJson,
  packMobileSources,
} from './assets';

import {
  rollInFromLeft,
  shadowFadeIn,
  slideInFromRight,
  staggeredFadeIn,
  slideInFromRightWithDelay,
} from './animations';
import styles from './BowlPackSectionMobile.module.css';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

const bowlShadowSources = [
  bowlShadowMobileSources.webP,
  bowlShadowMobileSources.png,
];

export const BowlPackSectionMobile = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);
  const [isBowlSectionInView, setIsBowlSectionInView] = useState(false);
  const [isBowlTextAnimationComplete, setIsBowlTextAnimationComplete] =
    useState(false);
  const [isBowlAnimationComplete, setIsBowlAnimationComplete] = useState(false);
  const [isPackTextAnimationComplete, setIsPackTextAnimationComplete] =
    useState(false);
  const [isPackAnimationComplete, setIsPackAnimationComplete] = useState(false);
  const [secondContentInView, setSecondContentInView] = useState(false);

  const shouldRenderBowlArrow =
    isBowlTextAnimationComplete && isBowlAnimationComplete;
  const shouldRenderPackArrow =
    isPackTextAnimationComplete && isPackAnimationComplete;

  const turkeyPackAnimationVariant = shouldRenderBowlArrow
    ? slideInFromRightWithDelay
    : slideInFromRight;

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="md"
    >
      <motion.div
        key="top-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.15 }}
        variants={staggeredFadeIn.container}
      >
        <GridItem className={styles.topContent}>
          <motion.div variants={staggeredFadeIn.item}>
            <Text
              variant="heading-28"
              as="h2"
              bold
              color="Kale3"
              id="bowl-pack-section-heading"
            >
              All the nutrients without the extreme processing
            </Text>
          </motion.div>
          <motion.div
            variants={staggeredFadeIn.item}
            onAnimationComplete={() => setIsBowlTextAnimationComplete(true)}
          >
            <Text variant="heading-16" as="p" color="Kale3" weight="semibold">
              Unlike most pet foods, our food is gently{' '}
              <br className={styles.mobile} />
              cooked using whole ingredients and balanced{' '}
              <br className={styles.mobile} />
              with the vitamins and minerals your dog needs.
            </Text>
          </motion.div>
          {shouldRenderBowlArrow && (
            <DotLottieReact
              aria-hidden="true"
              autoplay
              loop={false}
              data={BowlArrowLottieJson}
              className={styles.bowlArrow}
            />
          )}
        </GridItem>
      </motion.div>
      <motion.div
        viewport={{ once: true, amount: 0.15 }}
        onViewportEnter={() => setIsBowlSectionInView(true)}
        className={styles.bowlWrapper}
      >
        <motion.div
          layout
          variants={rollInFromLeft}
          initial="hidden"
          animate={isBowlSectionInView && 'visible'}
          className={styles.bowlContainer}
          onAnimationComplete={() => setIsBowlAnimationComplete(true)}
        >
          <Grid className={styles.bowlImageContainer}>
            <Picture
              sources={[bowlMobileSources.webP, bowlMobileSources.png]}
              alt="Turkey Bowl"
              className={styles.bowlImage}
            />
            <motion.div
              variants={shadowFadeIn}
              initial="hidden"
              animate="visible"
            >
              <Picture
                sources={bowlShadowSources}
                alt=""
                className={styles.bowlShadowImage}
              />
            </motion.div>
          </Grid>
        </motion.div>
      </motion.div>
      <Grid bottomSpacing="md" className={styles.packImageContainer}>
        <motion.div
          variants={turkeyPackAnimationVariant}
          initial="hidden"
          animate={
            ((shouldRenderBowlArrow && !secondContentInView) ||
              (isBowlSectionInView && secondContentInView)) &&
            'visible'
          }
          onAnimationComplete={() => setIsPackAnimationComplete(true)}
        >
          <Picture
            sources={[packMobileSources.webP, packMobileSources.png]}
            alt="Turkey Pack"
            className={styles.packImage}
          />
        </motion.div>
      </Grid>
      <motion.div
        key="bottom-section"
        initial="hidden"
        whileInView="visible"
        onViewportEnter={() => setSecondContentInView(true)}
        viewport={{ once: true, amount: 0.15 }}
        variants={staggeredFadeIn.container}
      >
        <GridItem className={styles.bottomContent}>
          {shouldRenderPackArrow && (
            <DotLottieReact
              aria-hidden="true"
              autoplay
              data={PackArrowLottieJson}
              loop={false}
              className={styles.packArrow}
            />
          )}
          <motion.div
            variants={staggeredFadeIn.item}
            onAnimationComplete={() => setIsPackTextAnimationComplete(true)}
          >
            <Text color="Kale3" variant="heading-28" bold as="h2">
              The right amount of
              <br />
              food every time
            </Text>
          </motion.div>
          <motion.div variants={staggeredFadeIn.item}>
            <Text
              variant="heading-16"
              weight="semibold"
              as="p"
              color="Kale3"
              bottomSpacing="md"
            >
              Pre-portioned packs make it easy to
              <br />
              keep your dog at a healthy weight.
              <br />
              No more guessing or vague scoops.
            </Text>
          </motion.div>
        </GridItem>
        <GridItem>
          <motion.div variants={staggeredFadeIn.item}>
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.BowlPack}
            >
              <ExtendedButton
                variant="solid-kale"
                type="link"
                to={linkToFreshSignup}
              >
                Build Your Plan
              </ExtendedButton>
            </CtaTrackerV2>
          </motion.div>
        </GridItem>
      </motion.div>
    </Grid>
  );
};
