import type { NextFunction, Request, Response } from 'express';
import { isbot } from 'isbot';

import isEmpty from 'lodash/isEmpty';

import { Logger } from '@farmersdog/logger';

import { getAnonymousIdCookie } from '../../utils/anonymousIdCookie';

import { getTreatmentsWithParsedConfigs } from './getTreatmentsWithParsedConfigs';

const log = new Logger('app:server:experimentsMiddleware');

export async function experimentsMiddleware(
  request: Request,
  _: Response,
  next: NextFunction
) {
  try {
    const splitAnonymousTreatments = await getTreatmentsWithParsedConfigs({
      request,
    });

    if (!isEmpty(splitAnonymousTreatments)) {
      const originalUrl = new URL(
        `${request.protocol}://${request.hostname}${request.originalUrl}`
      );
      const searchParams = Object.fromEntries(
        new URLSearchParams(originalUrl.search)
      );

      const logContext = {
        path: request.path,
        hostname: request.hostname,
        ...request.headers,
        ...searchParams,
        anonymousIdCookie: getAnonymousIdCookie(request),
        anonymousIdOnRequest: request.anonymousId,
        loadBalancerIp: request.ip,
        splitAnonymousTreatments,
        isBot: isbot(request.get('user-agent')),
      };

      log.warn('SSR Split - Fetched split treatments', logContext);
    }

    request.experiments = splitAnonymousTreatments;
  } catch (error) {
    log.error('SSR Split - Failed to fetch split treatments', {
      error,
      anonymousId: request.anonymousId,
    });
  } finally {
    next();
  }
}
