import { events, track } from 'src/analytics';

interface trackTreatsSkipModalViewProps {
  path: string;
  category: 'treats' | 'chews';
}

export function trackTreatsSkipModalView({
  path,
  category,
}: trackTreatsSkipModalViewProps) {
  return track(events.treats_skip_modal_view, {
    path,
    category,
  });
}
