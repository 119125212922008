import classNames from 'classnames';

import {
  Text,
  type TextProps as OriginalTextProps,
  type TextVariant,
} from '@farmersdog/corgi-x';

import styles from './ExtendedText.module.css';

export type ExtendedTextVariant =
  | TextVariant
  | 'article-24'
  | 'heading-18'
  | 'heading-36'
  | 'spaced';

export type ExtendedTextProps = Omit<OriginalTextProps, 'variant'> & {
  variant?: ExtendedTextVariant;
  weight?: 'normal' | 'medium' | 'semibold' | 'bold';
};

const getTextVariantClassName = (variant?: ExtendedTextVariant) => {
  if (variant === 'article-24') {
    return styles.article24;
  }

  if (variant === 'heading-18') {
    return styles.heading18;
  }
  if (variant === 'heading-22') {
    return styles.heading22;
  }

  if (variant === 'heading-28') {
    return styles.heading28;
  }
  if (variant === 'heading-36') {
    return styles.heading36;
  }

  if (variant === 'spaced') {
    return styles.spaced;
  }

  return '';
};

export const ExtendedText = ({
  variant,
  className,
  ...rest
}: ExtendedTextProps) => {
  return (
    <Text
      variant={variant as TextVariant}
      className={classNames(getTextVariantClassName(variant), className)}
      {...(rest as OriginalTextProps)}
    />
  );
};
