import { Button } from '@farmersdog/corgi-x';

import { TurnstileState } from '@/account/app/components/Turnstile/TurnstileWidget';

import styles from './SubmitButton.module.css';

export type SubmitButtonProps = {
  loading: boolean;
  email: string;
  captchaState: TurnstileState;
  isTurnstileEnabled: boolean;
  isInteractiveChallenge: boolean;
  isSubmitting: boolean;
};

export const SubmitButton = ({
  loading,
  email,
  captchaState,
  isTurnstileEnabled,
  isInteractiveChallenge,
  isSubmitting,
}: SubmitButtonProps) => {
  const shouldDisableSubmit = () => {
    // If Turnstile is not enabled, then the captcha state is irrelevant, we should only disable it on missing email input
    if (!isTurnstileEnabled) {
      return !email;
    }

    const invalidCaptcha = captchaState !== TurnstileState.PASSED;
    return (isInteractiveChallenge && invalidCaptcha) || !email;
  };

  return (
    <Button
      className={styles.submitButton}
      type="submit"
      loading={loading || isSubmitting}
      aria-disabled={shouldDisableSubmit()}
    >
      Submit
    </Button>
  );
};
