import { useMemo } from 'react';

import { DiscountType, useCoupon } from '@farmersdog/coupons';

import config from '../../config';
import { FeatureName } from '../../utils';

import type { UseFeatureHook } from '../../types';

export function useTosaDiscountBannerCopy(useFeature: UseFeatureHook) {
  const { url, referrerRoute } = config.get('tosa.app.coreApi');

  const { discountAmount, referrerType, couponCode } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  });

  const useFeatureOpts = useMemo(
    () => ({
      attributes: { discountAmount, referrerType, couponCode },
    }),
    [discountAmount, referrerType, couponCode]
  );

  const tosaDiscountBannerCopy = useFeature(
    FeatureName.CVR_TOSA_DISCOUNT_BANNER_COPY,
    useFeatureOpts
  );

  return tosaDiscountBannerCopy;
}
