import config from 'src/config';
import type { Referrer } from 'src/hooks/useReferrer';

import identify from '../identify';

interface IdentifyMeArgs {
  referrer: Omit<Referrer, 'discountPercentage' | 'invalid'>;
  couponCode: string | undefined;
  user: {
    email: string;
    id: number;
    firstName: string | null;
    pets: Array<Record<string, unknown>>;
    shippingAddress: { zip: string | null };
  };
}

/**
 * Identify the user based on referrer characteristics and other data from the
 * signup me page
 *
 * @param referrer - The user referrer
 * @param user - The user object
 */
export function identifyMePage({ referrer, couponCode, user }: IdentifyMeArgs) {
  identify(user, {
    discountType: referrer?.discountType,
    discountAmount: referrer?.discountAmount,
    // TODO: figure out if this prop must be added to returned referrer when globalDiscountOverride is on
    couponType: referrer?.type,
    couponCode,
    userCRMLink: getCRMLink(user.id),
  });
}

/**
 * Return the CRM link for a user based on their id
 *
 * @param userId - The id of the user
 */
function getCRMLink(userId: number): string {
  return `${config('app.crmUrl')}/user/${userId}/account`;
}
