import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';

export const activeHomepageRedesignTreatments = [
  HomepageEndToEndRedesignTreatments.CurrentHeroDog,
  HomepageEndToEndRedesignTreatments.OptimizedHero,
  HomepageEndToEndRedesignTreatments.CurrentFormat,
] as const;

export type ActiveHomepageRedesignTreatment =
  (typeof activeHomepageRedesignTreatments)[number];
