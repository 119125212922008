import { Nowrap } from '@farmersdog/corgi-x';

const DELIVERY_ESTIMATE_COPY = {
  desktop: {
    sunToWed: (
      <>
        <Nowrap>Delivered as early</Nowrap> <Nowrap>as this week!</Nowrap>
      </>
    ),
    thursToSat: (
      <>
        <Nowrap>Delivered early</Nowrap> <Nowrap>next week!</Nowrap>
      </>
    ),
  },
  mobile: {
    sunToWed: 'as early as this week!',
    thursToSat: 'early next week!',
  },
};

export const getDeliveryEstimateText = (layout: 'mobile' | 'desktop') => {
  const day = new Date().getDay();
  const copySet = DELIVERY_ESTIMATE_COPY[layout];
  return copySet[day <= 3 ? 'sunToWed' : 'thursToSat'];
};
