import { Text, useViewport } from '@farmersdog/corgi-x';

import styles from './Quote.module.css';

interface QuoteProps {
  quote: string;
  author: string;
}

export const Quote = ({ quote, author }: QuoteProps) => {
  const viewport = useViewport();
  const isMobile = !viewport.md;

  return (
    <>
      <Text
        as="p"
        color="kale-2"
        align="left"
        topSpacing="none"
        variant="article-28"
        bold
        className={styles.quote}
      >
        {quote}
      </Text>
      <Text
        bold
        as="p"
        align="left"
        variant={isMobile ? 'heading-16' : 'heading-22'}
      >
        {author}
      </Text>
    </>
  );
};
