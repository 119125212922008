import classNames from 'classnames';
import { useId } from 'react';

import { PATH_TREAT_PRODUCTS } from '@farmersdog/constants/paths';
import { Picture, Button, Text, Card, useViewport } from '@farmersdog/corgi-x';

import { treatsDogDesktop, treatsDogMobile } from '@/account/treats/assets';

import styles from './EmptyTreats.module.css';

export function EmptyTreats({ className }: { className?: string }) {
  const isLarge = useViewport().lg;
  const id = useId();
  return (
    <Card className={classNames(className, styles.container)}>
      <Picture
        alt="treats dog"
        sources={isLarge ? treatsDogDesktop : treatsDogMobile}
        className={styles.image}
      />
      <section aria-labelledby={id} className={styles.content}>
        <Text variant="heading-22" color="kale-3" id={id}>
          Add our{' '}
          <Text variant="heading-22" bold>
            healthier, tastier treats
          </Text>{' '}
          to your plan!
        </Text>
        <div>
          <Button type="link" to={PATH_TREAT_PRODUCTS}>
            See Treats
          </Button>
        </div>
      </section>
    </Card>
  );
}
