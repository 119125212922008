import { track } from '@farmersdog/analytics';
import {
  cookie,
  getCurrentSignupSubscriptionType,
} from '@farmersdog/lead-browser-storage';

import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { FetchLeadQuery } from '../../graphql/types';

interface TrackPetsSuccessArgs {
  requestAttributes: Record<string, unknown>;
  lead: FetchLeadQuery['lead'] | null;
}

/**
 * A tracking event that is used to mark a customer completing the /signup/pets page for 1 pet.
 *
 * This fires for every pet, but we may only send events to specific vendors for the last pet.
 *
 */

export function trackPetsSuccess({
  requestAttributes,
  lead,
}: TrackPetsSuccessArgs) {
  const currentSignupSubscriptionType = getCurrentSignupSubscriptionType();

  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Pets+-+Success
  track(segmentTrackEvents.pets_success, {
    ...requestAttributes,
    firstName: lead?.firstName,
    email: lead?.email,
    humanId: lead?.humanId,
    product_line: currentSignupSubscriptionType,
    label: currentSignupSubscriptionType,
    fbp: cookie.getFacebookPixelCookie(),
  });
}
