import events from 'src/analytics/eventNames';
import track from 'src/analytics/track';

import type { RecommendedTreatsPackage } from '@/account/treats/TreatsDetails/hooks';

export function trackTreatsProductRecommendation(
  recommendedTreatsPackages: RecommendedTreatsPackage[]
): void {
  track(events.treats_recommendation, {
    recommendedTreatsPackages: recommendedTreatsPackages.map(treatPackage => ({
      packageSize: treatPackage.packageSize,
      quantity: treatPackage.quantity,
      recipeName: treatPackage.productName,
      approximateDays: treatPackage.approximateDays,
    })),
  });
}
