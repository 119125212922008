import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { Picture, Section } from '@farmersdog/corgi-x';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';

import { ExtendedButton } from '../../../ExtendedButton';
import {
  generateSourcesForPicture,
  type MobileAndDesktopSources,
} from 'src/pages/Home/components/AssetPreloader/assets/shared';

import styles from './HeroImage.module.css';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

type HeroImageProps = {
  sources: MobileAndDesktopSources;
  objectPosition?: React.CSSProperties['objectPosition'];
};

const ctaLabel = 'Shop Fresh';

export const HeroImage = ({ sources, objectPosition }: HeroImageProps) => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);
  const sourcesForPicture = generateSourcesForPicture(sources);

  return (
    <Section as="div" hSpacing={{ xs: 'md', lg: 'none' }}>
      <div className={styles.container}>
        <Picture
          alt="A dog with a bowl of food"
          className={styles.picture}
          sources={sourcesForPicture}
          style={{ objectPosition }}
        />
        <div className={styles.overlay}>
          <CtaTrackerV2
            type="select"
            moduleLevel={1}
            moduleName={HomepageRedesignSections.Hero}
          >
            <ExtendedButton
              variant="bordered-white"
              type="link"
              to={linkToFreshSignup}
              className={styles.cta}
            >
              {ctaLabel}
            </ExtendedButton>
          </CtaTrackerV2>
        </div>
      </div>
    </Section>
  );
};
