// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation?language=en_US
import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import { SnapchatMethod } from './types';

import type { SnapchatEvent, SnapchatTagMounter } from './types';

export interface TrackSnapchatEventArgs {
  name: SnapchatEvent;
  properties?: Record<string, unknown>;
}

declare global {
  interface Window {
    snaptr?: SnapchatTagMounter;
  }
}

function _mountSnapchatEvent(
  w: Window,
  { name, properties = {} }: TrackSnapchatEventArgs
) {
  w.snaptr?.(SnapchatMethod.Track, name, properties);
}

export const mountSnapchatEvent = mountVendorWindowEvent(
  'Snapchat',
  _mountSnapchatEvent
);
