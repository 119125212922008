import { useEffect, useState } from 'react';
import {
  ButtonBase,
  Grid,
  GridItem,
  Section,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';
import { DiscountType, useCoupon } from '@farmersdog/coupons';

import config from '../../../../config';
import { ExtendedButton } from '../ExtendedButton';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { trackModuleViewed } from '../../../../analytics/events/trackModuleViewed';

import styles from './Survey.module.css';

export enum DOG_SIZE_OPTIONS {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const DOG_SIZE_DETAILS = {
  [DOG_SIZE_OPTIONS.SMALL]: { label: 'Small', description: 'but mighty' },
  [DOG_SIZE_OPTIONS.MEDIUM]: { label: 'Middle', description: 'of the pack' },
  [DOG_SIZE_OPTIONS.LARGE]: { label: 'Large', description: 'and in charge' },
};

const pricesByDogSize = {
  [DOG_SIZE_OPTIONS.SMALL]: '2',
  [DOG_SIZE_OPTIONS.MEDIUM]: '3',
  [DOG_SIZE_OPTIONS.LARGE]: '4.50',
};

const surveyHeadlineId = 'survey-headline';

export const Survey = () => {
  const isDesktop = useViewport().lg;
  const { url, referrerRoute } = config('tosa.app.coreApi');
  const { discountAmount } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config('features.defaultTrialDiscount'),
    },
  });

  const [selectedSize, setSelectedSize] = useState<DOG_SIZE_OPTIONS | null>(
    null
  );

  const resetSelection = () => {
    setSelectedSize(null);
  };

  useEffect(() => {
    if (selectedSize) {
      trackModuleViewed({
        moduleName: HomepageRedesignSections.LetsGetStarted,
        moduleLevel: 2,
      });
    }
  }, [selectedSize]);

  const sectionRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.LetsGetStarted
  );

  return (
    <Section
      hSpacing="md"
      aria-labelledby={surveyHeadlineId}
      className={styles.wrapper}
      sectionRef={sectionRef}
    >
      <div className={styles.container}>
        {!selectedSize ? (
          <>
            <Text
              weight="semibold"
              as="p"
              color="white"
              align="center"
              topSpacing="none"
              bottomSpacing={{ xs: 'xs', lg: 'md' }}
              variant={isDesktop ? 'heading-16' : 'heading-12'}
              className={styles.subtitle}
            >
              Let’s get started
            </Text>
            <Text
              id={surveyHeadlineId}
              bold
              as="h2"
              color="white"
              align="center"
              bottomSpacing={{ xs: 'md', lg: 'xl' }}
              variant={isDesktop ? 'heading-40' : 'heading-28'}
              className={styles.title}
            >
              What is your <br className={styles.mobileBreakline} />
              dog’s size?
            </Text>
            <Grid
              gap={{ xs: 'sm', lg: 'md' }}
              alignItems="center"
              flexDirection={{
                xs: 'column',
                lg: 'row',
              }}
            >
              {Object.entries(DOG_SIZE_DETAILS).map(
                ([size, { label, description }]) => (
                  <GridItem key={size} xs={12} lg={4}>
                    <CtaTrackerV2
                      type="select"
                      moduleLevel={1}
                      moduleName={HomepageRedesignSections.LetsGetStarted}
                    >
                      <ExtendedButton
                        variant="bordered-white"
                        className={styles.button}
                        onClick={() =>
                          setSelectedSize(size as DOG_SIZE_OPTIONS)
                        }
                      >
                        <Text
                          as="span"
                          weight="semibold"
                          className={styles.sizeText}
                        >
                          {label}
                        </Text>{' '}
                        <span className={styles.normalText}>{description}</span>
                      </ExtendedButton>
                    </CtaTrackerV2>
                  </GridItem>
                )
              )}
            </Grid>
            <Text
              as="p"
              color="white"
              align="center"
              topSpacing={{
                xs: 'md',
                lg: 'xl',
              }}
              bottomSpacing="none"
              variant={isDesktop ? 'heading-16' : 'heading-12'}
              className={styles.description}
            >
              Daily price is determined based on multiple{' '}
              <br className={styles.mobileBreakline} /> factors, including your
              dog’s size.
            </Text>
          </>
        ) : (
          <>
            <Text
              bold
              as="h2"
              color="white"
              align="center"
              topSpacing="none"
              bottomSpacing={{ xs: 'md', lg: 'xl' }}
              variant={isDesktop ? 'heading-40' : 'heading-28'}
              className={styles.selectedTitle}
            >
              Plans for {selectedSize}
              <br className={styles.mobileBreakline} /> dogs start as low{' '}
              <br className={styles.mobileBreakline} /> as $
              {pricesByDogSize[selectedSize] ?? '8'}
              /day
            </Text>
            <Grid alignItems="center" justifyContent="center">
              <CtaTrackerV2
                type="select"
                moduleLevel={2}
                moduleName={HomepageRedesignSections.LetsGetStarted}
              >
                <ExtendedButton
                  type="link"
                  to={paths.PATH_SIGNUP}
                  variant="bordered-white"
                  className={styles.continueButton}
                >
                  Get {discountAmount}% Off Your First Box
                </ExtendedButton>
              </CtaTrackerV2>
            </Grid>
            <Text
              as="p"
              color="white"
              align="center"
              variant="heading-12"
              className={styles.description}
              topSpacing={{
                xs: 'md',
                lg: 'xl',
              }}
            >
              Our subscription plans are flexible,{' '}
              <br className={styles.mobileBreakline} />
              you can always contact support for{' '}
              <br className={styles.mobileBreakline} />
              further customization.
            </Text>
            <CtaTrackerV2
              type="exit"
              moduleLevel={2}
              moduleName={HomepageRedesignSections.LetsGetStarted}
            >
              <ButtonBase
                className={styles.resetButton}
                onClick={resetSelection}
              >
                <Text variant="heading-16">Back</Text>
              </ButtonBase>
            </CtaTrackerV2>
          </>
        )}
      </div>
    </Section>
  );
};
