import classNames from 'classnames';
import { useViewport } from 'src/screen';

import { Nowrap, Text, PageWidth } from '@farmersdog/corgi-x';

import UnderlineDecorator from 'src/components/UnderlineDecorator';
import ReviewsSlider from 'src/components/ReviewsSlider';

import HeadlineList from '../HeadlineList';
import CounterTitle from './CounterTitle';

import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { HomepageSections } from '../../../HomepageRedesign/components/CtaTrackerV2';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

import styles from './SectionReviews.module.css';

function SectionReviews() {
  const columns = useViewport({ xs: 2, sm: 3, md: 5 });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  const containerClassNames = classNames(styles.section, useViewport(styles), {
    [styles.accessibilityTheme]: isAccessibilityThemeOn,
  });

  const sectionRef = useTrackedSectionRef(HomepageSections.Reviews);

  return (
    <section className={containerClassNames} ref={sectionRef}>
      <PageWidth>
        <CounterTitle />
        <UnderlineDecorator center>
          <Text as="h3" variant="heading-22" color="kale-3" bold>
            What Customers Are Saying
          </Text>
        </UnderlineDecorator>
        <HeadlineList columns={columns} align="center">
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Increased
            <br />
            Vitality
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            <Nowrap>
              Clear Skin &<br />
              Shiny Coat
            </Nowrap>
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Less
            <br />
            <Nowrap>“Dog Odor”</Nowrap>
          </Text>
          <Text
            as="h4"
            variant="heading-16"
            color="kale-3"
            className={styles.text}
            bold
          >
            Better
            <br />
            #2
          </Text>
        </HeadlineList>
      </PageWidth>
      <ReviewsSlider />
    </section>
  );
}
export default SectionReviews;
