import get from 'lodash/get';
import set from 'lodash/set';

import { NodeNames } from '@farmersdog/constants';

import { Positions } from '../blueprint/types';

import type { NodeProgress } from '../hooks/useProgress';
import type { FormFieldsType } from '../types';

interface GetCompletedDataArgs {
  data: FormFieldsType;
  completedLeafNodes: NodeProgress[];
}

export function getCompletedData({
  data,
  completedLeafNodes,
}: GetCompletedDataArgs): FormFieldsType {
  const completedFields = completedLeafNodes.map(node => node.name);

  const completedData = completedFields.reduce(
    (accum: FormFieldsType, key: string): FormFieldsType => {
      const value = get(data, key) as unknown;

      if (value !== undefined) {
        set(accum, key, value);
      }

      return accum;
    },
    {} as FormFieldsType
  );

  // Manually add fresh recipe selections since they're no longer in the
  // blueprint
  Positions.forEach(position => {
    const path = `${NodeNames.FreshSelection}-${position}`;

    const freshRecipeSelections = get(data, path) as unknown;

    if (freshRecipeSelections) {
      set(completedData, path, freshRecipeSelections);
    }
  });

  return completedData;
}
