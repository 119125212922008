import { mountAmazonTagEvent } from './mountAmazonTagEvent';
import { AmazonTagEvent } from './types';

interface MountAmazonTagCheckoutArgs {
  userId: number;
  orderId: number;
}
/**
 * Mount an Amazon checkout event
 */
export function mountAmazonTagCheckout({
  userId,
  orderId,
}: MountAmazonTagCheckoutArgs): void {
  mountAmazonTagEvent({
    name: AmazonTagEvent.Checkout,
    properties: { userId, orderId },
  });
}
