import type { OrderQuoteDailyBreakdown } from 'src/graphql/tosa.types';

// Compare the trial and regular order breakdowns and returns true if total daily price and price per pet are the same across both
export function doesTrialAndRegularAmountsMatch(
  trial: OrderQuoteDailyBreakdown,
  regular: OrderQuoteDailyBreakdown
) {
  return (
    trial.amount === regular.amount &&
    trial.pets.every(
      (trialPet, index) => trialPet.amount === regular.pets[index].amount
    )
  );
}
