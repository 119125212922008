import { useLabel } from '../../../hooks/useLabel';
import { updatePetCount } from '../../../schema/actions/updatePetCount';
import { getInputAttributes, parseTextInputValue } from '../../../utils';
import { InlineInput } from '../base/InlineInput';

import styles from './NumPetInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { ChangeEvent } from 'react';

export function NumPetInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, schema, setFormSteps, formMethods, registry } = props;
  const { trigger, getValues, register, setValue, unregister } = formMethods;
  const registerProps = register(node.name);
  const { frontLabel, backLabel } = useLabel({
    node,
    getValues,
    checkIfPlural: true,
  });

  const inputAttributes = getInputAttributes(node);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    await registerProps.onChange(e);

    const parsedValue = parseTextInputValue({
      value,
      node,
    });
    setValue(node.name, parsedValue, { shouldValidate: true });

    const valid = await trigger(node.name);
    if (valid) {
      updatePetCount({
        value: parseInt(value),
        schema,
        setFormSteps,
        nodeName: node.name,
        registry,
        setValue,
        unregister,
      });
    }
  };

  return (
    <>
      {frontLabel}
      <InlineInput
        className={styles.input}
        {...inputAttributes}
        {...registerProps}
        onChange={handleChange}
      />
      {backLabel}
    </>
  );
}
