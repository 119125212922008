import type { FreshRecipeRecommendation } from '../../../graphql/types';

interface GetSelectedAvailableRecipesArgs {
  availableRecipes: Pick<FreshRecipeRecommendation, 'name'>[];
  currentSelection: string[];
}

export function getSelectedAvailableRecipes({
  availableRecipes,
  currentSelection,
}: GetSelectedAvailableRecipesArgs): string[] {
  return currentSelection.filter(recipe =>
    availableRecipes.find(
      availableRecipe =>
        availableRecipe.name.toLowerCase() === recipe.toLowerCase()
    )
  );
}
