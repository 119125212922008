import { reporter } from 'src/services/reporter';
import { getFacebookPixelAttributes } from 'src/vendors/facebook';

import events from '../eventNames';
import track from '../track';

import getPreTaxTotal from 'src/analytics/getPreTaxTotal';
import { SubscriptionType } from 'src/graphql/types';

interface TrackCheckoutSuccessArgs {
  user?: Reducer.User;
  configuration?: Record<string, unknown>;
  requestAttributes?: Record<string, unknown>;
  subtotalAmount: number;
}

/**
 * A segment tracking event that is used to mark a customer successfully
 * checking out.
 *
 * @param user - A TFD user object
 * @param subtotalAmount - The subtotal amount of the DIY quote.
 * @param configuration - The configuration object stored in redux.
 * @param requestAttributes- Freeform attributes to submit to segment.
 */
export function trackDiyCheckoutSuccess({
  user,
  subtotalAmount,
  configuration,
  requestAttributes,
}: TrackCheckoutSuccessArgs) {
  if (!user) {
    reporter.warn(
      `Segment event "${events.checkout_success}" has missing parameter "user"`
    );
  }

  if (!configuration) {
    reporter.warn(
      `Segment event "${events.checkout_success}" has missing parameter "configuration"`
    );
  }

  const value = getPreTaxTotal(subtotalAmount);

  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Checkout+-+Success
  track(events.checkout_success, {
    mixingPlan: false,
    currency: 'USD',
    value,
    // @ts-expect-error User can be undefined
    ...getFacebookPixelAttributes(user, configuration),
    ...requestAttributes,
    product_line: SubscriptionType.Diy,
    label: SubscriptionType.Diy,
  });
}
