/* CH#49502: Used by RET and CVR */
import { Helmet } from 'react-helmet-async';

import { browserOnly } from '@farmersdog/utils';
import { useIsPageLoaded } from '../../hooks/useIsPageLoaded';

import { reporter } from 'src/services/reporter';
import type { ScriptHTMLAttributes } from 'react';

declare global {
  interface Window {
    onScriptError?: (src: string) => void;
  }
}

browserOnly(window => {
  window.onScriptError = (src: string) =>
    reporter.error(`Script failed to load: src=${src}`);
});

export function Script(props: ScriptHTMLAttributes<HTMLScriptElement>) {
  const isPageLoaded = useIsPageLoaded();

  if (!isPageLoaded) {
    return null;
  }

  return (
    <Helmet>
      <script
        {...props}
        // @ts-expect-error react helmet converts these to strings
        onError={`window.onScriptError('${props.src}')`}
      />
    </Helmet>
  );
}
