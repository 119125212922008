import { getSnapchatPii } from './getSnapchatPii';
import { mountSnapchatEvent } from './mountSnapchatEvent';
import { SnapchatEvent } from './types';

interface MountSnapchatPurchaseArgs {
  email?: string;
  transactionId?: string | number;
}

export async function mountSnapchatPurchase({
  email,
  transactionId,
}: MountSnapchatPurchaseArgs) {
  const transactionIdString = String(transactionId);
  const properties = {
    ...(await getSnapchatPii(email)),
    transaction_id: transactionIdString,
    event_id: transactionIdString,
  };

  mountSnapchatEvent({ name: SnapchatEvent.Purchase, properties });
}
