import { useMemo, type ChangeEvent, useCallback } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupUpdatedAssets } from '../../../hooks';
import { useSignupCardSelectors } from '../../../hooks/experiments/useSignupCardsSelectors';
import { useLabel } from '../../../hooks/useLabel';
import { toggleFragment } from '../../../schema/actions/toggleFragment';
import { RadioSelectorGroup } from '../base';
import { CardRadioGroup } from '../base/CardRadioGroup';
import { getIconAltTag } from '../base/RadioSelectorGroup/utils';

import { bodyConditionAssets, bodyConditionNewAssets } from './assets';
import styles from './BodyConditionInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { SelectionOption, Value } from '../base/CardRadioGroup';

export function BodyConditionInput(props: TOSAComponentInput<TOSALeafNode>) {
  const {
    node,
    schema,
    setFormSteps,
    formMethods,
    formValidationSchema,
    registry,
    useFeature,
  } = props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const bodyConditionField = `${NodeNames.BodyCondition}-${position}` as const;
  const currentValue = getValues(bodyConditionField) ?? null;
  const { frontLabel } = useLabel({ node, getValues });

  const handleComplete = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value;
    void registerProps.onChange(e);

    toggleFragment({
      value,
      node,
      schema,
      setFormSteps,
      formValidationSchema,
      unregister: formMethods.unregister,
      registry,
    });
  };

  const radioGroupOptions =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  const imageAssets = isSignupUpdatedAssetsOn
    ? bodyConditionNewAssets
    : bodyConditionAssets;

  const cardGroupOptions: SelectionOption<Value>[] = useMemo(() => {
    return (
      node.input?.options?.map(({ name, value, description }, index) => ({
        heading: name,
        value,
        description: `${description}.`,
        imageSources: imageAssets.large[index],
        imageAlt: getIconAltTag(value),
      })) ?? []
    );
  }, [node.input?.options, imageAssets]);

  const cardGroupOnChange = useCallback(
    (value: Value) => {
      const event = {
        target: {
          name: node.name,
          value,
        },
      };

      void registerProps.onChange(event);

      toggleFragment({
        value,
        node,
        schema,
        setFormSteps,
        formValidationSchema,
        unregister: formMethods.unregister,
        registry,
      });
    },
    [
      registerProps,
      node,
      formMethods,
      formValidationSchema,
      registry,
      schema,
      setFormSteps,
    ]
  );

  const { shouldShowSignupCardSelectors } = useSignupCardSelectors({
    useFeature,
  });

  return (
    <>
      <span>{frontLabel}</span>
      <div className={styles.wrapper}>
        {shouldShowSignupCardSelectors ? (
          <CardRadioGroup
            options={cardGroupOptions}
            name={registerProps.name}
            currentValue={currentValue}
            onChange={cardGroupOnChange}
            groupDescription="Select the body type closest to your dog's."
          />
        ) : (
          <RadioSelectorGroup
            registerProps={registerProps}
            options={radioGroupOptions}
            icons={imageAssets}
            legend="Body Condition"
            currentValue={currentValue}
            onChange={handleComplete}
            defaultDescription="Select the body type closest to your dog's."
          />
        )}
      </div>
    </>
  );
}
