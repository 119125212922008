import classNames from 'classnames';
import type React from 'react';

import { Text, Grid, GridItem } from '@farmersdog/corgi-x';

import DoubleQuote from '../../assets/double-quote.svg';
import { ExtendedText } from '../ExtendedText';
import type { ExtendedTextVariant } from '../ExtendedText';

import styles from './Quote.module.css';

interface QuoteProps {
  doubleQuotesOnTop?: boolean;
  text: string | React.ReactNode;
  author?: string;
  className?: string;
  textStyles?: string;
  authorStyles?: string;
  rotateQuote?: boolean;
  mirrorQuote?: boolean;
  variant?: string;
}

export const Quote = ({
  doubleQuotesOnTop = false,
  rotateQuote = false,
  mirrorQuote = false,
  text,
  author,
  className,
  textStyles,
  authorStyles,
  variant = 'article-24',
}: QuoteProps) => {
  return (
    <Grid gap="sm" className={className}>
      {doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote
            aria-hidden="true"
            className={classNames({
              [styles.rotateQuote]: rotateQuote,
              [styles.mirrorQuote]: mirrorQuote,
            })}
          />
        </GridItem>
      )}
      <GridItem>
        <ExtendedText
          variant={variant as ExtendedTextVariant}
          color="Kale3"
          className={classNames(textStyles)}
        >
          {text}
        </ExtendedText>
      </GridItem>
      {!doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote aria-hidden="true" className={styles.rotateQuote} />
        </GridItem>
      )}
      {author && (
        <GridItem>
          <Text
            variant="heading-16"
            weight="semibold"
            color="charcoal-3"
            className={classNames(styles.author, authorStyles)}
          >
            {author}
          </Text>
        </GridItem>
      )}
    </Grid>
  );
};
