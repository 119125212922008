import { Redirect, Route, Switch } from 'react-router-dom';

import { paths } from '@farmersdog/constants';

import config from '../../config';
import { useGetLead } from '../../graphql/queries';
import { useHandlePageView } from '../../hooks/useHandlePageView';
import { SignupTreatsPage } from '../SignupTreatsPage';
import { TosaDevToolsNonBlueprint } from '../TosaDevTools';

import { RecipesPageRoute } from './components/RecipesPageRoute';
import { RouteWithOptionalEmail } from './components/RouteWithOptionalEmail';
import { useAvailableLeadJourneyRoutes } from './useAvailableLeadJourneyRoutes';
import { getRecipesRoutes } from './utils/getRecipesRoutes';
import { getTreatsRoutes } from './utils/getTreatsRoutes';

import type { OnTosaCompleted } from '../../exports';
import type { UseFeatureHook } from '../../types';

interface NewTosaRouterProps {
  useFeature: UseFeatureHook;
  onTosaCompleted: OnTosaCompleted;
}

export function NewTosaRouter({
  useFeature,
  onTosaCompleted,
}: NewTosaRouterProps) {
  const enableDevTools = config.get('features.tosaDevTools');
  const lead = useGetLead().data?.lead;

  useHandlePageView({
    isFirstPetsPage: false,
    email: lead?.email,
  });

  const routes = useAvailableLeadJourneyRoutes({
    useFeature,
  });
  if (!routes) {
    // This is just to keep users inside of NewTOSARouter until the routes have
    // had a chance to load.
    //
    // Once we have completely switched over to NewTOSARouter, we can update
    // this to just return null.
    return <Route />;
  }

  const recipesRoutes = getRecipesRoutes(routes);
  const treatsRoutes = getTreatsRoutes(routes);

  return (
    <>
      <Switch>
        {lead && (
          <>
            {recipesRoutes && (
              <RouteWithOptionalEmail path={paths.PATH_SIGNUP_RECIPES}>
                <RecipesPageRoute
                  lead={lead}
                  useFeature={useFeature}
                  onTosaCompleted={onTosaCompleted}
                  recipesRoutes={recipesRoutes.recipesRoutes}
                  routeBeforeRecipes={recipesRoutes.previousRoute}
                  routeAfterRecipes={recipesRoutes.nextRoute}
                />
              </RouteWithOptionalEmail>
            )}

            {treatsRoutes && (
              <RouteWithOptionalEmail path={treatsRoutes.treatsRoute}>
                <SignupTreatsPage
                  lead={lead}
                  previousRoute={treatsRoutes.previousRoute}
                  nextRoute={treatsRoutes.nextRoute}
                />
              </RouteWithOptionalEmail>
            )}
          </>
        )}

        <Route>
          <Redirect to={paths.PATH_SIGNUP} />
        </Route>
      </Switch>

      {enableDevTools && <TosaDevToolsNonBlueprint />}
    </>
  );
}
