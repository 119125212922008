import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { Button, Grid, Modal, Section, Text } from '@farmersdog/corgi-x';

import {
  trackTreatsSkipFromModal,
  trackTreatsSkipModalView,
} from '@/account/analytics';

import { useSkipTreatsSubmit } from './graphql/core';
import styles from './SkipAddOnsModal.module.css';

interface SkipAddOnsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRemoveFromPlan: () => void;
  onSkipFromNextOrder?: () => void;
  displayName: 'Chews' | 'Treats';
}

/**
 * A modal that appears informing a customer that they can skip treats or chews
 * for their next scheduled order, rather than removing it.
 */
export function SkipAddOnsModal(props: SkipAddOnsModalProps) {
  const { submit, isSubmitting } = useSkipTreatsSubmit(props.displayName);
  const location = useLocation();

  useEffect(() => {
    if (props.isOpen) {
      trackTreatsSkipModalView({
        path: location.pathname,
        category: props.displayName === 'Chews' ? 'chews' : 'treats',
      });
    }
  }, [props.isOpen, location.pathname, props.displayName]);

  async function skipFromNextOrder() {
    await submit({ input: { skipTreatsFromNextOrder: true } });
    trackTreatsSkipFromModal({
      path: location.pathname,
      category: props.displayName === 'Chews' ? 'chews' : 'treats',
    });
    if (props.onSkipFromNextOrder) {
      props.onSkipFromNextOrder();
    }
    props.onClose();
  }

  return (
    <Modal
      aria-label="Did you know you can skip a treats or chews order?"
      isOpen={props.isOpen}
      onClose={props.onClose}
      closeOnBackdropClick
      withCloseButton
      contentClassName={styles.modal}
    >
      <Section backgroundColor="Chickpea0" as="div">
        <Grid
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          topSpacing="md"
          className={styles.grid}
        >
          <Text variant="heading-12" color="kale-2" bold>
            Did You Know?
          </Text>
          <Text variant="heading-28" color="kale-3" bold topSpacing="xs">
            You Can Skip a {props.displayName} Order
          </Text>
          <Text variant="article-16" color="charcoal-3" topSpacing="sm">
            Do you want to skip {props.displayName.toLowerCase()} for your next
            scheduled order, or remove them from your ongoing plan?
          </Text>

          <Button
            type="button"
            variant="primary"
            className={styles.skipTreatsButton}
            loading={isSubmitting}
            onClick={skipFromNextOrder}
          >
            Skip {props.displayName} Once
          </Button>
          <Button
            type="button"
            variant="plain-text"
            className={styles.removeFromPlanButton}
            onClick={props.onRemoveFromPlan}
          >
            Remove from Plan
          </Button>
        </Grid>
      </Section>
    </Modal>
  );
}
