import BezierEasing from 'bezier-easing';

interface Animation {
  /**
   * A bezier curve function for projecting progress across for an animation. To
   * visualize animation curves this is a helpful website.
   *
   * {@link https://cubic-bezier.com/}
   */
  curve: ReturnType<typeof BezierEasing>;
  /** The minimum scaled transform */
  minScale: number;
  /** The maximum scaled transform */
  maxScale: number;
}

export const notWide: Animation = {
  curve: BezierEasing(0.25, 0.75, 1, 1),
  minScale: 0.83,
  maxScale: 1,
};

export const wide: Animation = {
  curve: BezierEasing(0, 0, 1, 0.25),
  minScale: 0.9,
  maxScale: 1,
};
