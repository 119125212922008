import BowlMobilePng from './bowl-mobile.png';
import BowlMobileWebP from './bowl-mobile.webp';
import BowlShadowMobilePng from './bowl-shadow-mobile.png';
import BowlShadowMobileWebp from './bowl-shadow-mobile.webp';
import PackMobilePng from './pack-mobile.png';
import PackMobileWebP from './pack-mobile.webp';

import BowlArrowLottieJson from './bowl-arrow.json';
import PackArrowLottieJson from './pack-arrow.json';

export const bowlMobileSources = {
  png: BowlMobilePng,
  webP: BowlMobileWebP,
};

export const bowlShadowMobileSources = {
  png: BowlShadowMobilePng,
  webP: BowlShadowMobileWebp,
};

export const packMobileSources = {
  png: PackMobilePng,
  webP: PackMobileWebP,
};

export { BowlArrowLottieJson, PackArrowLottieJson };
