import { useEffect } from 'react';

import { Button, ContentReveal } from '@farmersdog/corgi-x';

import styles from './SubmitButton.css';

import type { BranchNode } from '../../../blueprint/types';
import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export interface SubmitButtonProps
  extends TOSAComponentInput<TOSALeafNode | BranchNode> {
  loading: boolean;
  fixedButton?: boolean;
  smallWhiteSpace?: boolean;
}

export function SubmitButton(props: SubmitButtonProps) {
  const { node, progress, formMethods, schema } = props;
  const branchCompleted = progress.getBranchComplete(node.__self__);
  const { isValid, isSubmitting } = formMethods.formState;
  useEffect(() => {
    void formMethods.trigger();
  }, [schema, formMethods]);

  const shouldReveal = branchCompleted;

  if (!shouldReveal) {
    return null;
  }

  const containerClassName = props.smallWhiteSpace
    ? styles.smallWhiteSpace
    : styles.additionalWhiteSpace;

  return (
    <div className={containerClassName}>
      <ContentReveal in={shouldReveal} inAnimation="fade">
        <div className={styles.innerContainer}>
          <Button
            aria-disabled={!isValid || isSubmitting}
            className={styles.button}
            loading={props.loading}
            type="submit"
            variant="special"
          >
            {'Continue'}
          </Button>
        </div>
      </ContentReveal>
    </div>
  );
}
