interface Options {
  /**
   * Whether or not to round the value provided to the nearest full dollar.  If true, the value of displayCents is always treated as false.
   * defaults to false
   * @deprecated Use any rounding method(s) valid for the context of the value before formatting it for display.
   */
  round?: boolean;
  /**
   * The unit that the input currency is in.
   * defaults to "cents"
   */
  unit?: 'dollars' | 'cents';
  /**
   * Whether or not to display cents in the formatted value.  If round (deprecated) is true, this option is always evaluated as false.
   * defaults to true
   */
  displayCents?: boolean;
}

/**
 * Format a currency value
 */
export function formatCurrency(
  value: number,
  { round = false, unit = 'cents', displayCents = true }: Options = {}
): string {
  // Rounding truncates cents to preserve the expected deprecated return value
  const showCents = displayCents && !round;
  const fixedPrecision = showCents ? 2 : 0;
  const prefix = value < 0 ? '–' : '';

  const transformSequence: ((v: number) => number)[] = [
    /* Absolute Value */
    v => Math.abs(v),
    /* Dollar Value */
    v => (unit === 'cents' ? v / 100 : v),
    /* [Deprecated] Rounded Value */
    v => (round ? Math.round(v) : v),
    /* Truncated Value */
    v => (!showCents ? Math.trunc(v) : v),
  ];

  const formattedValue = transformSequence
    .reduce((acc, transform) => transform(acc), value)
    .toFixed(fixedPrecision);

  return `$${prefix}${formattedValue}`;
}
