import { useRouteMatch } from 'react-router-dom';

interface Args {
  pageOrEmail?: string;
  email?: string;
}

export function getSignupParams({ pageOrEmail, email }: Args) {
  // /signup
  if (!pageOrEmail) {
    return { email: '', page: '' };
  }
  // /signup/page/email
  if (email) {
    return { email, page: pageOrEmail };
  }
  // /signup/email
  if (pageOrEmail.indexOf('@') > -1) {
    return { email: pageOrEmail, page: '' };
  }
  // /signup/page
  return { email: '', page: pageOrEmail };
}

function useSignupParams() {
  const { params } = useRouteMatch();
  return getSignupParams(params);
}

export default useSignupParams;
