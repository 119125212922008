import events from 'src/analytics/eventNames';
import track from 'src/analytics/track';

import type { CgsChewLength, CgsChewSize } from '@/account/graphql/cgs/types';

export enum ChewsScreen {
  chewsDetailPage = 'Detail Page',
  planPage = 'Plan Page',
}

interface TrackChewsAddToPlanInput {
  productName: string;
  chewSize: CgsChewSize;
  chewLength: CgsChewLength;
  quantity: number;
  viewScreen: ChewsScreen;
}

export function trackChewsAddToPlan(input: TrackChewsAddToPlanInput): void {
  track(events.chews_add_to_plan, { ...input });
}
