import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'motion/react';
import { Picture, StatusMessage } from '@farmersdog/corgi-x';
import { useInterval } from 'src/hooks/useInterval/useInterval';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { HomepageRedesignSections } from '../CtaTrackerV2';

import { CHANGE_ITEM_INTERVAL_SECONDS } from './config';
import { getPressBarItems } from './pressBarItems';
import { PressBarItem } from './PressBarItem';

import styles from './PressBarMobile.module.css';

const ariaQuoteElementId = 'quote-element-id';

export const PressBarMobile = () => {
  const [isTriggered, setIsTriggered] = useState(false);

  const pressBarItems = getPressBarItems({ isMobile: true });
  const itemCount = pressBarItems.length;

  // Create an extended array by prepending the last item and appending the first 2 elements to simulate infinite scrolling
  const extendedItems = [
    pressBarItems.at(-1),
    ...pressBarItems,
    pressBarItems.at(0),
    pressBarItems.at(1),
  ];

  // The first element is now at index = 1 because we prepended an item
  const [activeIndex, setActiveIndex] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // For tracking section visibility
  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.PressBar);

  // Handle the reset logic with fade transition
  useEffect(() => {
    // When we reach the end of a set, we need to reset with a fade transition
    if (activeIndex >= itemCount + 1 && !isAnimating) {
      handleReset();
    }
  }, [activeIndex, itemCount, isAnimating]);

  const handleReset = () => {
    // Start the reset transition
    setIsResetting(true);
    // Disable animation during the actual position reset
    setIsAnimating(false);
    // Reset to initial position
    setActiveIndex(1);
    // zero delay timeout makes sure the `isResetting` state is set after the index change has taken effect
    setTimeout(() => setIsResetting(false));
  };

  // Advance to the next item automatically
  useInterval({
    durationSeconds: CHANGE_ITEM_INTERVAL_SECONDS,
    onTimeLapsed: () => {
      // Set animating flag first
      setIsAnimating(true);
      // Then update active index
      setActiveIndex(prev => prev + 1);
    },
    enabled: isTriggered,
  });

  // Calculate the real index for the footnote (modulo operation)
  const realIndex = (activeIndex - 1) % itemCount;

  // For proper display and scrolling
  const [visibleWidth, setVisibleWidth] = useState(0);

  useEffect(() => {
    // Update on resize
    const handleResize = () => {
      if (containerRef.current) {
        setVisibleWidth(containerRef.current.offsetWidth);
      }
    };

    // Set initial visible width
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate the slide width as 33.33% of container to show 3 items
  const slideWidth = visibleWidth / 3;

  // Calculate transform based on the center item being active
  // We offset by one slide width to center the active slide
  const translateX = -(
    activeIndex * slideWidth -
    visibleWidth / 2 +
    slideWidth / 2
  );

  return (
    <section className={styles.section} ref={sectionRef}>
      {/* Carousel Container */}
      <div className={styles.container} ref={containerRef}>
        <motion.div
          onViewportEnter={() => setIsTriggered(true)}
          className={styles.slidesTrack}
          initial={{ x: 0 }}
          animate={{ x: translateX }}
          transition={{
            duration: isResetting ? 0 : 0.4,
            ease: 'easeInOut',
          }}
          onAnimationComplete={() => {
            setIsAnimating(false);
          }}
        >
          {extendedItems.map((item, index) => {
            const isDirectMatch = index === activeIndex;
            const isFullLoop = activeIndex - index === itemCount;
            const isActive = isDirectMatch || isFullLoop;
            const isAuxiliaryItem = index === 0 || index > itemCount;

            return (
              item && (
                <div
                  key={`${item.alt}-${index}`}
                  className={styles.slide}
                  style={{ width: `${slideWidth}px` }}
                >
                  <Picture
                    sources={item.sources}
                    alt={isAuxiliaryItem ? '' : item.alt}
                    style={{ height: item.height.mobile }}
                    className={`${styles.slideImage} ${isActive ? styles.active : styles.inactive}`}
                    aria-describedby={ariaQuoteElementId}
                  />
                </div>
              )
            );
          })}
        </motion.div>
      </div>

      {/* Footnote with AnimatePresence for smooth transitions */}
      <div className={styles.footnoteContainer} tabIndex={0}>
        <StatusMessage
          id={ariaQuoteElementId}
          politeness="polite"
          aria-label={`${pressBarItems[realIndex].alt}: ${pressBarItems[realIndex].quote}`}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={realIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              className={styles.footnote}
              aria-hidden
            >
              <PressBarItem quote={pressBarItems[realIndex].quote} />
            </motion.div>
          </AnimatePresence>
        </StatusMessage>
      </div>
    </section>
  );
};
