import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import get from 'lodash/get';
import download from 'downloadjs';

import type { RequestAction } from './utils/isRequestAction';
import { isRequestAction } from './utils/isRequestAction';
import configureAxiosClient from './utils/configureAxiosClient';
import { parseSuccess, parseFailure } from './utils/responseParsers';
import getAttachmentFromResponse from './utils/getAttachmentFromResponse';

import type { UnknownAction, Middleware, MiddlewareAPI } from 'redux';

const MAX_RETRY_COUNT = 3;
const JWT_EXPIRED_MSG = 'jwt expired';

const defaultRetryOnFailure = (error: Error & { retryCount?: number }) =>
  error.name === 'NetworkError' && (error.retryCount || 0) < MAX_RETRY_COUNT;
const promiseTimeout = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

const noop = () => {};

export type RequestMiddlewareOptions = {
  apiUrl?: string;
  selectToken?: (state: unknown) => string;
  refreshToken?: (token: string) => RequestAction;
  selectEmail?: (state: unknown) => string;
  logout?: () => UnknownAction;
  retryOnFailure: (error: Error & { retryCount?: number }) => boolean;
  retryAfter: number;
  logger: {
    trace: (msg: string, ...context: unknown[]) => void;
    debug: (msg: string, ...context: unknown[]) => void;
    info: (msg: string, ...context: unknown[]) => void;
    warn: (msg: string, ...context: unknown[]) => void;
    error: (msg: string, ...context: unknown[]) => void;
  };
};

const defaultOptions: RequestMiddlewareOptions = {
  retryOnFailure: defaultRetryOnFailure,
  retryAfter: 2000,
  logger: {
    trace: noop,
    debug: noop,
    info: noop,
    warn: noop,
    error: noop,
  },
};
/**
 * This middleware intercepts "Request Actions", i.e. actions containing
 * `{request: { url } }` in its `payload`, to perform HTTP requests using
 * [axios](https://github.com/axios/axios) to our API.
 *
 * - The `payload.request` object will be used as axios config
 * - Responses are parsed using `./responseParsers.js`
 * - It will add the authentication token from the store if available
 * - Dispatching a Request Action will return an axios `Promise`
 * - The HTTP request can be canceled used `returnedPromise.cancel()`
 * - Given `FOO` as action type, it will dispatch the following action types:
 *   - `FOO_START` when the request is started
 *   - `FOO` when the request is successful
 *   - `FOO_FAILURE` when the request fails
 *   - `FOO_CANCEL` when the request is canceled
 * - It will logout the user if the response status is 401 Unauthorized
 *
 * @param {?Object} options
 * @param {?String} options.apiUrl - The API base URL used by axios
 * @param {?Function} options.selectToken A redux selector that returns the auth
 * @param {?Function} options.refreshToken A redux action for refreshing the auth token out. It get the emails from `selectEmail` option.
 * @param {?Function} options.selectEmail A redux selector that returns the auth
 * email. Required for refreshing the token.
 * @param {?Function} options.logout A redux action for logging out.
 * @param {?Function} options.retryOnFailure Return `true` when a failed request
 * should be retried. As default, it retries "Network errors" for 3 times.
 * Function receives the error object with a `retryCount` property.
 * @param {?Number} options.retryAfter Milliseconds to wait before retrying a
 * failed request. Default is `2000` (2 seconds).
 * @param {?Object} options.logger An object with logger functions (`trace`, `debug`, `info`, `warn`, and `error`). Will enable logging.
 *
 * @return {Function}
 */
export function requestMiddleware(
  customOptions: Partial<RequestMiddlewareOptions>
): Middleware {
  const options = { ...defaultOptions, ...customOptions };
  const axiosClient = configureAxiosClient({ baseURL: options.apiUrl });
  options.logger.debug('Middleware has been initialized with options', options);

  return (store: MiddlewareAPI) => next =>
    // Function must be named as it is called recursively for retrying actions.
    function middleware<T = unknown>(
      action: T
    ): T extends RequestAction
      ? Promise<AxiosResponse>
      : ReturnType<typeof next> {
      if (!isRequestAction(action)) {
        // @ts-expect-error this is unknown here, which is fine
        return next(action);
      }

      // Logger shortcut
      const log = (msg: string, ...args: unknown[]) =>
        options.logger.debug(
          `[request-middleware] ${action.type}: ${msg}`,
          ...args
        );

      // Define action types
      const START = `${action.type}_START`;
      const SUCCESS = action.type;
      const FAILURE = `${action.type}_FAILURE`;
      const CANCEL = `${action.type}_CANCEL`;

      log(`Dispatching request start action...`);
      next({ ...action, type: START });

      // Get the axios config from the request
      const { request: config } = action.payload;

      if (options.selectToken) {
        // Set authorization header with the token from the store
        const token = options.selectToken(store.getState());
        if (token) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${token}`,
          };
          log(`Authorization header set with token %s`, token);
        }
      }

      // Setup request cancellation

      const source = axios.CancelToken.source();
      config.cancelToken = source.token;
      const cancelAction = { ...action, type: CANCEL };

      // Check if it should sleep before running the request
      const sleep = get(action, 'meta.sleep') as number;
      let axiosPromise: Promise<AxiosResponse>;
      if (sleep) {
        log(`Will sleep ${sleep}ms before starting the request`);
        axiosPromise = promiseTimeout(sleep).then(() => {
          log(`Running request after sleeping...`);
          return axiosClient.request(config);
        });
      } else {
        log(`Running request to %s...`, config.url, config);
        axiosPromise = axiosClient.request(config);
      }

      // Run the request and handle the responses
      const wrappedPromise = new Promise<
        ReturnType<typeof parseSuccess> | undefined
      >((resolve, reject) => {
        const successHandler = (response: AxiosResponse) => {
          const attachment = getAttachmentFromResponse(response);
          if (attachment) {
            log('Downloading attachment from response');
            download(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              response.data,
              attachment,
              response.headers['content-type'] as string
            );
          }
          const payload = parseSuccess(response);
          const successAction = { ...action, type: SUCCESS, payload };
          log(`Request to "${config.url}" succeeded!`);

          next(successAction);
          resolve(payload);
        };

        const errorHandler = (error: AxiosError) => {
          log(`Request to "${config.url}" failed with error`, error);

          // Should ignore failures?
          if (get(action, 'meta.ignoreFailure')) {
            log(`Ignoring failure because meta "ignoreFailure" is set`);
            resolve(undefined);
            return;
          }

          // Has been canceled?

          if (axios.isCancel(error)) {
            log('Request canceled');
            // Don't resolve/reject the promise and dispatch the cancel action
            next(cancelAction);

            if (get(action, 'meta.rejectOnCancel')) {
              log('Rejecting promise because meta "rejectOnCancel" is set');
              reject(new Error('Request canceled'));
            }
            return;
          }

          const rejectReason = parseFailure(error);

          // Should retry?
          rejectReason.setRetryCount(
            (get(action, 'meta.retryCount') as number | undefined) || 0
          );
          if (options.retryOnFailure(rejectReason)) {
            const retryCount = rejectReason.retryCount + 1;
            log('Retrying request, retry count is %s', retryCount);
            const retryAction = {
              type: action.type,
              payload: action.payload,
              meta: {
                ...action.meta,
                sleep: options.retryAfter,
                retryCount,
              },
            };

            // Dispatch again the action by calling recursively this middleware

            middleware(retryAction).then(resolve, reject);

            return;
          }

          log(`Failed response is`, rejectReason.response);

          if (
            !get(action, 'meta.skipTokenRefresh') &&
            options.refreshToken &&
            options.selectEmail &&
            get(rejectReason, 'response.status') === 401 &&
            get(rejectReason, 'response.data.data') === JWT_EXPIRED_MSG
          ) {
            const email = options.selectEmail(store.getState());
            const refreshTokenAction = options.refreshToken(email);

            log(`JWT expired. Refreshing token for "%s"`, email);

            // Retry the same action but skip refreshing the token
            const retryAction = {
              ...action,
              meta: { ...action.meta, skipTokenRefresh: true },
            };

            if (!email) {
              log(
                'Email not valid. Dispatching original action (expected to fail)'
              );

              middleware(retryAction).then(resolve, reject);
              return;
            }

            // Dispatch again the refresh token action, then retry the original action again

            middleware(refreshTokenAction)
              .then(() => {
                log('Token refreshed: retrying original action...');

                return middleware(retryAction).then(resolve, reject);
              })

              .catch((err: unknown) => {
                log(
                  'Error while refreshing the token. Dispatching original action (expected to fail)',
                  err
                );

                return middleware(retryAction).then(resolve, reject);
              });

            return;
          }

          if (
            options.logout &&
            Boolean(options.selectToken?.(store.getState())) &&
            get(rejectReason, 'response.status') === 401
          ) {
            log(
              `Dispatching "logout" action out after "Unauthorized" response...`
            );
            next(options.logout());
          } else {
            log(`Dispatching ${FAILURE}`);
            next({ ...action, type: FAILURE, error: rejectReason });
          }
          reject(rejectReason);
        };

        axiosPromise.then(successHandler).catch(errorHandler);
      });

      // @ts-expect-error Promise doesn't allow arbitrary properties in its type
      wrappedPromise.cancel = source.cancel;

      // @ts-expect-error this is a Promise here since the action is a RequestAction type
      return wrappedPromise;
    };
}
