import classNames from 'classnames';
import { useSelect } from 'downshift';
import { useId, useRef, useState, useEffect } from 'react';
import { useController } from 'react-hook-form';

import { heading } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { interceptDOMEvent } from '@farmersdog/utils';

import { getNodeNameAndPosition } from '../../../../blueprint/utils';
import { useLabel } from '../../../../hooks/useLabel';
import { DropdownArrow } from '../DropdownArrow';

import styles from './DropdownInput.module.css';

import type {
  DownshiftEvent,
  FormFieldsType,
  TOSAComponentInput,
  TOSALeafNode,
} from '../../../../types';

const INITIAL_MIN_WIDTH = 60;
const heading22 = heading.create({ size: 22 });
const heading28 = heading.create({
  size: 28,
  color: 'carrot-2',
});

export interface Item {
  name: string;
  value: number | string;
}
export interface DownshiftChangeHandler {
  (e: DownshiftEvent): void;
}

export interface DropdownInputAdditionalProps {
  itemToString?: (item: Item | null) => string;
  overrideLabel?: string;
}

export type DropdownInputProps = DropdownInputAdditionalProps &
  TOSAComponentInput<TOSALeafNode>;

const ICON_WIDTH = 24;
const EXTRA_SPACING = 12;

export function DropdownInput({
  node,
  formMethods,
  itemToString = (item: Item | null) => item?.name ?? 'unknown',
  overrideLabel,
  ...props
}: DropdownInputProps) {
  const dropdownId = useId();
  const [focused, setFocused] = useState(false);
  const [width, setWidth] = useState(INITIAL_MIN_WIDTH);
  const menuElRef = useRef<HTMLUListElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  if (!node.input?.options) {
    throw new Error('DropdownInput requires options');
  }

  const controller = useController<FormFieldsType>({
    name: node.name,
    control: formMethods.control,
    defaultValue: node.input.default,
  });

  const items = node.input.options;
  const value = controller.field.value ?? node.input.default;
  const initialSelectedItem = items.find(item => {
    return item.value === value;
  });

  useEffect(() => {
    if (items.length === 0 || !buttonRef.current) {
      return;
    }
    // We get all the paddings and margins of the button to calculate the width
    const style = window.getComputedStyle(buttonRef.current);
    const font = style.font;

    const paddingLeft = parseFloat(style.paddingLeft);
    const iconElement = buttonRef.current.querySelector(
      `.${styles.dropdownIcon}`
    );

    const iconWidth = iconElement
      ? iconElement.getBoundingClientRect().width
      : ICON_WIDTH;

    // Create an off-screen canvas to accurately measure text width using the same font
    // metrics as the rendered UI. This approach ensures that the calculated width
    // matches the actual visual text size, rather than relying on CSS alone.
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font = font;
      const maxWidth = items.reduce((max, item) => {
        const textWidth = context.measureText(itemToString(item)).width;
        return Math.max(max, textWidth);
      }, INITIAL_MIN_WIDTH);

      const extraSpace = paddingLeft + iconWidth + EXTRA_SPACING;
      setWidth(maxWidth + extraSpace);
    }
  }, [items, itemToString]);

  const downshift = useSelect({
    id: dropdownId,
    items,
    itemToString,
    initialSelectedItem,
    onSelectedItemChange(changes) {
      if (!changes.selectedItem) {
        return;
      }

      const event: DownshiftEvent = {
        target: {
          name: node.name,
          value: changes.selectedItem.value,
        },
      };

      controller.field.onChange(event);
      if (props.onChange) {
        props.onChange(event);
      }
    },
  });

  const { frontLabel, backLabel } = useLabel({
    node,
    getValues: formMethods.getValues,
    overrideLabel,
  });

  const buttonClassName = classNames(styles.button, heading28.className);
  const handleFocus = () => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
    setFocused(true);
  };
  const handleBlur = () => setFocused(false);

  const menuProps = downshift.getMenuProps({
    ref: menuElRef,
  });

  const buttonProps = downshift.getToggleButtonProps({
    onFocus: handleFocus,
    onBlur: interceptDOMEvent(handleBlur, controller.field.onBlur),
    ref: buttonRef,
  });

  const placeholderText = node.input?.placeholder || 'select';
  const selectedItemStyle =
    initialSelectedItem === undefined
      ? styles.buttonTextPlaceholder
      : styles.buttonText;

  const { name: nonIndexedNodeName } = getNodeNameAndPosition(node.name);

  // TOFIX: do not use the node name as accessible label as it is not always human readable
  const accessibleLabel = node.input?.accessibleLabel || node.name;
  return (
    <div className={styles.wrapper}>
      {frontLabel && (
        <Text as="span" variant="heading-28" color="charcoal-3">
          {frontLabel}
        </Text>
      )}
      <div
        style={{ width }}
        className={classNames(
          styles.container,
          styles[nonIndexedNodeName],
          focused ? styles.focused : null
        )}
      >
        {/* Accessible label */}
        <label className={styles.label} {...downshift.getLabelProps()}>
          {accessibleLabel}
        </label>

        {/* Toggle button */}
        <div className={buttonClassName} {...buttonProps}>
          <span className={selectedItemStyle}>
            {downshift.selectedItem
              ? itemToString(downshift.selectedItem)
              : placeholderText}
          </span>
          <span className={styles.dropdownIcon}>
            <DropdownArrow isOpen={downshift.isOpen} />
          </span>
        </div>

        {/* Listbox */}
        <ul {...menuProps} className={styles.menu} hidden={!downshift.isOpen}>
          {items.map((item, index) => {
            const itemProps = downshift.getItemProps({ item, index });
            const itemName = itemToString(item);
            const itemClassName = classNames(styles.item, heading22.className, {
              [styles.itemSelected]: downshift.highlightedIndex === index,
            });

            return (
              <li key={item.value} className={itemClassName} {...itemProps}>
                {itemName}
              </li>
            );
          })}
        </ul>
      </div>
      {backLabel && (
        <Text as="span" variant="heading-28" color="charcoal-3">
          {backLabel}
        </Text>
      )}
    </div>
  );
}
