import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import browserOnly from 'src/utils/browserOnly';

/**
 * Track mouse and touch scroll movement to determine exit intent
 */
class ExitIntentTracker extends Component {
  static propTypes = {
    /** Function to call when exit intent is triggered */
    onExit: PropTypes.func,
    /** Type of pointer either mouse or touch */
    pointer: PropTypes.oneOf(['mouse', 'touch']),
    /** Timeout and debounce setting for measuring time between interactions */
    interactionLimit: PropTypes.number,
    /** Timeout setting for false positives where mouse quickly re-enters viewport */
    falsePositiveTimeout: PropTypes.number,
  };

  static defaultProps = {
    pointer: 'mouse',
    interactionLimit: 125,
    falsePositiveTimeout: 300,
  };

  constructor(props) {
    super(props);

    this.targetNode = null;
    this.timeout = null;

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleInteraction = debounce(
      this.handleInteraction.bind(this),
      props.interactionLimit,
      { leading: true, trailing: false }
    );

    this.state = {
      exiting: false,
      lastInteraction: Date.now(),
      exitInteraction: Date.now(),
      enterInteraction: Date.now(),
    };
  }

  componentDidMount() {
    browserOnly(window => {
      this.targetNode = window.document.body;
    });

    const { pointer } = this.props;

    if (pointer === 'mouse') {
      this.scheduleMouseListeners();
    } else {
      this.scheduleTouchListeners();
    }
  }

  componentDidUpdate(_, prevState) {
    const { pointer, onExit } = this.props;
    const { exiting } = this.state;

    if (!prevState.exiting && exiting) {
      if (typeof onExit === 'function') {
        onExit(exiting);
      }

      if (pointer === 'mouse') {
        this.unscheduleMouseListeners();
      } else {
        this.unscheduleTouchListeners();
      }
    }
  }

  componentWillUnmount() {
    const { pointer } = this.props;

    if (pointer === 'mouse') {
      this.unscheduleMouseListeners();
    } else {
      this.unscheduleTouchListeners();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  scheduleMouseListeners() {
    const options = { passive: true };

    this.targetNode.addEventListener(
      'mousemove',
      this.handleInteraction,
      options
    );

    this.targetNode.addEventListener(
      'mouseenter',
      this.handleMouseEnter,
      options
    );

    this.targetNode.addEventListener(
      'mouseleave',
      this.handleMouseLeave,
      options
    );
  }

  unscheduleMouseListeners() {
    this.targetNode.removeEventListener('mousemove', this.handleInteraction);
    this.targetNode.removeEventListener('mouseenter', this.handleMouseEnter);
    this.targetNode.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  scheduleTouchListeners() {
    const options = { passive: true };

    this.targetNode.addEventListener(
      'touchmove',
      this.handleInteraction,
      options
    );
  }

  unscheduleTouchListeners() {
    this.targetNode.removeEventListener('touchmove', this.handleInteraction);
  }

  handleInteraction() {
    this.setState({
      lastInteraction: Date.now(),
    });
  }

  handleMouseEnter() {
    this.setState({
      enterInteraction: Date.now(),
    });
  }

  handleMouseLeave(e) {
    const { clientY } = e;
    const { interactionLimit, falsePositiveTimeout } = this.props;

    if (clientY < 0) {
      this.setState(state => {
        const now = Date.now();
        return now - state.lastInteraction > interactionLimit
          ? { exitInteraction: now }
          : null;
      });

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.setState(state => ({
          exiting: state.exitInteraction > state.enterInteraction,
        }));
      }, falsePositiveTimeout);
    }
  }

  render() {
    return null;
  }
}

export default ExitIntentTracker;
