import { events, track } from 'src/analytics';

import type { RemoveTreatsReasonsEnum } from '@/account/fresh-plans/TreatsSection';

interface TrackRemovedTreatV2Params {
  productCategory: 'treats';
  feedback: string;
  productName: string | null;
  size: string | null;
  removedTreat: string | null;
  selectedReason: RemoveTreatsReasonsEnum | string;
}

interface TrackRemovedChewParams {
  productCategory: 'chews';
  feedback: string;
  productName: string | null;
  size: string | null;
  chewLength: string | null;
}

/**
 * This function tracks the customer’s desired treat
 * @param feedback - The customer’s feedback
 * @param productName - The customer’s removed treat productName
 * @param size - The customer’s removed treat size
 * @param selectedReason - The customer’s selected reason
 * @param removedTreat - The SKU corresponding to the removed treat
 */
export function trackRemovedTreatV2(
  params: TrackRemovedTreatV2Params | TrackRemovedChewParams
) {
  track(events.treats_removed_a_treat, {
    productCategory: params.productCategory,
    feedback: params.feedback,
    productName: params.productName,
    size: params.size,
    removedTreat:
      params.productCategory === 'treats' ? params.removedTreat : null,
    selectedReason:
      params.productCategory === 'treats' ? params.selectedReason : null,
    chewLength: params.productCategory === 'chews' ? params.chewLength : null,
  });
}
