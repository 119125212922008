import { useEffect } from 'react';

import { trackRecipesPlanDetails } from '../analytics';
import { usePetNamesAndRecommendedCalories } from '../components/RecipesPage/hooks';
import { addRequestedCaloriesToPets } from '../components/RecipesPage/utils/addRequestedCaloriesToPets';
import { useQuote } from '../graphql/queries/useQuote';
import { convertToPetInputsForQuote } from '../utils/convertToPetInputsForQuote';
import { getQuoteDiscountType } from '../utils/getQuoteDiscountType/getQuoteDiscountType';

import { getDiscountInput } from './useLeadState/utils/convertFormStateToLeadInput/getDiscountInput';
import { useThrowToErrorBoundary } from './useThrowToErrorBoundary';

import type { FetchLeadQuery } from '../graphql/types';
import type { PetRecipes } from '../types';

interface UseFetchQuoteArgs {
  currentPetName: string;
  availablePetRecipesMap: PetRecipes;
  pets: FetchLeadQuery['lead']['pets'];
  currentPetRecipeSelection: string[];
  shouldTrack?: boolean;
  isRecommendedPlan?: boolean;
}

export const useFetchQuote = ({
  currentPetName,
  availablePetRecipesMap,
  pets,
  currentPetRecipeSelection,
  shouldTrack = true,
  isRecommendedPlan = false,
}: UseFetchQuoteArgs) => {
  const discount = getDiscountInput();
  const { petNamesAndRecommendedCalories } =
    usePetNamesAndRecommendedCalories();

  const recipeSelectionsAreValid = currentPetRecipeSelection.length > 0;

  const petsInput = recipeSelectionsAreValid
    ? convertToPetInputsForQuote({
        pets,
        currentPetRecipeSelection: currentPetRecipeSelection,
        currentPetName,
        availablePetRecipesMap,
      })
    : undefined;

  const petsWithRecommendedCalories =
    petsInput && petNamesAndRecommendedCalories
      ? addRequestedCaloriesToPets(petsInput, petNamesAndRecommendedCalories)
      : undefined;

  const discountType =
    (discount?.type && getQuoteDiscountType(discount.type)) || null;

  const { data, error, loading } = useQuote({
    variables: {
      pets: petsWithRecommendedCalories!,
      shippingZip: null,
      discountCode: discount?.code || null,
      discountType,
    },
    skip: !petsWithRecommendedCalories,
  });

  const throwToErrorBoundary = useThrowToErrorBoundary();

  if (error) {
    throwToErrorBoundary(error);
  }

  useEffect(() => {
    if (data && petsInput && shouldTrack) {
      trackRecipesPlanDetails({
        currentPetName,
        petsInput,
        quote: data.fetchQuote,
        isRecommendedPlan,
      });
    }
    // We only want to track when the response changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { quote: data?.fetchQuote || null, quoteQueryState: { loading } };
};
