import { Grid, Picture, Slideshow, Text } from '@farmersdog/corgi-x';
import styles from './Testimonials.module.scss';
import VETS_DATA from '../../vetsData';
import classNames from 'classnames';
import { HomepageTitle1 } from '../../../HomepageTitle';
import { useInterval } from '../../../../../../hooks/useInterval/useInterval';
import { useState } from 'react';

interface TestimonialsProps {
  isMobile: boolean;
}

const SECONDS_PER_SLIDE = 8;

export const Testimonials = ({ isMobile }: TestimonialsProps) => {
  const { reset: resetTimer } = useInterval({
    durationSeconds: SECONDS_PER_SLIDE,
    onTimeLapsed: () => {
      setSlide(slide => {
        if (slide == VETS_DATA.length - 1) {
          return 0;
        }
        return slide + 1;
      });
    },
  });
  const [slide, setSlide] = useState(0);

  const getTestimonials = () => {
    return VETS_DATA.map(vet => {
      return (
        <Grid
          flexDirection="column"
          alignItems={isMobile ? 'flex-start' : 'center'}
          key={vet.name}
          className={styles.fullHeight}
        >
          <Grid
            flexDirection={isMobile ? 'row' : 'column'}
            alignItems="center"
            className={classNames(
              styles.fullHeight,
              styles.testimonialsContainer
            )}
          >
            <Text
              variant="article-20"
              align={isMobile ? 'left' : 'center'}
              bottomSpacing="md"
              className={classNames({ [styles.quote]: !isMobile })}
            >
              &#8220;{vet.quote}&#8221;
            </Text>
            <Grid
              flexDirection={isMobile ? 'row' : 'column'}
              alignItems="center"
            >
              <Text
                variant="heading-16"
                bold
                bottomSpacing={isMobile ? 'none' : 'sm'}
                className={classNames({ [styles.nameMobile]: isMobile })}
              >
                {vet.name}
              </Text>
              <Picture
                sources={vet.sources}
                alt={vet.name}
                loading="lazy"
                className={styles.photo}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const onSlideChange = (index: number) => {
    setSlide(index);
    resetTimer();
  };
  return (
    <Grid
      className={styles.testimonials}
      vSpacing="xxl"
      hSpacing={isMobile ? 'md' : 'none'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      flexDirection="column"
    >
      <HomepageTitle1
        className={styles.title}
        center={!isMobile}
        bottomSpacing="none"
        spacing="none"
        vSpacing="3xl"
      >
        Trusted by vets.
      </HomepageTitle1>
      <Text
        variant="article-16"
        color="charcoal-3"
        bottomSpacing={isMobile ? 'md' : 'lg'}
      >
        Thousands of veterinary professionals recommend our food—and feed it to
        their own dogs.
      </Text>
      <div className={styles.slideShow}>
        <Slideshow
          label="vet testimonials gallery"
          withDots
          scrollBehavior="smooth"
          withLoop
          activeIndex={slide}
          onSlideChange={onSlideChange}
        >
          {getTestimonials()}
        </Slideshow>
      </div>
    </Grid>
  );
};
