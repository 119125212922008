import type { Request, Response } from 'express';

import { SEGMENT_ANONYMOUS_ID_COOKIE_KEY } from '@farmersdog/lead-browser-storage';

import { getCookiesFromRequest } from '../getCookiesFromRequest';
import { getRootDomain } from '../getRootDomain';

const ONE_YEAR_IN_MILLISECONDS = 365 * 24 * 60 * 60 * 1000;

/**
 * Retrieves the segment anonymous ID cookie from the request
 */
export function getAnonymousIdCookie(req: Request): string | undefined {
  const cookies = getCookiesFromRequest(req);
  const existingCookie = cookies[SEGMENT_ANONYMOUS_ID_COOKIE_KEY];
  return existingCookie;
}

interface SetServerCookieParams {
  anonymousId: string;
  response: Response;
  hostname: string;
}
/**
 * Sets the segment anonymous ID cookie on the response
 */
export function setServerAnonymousIdCookie({
  anonymousId,
  response,
  hostname,
}: SetServerCookieParams): void {
  const rootDomain = getRootDomain(hostname);

  response.cookie(SEGMENT_ANONYMOUS_ID_COOKIE_KEY, anonymousId, {
    maxAge: ONE_YEAR_IN_MILLISECONDS,
    path: '/',
    sameSite: 'lax',
    secure: false,
    domain: rootDomain,
  });
}
