import type { ToggleTreatment } from './types';
import { useFeature } from './useFeature';

/** Possible cx contact types*/
export type ContactType = 'prospective' | 'customer';

export const featureNameMap = {
  prospective: 'prospective_cx_phone_number',
  customer: 'customer_cx_phone_number',
};

interface UseCxContactReturn {
  phone: boolean;
  email: boolean;
}

export const useCxContact = (
  /** What type of customer is this for */
  type: ContactType,
  /** What config key overrides treatment */
  configKey?: string
): UseCxContactReturn => {
  const featureName = featureNameMap[type];
  const { treatment, config } = useFeature<
    ToggleTreatment,
    Record<string, UseCxContactReturn | undefined>
  >(featureName);

  // If there is a key and key.phone is not undefined use the config value, else
  // fallback to treatment
  const hasConfigValue =
    (configKey ? config[configKey]?.phone : undefined) !== undefined;

  const displayPhoneFromConfig = configKey
    ? (config[configKey]?.phone ?? false)
    : false;

  // Because the treatment is only for phone we should default email to on

  const displayEmailFromConfig = configKey
    ? (config[configKey]?.email ?? true)
    : true;

  return {
    phone: hasConfigValue ? displayPhoneFromConfig : treatment === 'on',

    email: displayEmailFromConfig,
  };
};
