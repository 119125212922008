import type { DotLottie } from '@lottiefiles/dotlottie-react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useState, useRef, useEffect, useCallback } from 'react';
import {
  Text,
  Grid,
  Link,
  Nowrap,
  useViewport,
  GridItem,
  useIntersection,
} from '@farmersdog/corgi-x';
import config from 'src/config';
import classNames from 'classnames';

import { Quote } from '../Quote';

import { ExtendedVideoSection } from '../ExtendedVideoSection';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

import CheckMark from '../../assets/checkmark.svg';
import Certification from '../../assets/certification.svg';
import Checklist from '../../assets/checklist.svg';
import CertificationStethoscope from '../../assets/certification-stethoscope.svg';
import APOPLogo from '../../assets/apop-logo.svg';
import WSAVALogo from '../../assets/wsava-logo.svg';
import { useHasScrolled } from 'src/hooks/useHasScrolled';

import UnderlineLottie from './assets';

import styles from './VetsKnowBest.module.css';

interface Certification {
  icon: JSX.Element;
  text: string | React.ReactNode;
}

const CERTIFICATIONS: Certification[] = [
  {
    icon: <CertificationStethoscope aria-hidden="true" />,
    text: 'On-staff veterinarians and board-certified nutritionists',
  },
  {
    icon: <Checklist aria-hidden="true" />,
    text: (
      <>
        Meets or exceeds WSAVA’s guidelines for selecting{' '}
        <Nowrap>pet foods</Nowrap>
      </>
    ),
  },
  {
    icon: <Certification aria-hidden="true" />,
    text: 'AAFCO-certified complete and balanced nutrition',
  },
  {
    icon: <CheckMark aria-hidden="true" />,
    text: 'Rigorously tested for quality and safety',
  },
];

const WISTIA_IDS = {
  desktop: 'hfadsk2rqc',
  mobile: 'jm7th76rvo',
};

export const VetsKnowBest = () => {
  const hasScrolled = useHasScrolled();
  const viewport = useViewport();
  const isMobile = !viewport?.lg;
  const isViewportReady = !!viewport.xs;

  const [underlineLottiePlayer, setUnderlineLottiePlayer] =
    useState<DotLottie | null>(null);
  const underlineRef = useRef<HTMLDivElement>(null);

  const intersection = useIntersection(underlineRef.current, {
    threshold: 1,
    rootMargin: '0px 0px -200px 0px',
  });

  const dotLottieRefCallback = useCallback(
    (dotLottie: DotLottie | null) => {
      setUnderlineLottiePlayer(dotLottie);
    },
    [setUnderlineLottiePlayer]
  );

  const wistiaId = isMobile ? WISTIA_IDS.mobile : WISTIA_IDS.desktop;

  useEffect(() => {
    if (intersection?.isIntersecting) {
      underlineLottiePlayer?.play();
    }
  }, [intersection?.isIntersecting, underlineLottiePlayer]);

  const vetVideoRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.VetKnowsBestVideo
  );
  const vetContentRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.VetKnowsBestContent
  );

  const QUOTE_TEXT = (
    <>
      No animal’s digestive system is designed to eat high-processed food every
      meal of their lives. The Farmer’s Dog makes it easy to feed{' '}
      <Nowrap>
        something
        <span className={styles.underlinedSubject} ref={underlineRef}>
          {' '}
          better.
          {hasScrolled && (
            <DotLottieReact
              dotLottieRefCallback={dotLottieRefCallback}
              data={UnderlineLottie}
              autoplay={false}
              loop={false}
              className={styles.underline}
            />
          )}
        </span>
      </Nowrap>
    </>
  );

  if (!isViewportReady) {
    return null;
  }

  return (
    <div className={styles.vetsKnowBestWrapper}>
      <div className={styles.videoContainer} ref={vetVideoRef}>
        <ExtendedVideoSection
          className={styles.video}
          wistiaId={wistiaId}
          controlsPosition="center"
          wistiaOptions={{
            autoPlay: false,
            playsinline: true,
            endVideoBehavior: 'reset',
            playPauseNotifier: false,
            videoFoam: true,
          }}
          moduleName={HomepageRedesignSections.VetKnowsBestVideo}
        />
      </div>
      <div className={styles.container} ref={vetContentRef}>
        <div className={styles.testimonialHeading}>
          <Text
            variant={isMobile ? 'heading-28' : 'heading-40'}
            color="kale-3"
            bold
          >
            Vets know best
          </Text>
          <Quote
            className={styles.quote}
            text={QUOTE_TEXT}
            author="Dr. Alex Schechter"
            doubleQuotesOnTop
            textStyles={styles.quoteText}
            rotateQuote={!isMobile}
            mirrorQuote={!isMobile}
            variant={isMobile ? 'article-24' : 'article-28'}
            authorStyles={styles.author}
          />
        </div>
        <Grid
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 'md' : 'xxl'}
          justifyContent="center"
        >
          {CERTIFICATIONS.map(({ icon, text }, index) => (
            <GridItem
              key={`${index}`}
              sm={12}
              lg={3}
              alignItems="center"
              className={classNames(styles.certificationContainer, {
                [styles.withBottomBorder]: index < CERTIFICATIONS.length - 1,
              })}
            >
              <div className={styles.iconContainer}>{icon}</div>
              <Text
                weight="semibold"
                as="p"
                variant="heading-16"
                color="kale-3"
                vSpacing="none"
                align={isMobile ? 'left' : 'center'}
              >
                {text}
              </Text>
            </GridItem>
          ))}
        </Grid>
        <Grid
          className={styles.associationLogos}
          justifyContent="center"
          gap="sm"
        >
          <WSAVALogo aria-label="WSAVA Logo" className={styles.wsavaLogo} />
          <APOPLogo
            aria-label="Association for Pet Obesity Prevention"
            className={styles.apopLogo}
          />
        </Grid>
        <div className={styles.vetProfessional}>
          <Text as="div" align="center" color="kale-3">
            Are you a vet professional?
          </Text>
          <Text as="div" align="center" color="kale-3">
            Visit our{' '}
            <CtaTrackerV2
              type="select"
              moduleLevel={1}
              moduleName={HomepageRedesignSections.VetKnowsBestContent}
            >
              <Link
                to={`${config('app.vetPortalUrl')}`}
                className={styles.vetPortalLink}
              >
                Vet Team Portal
              </Link>
            </CtaTrackerV2>
          </Text>
        </div>
      </div>
    </div>
  );
};
