import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const turkeyAndRice: DiyRecipe = {
  name: 'Turkey & Rice',
  primaryProtein: PrimaryProtein.Turkey,
  ingredients: [
    {
      name: '93% lean ground turkey',
      quantity: 1135,
      unit: 'g',
      alternativeQuantity: 2.5,
      alternativeUnit: 'lb',
    },
    {
      name: 'carrots, shredded or cut into 1/4 inch dice',
      quantity: 300,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, cut into 1/4 inch dice',
      quantity: 290,
      unit: 'g',
      alternativeQuantity: 3.25,
      alternativeUnit: 'cup',
    },
    {
      name: 'cooked white rice',
      quantity: 250,
      unit: 'g',
      alternativeQuantity: 1.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 250,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 18,
      unit: 'g',
      alternativeQuantity: 4,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  id: 'turkey-and-rice',
  cookTime: '40 mins',
  ingredientSummary:
    'Ground Turkey, Carrots, Broccoli, White Rice, Sweet Potato, DIY Nutrient Mix, Sunflower Oil, Salmon Oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food-safe thermometer',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. Pour the sunflower oil in and spread it to evenly coat the bottom.',
    },
    {
      name: 'Step 3',
      description:
        'Add the ground turkey to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 4',
      description:
        'Add the carrots, broccoli, and sweet potato. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.',
      note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.',
    },
    {
      name: 'Step 5',
      description:
        'Add the cooked rice and salmon oil and stir to evenly distribute. Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        'Remove the pot from the heat and sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.',
    },
  ],
  calories: 2430,
};
