import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { track, events } from 'src/analytics';

import { anonymousFeatures, userFeatures } from './features';

const allFeatures = { ...anonymousFeatures, ...userFeatures };

interface Logged {
  /** Cache of all logged feature treatment values */
  cache: {
    [keyName: string]: {
      [feature: string]: SplitIO.ImpressionData;
    };
  };
  /** Get an impression from cache */
  get: (
    impressionData: SplitIO.ImpressionData
  ) => SplitIO.ImpressionData | undefined;
  /** Store an impression in cache */
  set: (impressionData: SplitIO.ImpressionData) => void;
}

/**
 * A cache to manage if the treatment as already been logged to Segment. If the
 * feature's treatment or attributes are different from cache will remove from
 * cache.
 */
export const logged: Logged = {
  cache: {},
  get(impressionData: SplitIO.ImpressionData) {
    const attributes = impressionData.attributes;
    const { treatment, keyName, feature } = impressionData.impression;

    const inCache = this.cache[keyName]?.[feature];

    if (!inCache) {
      return;
    }
    const cachedAttributes = inCache.attributes;
    const cachedTreatment = inCache.impression.treatment;

    if (
      treatment !== cachedTreatment ||
      !isEqual(
        omit(attributes, ['current_date']),
        omit(cachedAttributes, ['current_date'])
      )
    ) {
      return;
    }

    return inCache;
  },
  set(impressionData: SplitIO.ImpressionData) {
    const { keyName, feature } = impressionData.impression;

    if (!this.cache[keyName]) {
      this.cache[keyName] = {
        [feature]: impressionData,
      };
    }

    this.cache[keyName][feature] = impressionData;
  },
};

/**
 * Gets treatment data from split as well as some split meta data
 * and logs to segment
 *
 * @param impressionData - Tracking impression data provided by split factory
 */
export function logImpression(impressionData: SplitIO.ImpressionData) {
  const feature = impressionData.impression.feature;

  const shouldTrack = allFeatures[feature]?.track;
  if (!shouldTrack) {
    return;
  }

  const hasLogged = Boolean(logged.get(impressionData));
  if (hasLogged) {
    return;
  }

  track(events.split_get_treatment, impressionData);

  logged.set(impressionData);
}
