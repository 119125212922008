import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import { RedditMethod } from './types';

import type { RedditEvent, RedditTagMounter } from './types';

export interface TrackRedditEventArgs {
  name: RedditEvent;
  properties?: Record<string, unknown>;
}

declare global {
  interface Window {
    rdt?: RedditTagMounter;
  }
}

function _mountRedditEvent(
  w: Window,
  { name, properties = {} }: TrackRedditEventArgs
) {
  // TODO update based on primary tracking method
  w.rdt?.(RedditMethod.Track, name, properties);
}

export const mountRedditEvent = mountVendorWindowEvent(
  'Reddit',
  _mountRedditEvent
);
