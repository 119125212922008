import { PageWidth } from '@farmersdog/corgi-x';
import { CheckMarksSection } from './CheckMarksSection/CheckMarksSection';
import styles from './SectionVet.module.scss';
import { useCorgiViewport } from 'src/screen';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { HomepageSections } from '../../../HomepageRedesign/components/CtaTrackerV2';

export const SectionVet = () => {
  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const sectionRef = useTrackedSectionRef(HomepageSections.Vet);

  return (
    <section className={styles.container} ref={sectionRef}>
      <PageWidth>
        <CheckMarksSection isMobile={isMobile} />
      </PageWidth>
    </section>
  );
};
