import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import { Grid, GridItem, Picture, Section } from '@farmersdog/corgi-x';

import {
  PickyEatingMobileSources,
  WeightManagementMobileSources,
  SeniorCareMobileSources,
  PuppyCareMobileSources,
} from '../../assets';
import OpenIcon from '../../assets/open-icon.svg';
import { BenefitCardVariant, benefitDataMap } from '../../data';
import { ExtendedText } from '../../../ExtendedText';
import { ExtendedButton } from '../../../ExtendedButton';
import { generateMobileSourcesForPicture } from '../../../../../Home/components/AssetPreloader/assets/shared';
import { useTrackedSectionRef } from '../../../../../../hooks/useTrackedSections';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

import styles from './LifetimeOfBenefitsMobile.module.css';

const tileData = {
  [BenefitCardVariant.WeightManagement]: {
    sources: WeightManagementMobileSources,
    title: (
      <>
        Weight
        <br />
        Management
      </>
    ),
  },
  [BenefitCardVariant.PickyEating]: {
    sources: PickyEatingMobileSources,
    title: (
      <>
        Picky
        <br />
        Eating
      </>
    ),
  },
  [BenefitCardVariant.SeniorCare]: {
    sources: SeniorCareMobileSources,
    title: (
      <>
        Senior
        <br />
        Care
      </>
    ),
  },
  [BenefitCardVariant.PuppyCare]: {
    sources: PuppyCareMobileSources,
    title: (
      <>
        Puppy
        <br />
        Care
      </>
    ),
  },
};

interface LifetimeOfBenefitsProps {
  onTileClick: (key: BenefitCardVariant) => void;
}

export const LifetimeOfBenefitsMobile = ({
  onTileClick,
}: LifetimeOfBenefitsProps) => {
  const handleButtonClick = (data: BenefitCardVariant) => {
    onTileClick(data);
  };

  const sectionRef = useTrackedSectionRef(
    HomepageRedesignSections.LifetimeOfBenefits
  );

  return (
    <Section
      variant="default"
      className={styles.section}
      aria-label="A lifetime of benefits"
      sectionRef={sectionRef}
    >
      <ExtendedText variant="heading-28" color="kale-3" bold>
        A lifetime of benefits
      </ExtendedText>
      <ExtendedText
        as="div"
        variant="heading-16"
        color="kale-3"
        weight="semibold"
        topSpacing="sm"
        bottomSpacing="md"
      >
        Real, fresh food for all dogs with plans personalized to your dog’s
        specific needs.
      </ExtendedText>
      <Grid columnGap="sm" rowGap="sm">
        {Object.keys(benefitDataMap).map((tileName: string) => {
          const data = benefitDataMap[tileName as BenefitCardVariant];
          const { sources, title } = tileData[tileName as BenefitCardVariant];
          const sourcesForPicture = generateMobileSourcesForPicture(sources);

          return (
            <GridItem sm={6} key={tileName}>
              <CtaTrackerV2
                type="select"
                moduleLevel={1}
                moduleName={HomepageRedesignSections.LifetimeOfBenefits}
              >
                <button
                  className={styles.tile}
                  onClick={() =>
                    handleButtonClick(tileName as BenefitCardVariant)
                  }
                >
                  <ExtendedText
                    variant="heading-18"
                    bold
                    color="white"
                    className={styles.tileTitle}
                    align="left"
                  >
                    {title}
                  </ExtendedText>
                  <OpenIcon className={styles.openIcon} />
                  <Picture
                    className={styles.tileImage}
                    loading="lazy"
                    sources={sourcesForPicture}
                    alt={data.title}
                  />
                </button>
              </CtaTrackerV2>
            </GridItem>
          );
        })}
      </Grid>
      <CtaTrackerV2
        type="select"
        moduleLevel={1}
        moduleName={HomepageRedesignSections.LifetimeOfBenefits}
      >
        <ExtendedButton
          className={styles.button}
          variant="solid-kale"
          type="link"
          to={PATH_SIGNUP}
        >
          Personalize Your Plan Today
        </ExtendedButton>
      </CtaTrackerV2>
    </Section>
  );
};
