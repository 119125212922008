import { useState } from 'react';

import {
  useCustomerAccountData,
  type UseCustomerAccountData,
} from '@/account/app/hooks/useCustomerAccountData';
import { useCustomerAccountNavigation } from '@/account/app/hooks/useCustomerAccountNavigation';
import { usePrefetchData } from '@/account/app/hooks/usePrefetchData';
import type { Navigation } from '@/account/app/utils/getNavigation';
import { useSubscriptionStatus } from '@/account/graphql/core/queries/useSubscriptionStatus';
import type { SubscriptionType } from '@/account/graphql/core/types';

import type { ApolloError } from '@apollo/client';

export type UseCustomerAccount = {
  isLoading: boolean;
  loadingError: Error | ApolloError | undefined;
  activePets: UseCustomerAccountData['activePets'];
  navigation: Navigation | undefined;
  justInTimeEnabled: boolean | undefined;
  subscriptionType: SubscriptionType | undefined;
  isSubscriptionActive: boolean;
  withRegularOrder: boolean;
  hasTreatsInSubscription: boolean;
  bothSuspended: boolean;
  hidePaymentFailedWarningBadge: boolean;
  setHidePaymentFailedWarningBadge: (value: boolean) => void;
};

/** Feature hook for the customer account page. */
export function useCustomerAccount(): UseCustomerAccount {
  const prefetchState = usePrefetchData();
  const data = useCustomerAccountData();
  const navigation = useCustomerAccountNavigation(data);
  const { bothSuspended } = useSubscriptionStatus();

  /**
   * Session state, to be used as sparingly as possible and only for very
   * explicitly needed state management at the application level. State should
   * always be deliberately managed according to a feature's requirements. For
   * the session state variables below please add a comment explaining why it
   * should be managed at this level.
   */

  // This variable is used to toggle off the payment failed warning badge after
  // a customer uses it to open the payment method form. Due to complexities
  // right now with fetching accurate payment data, managing this state at the
  // app level is the best experience for the customer.
  const [hidePaymentFailedWarningBadge, setHidePaymentFailedWarningBadge] =
    useState(false);

  const customerAccount: UseCustomerAccount = {
    isLoading: data.isLoading || prefetchState.loading || !prefetchState.called,
    loadingError: data.loadingError || prefetchState.error,
    activePets: data.activePets,
    navigation,
    justInTimeEnabled: data?.subscription?.justInTimeEnabled ?? undefined,
    subscriptionType: data?.subscription?.type ?? undefined,
    isSubscriptionActive: data.isSubscriptionActive,
    withRegularOrder: data.withRegularOrder,

    hasTreatsInSubscription: data.hasTreatsInSubscription,
    bothSuspended: bothSuspended,

    hidePaymentFailedWarningBadge,
    setHidePaymentFailedWarningBadge,
  };

  return customerAccount;
}
