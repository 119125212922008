import { useCallback, useEffect, useState } from 'react';
import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import {
  PickyEatingSources,
  PuppyCareSources,
  SeniorCareSources,
  WeightManagementSources,
} from '../../assets';
import { BenefitCard } from '../BenefitCard';
import { BenefitModal } from '../BenefitModal';
import { BenefitCardVariant } from '../../data';
import { ExtendedButton } from '../../../ExtendedButton';
import { useTrackedSectionRef } from '../../../../../../hooks/useTrackedSections';
import { HomepageRedesignSections, CtaTrackerV2 } from '../../../CtaTrackerV2';
import { trackModuleViewed } from '../../../../../../analytics/events/trackModuleViewed';
import { PATH_SIGNUP } from '@farmersdog/constants/paths';

import styles from './LifetimeBenefitsDesktop.module.css';

export const LifetimeBenefitsDesktop = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<BenefitCardVariant | null>(
    null
  );

  const handleClick = (variant: BenefitCardVariant) => {
    setSelectedCard(variant);
    setIsOpen(true);
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      trackModuleViewed({
        moduleName: HomepageRedesignSections.LifetimeOfBenefits,
        moduleLevel: 2,
      });
    }
  }, [isOpen]);

  const sectionRef = useTrackedSectionRef(
    HomepageRedesignSections.LifetimeOfBenefits
  );

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={styles.container}>
        <Text align="center" as="h2" variant="heading-40" color="kale-3" bold>
          A lifetime of benefits
        </Text>
        <Text
          weight="semibold"
          as="p"
          color="kale-3"
          align="center"
          topSpacing="sm"
          bottomSpacing="xl"
          variant="heading-22"
        >
          Real, fresh food for all dogs with plans personalized to your dog’s
          specific needs.
        </Text>

        <Grid alignItems="center" gap="md" flexDirection="column">
          <GridItem lg={12}>
            <Grid alignItems="flex-end" gap="md">
              <GridItem lg={6}>
                <BenefitCard
                  ariaLabel="Open Weight Management Modal"
                  title={
                    <>
                      Weight <br /> Management
                    </>
                  }
                  onClick={() =>
                    handleClick(BenefitCardVariant.WeightManagement)
                  }
                  alt="" // We don't need to add an alt text here since we are using aria-label
                  sources={WeightManagementSources}
                />
              </GridItem>
              <GridItem lg={6}>
                <BenefitCard
                  ariaLabel="Open Picky Eating Modal"
                  title={
                    <>
                      Picky <br /> Eating
                    </>
                  }
                  onClick={() => handleClick(BenefitCardVariant.PickyEating)}
                  alt="" // We don't need to add an alt text here since we are using aria-label
                  sources={PickyEatingSources}
                />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem lg={10}>
            <Grid gap="md">
              <GridItem lg={5}>
                <BenefitCard
                  ariaLabel="Open Senior Care Modal"
                  title={
                    <>
                      Senior <br /> Care
                    </>
                  }
                  onClick={() => handleClick(BenefitCardVariant.SeniorCare)}
                  alt="" // We don't need to add an alt text here since we are using aria-label
                  sources={SeniorCareSources}
                />
              </GridItem>
              <GridItem lg={7}>
                <BenefitCard
                  ariaLabel="Open Puppy Care Modal"
                  title={
                    <>
                      Puppy <br /> Care
                    </>
                  }
                  zoomOrigin="right"
                  onClick={() => handleClick(BenefitCardVariant.PuppyCare)}
                  alt="" // We don't need to add an alt text here since we are using aria-label
                  sources={PuppyCareSources}
                />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        {selectedCard && (
          <BenefitModal
            contentKey={selectedCard}
            isOpen={isOpen}
            onClose={closeModal}
          />
        )}
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.LifetimeOfBenefits}
        >
          <ExtendedButton
            className={styles.button}
            variant="solid-kale"
            type="link"
            to={PATH_SIGNUP}
          >
            Personalize Your Plan Today
          </ExtendedButton>
        </CtaTrackerV2>
      </div>
    </section>
  );
};
