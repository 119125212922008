import { Breakpoint } from '@farmersdog/corgi-x';
import { PreloadLinkType } from '@farmersdog/tosa/src/components/shared/PreloadLink';

const mobileMediaQuery = `(max-width: ${Breakpoint.LG - 1}px)`;
const desktopMediaQuery = `(min-width: ${Breakpoint.LG}px)`;

export function generateSrcSetString(src: string, size: number): string {
  return `${src} ${size}w`;
}

interface PreloaderSources {
  webP: string;
  jpg: string;
}

export interface MobileAndDesktopSources {
  mobileSources: PreloaderSources;
  desktopSources: PreloaderSources;
}

export function generateSourcesForPreloader({
  mobileSources,
  desktopSources,
}: MobileAndDesktopSources) {
  const mobileSrcSetStrings = [
    generateSrcSetString(mobileSources.webP, Breakpoint.MD),
    generateSrcSetString(mobileSources.jpg, Breakpoint.MD),
  ];

  const desktopSrcSetStrings = [
    generateSrcSetString(desktopSources.webP, Breakpoint.LG),
    generateSrcSetString(desktopSources.jpg, Breakpoint.LG),
  ];

  const mobileSourcesForPreloader = {
    imageSrcSet: mobileSrcSetStrings.join(', '),
    media: mobileMediaQuery,
  };

  const desktopSourcesForPreloader = {
    imageSrcSet: desktopSrcSetStrings.join(', '),
    media: desktopMediaQuery,
  };

  return [mobileSourcesForPreloader, desktopSourcesForPreloader];
}

export function generateMobileSourcesForPicture(
  mobileSources: PreloaderSources
) {
  return [
    {
      src: mobileSources.webP,
      type: PreloadLinkType.Webp as string,
      media: mobileMediaQuery,
    },
    {
      src: mobileSources.jpg,
      type: PreloadLinkType.Jpg as string,
      media: mobileMediaQuery,
    },
  ];
}

export function generateDesktopSourcesForPicture(
  desktopSources: PreloaderSources
) {
  return [
    {
      src: desktopSources.webP,
      type: PreloadLinkType.Webp as string,
      media: desktopMediaQuery,
    },
    {
      src: desktopSources.jpg,
      type: PreloadLinkType.Jpg as string,
      media: desktopMediaQuery,
    },
  ];
}

export function generateSourcesForPicture({
  mobileSources,
  desktopSources,
}: MobileAndDesktopSources) {
  const mobileSourcesForPreloader =
    generateMobileSourcesForPicture(mobileSources);

  const desktopSourcesForPreloader =
    generateDesktopSourcesForPicture(desktopSources);

  return [...mobileSourcesForPreloader, ...desktopSourcesForPreloader];
}
