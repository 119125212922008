import type { Variants } from 'motion/react';

export const fadeIn: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 1, duration: 0.25 } },
};

export const shadowFadeIn: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 1.25, duration: 0.25 } },
};

export const rollInFromLeft: Variants = {
  hidden: { x: -800, rotate: -180 },
  visible: {
    x: 0,
    rotate: 0,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 12,
    },
  },
};

export const slideInFromRight: Variants = {
  hidden: { opacity: 0, x: 800, rotate: 45 },
  visible: {
    opacity: 1,
    x: 0,
    rotate: 0,
    transition: {
      type: 'spring',
      stiffness: 45,
      damping: 12,
      delay: 0,
    },
  },
};

export const slideInFromRightWithDelay: Variants = {
  hidden: { opacity: 0, x: 800, rotate: 45 },
  visible: {
    opacity: 1,
    x: 0,
    rotate: 0,
    transition: {
      type: 'spring',
      stiffness: 45,
      damping: 12,
      delay: 0.5,
    },
  },
};

export const staggeredFadeIn: { container: Variants; item: Variants } = {
  container: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.25,
        staggerChildren: 0.5,
      },
    },
  },
  item: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
};
