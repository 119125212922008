interface FilterQueryParameterArgs {
  search: string;
  params: Array<string>;
}

export function filterQueryParameters({
  search,
  params,
}: FilterQueryParameterArgs) {
  const searchParams = new URLSearchParams(search);
  params.forEach(parameter => searchParams.delete(parameter));
  const filteredQueryString = searchParams.toString();
  return filteredQueryString === '' ? '' : `?${filteredQueryString}`;
}
