import type { RecipeNames } from '@farmersdog/constants';

export const cvr_weight_override = 'cvr_weight_override';
export const cvr_global_discount_override = 'cvr_globaldiscount_override';
export const cvr_default_discount = 'cvr_default_discount';

export enum FeatureName {
  CVR_DEFAULT_DISCOUNT = 'cvr_default_discount',
  CVR_GLOBAL_DISCOUNT_OVERRIDE = 'cvr_globaldiscount_override',
  CVR_SKIP_GET_STARTED = 'cvr_skip_get_started',
  CVR_SURPRISE_HIGHER_DISCOUNT = 'cvr_surprise_higher_discount',
  CVR_WEIGHT_OVERRIDE = 'cvr_weight_override',
  CVR_TREATS_IN_CHECKOUT_UI = 'cvr_treats_in_checkout_ui',
  CVR_SIGNUP_ICON_UPDATE = 'cvr_signup_icon_update',
  CVR_TOSA_DISCOUNT_BANNER_COPY = 'cvr_tosa_discount_banner_copy',
  CVR_SIGNUP_CARD_SELECTORS = 'cvr_signup_card_selectors',
  AC_ME3_PAGE = 'ac_me3_page',
  AC_GET_STARTED_PAGE = 'ac_get_started_page',
}

export enum ToggleTreatments {
  off = 'off',
  on = 'on',
  control = 'control',
}

export enum RecipePagePackOrderEnum {
  off = 'off',
  chicken_first = 'chicken_first',
}

export enum SkipGetStartedTreatments {
  off = 'off',
  same_copy = 'same_copy',
  new_copy = 'new_copy',
  no_copy = 'no_copy',
}

export enum CvrSurpriseHigherDiscountTreatments {
  off = 'off',
  recipes = 'recipes',
  checkout = 'checkout',
}

export enum CvrTreatsInCheckoutUiTreatments {
  control = 'control',
  off = 'off',
  page = 'page',
}

export enum CvrPriorFoodTypeProgressionTreatments {
  off = 'off',
  on = 'on',
}
export enum CvrSignupCardSelectorsTreatments {
  off = 'off',
  with_continue_button = 'with_continue_button',
  without_continue_button = 'without_continue_button',
  control = 'control',
}

export enum AcMe3PageTreatments {
  off = 'off',
  me3_page_only_copy_change = 'me3_page_only_copy_change',
}

export enum AcGetStartedPageTreatments {
  off = 'off',
  get_started_page_only_copy_change = 'get_started_page_only_copy_change',
}

// Configs
export interface RecipePagePackOrderConfig {
  recipeOrder: RecipeNames[];
}
