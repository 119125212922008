import { useTosaUserFeature } from '../../../hooks/useTosaUserFeature';
import { AcGetStartedPageTreatments, FeatureName } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface UseGetStartedPageExperimentArgs {
  useFeature: UseFeatureHook;
}

export interface UseGetStartedPageExperimentReturn {
  isGetStartedPageEnabled: boolean;
}

export function useGetStartedPageExperiment({
  useFeature,
}: UseGetStartedPageExperimentArgs): UseGetStartedPageExperimentReturn {
  const { treatment } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.AC_GET_STARTED_PAGE,
  });

  return {
    isGetStartedPageEnabled:
      treatment ===
      AcGetStartedPageTreatments.get_started_page_only_copy_change,
  };
}
