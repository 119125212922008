import type { AriaAttributes } from 'react';
import { useRef } from 'react';
import { FocusOn } from 'react-focus-on';
import classNames from 'classnames';

import styles from './Drawer.module.css';

interface DrawerProps extends AriaAttributes {
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const Drawer = (props: DrawerProps) => {
  const { id, isOpen, onClose, children } = props;
  const startY = useRef<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startY.current === null) {
      return;
    }

    const endY = e.changedTouches[0].clientY;
    const deltaY = endY - startY.current;

    if (deltaY > 50) {
      onClose();
    }

    startY.current = null;
  };

  return (
    <FocusOn enabled={isOpen} focusLock scrollLock onEscapeKey={onClose}>
      <div
        data-testid="drawer-container"
        className={classNames(styles.container, { [styles.open]: isOpen })}
      >
        <button
          className={classNames(styles.overlay, { [styles.open]: isOpen })}
          onClick={onClose}
        />
        <div
          id={id}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          aria-modal={true}
          aria-hidden={!isOpen}
          aria-labelledby={props['aria-labelledby']}
          className={classNames(styles.slidingPanel, {
            [styles.open]: isOpen,
          })}
        >
          {children}
        </div>
      </div>
    </FocusOn>
  );
};
