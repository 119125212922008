import { RedditMethod } from './types';

import type { ScriptFunctionWithFallbackReturn } from '../../types';

export function getRedditScript(id: string): ScriptFunctionWithFallbackReturn {
  const fallbackScript = `
    !function(w) {
    if (!w.rdt) {
        var p = w.rdt = function() {
            p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
        };
        p.callQueue = [];
    }
    rdt('${RedditMethod.Init}', '${id}');
  }(window);
  `;

  // TODO: Add SDK loader script
  const sdkLoader = `
  !function(d) {
    var id = 'reddit-pixel'
    if (!d.getElementById(id)) {
        var t = d.createElement("script");
        t.src = "https://www.redditstatic.com/ads/pixel.js";
        t.async = true;
        t.id = id;
        var s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
    }
  }(document);
  `;

  return { fallbackScript, sdkLoader };
}
