export enum AmazonTagMethod {
  TrackEvent = 'trackEvent',
}

export enum AmazonTagEvent {
  PageView = 'PageView',
  Lead = 'Lead',
  AddToShoppingCart = 'AddToShoppingCart',
  Checkout = 'Checkout',
}

export type AmazonTagMounter = (
  method: AmazonTagMethod,
  eventName: AmazonTagEvent,
  properties?: Record<string, unknown>
) => void;
