import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import type { X_Events } from './types';

interface MountXEventParams {
  eventName: X_Events;
}
export function _mountXEvents(
  window: Window,
  { eventName }: MountXEventParams
) {
  const sdk = window.twq;
  sdk?.('event', eventName);
}

export const mountXEvent = mountVendorWindowEvent('X', _mountXEvents);
