import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import type { Ga4Configuration } from './types';

export interface ConfigureGa4Args {
  config: Ga4Configuration;
  properties: Record<string, unknown>;
}

function _configureGa4(
  w: Window,
  { config, properties = {} }: ConfigureGa4Args
) {
  const enabled = config.enabled;
  const measurementId = config.measurementId;

  if (!enabled || typeof measurementId !== 'string' || measurementId === '') {
    return;
  }

  w.gtag?.('config', measurementId, properties);
}

export const configureGa4 = mountVendorWindowEvent(
  'Ga4 Configure',
  _configureGa4
);
