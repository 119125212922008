import { Text, Mask, Nowrap, useViewport } from '@farmersdog/corgi-x';

import Picture from 'src/components/Picture';

import { jadaSources } from './assets';
import styles from './OurTale.module.scss';

function OurTale() {
  const { lg } = useViewport();
  const isMobile = !lg;
  return (
    <>
      <div className={styles.container}>
        <Text
          variant={isMobile ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Our tale...
        </Text>
        <Mask shape="circle" size="xl">
          <Picture
            className={styles.jadaPuppy}
            sources={jadaSources}
            alt="about-us-jada-puppy"
          />
        </Mask>
        <Text
          variant={isMobile ? 'heading-22' : 'heading-28'}
          color="kale-3"
          as="h2"
          bold
        >
          It began with Jada
        </Text>
        <Text variant="article-20" color="kale-3" as="p">
          Jada was the most beautiful puppy, but suffered from severe digestive
          issues the first two years of her life. Her dad (aka our{' '}
          <Nowrap>co-founder</Nowrap>), Brett, tried every food on the market.{' '}
          <Nowrap>Nothing helped.</Nowrap>
        </Text>
      </div>
    </>
  );
}

export default OurTale;
