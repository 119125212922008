import { useEffect, useState } from 'react';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import { Modal } from '@farmersdog/corgi';
import { Text, Button, Divider, Accordion } from '@farmersdog/corgi-x';

import { useGetLead } from '../../graphql/queries';
import { useGlobalLightbox, LightboxId } from '../../hooks';

import { useHotKeys } from './hooks';
import styles from './TosaDevTools.module.css';

export function TosaDevToolsNonBlueprint() {
  const lightboxControl = useGlobalLightbox({
    id: LightboxId.TosaDevTools,
  });

  const { data: leadData } = useGetLead();
  useHotKeys();

  const [isSignupRecoveryLinkCopied, setIsSignupRecoveryLinkCopied] =
    useState<boolean>(false);
  const [isEmailCopied, setIsEmailCopied] = useState<boolean>(false);
  const leadEmail = leadData?.lead?.email;

  useEffect(() => {
    const buttonDisableTimeout = 3_000;
    if (isSignupRecoveryLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsSignupRecoveryLinkCopied(false);
      }, buttonDisableTimeout);
      return () => clearTimeout(timeoutId);
    }
    if (isEmailCopied) {
      const timeoutId = setTimeout(() => {
        setIsEmailCopied(false);
      }, buttonDisableTimeout);
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [isSignupRecoveryLinkCopied, isEmailCopied]);

  const handleCopySignupLink = async () => {
    if ('clipboard' in navigator && leadEmail) {
      setIsSignupRecoveryLinkCopied(true);
      return await navigator.clipboard.writeText(
        `${window.location.origin}${PATH_SIGNUP}/${leadEmail}`
      );
    }
  };

  const handleCopyEmail = async () => {
    if ('clipboard' in navigator && leadEmail) {
      setIsEmailCopied(true);
      return await navigator.clipboard.writeText(leadEmail);
    }
  };

  return (
    <Modal {...lightboxControl}>
      <Text variant="heading-28" bold color="kale-3">
        TOSA Developer Tools 🐶
      </Text>
      <Divider color="Charcoal0" className={styles.divider} />
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          onClick={handleCopySignupLink}
          disabled={!leadEmail || isSignupRecoveryLinkCopied}
          className={styles.copyButton}
        >
          {isSignupRecoveryLinkCopied
            ? 'Copied Recovery Link!'
            : 'Copy Recovery Link'}
        </Button>
        <Button
          type="button"
          onClick={handleCopyEmail}
          disabled={!leadEmail || isEmailCopied}
          className={styles.copyButton}
        >
          {isEmailCopied ? 'Copied Email!' : 'Copy Email Address'}
        </Button>
      </div>
      <Accordion
        rows={[
          {
            title: 'LeadView',
            content: (
              <Text variant="heading-16">
                <pre>{JSON.stringify(leadData?.lead, null, 2)}</pre>
              </Text>
            ),
          },
        ]}
      />
    </Modal>
  );
}
