import { Picture } from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { imageCardSources } from './assets';

import { ExtendedButton } from '../ExtendedButton';

import styles from './ImageCard.module.css';

export const ImageCard = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  return (
    <div className={styles.container}>
      <Picture
        sources={[imageCardSources.webp, imageCardSources.jpg]}
        className={styles.backgroundImage}
        loading="lazy"
        alt="Boy sitting in The Farmers Dog box"
      />
      <ExtendedButton
        className={styles.cta}
        variant="bordered-white"
        type="link"
        to={linkToFreshSignup}
      >
        Get Started
      </ExtendedButton>
    </div>
  );
};
