import { Grid, Text, Nowrap } from '@farmersdog/corgi-x';
import { HeartIconSvg, IngredientsIconSvg, SaucepanIconSvg } from './assets';

import styles from './HeroIconItemsMobile.module.css';

const heroIconItems = [
  {
    id: 'human-grade-safety',
    icon: IngredientsIconSvg,
    iconLabel: 'Dog food ingredients',
    text: (
      <>
        <Nowrap>Human-grade</Nowrap> <Nowrap>safety standards</Nowrap>
      </>
    ),
  },
  {
    id: 'delivered-to-your-door',
    icon: SaucepanIconSvg,
    iconLabel: 'Ingredients in Saucepan',
    text: (
      <>
        <Nowrap>Real food, </Nowrap> <Nowrap>gently cooked</Nowrap>
      </>
    ),
  },
  {
    id: 'loved-by-millions',
    icon: HeartIconSvg,
    iconLabel: 'Heart',
    text: (
      <>
        <Nowrap>Loved by</Nowrap> millions
      </>
    ),
  },
];

export const HeroIconItemsMobile = () => (
  <Grid
    alignItems="center"
    justifyContent="space-between"
    hSpacing={{ xs: 'none', md: 'lg' }}
    className={styles.iconItemsContainer}
  >
    {heroIconItems.map(item => (
      <div key={item.id} className={styles.iconItem}>
        <item.icon
          role="img"
          aria-label={item.iconLabel}
          className={styles.icon}
        />
        <Text
          color="kale-3"
          variant="heading-12"
          className={styles.iconText}
          weight="semibold"
        >
          {item.text}
        </Text>
      </div>
    ))}
  </Grid>
);
