import config from 'src/config';

import { logImpression } from './logImpression';
import { mockedFeatures } from './features';

const isDev = config('splitio.authorizationKey') === 'localhost';

/**
 * Split configuration settings to be used on the browser
 */
export const clientConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: config('splitio.authorizationKey'),
    key: 'NO_SEGMENT_ID',
  },
  impressionListener: {
    logImpression,
  },
  features: isDev ? mockedFeatures : undefined,

  debug: config('splitio.debug'),
};
