export function pluralizeString(
  quantity: number,
  singular: string,
  plural: string,
  options?: { prependQuantity: boolean }
): string {
  const string = options?.prependQuantity ? `${quantity} ` : '';
  if (quantity === 0) {
    return string + plural;
  }
  if (quantity <= 1) {
    return string + singular;
  }
  return string + plural;
}
