import { track } from '../../../../analytics';
import type { NativeWistiaPlayer } from './types';

export interface VideoTrackOpts {
  moduleName: string;
  videoTag?: string;
}

export function trackVideoPlayed(
  trackOpts: VideoTrackOpts,
  wistia: NativeWistiaPlayer
) {
  track('video_played', {
    action: 'play',
    ...trackOpts,
    ...getWistiaTrackOpts(wistia),
  });
}

export function trackVideoPaused(
  trackOpts: VideoTrackOpts,
  wistia: NativeWistiaPlayer
) {
  track('video_played', {
    action: 'pause',
    ...trackOpts,
    ...getWistiaTrackOpts(wistia),
  });
}

export function trackVideoCompleted(
  trackOpts: VideoTrackOpts,
  wistia: NativeWistiaPlayer
) {
  track('video_completed', {
    ...trackOpts,
    ...getWistiaTrackOpts(wistia),
  });
}

function getWistiaTrackOpts(wistia: NativeWistiaPlayer) {
  return {
    videoName: wistia.name(),
    timePlayed: wistia.time(),
  };
}
