import { ProductLineType } from '../../graphql/types';

import type { FetchLeadQuery, PetInputForQuote } from '../../graphql/types';
import type { PetRecipes } from '../../types';
interface ConvertToPetInputsForQuoteArgs {
  pets: FetchLeadQuery['lead']['pets'];
  currentPetRecipeSelection: string[];
  currentPetName: string;
  availablePetRecipesMap: PetRecipes;
}

export function convertToPetInputsForQuote({
  pets,
  currentPetRecipeSelection,
  currentPetName,
  availablePetRecipesMap,
}: ConvertToPetInputsForQuoteArgs): Omit<
  PetInputForQuote,
  'requestedCalories'
>[] {
  return pets.map(pet => {
    const selection =
      pet.name === currentPetName
        ? currentPetRecipeSelection
        : getNonCurrentPetSelection({
            pet,
            availablePetRecipesMap,
          });

    return {
      name: pet.name,
      selection: {
        diy: null,
        fresh: {
          productLine: ProductLineType.Fresh,
          options: { recipes: selection },
        },
      },
    };
  });
}

function getNonCurrentPetSelection({
  pet,
  availablePetRecipesMap,
}: {
  pet: FetchLeadQuery['lead']['pets'][number];
  availablePetRecipesMap: PetRecipes;
}) {
  const storedPetSelection = pet.selection?.fresh?.options.recipes;
  const recommendation = availablePetRecipesMap[pet.name]
    .filter(recipe => recipe.recommended)
    .map(recipe => recipe.name);

  return storedPetSelection ?? recommendation;
}
