import { Grid, GridItem, Link, PageWidth, Text } from '@farmersdog/corgi-x';
import FoodItem from '../FoodItem';

import { PATH_WHY_FRESH } from '@farmersdog/constants/paths';
import { ITEMS } from './constants';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import {
  CtaTrackerV2,
  HomepageSections,
} from '../../../HomepageRedesign/components/CtaTrackerV2';

import styles from './SectionFood.module.scss';
import classNames from 'classnames';

function SectionFood() {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  const sectionRef = useTrackedSectionRef(HomepageSections.Food);

  return (
    <section
      className={classNames(styles.section, {
        [styles.accessibilityTheme]: isAccessibilityThemeOn,
      })}
      ref={sectionRef}
    >
      <PageWidth>
        <Grid
          className={styles.container}
          rowGap={{ xs: 'xl', lg: 'xxs' }}
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          topSpacing={{ xs: 'xl', lg: '3xl' }}
          columnGap="xl"
        >
          <GridItem flexDirection="column" lg={6}>
            <GridItem className={styles.infoContainer} flexDirection="column">
              <Text
                variant="heading-40"
                bold
                color={isAccessibilityThemeOn ? 'charcoal-3' : 'white'}
              >
                It’s time for a fresh
                <br /> approach to pet food.
              </Text>
              <Text
                variant="article-20"
                color={isAccessibilityThemeOn ? 'charcoal-3' : 'white'}
                className={styles.description}
              >
                By sending food directly to you, we can invest in better
                ingredients and fresher food. Quality never found in a store.{' '}
                <CtaTrackerV2
                  moduleLevel={1}
                  moduleName={HomepageSections.Food}
                  type="select"
                >
                  <Link
                    className={classNames(styles.descriptionLink, {
                      [styles.accessibilityThemeLink]: isAccessibilityThemeOn,
                    })}
                    color="kale"
                    to={PATH_WHY_FRESH}
                  >
                    Learn more about the importance of real food.
                  </Link>
                </CtaTrackerV2>
              </Text>
            </GridItem>
          </GridItem>
          <GridItem
            className={styles.itemsSection}
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: 'center', lg: 'flex-end' }}
            lg={6}
          >
            <GridItem
              className={styles.itemsContainer}
              flexDirection={{ xs: 'row', lg: 'column' }}
              justifyContent="center"
            >
              {ITEMS.map(({ title, accessibleTitle, description, id }) => (
                <FoodItem
                  title={title}
                  accessibleTitle={accessibleTitle}
                  key={id}
                  isTitleStrikeOut
                  color={isAccessibilityThemeOn ? 'charcoal-3' : undefined}
                >
                  {description}
                </FoodItem>
              ))}
            </GridItem>
          </GridItem>
        </Grid>
      </PageWidth>
    </section>
  );
}

export default SectionFood;
