import cookie from 'js-cookie';

import { IMPACT_CLICK_ID_COOKIE } from '../constants/cookie';

/**
 * Sanitizes a referrer code that may have been
 * parsed improperly due to a malformed URL
 *
 * @param str - unknown value that should represent a potential code
 */
export function sanitizeCode(str: unknown) {
  if (!str || typeof str !== 'string') {
    return '';
  }
  const match = str.substr(0, 100).match(/\w+/);
  return match ? match[0] : '';
}

/**
 * Returns the Segment context object needed to trigger ImpactRadius
 */
export function getImpactRadiusSegmentContext() {
  const id = cookie.get(IMPACT_CLICK_ID_COOKIE) || null;
  return id
    ? {
        referrer: {
          type: 'impactRadius',
          id,
        },
      }
    : {};
}
