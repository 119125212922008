import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Grid, useViewport } from '@farmersdog/corgi-x';

import { Drawer } from 'src/components/Drawer';
import { Navigation } from 'src/components/Navigation';
import { FullFooter } from '../../components/Footer';

import Page from 'src/components/Page';
import schema from '../Home/schema.json';

import {
  BenefitCardVariant,
  BetterForThem,
  ContactUs,
  DidYouKnowSection,
  DrawerContent,
  FAQ,
  HeroSection,
  LifetimeOfBenefits,
  PressBarSection,
  StoriesFromSection,
  VetsKnowBest,
  Survey,
} from './components';
import { HomepageRedesignSections } from './components/CtaTrackerV2';
import { trackModuleViewed } from '../../analytics/events/trackModuleViewed';

import styles from './HomepageRedesign.module.css';

export const HomepageRedesign = () => {
  const ctaReveal = useRef(null);
  const [drawerDataKey, setDrawerDataKey] = useState<BenefitCardVariant>(
    BenefitCardVariant.WeightManagement
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isDesktop = useViewport().lg;

  const handleDrawerOpen = (key: BenefitCardVariant) => {
    setDrawerDataKey(key);
    setIsDrawerOpen(true);
  };

  const onCloseDrawer = () => setIsDrawerOpen(false);

  if (isDesktop && isDrawerOpen) {
    onCloseDrawer();
  }

  useEffect(() => {
    if (isDrawerOpen) {
      trackModuleViewed({
        moduleName: HomepageRedesignSections.LifetimeOfBenefits,
        moduleLevel: 2,
      });
    }
  }, [isDrawerOpen]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div className={styles.pageBackground}>
        <Navigation backgroundColor="chickpea" ctaTrigger={ctaReveal} />
        <HeroSection ctaRevealElement={ctaReveal} />
        <div>
          <Page style={{ overflowX: 'hidden' }}>
            <Grid
              className={styles.grid}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <DidYouKnowSection />
              <LifetimeOfBenefits onTileClick={handleDrawerOpen} />
              <PressBarSection />
              <BetterForThem />
              <StoriesFromSection />
              <Survey />
              <VetsKnowBest />
              <FAQ />
              <ContactUs />
              <FullFooter />
            </Grid>
          </Page>
        </div>
      </div>
      <Drawer
        isOpen={isDrawerOpen}
        onClose={onCloseDrawer}
        id="lifetime-benefits-drawer"
        aria-labelled-by="lifetime-benefits-drawer-title"
      >
        <DrawerContent contentKey={drawerDataKey} onClose={onCloseDrawer} />
      </Drawer>
    </>
  );
};
