import { mountRedditEvent } from './mountRedditEvent';
import { RedditEvent } from './types';

import type { RedditEventBaseProperties } from './types';

export function mountRedditPurchase({ eventId }: RedditEventBaseProperties) {
  mountRedditEvent({
    name: RedditEvent.Purchase,
    properties: { conversionId: eventId },
  });
}
