import { HomepageHeroImageTreatments } from 'src/abTesting';

export const activeHomepageHeroTreatments = [
  HomepageHeroImageTreatments.LovedByHumans,
  HomepageHeroImageTreatments.CtaOnly50Off,
  HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing,
] as const;

export type CurrentHomepageHeroTreatment =
  (typeof activeHomepageHeroTreatments)[number];
