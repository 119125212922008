import type React from 'react';
import { useCallback } from 'react';
import { Button, Link, Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

import styles from './Heading.module.css';
import classNames from 'classnames';
import { trackHomepageSuccess } from 'src/analytics';
import { useSelector } from 'react-redux';
import { selectRequestAttributes } from 'src/reducers/referrer';
import {
  CtaTrackerV2,
  HomepageSections,
} from '../../../../../../../HomepageRedesign/components/CtaTrackerV2';

interface HeadingProps {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
  isSmallSubtitle?: boolean;
  isTextOnBottom?: boolean;
  greenFont?: boolean;
  ctaString: string;
  buttonClassname?: string;
  subtitleClassname?: string;
  titleClassname?: string;
  ctaClassname?: string;
  copyContainerClassname?: string;
}

export const Heading = ({
  title,
  subtitle,
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  isTextOnBottom,
  greenFont,
  ctaString,
  buttonClassname,
  subtitleClassname,
  titleClassname,
  ctaClassname,
  copyContainerClassname,
}: HeadingProps) => {
  const isMobile = useCorgiViewport({
    xs: true,
    lg: false,
  });

  const requestAttributes = useSelector(selectRequestAttributes);

  const ctaClassNames = classNames(buttonClassname, ctaClassname, {
    [styles.accessibilityThemeCta]: isAccessibilityThemeOn,
    [styles.ctaButton]: !isAccessibilityThemeOn,
    [styles.bottomTextButton]: isTextOnBottom,
  });

  const subtitleClassNames = classNames(styles.subtitle, subtitleClassname);
  const titleClassNames = classNames(styles.title, titleClassname);
  const wrapperClassNames = classNames(
    styles.baseCopyContainerClassname,
    copyContainerClassname
  );

  const titleColor = isHeadlineLink
    ? undefined
    : greenFont
      ? 'kale-3'
      : 'white';

  const trackHomepageSuccessClick = useCallback(() => {
    trackHomepageSuccess({
      location: 'Hero',
      ctaCopy: ctaString,
      requestAttributes,
    });
  }, [ctaString, requestAttributes]);

  return (
    <>
      <div className={wrapperClassNames}>
        <Text
          bold
          as="h1"
          topSpacing="none"
          bottomSpacing={isMobile ? 'sm' : 'md'}
          className={titleClassNames}
          data-testid="static-hero-title"
          color={titleColor}
        >
          {isHeadlineLink ? (
            <Link baseColor="white" to={linkToFreshSignup}>
              {title}
            </Link>
          ) : (
            title
          )}
        </Text>
        <Text
          bold
          as="h2"
          color={greenFont ? 'kale-3' : 'white'}
          topSpacing="none"
          className={subtitleClassNames}
          bottomSpacing={{
            lg: 'xl',
          }}
        >
          {subtitle}
        </Text>
      </div>
      <>
        <CtaTrackerV2
          moduleLevel={1}
          moduleName={HomepageSections.Hero}
          type="select"
        >
          <Button
            className={ctaClassNames}
            type="link"
            to={linkToFreshSignup}
            onClick={trackHomepageSuccessClick}
          >
            {ctaString}
          </Button>
        </CtaTrackerV2>
      </>
    </>
  );
};
