import classNames from 'classnames';
import { AnimatePresence, motion } from 'motion/react';
import {
  Grid,
  GridItem,
  Picture,
  Section,
  StatusMessage,
} from '@farmersdog/corgi-x';
import { useInterval } from 'src/hooks/useInterval/useInterval';
import { useState } from 'react';

import { CHANGE_ITEM_INTERVAL_SECONDS } from './config';
import { getPressBarItems } from './pressBarItems';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { HomepageRedesignSections } from '../CtaTrackerV2';
import { PressBarItem } from './PressBarItem';

import styles from './PressBarDesktop.module.css';

const ariaQuoteElementId = 'aria-quote-element';

export const PressBarDesktop = () => {
  const [triggered, setTriggered] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const pressBarItems = getPressBarItems({ isMobile: false });

  useInterval({
    durationSeconds: CHANGE_ITEM_INTERVAL_SECONDS,
    onTimeLapsed: () =>
      setActiveItem(prev => (prev + 1) % pressBarItems.length),
    enabled: triggered,
  });

  const boundedIndex = activeItem % pressBarItems.length;

  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.PressBar);

  return (
    <Section
      aria-label="Press Bar"
      hSpacing="lg"
      className={styles.wrapper}
      sectionRef={sectionRef}
    >
      <motion.div
        onViewportEnter={() => setTriggered(true)}
        className={styles.container}
      >
        <Grid className={styles.logos}>
          {pressBarItems.map((item, index) => (
            <GridItem md={2} key={item.alt}>
              <Picture
                sources={item.sources}
                alt={item.alt}
                style={{ maxHeight: item.height.desktop }}
                className={classNames(styles.image, {
                  [styles['active']]: activeItem === index,
                })}
                aria-describedby={ariaQuoteElementId}
              />
            </GridItem>
          ))}
        </Grid>
        <div className={styles.quote} tabIndex={0}>
          <StatusMessage
            id={ariaQuoteElementId}
            politeness="polite"
            aria-label={`${pressBarItems[boundedIndex].alt}: ${pressBarItems[boundedIndex].quote}`}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={boundedIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                aria-hidden
              >
                <PressBarItem quote={pressBarItems[boundedIndex].quote} />
              </motion.div>
            </AnimatePresence>
          </StatusMessage>
        </div>
      </motion.div>
    </Section>
  );
};
