import { Grid, GridItem, Button, PageWidth } from '@farmersdog/corgi-x';
import { HomepageTitle1 } from '../HomepageTitle';
import HowItWorksProgress from './HowItWorksProgress';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './SectionHowItWorks.module.scss';
import { track, events, trackHomepageSuccess } from 'src/analytics';
import { useSelector } from 'react-redux';
import { selectRequestAttributes } from 'src/reducers/referrer';
import {
  CtaTrackerV2,
  HomepageSections,
} from '../../../HomepageRedesign/components/CtaTrackerV2';

const CTA_COPY = 'Build My Plan';

function SectionHowItWorks() {
  const requestAttributes = useSelector(selectRequestAttributes);
  const handleClickBuildMyPlan = () => {
    track(events.homepage_click_how_it_works_cta);
    trackHomepageSuccess({
      location: 'How your plan works',
      ctaCopy: CTA_COPY,
      requestAttributes,
    });
  };
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <section className={styles.section}>
      <PageWidth>
        <Grid>
          <GridItem>
            <HomepageTitle1 center style={{ marginBottom: 0 }}>
              How your plan works
            </HomepageTitle1>
          </GridItem>
          <GridItem
            className={styles.infoContainer}
            justifyContent={{ xs: 'center' }}
          >
            <HowItWorksProgress />
          </GridItem>
          <GridItem
            className={styles.buttonContainer}
            justifyContent={{ xs: 'center' }}
          >
            <CtaTrackerV2
              moduleLevel={1}
              moduleName={HomepageSections.HowItWorks}
              type="select"
            >
              <Button
                type="link"
                to={PATH_SIGNUP_ME}
                onClick={handleClickBuildMyPlan}
                className={accessibilityThemeStyles.responsiveCta}
              >
                {CTA_COPY}
              </Button>
            </CtaTrackerV2>
          </GridItem>
        </Grid>
      </PageWidth>
    </section>
  );
}

export default SectionHowItWorks;
