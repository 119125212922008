export interface TikTokHashedData {
  sha256_email?: string;
  sha256_phone_number?: string;
  eventId?: string;
}

export enum TikTokCheckoutData {
  currency = 'USD',
}

export interface TikTokTrackEventProperties {
  event_id: string | undefined;
  content_type: 'product';
  content_id: 'fresh_food';
  value?: string;
  currency?: TikTokCheckoutData.currency;
}

export interface TikTokMounter {
  page?: () => void;
  track: (event: string, properties: TikTokTrackEventProperties) => void;
  identify: (data?: TikTokHashedData) => void;
}
