import type { MouseEvent } from 'react';
import { track } from '../../../../analytics';
import eventNames from '../../../../analytics/eventNames';

export interface TrackCtaClickProps {
  type: 'exit' | 'select';
  moduleLevel: 1 | 2;
  moduleName: string;
}

export function trackCtaClick(
  e: MouseEvent,
  { type, moduleLevel, moduleName }: TrackCtaClickProps
) {
  const elementTrackingProperties = getTrackingPropertiesFromClickableElement(
    e.currentTarget
  );

  track(eventNames.clicked, {
    component: 'button',
    type,
    moduleLevel,
    moduleName,
    ...elementTrackingProperties,
  });
}

function getTrackingPropertiesFromClickableElement(element: Element) {
  const text = element.textContent;
  const destination =
    element instanceof HTMLAnchorElement ? element.href : undefined;

  return {
    ...(text && { text }),
    ...(destination && { destination }),
  };
}
