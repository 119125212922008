import { useState, useEffect } from 'react';
import { reporter } from 'src/services/reporter';
import { useStripeElements } from '@farmersdog/corgi-x';
import { usePaymentRequestContext } from './PaymentRequestContext';
import { PAYMENT_REQUEST_BUTTON } from './constants';
import type {
  StripePaymentRequestButtonElement,
  StripePaymentRequestButtonElementClickEvent,
} from '@stripe/stripe-js';
import type { UseSubmitArgs } from '../pages/SignupCheckoutPage/hooks/useSubmit';
import { scrollToInput } from '../pages/SignupCheckoutPage/utils/scrollToInput';

const STRIPE_ELEMENT_ID = 'signup-payment-request-button';

const EXPRESS_PAY_RELEVANT_VALIDATION_FIELDS = [
  'hasAcceptedTermsAndPrivacyPolicy',
];

interface PaymentRequestButtonProps {
  validationErrors: UseSubmitArgs['validationErrors'];
  className?: string;
}

/**
 * Creates a dynamic payment request button from the Stripe Elements API. Can
 * only be used within both StripeContextProvider and StripeElementsProvider.
 */
function PaymentRequestButton({
  validationErrors,
  className,
}: PaymentRequestButtonProps) {
  const elements = useStripeElements();
  const {
    paymentRequest,
    canMakePayment,
    openPaymentInterface,
    attemptExpressPay,
  } = usePaymentRequestContext();
  const [isMounted, setIsMounted] = useState(false);
  const [stripeElement, setStripeElement] =
    useState<StripePaymentRequestButtonElement>();

  /**
   * Create payment request button Stripe Element. Requires paymentRequest
   * object to already exist.
   */
  useEffect(() => {
    if (!elements || !paymentRequest) {
      return;
    }

    const cachedElement = elements.getElement(PAYMENT_REQUEST_BUTTON);
    if (cachedElement) {
      setStripeElement(cachedElement);
      return;
    }

    setStripeElement(
      elements.create(PAYMENT_REQUEST_BUTTON, {
        paymentRequest,
        style: {
          paymentRequestButton: { height: '50px' },
        },
      })
    );
  }, [elements, paymentRequest]);

  /**
   * Mount element.
   */
  useEffect(() => {
    if (!stripeElement || !canMakePayment || isMounted) {
      return;
    }

    try {
      const element = document.getElementById(STRIPE_ELEMENT_ID);
      if (element) {
        stripeElement.mount(element);
      }
    } catch (error) {
      // Attempting to mount the button before calling canMakePayment on the
      // paymentRequest instance will throw a Stripe integration error.
      reporter.error(error);
      return;
    }

    setIsMounted(true);
  }, [stripeElement, canMakePayment, isMounted, openPaymentInterface]);

  /**
   * Manage event listeners.
   */
  useEffect(() => {
    if (!stripeElement || !isMounted) {
      return;
    }

    const onStripeElementClick = (
      e: StripePaymentRequestButtonElementClickEvent
    ) => {
      attemptExpressPay();

      const invalidField = (
        Object.keys(validationErrors) as (keyof typeof validationErrors)[]
      )
        .filter(field => EXPRESS_PAY_RELEVANT_VALIDATION_FIELDS.includes(field))
        .find(field => Boolean(validationErrors[field]));

      if (invalidField) {
        // See https://docs.stripe.com/js/element/events/on_click?type=paymentRequestButton#element_on_click-handler-preventDefault
        e.preventDefault();
        scrollToInput(invalidField);
        return;
      }

      openPaymentInterface();
    };

    stripeElement.on('click', onStripeElementClick);

    return () => {
      stripeElement.off('click', onStripeElementClick);
    };
  }, [
    stripeElement,
    isMounted,
    openPaymentInterface,
    validationErrors,
    attemptExpressPay,
  ]);

  // Returns a wrapper that Stripe appends a button to.
  return (
    <div
      className={className}
      id={STRIPE_ELEMENT_ID}
      data-testid="payment-request-button"
    />
  );
}

export default PaymentRequestButton;
