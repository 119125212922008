import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Logger } from '@farmersdog/logger';

import { logout } from 'src/actions/auth';
import { fetchPrescriptionDiets } from 'src/actions/configuration';
import { fetchProductCombinations } from 'src/actions/product';
import { fetchReferralStats } from 'src/actions/referral';
import { fetchUser } from 'src/actions/user';
import { USER_STATUS_ACTIVE } from 'src/constants/userStatus';
import { selectUser } from 'src/reducers/customerAccount/user';
import useAsyncFunction from 'src/utils/useAsyncFunction';

import { trackLogoutFrontendSuccess } from '@/account/app/analytics';
import type { UserStatus } from '@/account/graphql/core/types';

const log = new Logger('app:pages:CustomerAccount');

const UNAUTHORIZED_STATUS = 401;

/**
 * Return a function to fetch data required to display the customer account,
 * such as user data and app configuration.
 *
 */
export function useLazyPrefetchData() {
  const dispatch = useDispatch();

  return useCallback(
    async (cachedUser?: Reducer.User): Promise<UserStatus | undefined> => {
      log.debug('Fetching data for the Customer Account...');
      if (cachedUser && Object.keys(cachedUser).length > 0) {
        return cachedUser.status as UserStatus;
      }

      try {
        const [userResponse] = await Promise.all([
          dispatch(fetchUser()),
          /* TODO: Remove the fetchProductCombinations once we are no longer using
          the ChangeCalories workflow. SC60251 */
          dispatch(fetchProductCombinations()),
          dispatch(fetchReferralStats()),
          dispatch(fetchPrescriptionDiets()),
        ]);
        return userResponse.data.status as UserStatus;
      } catch (error: unknown) {
        // Ignore unauthorized error
        // @ts-expect-error Error can be any
        if (error?.status !== UNAUTHORIZED_STATUS) {
          throw error;
        }
        return;
      }
    },
    [dispatch]
  );
}

export function usePrefetchData() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const prefetchData = useLazyPrefetchData();
  const callLogout = () => {
    trackLogoutFrontendSuccess();
    dispatch(logout());
  };
  const onCompleted = (status: UserStatus | undefined) => {
    if (status !== USER_STATUS_ACTIVE) {
      callLogout();
    }
  };

  const [call, { loading, error, called }] = useAsyncFunction(
    () => prefetchData(user),
    {
      onCompleted,
      onError: callLogout,
    }
  );

  useEffect(() => {
    if (!called) {
      void call();
    }
  }, [call, called]);

  return {
    loading,
    error,
    called,
  };
}
