import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { anonymousId } from '@farmersdog/lead-browser-storage';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

import { selectTokenId } from 'src/reducers/auth';

const CONTROL = 'control';

interface UseCheckForTreatmentMismatchProps {
  featureKey: string;
  serverTreatment: string | undefined;
  clientTreatment: string;
  isReady: boolean;
  config: Record<string, unknown>;
}

export function useCheckForTreatmentMismatch({
  featureKey,
  serverTreatment,
  clientTreatment,
  isReady,
  config,
}: UseCheckForTreatmentMismatchProps): void {
  const didLog = useRef(false);
  const userId = useSelector(selectTokenId) as string | undefined;

  browserOnly(() => {
    if (
      isReady &&
      serverTreatment &&
      clientTreatment !== CONTROL &&
      clientTreatment !== serverTreatment &&
      !didLog.current
    ) {
      const tfdAnonymousId = anonymousId.get();
      const log = new Logger('website:ExperimentTreatmentMismatch');

      log.warn('Experiment Treatment Mismatch', {
        featureKey,
        clientTreatment,
        serverTreatment,
        userId,
        tfdAnonymousId,
        config,
      });

      didLog.current = true;
    }
  });
}
