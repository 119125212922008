import HeroOptimizedVariantDesktopJpg from './hero-optimized-desktop.jpg';
import HeroOptimizedVariantDesktopWebp from './hero-optimized-desktop.webp';
import HeroOptimizedVariantMobileJpg from './hero-optimized-mobile.jpg';
import HeroOptimizedVariantMobileWebp from './hero-optimized-mobile.webp';

export const heroOptimizedVariantSources = {
  mobileSources: {
    webP: HeroOptimizedVariantMobileWebp,
    jpg: HeroOptimizedVariantMobileJpg,
  },
  desktopSources: {
    webP: HeroOptimizedVariantDesktopWebp,
    jpg: HeroOptimizedVariantDesktopJpg,
  },
};
