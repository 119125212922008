import { useId } from 'react';

import {
  Text,
  PageWidth,
  Nowrap,
  VideoSection,
  useViewport,
} from '@farmersdog/corgi-x';

import { track } from 'src/analytics';
import events from 'src/analytics/eventNames';
import { MEET_THE_FOUNDERS } from 'src/constants/wistiaVideoIds';

import styles from './OurMission.module.scss';

const WISTIA_OPTIONS = {
  popover: true,
};

function OurMission() {
  const { lg } = useViewport();
  const overlayId = useId();

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <Text
          variant={lg ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Our mission
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          <p>
            We’re turning our unconditional love for dogs into uncomplicated
            care. And that starts with what you put in their bowl.
          </p>
          <p>
            We were founded by two dog lovers who were fed up with
            highly-processed, burnt brown balls being marketed as “natural” and{' '}
            <Nowrap id="ad-forever">“healthy”</Nowrap> — so they decided to
            reimagine pet food from the <Nowrap>ground up</Nowrap>.
          </p>
        </Text>
      </div>
      <PageWidth enabled={lg}>
        <h2 className={styles.videoHeading} aria-hidden="true" id={overlayId}>
          Meet the founders of The Farmer’s Dog who are on a mission to help all
          dogs live long, healthy lives.
        </h2>
        <VideoSection
          wistiaId={MEET_THE_FOUNDERS}
          wistiaOptions={WISTIA_OPTIONS}
          className={styles.video}
          aria-labelledby={overlayId}
          onPlay={() => track(events.about_us_video_play)}
          onPause={() => track(events.about_us_video_pause)}
          onEnd={() => track(events.about_us_video_end)}
        />
      </PageWidth>
    </div>
  );
}

export default OurMission;
