import { formatNames } from 'src/utils/formatters';

// Returns a single pet's name or 'your dogs'.
// Optionally use the possessive flag to return
// "your dogs'" or "{dog_name}'s"
export default function petNameOrYourDogs(
  petNames: string[],
  possessive?: boolean
) {
  const isPlural: boolean = petNames.length > 1;

  let formattedText: string = isPlural
    ? 'your dogs'
    : (formatNames(petNames) ?? '');
  if (possessive) {
    formattedText = formattedText.concat(isPlural ? "'" : "'s");
  }
  return formattedText;
}
