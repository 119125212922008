import { PATH_CAREERS } from '@farmersdog/constants/paths';
import { Nowrap, Text, Button, useViewport, Link } from '@farmersdog/corgi-x';

import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './Today.module.scss';

function Today() {
  const { lg } = useViewport();
  const isMobile = !lg;

  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <>
      <div className={styles.container}>
        <Text
          variant={isMobile ? 'heading-40' : 'heading-52'}
          color="kale-3"
          as="h1"
          bold
        >
          Today
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          Our team has grown to hundreds of employees (
          <Link to={PATH_CAREERS}>we’re hiring!</Link>) and we’re delivering
          millions of meals monthly. In many ways, we’re just getting started,
          but what drives us hasn’t changed: Making it easy to keep dogs
          healthy.
        </Text>
        <Text variant="article-20" color="charcoal-3" as="p">
          We’re not sure if Jada realizes how many dogs her story has ultimately
          impacted, but we think she’d be <Nowrap>pretty pleased.</Nowrap>
        </Text>
        <Button
          variant="primary"
          type="link"
          to={PATH_CAREERS}
          className={accessibilityThemeStyles.responsiveCta}
        >
          Start Your Fresh Journey
        </Button>
      </div>
    </>
  );
}

export default Today;
