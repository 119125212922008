import type { ReactNode } from 'react';

import { Link } from '@farmersdog/corgi';

import {
  PATH_DIY,
  PATH_HOME,
  PATH_LOGIN,
  PATH_PASSWORD_RESET,
  PATH_SIGNUP,
  PATH_WHY_FRESH,
} from '@farmersdog/constants/paths';

import {
  trackingInformationLink,
  orderRescheduleLink,
  orderArrivalLink,
  orderCancelLink,
} from 'src/pages/FAQ/FAQ';

import { EMAIL_DIY_HELP } from 'src/constants/emails';
import { EmailSupportButton } from 'src/components/EmailSupportButton';

interface Question {
  text: string;
  answer: ReactNode;
}

export interface FAQSection {
  title: string;
  questions: Question[];
}

export const sections: FAQSection[] = [
  {
    title: 'DIY Nutrient Mix',
    questions: [
      {
        text: 'Why do I need a Nutrient Mix for homemade dog food?',
        answer: (
          <p>
            A carefully formulated nutrient mix is vital to ensure your dog’s
            daily diet is complete and balanced and safe to feed long-term.
            Getting this balance right is hard when you’re cooking at home.
            Research shows just how hard: a well-known study found that 95% of
            published homemade dog food recipes lacked at least one vital
            nutrient for dogs, and 83% percent had multiple nutrient
            deficiencies. And nutritionally incomplete food is dangerous for
            your dog—nutrient deficiencies or imbalances can cause serious
            health issues including immune dysfunction, degenerative muscle
            diseases and bone deformities, and impaired liver function.
          </p>
        ),
      },
      {
        text: 'What’s in the Nutrient Mix?',
        answer: (
          <>
            <p>
              The DIY Nutrient Mix is a blend of vitamins and minerals designed
              to supplement the fresh-food recipes we recommend when you sign up
              for a DIY plan. Combined as suggested, they make nutritionally
              complete and balanced dog food that meets AAFCO’s nutritional
              standards for daily feeding.
            </p>
            <p>
              Here’s what’s in it: <br />
              Tricalcium Phosphate, Potassium Chloride, Choline Bitartrate, Sea
              Salt, Taurine, Zinc Amino Acid Chelate, Ferrous Amino Acid
              Chelate, Vitamin E Supplement, Potassium Iodide, Copper Amino Acid
              Chelate, Vitamin B12 Supplement, Manganese Amino Acid Chelate,
              Thiamine Mononitrate (Vitamin B1), Riboflavin Supplement (Vitamin
              B2), Sodium Selenite, Vitamin D3 Supplement, Pyridoxine
              Hydrochloride (Vitamin B-6), Folic Acid.
            </p>
          </>
        ),
      },
      {
        text: 'Are the ingredients human-grade?',
        answer: (
          <p>
            Every vitamin is 100% human grade and sourced from an FDA-regulated
            manufacturer.
          </p>
        ),
      },
      {
        text: 'Do you source any ingredients from China?',
        answer: <p>No, we do not source any of our ingredients from China.</p>,
      },
      {
        text: 'Can I add this to my current dry kibble/home cooked dog food recipes for a nutrient boost?',
        answer: (
          <p>
            No, the DIY Nutrient Mix is formulated specifically to accompany the
            fresh recipes that you can find in your customer account when you
            sign up for a DIY plan.
          </p>
        ),
      },
      {
        text: 'What do all those nutrients do?',
        answer: (
          <>
            <p>
              The nutrients in the DIY Mix work together to support your dog’s
              vital organs and bodily functions to help keep them healthy,
              smart, happy, and gorgeous. Here are just some of the things that
              the compounds in the DIY Nutrient Mix may do when combined with
              the fresh homemade dog food you cook:
            </p>
            <ul>
              <li>Contribute to brain development and memory</li>
              <li>Maintain a healthy heart</li>
              <li>Keep bones strong and joints limber</li>
              <li>Support immune function to keep illness at bay</li>
              <li>
                Improve skin and coat health to prevent itching—and promote
                stunning good looks
              </li>
              <li>Boost mood-related hormones for a happier dog!</li>
            </ul>
          </>
        ),
      },
      {
        text: 'How do I add the DIY Nutrient Mix to my homemade dog food recipe?',
        answer: (
          <>
            <p>
              We make the DIY Nutrient Mix easy to add to your home-prepared
              meals. Here’s how:
            </p>
            <p>
              Cook one of the fresh-food recipes recommended when you sign in to
              your account at thefarmersdog.com/diy.
            </p>
            <p>
              When the food is cooked, open the number of packets suggested in
              the recipe instructions.
            </p>
            <p>
              Add the contents of the packet or packets and stir well until the
              Mix is blended with the food.{' '}
            </p>
            <p>Let the food cool down, then serve or store.</p>
          </>
        ),
      },
      {
        text: 'What does complete and balanced mean? What is nutritionally complete homemade dog food?',
        answer: (
          <p>
            When stamped on a pet food label, “complete and balanced” indicates
            that the food inside has enough of the right nutrients, in the right
            balance, to sustain the life of a pet when fed exclusively. To earn
            the right to use those words, a pet food maker must show the food
            meets the nutritional adequacy standards set by the Association of
            American Feed Control Officials (AAFCO). Veterinarians and other
            experts recommend commercial pet food precisely because of this
            complete and balanced claim (even though most of these same products
            are actually{' '}
            <Link to={PATH_WHY_FRESH}>highly processed and unhealthy</Link>).
          </p>
        ),
      },
      {
        text: 'How do I know how much Nutrient Mix to add?',
        answer: (
          <p>
            When you <Link to={PATH_SIGNUP}>sign up</Link> for the DIY Nutrient
            Mix, you’ll get a plan that’s customized to YOUR dog or dogs, taking
            into account their age, weight, body condition, activity level, and
            more. We’ll show you the ideal recipes for your dog, and tell you
            exactly how much DIY Nutrient Mix to add to each batch of food to
            make it complete and balanced. No guesstimating or worrying—just
            fresh, healthy meals, made easier.
          </p>
        ),
      },
      {
        text: 'Does the Nutrient Mix work for homemade dog food for puppies?',
        answer: (
          <p>
            Yes! When you sign up you will find puppy-specific homemade dog food
            recipes that are formulated to meet their unique nutrient
            requirements.
          </p>
        ),
      },
      {
        text: 'Do I need to refrigerate the Nutrient Mix?',
        answer: (
          <p>
            No, the Nutrient Mix does not need to be refrigerated! Store in a
            cool, dry place and use by the best-by date on the packet.
          </p>
        ),
      },
      {
        text: 'What if I refrigerated the packets, do I need to throw it out?',
        answer: (
          <p>
            If you refrigerate the packet it will not impact the effectiveness
            of the nutrients. We do however recommend that you store the
            Nutrient Mix in a cool, dry place.
          </p>
        ),
      },
      {
        text: 'Do I need to consult my vet to use The Farmer’s Dog?',
        answer: (
          <p>
            Please do! They will appreciate the fact that we work with
            board-certified veterinary nutritionists to make our products. While
            there are a lot of different viewpoints on food in the veterinary
            community, just about every vet agrees that fresh, nutritionally
            balanced food is great for dogs.
          </p>
        ),
      },
      {
        text: 'Should I stop feeding my dog other supplements for their issues like digestive care or joint health?',
        answer: (
          <p>
            When you start using the DIY Nutrient Mix in your homemade food, you
            can and should stop using any other general-use dietary supplements.
            Continue to use any supplements for specific health issues as
            needed. Consult your vet if you have any questions.
          </p>
        ),
      },
      {
        text: 'Are humans able to eat the recipes w/ the nutrient pack added?',
        answer: (
          <p>
            Our DIY recipes and Nutrient Mix are specifically formulated just
            for dogs! While all of the ingredients we use are human-grade, so
            they’re safe enough for humans if you happen to consume some (go
            ahead and try a bite of your dog’s dinner...) we do not recommend
            adding the Mix to your human food. Consult your human doctor or
            nutritionist for supplement advice.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Creating your plan',
    questions: [
      {
        text: 'How do I sign up for a plan?',
        answer: (
          <p>
            Visit <Link to={PATH_DIY}>thefarmersdog.com/diy</Link> and answer a
            few questions about your dog. Based on the info you provide, we’ll
            show you a selection of homemade dog food recipes just right for
            your pup’s needs, and deliver your first shipment of DIY Nutrient
            Mix. Visit your account page at
            <Link to={PATH_LOGIN}>thefarmersdog.com/login</Link> any time to see
            additional recipes, instructions on how to prepare, cook, and store
            the food.
          </p>
        ),
      },
      {
        text: 'I currently have a subscription with The Farmer’s Dog. Can I still sign up for a DIY plan?',
        answer: (
          <p>
            If you already have a subscription to The Farmer’s Dog fresh meals
            and would like to try DIY, please reach out to our team at{' '}
            <Link to={`mailto:${EMAIL_DIY_HELP}`}>
              diysupport@thefarmersdog.com
            </Link>
            .
          </p>
        ),
      },
      {
        text: 'How much does the DIY Nutrient Mix cost?',
        answer: (
          <p>
            DIY Nutrient Mix plans start at $1/week and include free shipping.
            Each dog is different, and pricing will depend on a variety of
            factors including age, weight, activity level, and more. To
            determine your exact costs, simply visit thefarmersdog.com/DIY and
            tell us a bit about your pup!
          </p>
        ),
      },
      {
        text: 'Can I buy the Nutrient Mix without a subscription?',
        answer: (
          <p>
            At present, you can only buy the DIY Nutrient Mix by signing up for
            a plan at <Link to={PATH_DIY}>thefarmersdog.com/diy</Link>. Signing
            up for a plan ensures you’re getting homemade dog food recipes that
            are right for your dog, expert guidance on how much DIY Nutrient Mix
            to add to your meals, and how much food to serve your dog.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Cooking and feeding',
    questions: [
      {
        text: 'How do you make homemade dog food?',
        answer: (
          <p>
            Cooking complete and balanced homemade dog food is easy with The
            Farmer’s Dog DIY Nutrient Mix. Once you sign up, you will get
            tailored instructions that help you cook and portion out the food so
            that your dog gets all of the nutrients they need to thrive.
          </p>
        ),
      },
      {
        text: 'What homemade dog food recipes do you offer?',
        answer: (
          <p>
            Every dog has different dietary requirements and preferences, so we
            make it simple to discover the recipes that meet your own dog’s
            needs. When you sign up for a DIY plan, we’ll show you a customized
            range of easy-to-prepare homemade dog food recipes. We’ve got meals
            for most ingredient preferences, so you can pick what fits your dog
            best.
          </p>
        ),
      },
      {
        text: 'Do I have to purchase the ingredients myself?',
        answer: (
          <p>
            Yes. Shop for your ingredients at your store of choice. We’ll
            provide a list of ingredients and recipe instructions on your
            account page, and we’ll ship you the right amount of DIY Nutrient
            Mix.
          </p>
        ),
      },
      {
        text: 'Do you offer instructions for how to make homemade dog food?',
        answer: (
          <p>
            We sure do! We want to make cooking at home for your dog safe and
            enjoyable for you (no matter your culinary skill level), so we offer
            easy-to-follow instructions on all our homemade dog food recipes.
            We’ve even got videos to walk you through all the steps!
          </p>
        ),
      },
      {
        text: 'How often do I need to cook?',
        answer: (
          <p>
            How often you will need to cook depends on the size of your dog and
            your preference for the number of batches you want to cook at a
            time. You may wish to cook multiple batches at once—depending on
            your freezer space of course!
          </p>
        ),
      },
      {
        text: 'Can I use a slow cooker?',
        answer: (
          <p>
            Yes! Our instructions are tailored for easy, one-pot cooking on a
            stovetop, but you can also use a slow cooker if that’s preferred.
            Just make sure that all ingredients are cooked to a soft consistency
            (so they can be easily punctured with a fork), and the food reaches{' '}
            165 degrees throughout!
          </p>
        ),
      },
      {
        text: 'How do I figure out how much homemade dog food to feed?',
        answer: (
          <p>
            We take portion precision very seriously! (it’s one big advantage of
            signing up for a fresh-food plan at{' '}
            <Link to={PATH_HOME}>The Farmer’s Dog</Link>). Signing up for a DIY
            plan takes the guesswork out of cooking at home because we carefully
            calculate how much to feed your dog based on their individual needs.
            We’ll give you step-by-step instructions on how much food each batch
            will make and how much to feed your dog at each meal.
          </p>
        ),
      },
      {
        text: 'Do I need to transition my dog from their old food to homemade dog food?',
        answer: (
          <p>
            You should slowly transition your dog’s food over seven days. Start
            with a 25%-to-75% ratio of new to old food—so add 25% of a portion
            of home-cooked DIY food to 75% of a portion of their existing food.
            Slowly increase the portion of DIY over the course of the first week
            until you are feeding full DIY home-cooked meals. If your dog has a
            sensitive stomach you may want to transition slower!
          </p>
        ),
      },
      {
        text: ' How long can I keep the homemade dog food in the refrigerator?',
        answer: (
          <p>
            Treat the homemade dog food you make for your dog the same way you’d
            treat the food you cook for yourself. You can keep freshly cooked
            food in the fridge for up to 4 days. If you’re making a batch of
            food, keep a few days’ worth of food in the fridge for up to 4 days
            and freeze the rest. The food will keep in the freezer for up to 4
            months.
          </p>
        ),
      },
      {
        text: 'Where do I store the homemade dog food once it’s cooked?',
        answer: (
          <p>
            Once the food has cooled down and is portioned based on your
            instructions, you can set aside a few portions for each dog and put
            them in the fridge—you can store food in the fridge for up to 4
            days. Store the rest in the freezer. Move each portion to the fridge
            24 hours before feeding to thaw. If you forget to thaw, you can run
            the container under warm water until the food is fully thawed and
            ready to feed.
          </p>
        ),
      },
      {
        text: 'What’s the cost comparison between home cooked dog food and a fresh food plan?',
        answer: (
          <>
            <p>
              The cost of your dog’s food, whether it’s homecooked, bought in a
              store, or delivered as part of a subscription plan, depends on a
              range of factors. These include: the size, age, and body condition
              of your dog, where you live and shop, market conditions that may
              affect food prices, how much you buy and make at any one time, and
              many other considerations. Many people find that by the time they
              buy the ingredients, they may end up spending about the same, or
              (for certain dogs) even more than what they would spend on a
              subscription plan from The Farmer’s Dog. If you’ve been cooking at
              home and would like to check out pricing for a fresh-food delivery
              plan, simply visit thefarmersdog.com and tell us a bit about your
              dog.
            </p>
            <p>
              When it comes to the cost of feeding your dog, there’s one “rule”
              that applies to everyone: whatever investment you can make in
              fresh food for your dog is worth it—whether that’s home cooking or
              a subscription plan, and whether it’s just a small portion of
              their diet, or all they eat. It’s an investment in their health
              that can pay dividends in more happy, healthy years together.
            </p>
          </>
        ),
      },
      {
        text: 'Can I substitute ingredients?',
        answer: (
          <p>
            We formulate our recipes to vary ingredients to accommodate dogs
            with different palates (and make it easy for home cooks!). If you
            don’t find what you’re looking for,{' '}
            <EmailSupportButton asLink subject="DIY Support">
              reach out
            </EmailSupportButton>{' '}
            to us so we can work with our veterinary nutritionists on new
            recipes that accommodate your needs.
          </p>
        ),
      },
    ],
  },
  {
    title: 'Packaging and shipping',
    questions: [
      {
        text: 'Do you ship nationwide?',
        answer: <p>We currently ship to the 48 contiguous states.</p>,
      },
      {
        text: 'How can I find my tracking information?',
        answer: (
          <p>
            Click <Link to={trackingInformationLink}>here</Link> to see tracking
            info for your shipped orders. We also send an email notification
            with this information for each order.
          </p>
        ),
      },
      {
        text: 'Can I recycle the packaging?',
        answer: (
          <p>
            We pack our boxes with the environment in mind. You can recycle the
            cardboard box the DIY Nutrient Mix is delivered in. Our packets are
            currently non recyclable due to the stringent requirements in
            ensuring the product maintains its effectiveness.
          </p>
        ),
      },
      {
        text: 'It’s hot where I live. Can the packs be compromised by heat during transit?',
        answer: (
          <p>
            No! Our DIY Nutrient Mix is graded for all temperatures of cooking,
            which should be hotter than any temperatures it encounters in
            transit to your house :)
          </p>
        ),
      },
    ],
  },
  {
    title: 'Managing your plan',
    questions: [
      {
        text: 'Can I cancel or reactivate my subscription anytime?',
        answer: (
          <p>
            Yes, you can easily pause, cancel or reactivate your subscription
            anytime. Click <Link to={orderCancelLink}>here</Link> to visit your
            Customer Account to do so.
          </p>
        ),
      },
      {
        text: 'What if I am traveling?',
        answer: (
          <p>
            If you prefer to send the DIY Nutrient Mix directly to your
            destination, you can change your shipping address in your Customer
            Account at any time. If you’d rather travel with your Nutrient Mix
            and are running low, just rush an order to your home before you
            leave.
          </p>
        ),
      },
      {
        text: 'Can I change when my next order will arrive?',
        answer: (
          <p>
            Yes! In the Subscription tab of your Customer Account you can easily
            reschedule your upcoming order to receive it sooner, or to delay the
            shipment. Click <Link to={orderRescheduleLink}>here</Link> to
            reschedule your orders.
          </p>
        ),
      },
      {
        text: 'I forgot my password - how can I reset it to log into my Customer Account?',
        answer: (
          <p>
            You can reset your account password by visiting{' '}
            <Link to={PATH_PASSWORD_RESET}>this page</Link>. You’ll receive a
            reset link in your email inbox where you can then input a new
            password.
          </p>
        ),
      },
      {
        text: 'When will my orders arrive?',
        answer: (
          <p>
            Click <Link to={orderArrivalLink}>here</Link> to see a summary of
            your orders. Once an order is in transit, you’ll see its tracking
            link appear and can keep an eye on the anticipated delivery date.
          </p>
        ),
      },
      {
        text: 'Can I change when my next order will arrive?',
        answer: (
          <p>
            Yes! In the Subscription tab of your Customer Account you can easily
            reschedule your upcoming order to receive it sooner, or to delay the
            shipment. Click <Link to={orderRescheduleLink}>here</Link> to
            reschedule your orders.
          </p>
        ),
      },
    ],
  },
];
