export function submitFormById(formId: string) {
  // Because React uses synthetic events, we can't call `submit` on the `form`
  // element itself. Instead, we have to create an `input` with `type: submit`
  // for the form and manually call `click` on it.
  const submitButtonNode = document.createElement('input');
  submitButtonNode.type = 'submit';
  submitButtonNode.setAttribute('form', formId);
  document.body.appendChild(submitButtonNode);
  submitButtonNode.click();
  document.body.removeChild(submitButtonNode);
}
