import { useCallback } from 'react';

import { submitFormById } from '../utils/submitFormById';

import { LINGER_TIME_BEFORE_SUBMIT } from './experiments/useSignupCardsSelectors';
import { useSetTimeout } from './useSetTimeout';

export function useLingerAndSubmitFormById(
  formId: string | undefined,
  lingerTime = LINGER_TIME_BEFORE_SUBMIT
) {
  const submitForm = useCallback(() => {
    if (formId) {
      submitFormById(formId);
    }
  }, [formId]);

  return useSetTimeout(submitForm, lingerTime);
}
