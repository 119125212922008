import { isbot } from 'isbot';
import type { Request } from 'express';
import { getReferrerCookieSplitAttributes } from './getReferrerCookieSplitAttributes';
import { getReferralCookieFromRequest } from './getReferralCookieFromRequest';

export function getAttributes(request: Request) {
  const referrerCookie = getReferralCookieFromRequest(request);
  const referrerCookieSplitAttributes =
    getReferrerCookieSplitAttributes(referrerCookie);
  const isBot = isbot(request.get('user-agent'));
  return { ...referrerCookieSplitAttributes, isBot };
}
