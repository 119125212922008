import classNames from 'classnames';
import {
  Button,
  type ButtonProps as OriginalButtonProps,
  type ButtonType,
  type ButtonVariant,
} from '@farmersdog/corgi-x';
import styles from './ExtendedButton.module.css';

export type ExtendedButtonVariant =
  | ButtonVariant
  | 'solid-carrot'
  | 'solid-kale'
  | 'solid-white'
  | 'bordered-white'
  | 'bordered-kale'
  | 'bordered-charcoal';

export type ExtendedButtonProps<T extends ButtonType | undefined = 'button'> =
  Omit<OriginalButtonProps<T, ButtonVariant>, 'variant'> & {
    variant?: ExtendedButtonVariant;
  };

const getButtonVariantClassName = (variant?: ExtendedButtonVariant) => {
  if (variant === 'solid-carrot') {
    return styles.solidCarrot;
  }
  if (variant === 'solid-kale') {
    return styles.solidKale;
  }
  if (variant === 'solid-white') {
    return styles.solidWhite;
  }
  if (variant === 'bordered-white') {
    return styles.borderedWhite;
  }
  if (variant === 'bordered-kale') {
    return styles.borderedKale;
  }
  if (variant === 'bordered-charcoal') {
    return styles.borderedCharcoal;
  }
  return '';
};

export const ExtendedButton = <T extends ButtonType | undefined = 'button'>({
  variant,
  className,
  children,
  ...rest
}: ExtendedButtonProps<T>) => {
  return (
    <Button
      {...(rest as OriginalButtonProps<T, ButtonVariant>)}
      className={classNames(
        styles.extendedButtonBase,
        getButtonVariantClassName(variant),
        className
      )}
    >
      {children}
    </Button>
  );
};
