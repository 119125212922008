import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, useState, useCallback, useEffect } from 'react';

import classNames from 'classnames';

import { Play, Pause } from '@farmersdog/corgi-x/icons';

import { ActivityIndicator, ButtonBase, Text } from '@farmersdog/corgi-x';

import type { NativeWistiaOptions, WistiaEventHandlers } from './types';

import { useWistiaPlayer } from './useExtendedWistiaPlayer';
import { WistiaElement } from './ExtendedWistiaElement';

import NewPlayIcon from './assets/new-play-icon.svg';
import NewPauseIcon from './assets/new-pause-icon.svg';

import styles from './ExtendedVideoSection.module.css';

export type SectionAttributes = Pick<
  JSX.IntrinsicElements['section'],
  | 'className'
  | 'style'
  | 'aria-label'
  | 'aria-labelledby'
  | 'aria-description'
  | 'aria-describedby'
  | 'aria-hidden'
>;

type ControlsPosition = 'center' | 'bottom-right';

export interface VideoSectionProps
  extends WistiaEventHandlers,
    SectionAttributes {
  /** The video id from wistia (can be found in the url to the video). */
  wistiaId: string;
  /**
   * Options for the Wistia embedded player.
   *
   * @see https://wistia.com/support/developers/embed-options#options-list
   */
  wistiaOptions?: NativeWistiaOptions;
  /** Content to overlay on top of video, hides when played. */
  overlayContent?: ReactNode;
  /** Content to show before video loads */
  loadingPlaceholder?: ReactNode;
  /** Block all user interaction with the video. */
  blockInteractions?: boolean;
  /** Hide the play/pause button overlay. */
  hideControls?: boolean;
  /** @deprecated Use {@link VideoSectionProps.loadingPlaceholder} */
  children?: ReactNode;
  /** Defines the position of the play/pause button. */
  controlsPosition?: ControlsPosition;
  /** Defines the title of the video. */
  videoTitle?: string;
  /** Controls the visibility of the placeholder overlay */
  showOverlayContent?: boolean;
  /** Whether the video is the active slide in the gallery */
  activeSlideInGallery?: boolean;
  /** The page module the video is rendered in */
  moduleName: string;
  /** Test ID for the section component, used in testing environments */
  'data-testid'?: string;
}

/**
 * Render a section with a video.
 *
 * @see https://corgi-x.tfd.engineering/components/videosection
 */
export const ExtendedVideoSection = forwardRef(ExtendedVideoSection_);

function ExtendedVideoSection_(
  props: VideoSectionProps,
  forwardedRef?: ForwardedRef<HTMLElement>
) {
  const [focused, setFocused] = useState(false);
  const [hidePlayButton, setHidePlayButton] = useState(false);

  const handleOnEnd = useCallback(() => {
    setHidePlayButton(false);
    props.onEnd?.();
  }, [props]);

  const player = useWistiaPlayer(
    props.wistiaId,
    props.wistiaOptions,
    {
      onPlay: props.onPlay,
      onPause: props.onPause,
      onEnd: handleOnEnd,
      onReady: props.onReady,
    },
    props.moduleName,
    props.videoTitle
  );

  // Pause video when its not the active slide and show play button
  useEffect(() => {
    if (props.activeSlideInGallery === false && player.isPlaying) {
      player.pause();
    }
  }, [props.activeSlideInGallery, player]);

  const showControls =
    !props.hideControls &&
    hidePlayButton === false &&
    (focused ||
      (!player.isPlaying &&
        !player.isLoading &&
        !props.wistiaOptions?.autoPlay));

  const showOverlay = Boolean(props.overlayContent) && props.showOverlayContent;

  const playIcon =
    props.controlsPosition === 'bottom-right' ? NewPlayIcon : Play;
  const pauseIcon =
    props.controlsPosition === 'bottom-right' ? NewPauseIcon : Pause;

  const ControlsIcon = player.isPlaying ? pauseIcon : playIcon;

  const handleButtonClick = () => {
    if (!showControls) {
      return;
    }

    if (player.isPlaying) {
      player.pause();
    } else {
      player.play();
      if (hidePlayButton === false) {
        setHidePlayButton(true);
      }
    }
  };

  return (
    <section
      ref={forwardedRef}
      className={classNames(styles.container, props.className)}
      style={props.style}
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      aria-description={props['aria-description']}
      aria-describedby={props['aria-describedby']}
      aria-hidden={props['aria-hidden']}
      data-testid={props['data-testid']}
    >
      {props.loadingPlaceholder || props.children || (
        <div className={styles.activityIndicator}>
          <ActivityIndicator />
        </div>
      )}

      <WistiaElement
        className={styles.wistiaElement}
        id={props.wistiaId}
        options={props.wistiaOptions}
      />

      {(showControls || showOverlay) && <div className={styles.gradient} />}

      {showOverlay && (
        <div className={classNames(styles.overlay, styles.showOverlay)}>
          {props.overlayContent}
        </div>
      )}

      {props.videoTitle && showControls && (
        <div className={styles.title}>
          <Text color="white" bold variant="heading-16">
            {props.videoTitle}
          </Text>
        </div>
      )}

      <ButtonBase
        aria-label={player.isPlaying ? 'Pause Video' : 'Play Video'}
        aria-disabled={!showControls}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onClick={handleButtonClick}
        className={classNames(styles.controls, {
          [styles.bottomRightControls]:
            props.controlsPosition === 'bottom-right',
        })}
      >
        {showControls && (
          <ControlsIcon size={72} baseColor="White" aria-hidden />
        )}
      </ButtonBase>

      {props.blockInteractions && <div className={styles.interactionBlocker} />}
    </section>
  );
}

/** @deprecated Rename to {@link VideoSection}. */
export const Wistia = ExtendedVideoSection;
