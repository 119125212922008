import { MetaHeroGrid } from '../MetaHeroGrid';
import { FullScreenImageBackground } from '../FullScreenImageBackground';
import {
  LovedByHumansConfig,
  CtaOnly50OffConfig,
  CtaOnlySeePlansAndPricingConfig,
} from './HeroContent.config';
import styles from './HeroContent.module.css';
import { HomepageHeroImageTreatments } from 'src/abTesting';

interface HeroContentProps {
  variant: HomepageHeroImageTreatments;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
}

const DEFAULT_CONFIG = LovedByHumansConfig;

const HERO_CONFIG = {
  [HomepageHeroImageTreatments.Control]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.Off]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.LovedByHumans]: LovedByHumansConfig,
  [HomepageHeroImageTreatments.CtaOnly50Off]: CtaOnly50OffConfig,
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]:
    CtaOnlySeePlansAndPricingConfig,
};

export const HeroContent = ({
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  variant,
}: HeroContentProps) => {
  const heroConfig = HERO_CONFIG[variant];

  return (
    <MetaHeroGrid
      isTextOnBottom
      withRightOverlay
      isHeadlineLink={isHeadlineLink}
      linkToFreshSignup={linkToFreshSignup}
      isAccessibilityThemeOn={isAccessibilityThemeOn}
      headlineText={heroConfig.title}
      subtitle={heroConfig.subtitle}
      ctaString={heroConfig.cta}
      desktopColumnWidth={8}
      gridItemClassname={styles.cta}
      titleClassname={styles.title}
      subtitleClassname={styles.subtitle}
      buttonClassname={styles.button}
      containerClassname={styles.container}
    >
      <FullScreenImageBackground
        moveBackgroundLower={heroConfig.lowerBackground}
        sources={heroConfig.assets}
        alt={heroConfig.alt}
      />
    </MetaHeroGrid>
  );
};
