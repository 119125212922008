import classNames from 'classnames';
import { useCallback, useMemo } from 'react';

import type { RecipeNames } from '@farmersdog/constants';
import { useIntersection, useHtmlElement } from '@farmersdog/corgi';
import {
  AccessibleContent,
  Button,
  Divider,
  Grid,
  GridItem,
  Strikeout,
  Text,
  Wave,
} from '@farmersdog/corgi-x';

import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { useFetchQuote } from '../../../hooks/useFetchQuote';
import { formatCommaSeparatedList } from '../../../utils/formatCommaSeparatedList';
import { Footer } from '../../creative/GetStarted/Footer';
import { RecipesBenefits } from '../RecipesBenefits';
import { RecipesStack } from '../RecipesStack';
import { ReviewsSection } from '../Reviews/ReviewsSection';
import { checkLpfRecipeStatus } from '../utils/checkLpfRecipeStatus';
import { getQuoteData } from '../utils/getQuoteData';
import { getRecommendedRecipeCopy } from '../utils/getRecommendedRecipeCopy';

import { RecommendedPlanCta } from './components/cta';
import { StickyCta } from './components/cta/StickyCta';
import styles from './RecommendedPlan.module.css';
import { getFirstBoxAccessibleText } from './utils';

import type { FetchLeadQuery, FetchQuoteQuery } from '../../../graphql/types';
import type { PetRecipes } from '../../../types';

interface RecommendedPlanProps {
  className?: string;
  customizePlan: () => void;
  onSubmit: (quote: FetchQuoteQuery['fetchQuote'] | null) => void;
  isFormSubmitting: boolean;
  pets: FetchLeadQuery['lead']['pets'];
  petName: string;
  availablePetRecipesMap: PetRecipes;
}

export const RecommendedPlan = ({
  className,
  customizePlan,
  onSubmit,
  isFormSubmitting,
  pets,
  petName,
  availablePetRecipesMap,
}: RecommendedPlanProps) => {
  const currentPetRecipes = availablePetRecipesMap[petName];
  const recommendedRecipes = useMemo(
    () => currentPetRecipes.filter(recipe => recipe.recommended),
    [currentPetRecipes]
  );
  const formattedRecommendedRecipes = useMemo(
    () =>
      recommendedRecipes.map(recipe => ({
        name: recipe.name as RecipeNames,
        displayName: recipe.content.displayName,
        recommended: recipe.recommended,
      })),
    [recommendedRecipes]
  );

  const { quote } = useFetchQuote({
    currentPetName: petName,
    availablePetRecipesMap,
    pets,
    currentPetRecipeSelection: recommendedRecipes.map(recipe => recipe.name),
    isRecommendedPlan: true,
  });

  const { petDailySubtotal, petDailyDiscountedSubtotal, discountPercentage } =
    getQuoteData({
      quote,
      currentPetName: petName,
    });

  const recommendedRecipeDisplayNames = recommendedRecipes.map(
    recipe => recipe.content.displayName
  );
  const formattedRecipeNames = formatCommaSeparatedList(
    recommendedRecipeDisplayNames
  );

  const { open } = useGlobalLightbox({
    id: LightboxId.MultiRecipeDetailModal,
  });

  const [recipesContinueElement, recipesContinueRef] = useHtmlElement();

  const intersection = useIntersection(recipesContinueElement);
  const shouldRevealStickyCta = Boolean(
    intersection && !intersection.isIntersecting && intersection.rootBounds
  );

  const firstBoxAccessibleText = getFirstBoxAccessibleText({
    petDailySubtotal,
    petDailyDiscountedSubtotal,
  });

  const { isOnlyLpfRecipeRecommended } = checkLpfRecipeStatus(
    currentPetRecipes ?? []
  );

  const recommendedRecipeCopy = getRecommendedRecipeCopy({
    petName,
    lpfRecipeDisplayNames: currentPetRecipes
      ?.filter(({ content: { productLine } }) => productLine === 'lpf')
      .map(({ content: { displayName } }) => displayName),
    isOnlyLpfRecipeRecommended,
  });

  const handleClick = useCallback(() => {
    onSubmit(quote);
  }, [onSubmit, quote]);

  const handleOpen = useCallback(() => {
    open();
  }, [open]);

  return (
    <>
      <Grid
        className={classNames([styles.container, className])}
        columnGap="xl"
        justifyContent="center"
      >
        <GridItem
          alignItems={{ xs: 'center', md: 'flex-start' }}
          className={styles.infoContainer}
          md={6}
          lg={5}
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            variant="heading-28"
            bold
            color="charcoal-3"
            as="h1"
            className={styles.recipePageTitle}
          >
            <span className={styles.newLine}>{petName}’s</span>
            <span className={styles.newLine}>Recommended Plan</span>
          </Text>
          <div className={styles.recipesInfoContainer}>
            <div className={styles.recommendedRecipesListContainer}>
              <Text
                variant="heading-16"
                color="charcoal-3"
                as="p"
                className={styles.recommendedRecipesList}
              >
                {formattedRecipeNames}
                <Button
                  variant="plain-text"
                  type="button"
                  onClick={handleOpen}
                  className={styles.seeDetailsButtonContainer}
                >
                  <Text
                    variant="heading-12"
                    color="carrot-2"
                    className={styles.seeDetailsButton}
                  >
                    Nutrition Facts
                  </Text>
                </Button>
              </Text>
            </div>
            {quote && (
              <AccessibleContent
                accessibleText={firstBoxAccessibleText}
                visualContent={
                  <Text
                    variant="heading-16"
                    bold
                    color="charcoal-3"
                    as="p"
                    className={styles.pricePerDay}
                  >
                    First Box:{' '}
                    <Strikeout className={styles.strikeoutPrice}>
                      <Text variant="heading-16" className={styles.pricePerDay}>
                        {petDailySubtotal}/day
                      </Text>
                    </Strikeout>{' '}
                    <Text
                      variant="heading-16"
                      as="span"
                      color="kale-2"
                      bold
                      className={styles.pricePerDay}
                    >
                      {petDailyDiscountedSubtotal}/day{' '}
                    </Text>
                    {discountPercentage && (
                      <Text
                        variant="heading-16"
                        as="span"
                        color="kale-2"
                        bold
                        className={styles.pricePerDay}
                      >
                        ({discountPercentage}% OFF)
                      </Text>
                    )}
                  </Text>
                }
              />
            )}
          </div>
          <Divider
            className={styles.divider}
            variant="red-squiggly"
            spacing={0}
            align="start"
          />
          <GridItem alignItems="center" className={styles.mobileRecipeStack}>
            <RecipesStack
              petName={petName}
              foodPlanRecipes={formattedRecommendedRecipes}
            />
          </GridItem>
          <div className={styles.planDescriptionContainer}>
            <div className={styles.planCopyContainer}>
              <Text
                variant="article-16"
                color="charcoal-3"
                as="p"
                className={styles.planDescription}
              >
                {recommendedRecipeCopy}
              </Text>
            </div>
          </div>
          {/* // establish signup header fade element when user scrolls past the CTA */}
          <div ref={recipesContinueRef} className={styles.ctaContainer}>
            <RecommendedPlanCta
              // TODO: Remove recommendationDisabled in favor of
              // recommendationLoading
              recommendationDisabled={recommendedRecipes.length === 0}
              recommendationLoading={recommendedRecipes.length === 0}
              formSubmitting={isFormSubmitting}
              onClick={handleClick}
            />

            <div>
              <Button
                disabled={isFormSubmitting}
                type="button"
                variant="plain-text"
                onClick={customizePlan}
              >
                <Text
                  bold
                  variant="heading-16"
                  color="carrot-2"
                  className={styles.selectPlanButton}
                >
                  Or Select Your Own Plan
                </Text>
              </Button>
            </div>
          </div>
        </GridItem>
        <GridItem
          md={6}
          lg={7}
          alignItems="center"
          className={styles.recipeStack}
        >
          <RecipesStack
            petName={petName}
            foodPlanRecipes={formattedRecommendedRecipes}
          />
        </GridItem>
      </Grid>
      <StickyCta
        discountPercentage={discountPercentage}
        petDailySubtotal={petDailySubtotal}
        petDailyDiscountedSubtotal={petDailyDiscountedSubtotal}
        accessibleText={firstBoxAccessibleText}
        shouldReveal={shouldRevealStickyCta}
        onClick={handleClick}
        disabled={recommendedRecipes.length === 0 || isFormSubmitting}
        loading={recommendedRecipes.length === 0 || isFormSubmitting}
      />
      <Wave
        data-testid="wave-container"
        withTop
        waveHeight={38}
        className={styles.wave}
      >
        <RecipesBenefits petName={petName} />
        <ReviewsSection />
        <Footer className={styles.footer} />
      </Wave>
    </>
  );
};
