const TERMS_ACCEPTANCE_STORAGE_KEY = 'termsAcceptance';

type TermsAcceptanceMap = Record<number, boolean>;

function getTermsAcceptanceMap(): TermsAcceptanceMap {
  const jsonMap = localStorage.getItem(TERMS_ACCEPTANCE_STORAGE_KEY);

  return jsonMap ? (JSON.parse(jsonMap) as TermsAcceptanceMap) : {};
}

function setTermsAcceptanceMap(newTermsAcceptanceMap: TermsAcceptanceMap) {
  localStorage.setItem(
    TERMS_ACCEPTANCE_STORAGE_KEY,
    JSON.stringify(newTermsAcceptanceMap)
  );
}

export function getHasAcceptedTerms(userId: number): boolean {
  const termsAcceptanceMap = getTermsAcceptanceMap();

  return termsAcceptanceMap[userId] ?? false;
}

export function setHasAcceptedTerms(userId: number, hasAcceptedTerms: boolean) {
  const termsAcceptanceMap = getTermsAcceptanceMap();

  setTermsAcceptanceMap({ ...termsAcceptanceMap, [userId]: hasAcceptedTerms });
}
