import { Component } from 'react';
import PropTypes from 'prop-types';

import { onLocationChange } from 'src/utils/dom';

import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    error: PropTypes.object,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.state = { hasError: !!props.error, error: props.error };
  }

  componentWillUnmount() {
    clearInterval(this.locationChangeInterval);
  }

  componentDidCatch(error, info) {
    this.locationChangeInterval = onLocationChange(this.handleLocationChange);

    error.extra = {
      ...error.extra,
      errorBoundaryInfo: info,
    };
  }

  handleLocationChange() {
    this.setState({ hasError: false, error: undefined });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return <ErrorPage error={this.state.error} />;
  }
}

export default ErrorBoundary;
