import type { GridItemSpan } from '@farmersdog/corgi-x';
import { Grid, GridItem } from '@farmersdog/corgi-x';
import styles from './MetaHeroGrid.module.css';
import { Heading } from '../Heading';
import classNames from 'classnames';

interface HeroSectionProps {
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
  headlineText: React.ReactNode;
  subtitle: React.ReactNode;
  children: React.ReactNode;
  isSmallSubtitle?: boolean;
  isTextOnBottom?: boolean;
  greenFont?: boolean;
  withTopOverlay?: boolean;
  withRightOverlay?: boolean;
  ctaString: string;
  buttonClassname?: string;
  subtitleClassname?: string;
  titleClassname?: string;
  containerClassname?: string;
  desktopColumnWidth?: GridItemSpan;
  gridItemClassname?: string;
  ctaClassname?: string;
  copyContainerClassname?: string;
}

export function MetaHeroGrid({
  headlineText,
  children,
  isTextOnBottom,
  withTopOverlay,
  withRightOverlay,
  containerClassname,
  desktopColumnWidth = 6,
  gridItemClassname,
  ...props
}: HeroSectionProps) {
  const containerClassnames = classNames(containerClassname, styles.container, {
    [styles.withTopOverlay]: withTopOverlay,
    [styles.withRightOverlay]: withRightOverlay,
  });

  const gridItemClassnames = classNames(styles.cta, gridItemClassname, {
    [styles.ctaTextOnBottom]: isTextOnBottom,
  });

  return (
    <Grid
      className={containerClassnames}
      flexDirection={{ lg: 'row' }}
      data-testid="static-hero"
    >
      <GridItem
        lg={desktopColumnWidth}
        xs={12}
        className={gridItemClassnames}
        alignItems="flex-start"
        flexDirection="column"
        justifyContent={{
          xs: isTextOnBottom ? 'flex-end' : 'flex-start',
          lg: 'center',
        }}
      >
        <Heading
          title={headlineText}
          isTextOnBottom={isTextOnBottom}
          {...props}
        />
      </GridItem>
      {children}
    </Grid>
  );
}
