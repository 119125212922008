import { getTosaFacebookPixelAttributes } from './vendors/facebook';
import { setGoogleTagManagerVar } from './vendors/google';

import type { FetchLeadQuery } from '../graphql/types';

export const handleStepCompleted = (
  lead: FetchLeadQuery['lead'] | null
): void => {
  if (lead) {
    const pixelAttributes = getTosaFacebookPixelAttributes({ lead });

    Object.entries(pixelAttributes).forEach(([variableName, value]) => {
      setGoogleTagManagerVar({ variableName, value });
    });
  }
};
