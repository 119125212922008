import classNames from 'classnames';
import {
  Link as ReactRouterLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Logo,
  Text,
  Link as CorgiLink,
  useViewport,
} from '@farmersdog/corgi-x';
import { isDiySignup, isFreshSignup } from '@farmersdog/lead-browser-storage';
import {
  PATH_SIGNUP_SUCCESS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_PLANS,
} from '@farmersdog/constants/paths';
import { useTreatsInCheckoutUi } from '@farmersdog/tosa/src/hooks';
import { PreviousLinkNavigationLabel } from '@farmersdog/tosa/src/hooks/usePreviousLinkNavigation';
import type { UseFeatureHook } from '@farmersdog/tosa/src/types';

import { useFeature } from 'src/abTesting';
import Sticky from 'src/components/Sticky';
import config from 'src/config';
import useProgress from 'src/pages/Signup/hooks/useProgress';
import { getReferrerCookie } from 'src/utils/cookies';

import { BackButton } from './BackButton';
import DiscountBadge from './DiscountBadge';
import HeaderProgress from './HeaderProgress';
import { useSteps, type Step, type Progress } from './steps';
import { getHomeLink, getHomeLinkAccessibleText } from './utils';

import styles from './SignupHeader.module.scss';

interface CollapsedViewProps {
  steps: Step[];
  showNavigation: boolean;
  showDiscountBadge: boolean;
  homeLink: string;
  homeLinkAccessibleText: string;
}

function CollapsedView({
  homeLink,
  steps,
  showNavigation,
  showDiscountBadge,
  homeLinkAccessibleText,
}: CollapsedViewProps) {
  const activeStepIndex = steps.findIndex(step => step.active);
  const { path, label } = steps[activeStepIndex] || {};
  return (
    <header className={styles.header} style={{ padding: '5px 10px' }}>
      <div className={styles.headerInner}>
        <div className={styles.logoContainer}>
          <ReactRouterLink
            to={homeLink}
            aria-label={homeLinkAccessibleText}
            data-name="logo-link"
          >
            <Logo variant="product" size={50} style={{ marginBottom: 10 }} />
          </ReactRouterLink>
        </div>
        {path && showNavigation && (
          <div className={styles.collapsedNavigationContainer}>
            <nav role="navigation" className={styles.nav}>
              <div className={classNames(styles.links, styles.mobileLinks)}>
                <Text variant="heading-22" className={styles.linkText}>
                  <CorgiLink
                    to={path}
                    className={styles.link}
                    activeClassName={styles.active}
                    isActive={() => true}
                  >
                    {label} ({activeStepIndex + 1}/{steps.length})
                  </CorgiLink>
                </Text>
              </div>
            </nav>
          </div>
        )}
        <div className={styles.collapsedDiscountBadge}>
          {showDiscountBadge ? (
            <DiscountBadge data-testid="discount-badge" />
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        {showNavigation && (
          <HeaderProgress
            steps={steps}
            style={{ position: 'absolute', top: 66, margin: '0 auto' }}
          />
        )}
      </div>
    </header>
  );
}

interface WideViewProps {
  steps: Step[];
  showNavigation: boolean;
  showDiscountBadge: boolean;
  homeLink: string;
}

function WideView({
  steps,
  showNavigation,
  showDiscountBadge,
  homeLink,
}: WideViewProps) {
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);

  const headerClass = classNames(styles.header, {
    [styles.moveHigher]: isRecipesPage || isPlansPage,
  });

  return (
    <Sticky
      as="header"
      className={headerClass}
      style={{ top: 0, left: 0, padding: '0 50px' }}
    >
      <div className={styles.headerInner}>
        <div style={{ marginRight: 'auto' }}>
          <ReactRouterLink
            to={homeLink}
            aria-label={PreviousLinkNavigationLabel.homePage}
            data-name="logo-link"
            className={styles.desktopLogo}
          >
            <Logo variant="product" size={50} />
          </ReactRouterLink>
        </div>
        <div>{showNavigation && <BackButton />}</div>
        <div className={styles.wideNavigationContainer}>
          {showNavigation && (
            <nav className={styles.nav} role="navigation">
              <div className={styles.links}>
                {steps.map((step, i) => {
                  const { path, label, active } = step;
                  const disabled = !steps
                    .slice(0, i + 1)
                    .every(s => s.completed);

                  return (
                    <Text
                      key={path}
                      variant="heading-22"
                      className={styles.linkText}
                    >
                      <CorgiLink
                        to={path}
                        activeClassName={styles.active}
                        className={classNames(styles.link, {
                          [styles.disabled]: disabled,
                        })}
                        isActive={() => active}
                        onClick={e => {
                          if (e && disabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {label}
                      </CorgiLink>
                    </Text>
                  );
                })}
              </div>
              <div className={styles.progressContainer}>
                <HeaderProgress steps={steps} style={{ width: '100%' }} />
              </div>
            </nav>
          )}
        </div>
        <div className={styles.wideDiscountBadge}>
          {showDiscountBadge ? (
            <DiscountBadge data-testid="discount-badge" />
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    </Sticky>
  );
}

function SignupHeader() {
  const location = useLocation();
  const progress = useProgress() as Progress;
  const { lg } = useViewport();
  const steps = useSteps(progress, location.pathname);
  const lastCompletedStep = steps
    .filter(step => step.completed && !step.active)
    .slice(-1)[0];
  const isSuccessPage = Boolean(useRouteMatch(PATH_SIGNUP_SUCCESS));
  const { shouldShowTreatsPage } = useTreatsInCheckoutUi({
    useFeature: useFeature as UseFeatureHook,
  });

  const referrerCookie = getReferrerCookie();
  const referrerCookieDiscountCode = referrerCookie?.referrerCode;
  const browserExtensionCouponCodes = config(
    'features.browserExtensionCouponCodes'
  );
  const hasBrowserExtensionCouponInCookie =
    referrerCookieDiscountCode &&
    browserExtensionCouponCodes &&
    browserExtensionCouponCodes.includes(referrerCookieDiscountCode);

  const showDiscountBadge =
    hasBrowserExtensionCouponInCookie || isSuccessPage
      ? false
      : isFreshSignup();

  // TODO: remove 'homeLink' and 'homeLinkAccessibleText' after implementing mobile back button https://app.shortcut.com/farmersdog/story/95979/add-back-button-for-signup-flow-outside-of-tosa-in-mobile
  const homeLink = getHomeLink({
    isSuccessPage,
    shouldShowTreatsPage: shouldShowTreatsPage && !isDiySignup(),
    lastCompletedStep,
  });
  const homeLinkAccessibleText = getHomeLinkAccessibleText({ homeLink });

  return lg ? (
    <WideView
      steps={steps}
      showNavigation={!isSuccessPage}
      showDiscountBadge={showDiscountBadge}
      homeLink={homeLink}
    />
  ) : (
    <CollapsedView
      steps={steps}
      showNavigation={!isSuccessPage}
      showDiscountBadge={showDiscountBadge}
      homeLink={homeLink}
      homeLinkAccessibleText={homeLinkAccessibleText}
    />
  );
}

export default SignupHeader;
