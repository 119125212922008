import BowlDesktopPng from './bowl-desktop.png';
import BowlDesktopWebP from './bowl-desktop.webp';
import BowlShadowDesktopPng from './bowl-shadow-desktop.png';
import BowlShadowDesktopWebP from './bowl-shadow-desktop.webp';
import PackDesktopPng from './pack-desktop.png';
import PackDesktopWebP from './pack-desktop.webp';

import BowlArrowLottieJson from './bowl-arrow.json';
import PackArrowLottieJson from './pack-arrow.json';

export const bowlDesktopSources = {
  png: BowlDesktopPng,
  webP: BowlDesktopWebP,
};

export const bowlShadowDesktopSources = {
  png: BowlShadowDesktopPng,
  webP: BowlShadowDesktopWebP,
};

export const packDesktopSources = {
  png: PackDesktopPng,
  webP: PackDesktopWebP,
};

export { BowlArrowLottieJson, PackArrowLottieJson };
