import { useEffect, useState } from 'react';
import fastdom from 'fastdom';
import noop from 'lodash/noop';

import { useIntersection, useHtmlElement } from '@farmersdog/corgi';
import { getScrollY } from 'src/utils/dom';
import Picture from 'src/components/Picture';

import mixedBowlPng from 'src/assets/images/bowl-mixed.png';
import mixedBowlWebp from 'src/assets/images/bowl-mixed.webp';
import ingredientsBowlPng from 'src/assets/images/bowl-ingredients.png';
import ingredientsBowlWebp from 'src/assets/images/bowl-ingredients.webp';

import styles from './RevealingBowl.module.scss';

const OFFSET = 20;

function RevealingBowl() {
  const [width, setWidth] = useState(0);
  const [imageEl, imageElRef] = useHtmlElement();
  const intersection = useIntersection(imageEl);

  useEffect(() => {
    if (!(intersection && intersection.isIntersecting)) {
      return noop;
    }

    const handleScroll = () => {
      fastdom.measure(() => {
        if (!imageEl) {
          return;
        }

        const { innerHeight } = window;
        const { top, height } = imageEl.getBoundingClientRect();
        const scrollY = getScrollY();
        const nodeTop = top + scrollY;
        const scrollTop = scrollY + innerHeight;

        if (scrollTop > nodeTop) {
          const bowlWidth =
            ((scrollTop - nodeTop - height / 2 + OFFSET) / innerHeight) * 100;

          setWidth(Math.min(bowlWidth, 100));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [intersection, imageEl]);

  const clipPath = `inset(0 ${width}% 0 0)`;

  return (
    <div className={styles.container} ref={imageElRef}>
      <Picture
        className={styles.mixed}
        sources={[mixedBowlWebp, mixedBowlPng]}
        alt="Bowl of The Farmer's Dog"
      />
      <Picture
        className={styles.ingredients}
        sources={[ingredientsBowlWebp, ingredientsBowlPng]}
        alt="Bowl of ingredients"
        style={{ clipPath, WebkitClipPath: clipPath }}
      />
      <div
        className={styles.divider}
        style={{ transform: `translate(-${width + 50}%, -50%)` }}
      />
    </div>
  );
}

export default RevealingBowl;
