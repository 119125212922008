export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
};

export type AccountInformation = {
  __typename?: 'AccountInformation';
  /** Customer's email */
  email: Scalars['String']['output'];
  /** Customer's first name */
  firstName: Scalars['String']['output'];
  /** Customer's last name */
  lastName: Scalars['String']['output'];
  /** Customer's phone number */
  phone: Scalars['String']['output'];
};

export type AddClinicInput = {
  clinic: ClinicInput;
  clinicEmail?: InputMaybe<Scalars['String']['input']>;
  clinicPhone?: InputMaybe<Scalars['String']['input']>;
  vetName?: InputMaybe<Scalars['String']['input']>;
};

export type AddClinicResponse = {
  __typename?: 'AddClinicResponse';
  clinic: Clinic;
};

export type AddCreditCardInput = {
  token: Scalars['String']['input'];
};

export type AddCreditCardResponse = {
  __typename?: 'AddCreditCardResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AddOnLimits = {
  __typename?: 'AddOnLimits';
  totalChewsQuantityLimit: Scalars['Int']['output'];
  totalTreatsQuantityLimit: Scalars['Int']['output'];
};

export type AddPetInput = {
  activityLevel: Scalars['String']['input'];
  ageNumber: Scalars['Float']['input'];
  ageUnit: Scalars['String']['input'];
  bodyCondition: Scalars['String']['input'];
  breed: Array<Scalars['String']['input']>;
  currentWeight: Scalars['Float']['input'];
  eatingStyle: Scalars['String']['input'];
  healthIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  petDietIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipes: Array<Scalars['String']['input']>;
  sex: Scalars['String']['input'];
  sterilization: Scalars['Boolean']['input'];
  targetWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AddPetResponse = {
  __typename?: 'AddPetResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AddressComponents = {
  __typename?: 'AddressComponents';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  streetAddress1: Scalars['String']['output'];
  streetAddress2: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressFields = {
  __typename?: 'AddressFields';
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['output'];
  /** The second line of the customer's address, if present */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The state abbreviation */
  stateAbbreviation: Scalars['String']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

export type AvailableFreshFoodProduct = {
  __typename?: 'AvailableFreshFoodProduct';
  /** Recipe name to display on the customer portal */
  displayName: Scalars['String']['output'];
  /** The recipe id */
  id: Scalars['Int']['output'];
  /** The synopsis recipe ingredient list in a single string */
  mainIngredients: Scalars['String']['output'];
  /** Recipe machine name */
  name: Scalars['String']['output'];
};

export type BillNowInput = {
  orderId: Scalars['Int']['input'];
};

export type BillNowResponse = {
  __typename?: 'BillNowResponse';
  id: Scalars['Int']['output'];
  paymentStatus: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['output'];
  /** The second line of the customer's address, if present */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The state abbreviation */
  stateAbbreviation: Scalars['String']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

export type CgsAddressInput = {
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['input'];
  /** The second line of the customer's address, if present */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The state abbreviation */
  stateAbbreviation: Scalars['String']['input'];
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export type CgsBillingInformationInput = {
  billingAddress: CgsAddressInput;
  stripeToken: Scalars['String']['input'];
};

export type CgsUpdateCustomerWithSuspendedSubscriptionInput = {
  billingInformation?: InputMaybe<CgsBillingInformationInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  shippingAddress: CgsAddressInput;
};

export enum CgsActivityLevel {
  Active = 'Active',
  NotVeryActive = 'NotVeryActive',
  ProAthlete = 'ProAthlete',
  VeryActive = 'VeryActive',
}

export type CgsAddOn = {
  __typename?: 'CgsAddOn';
  id: Scalars['Int']['output'];
  product: CgsProduct;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type CgsAddOnInput = {
  productName: Scalars['String']['input'];
  productSize: SnapsTreatSize;
  quantity: Scalars['Int']['input'];
};

export enum CgsBodyCondition {
  Chunky = 'Chunky',
  JustRight = 'JustRight',
  Rounded = 'Rounded',
  TooSkinny = 'TooSkinny',
}

export type CgsChewAddOnInput = {
  chewLength: CgsChewLength;
  productName: Scalars['String']['input'];
  productSize: CgsChewSize;
  quantity: Scalars['Int']['input'];
};

export enum CgsChewLength {
  Longer = 'Longer',
  Shorter = 'Shorter',
}

export enum CgsChewSize {
  Regular = 'Regular',
}

export type CgsCreditCard = {
  __typename?: 'CgsCreditCard';
  /** The billing address of the card */
  billingAddress: CreditCardBillingAddress;
  /** The credit card brand */
  brand: Scalars['String']['output'];
  /** The month the card expires */
  expirationMonth: Scalars['Int']['output'];
  /** The year the card expires */
  expirationYear: Scalars['Int']['output'];
  /** Whether this card is the default card */
  isDefault: Scalars['Boolean']['output'];
  /** The last 4 digits of the card */
  last4Digits: Scalars['String']['output'];
  /** The name on the card */
  nameOnCard: Scalars['String']['output'];
};

export type CgsCustomerOrder = {
  __typename?: 'CgsCustomerOrder';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isFailed: Scalars['Boolean']['output'];
  isFree: Scalars['Boolean']['output'];
  isLocked: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  packing: CgsCustomerOrderPacking;
  petNames: Array<Scalars['String']['output']>;
  pricing: CgsCustomerOrderPricing;
  scheduling: CgsCustomerOrderScheduling;
  shipping: CgsCustomerOrderShipping;
};

export type CgsCustomerOrderPacking = {
  __typename?: 'CgsCustomerOrderPacking';
  chews: Array<CgsCustomerOrderPackingChews>;
  diyQuantity: Scalars['Int']['output'];
  isSkippingTreats: Scalars['Boolean']['output'];
  numberOfFoodPacks: Scalars['Int']['output'];
  treats: Array<CgsCustomerOrderPackingTreats>;
};

export type CgsCustomerOrderPackingChews = {
  __typename?: 'CgsCustomerOrderPackingChews';
  chewLength: CgsChewLength;
  chewSize: CgsChewSize;
  displayName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type CgsCustomerOrderPackingTreats = {
  __typename?: 'CgsCustomerOrderPackingTreats';
  displayName: Scalars['String']['output'];
  packageSize: CgsTreatSize;
  quantity: Scalars['Int']['output'];
  variantSku: Scalars['String']['output'];
};

export type CgsCustomerOrderPricing = {
  __typename?: 'CgsCustomerOrderPricing';
  applicableCredits: Scalars['Int']['output'];
  cashTotal: Scalars['Int']['output'];
  creditsTotal: Maybe<Scalars['Int']['output']>;
  pricePerDay: Maybe<Scalars['Int']['output']>;
  pricePerWeek: Maybe<Scalars['Int']['output']>;
};

export type CgsCustomerOrderScheduling = {
  __typename?: 'CgsCustomerOrderScheduling';
  earliestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  isReschedulable: Scalars['Boolean']['output'];
  latestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  rescheduleCutOffDate: Scalars['DateTime']['output'];
};

export type CgsCustomerOrderShipping = {
  __typename?: 'CgsCustomerOrderShipping';
  address: ShippingAddress;
  arrivalDate: Maybe<Scalars['Date']['output']>;
  status: OrderDeliveryStatus;
  trackingURL: Maybe<Scalars['String']['output']>;
};

export type CgsCustomerOrders = {
  __typename?: 'CgsCustomerOrders';
  current: Maybe<CgsCustomerOrder>;
  future: Array<CgsCustomerOrder>;
  past: Array<CgsCustomerOrder>;
  subscription: CgsCustomerSubscription;
};

export type CgsCustomerSubscription = {
  __typename?: 'CgsCustomerSubscription';
  hasChews: Scalars['Boolean']['output'];
  hasTreats: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  isDiy: Scalars['Boolean']['output'];
};

export type CgsFoodBrand = {
  __typename?: 'CgsFoodBrand';
  id: Scalars['Int']['output'];
  /** Food brand name */
  name: Scalars['String']['output'];
};

export type CgsLineItemMetadata = {
  __typename?: 'CgsLineItemMetadata';
  pet: Maybe<CgsLineItemMetadataPet>;
  plan: Maybe<CgsLineItemMetadataPlan>;
  /** @deprecated We should be populating this data on the LineItem as product data */
  variant: CgsLineItemMetadataVariant;
};

export type CgsLineItemMetadataPet = {
  __typename?: 'CgsLineItemMetadataPet';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CgsLineItemMetadataPlan = {
  __typename?: 'CgsLineItemMetadataPlan';
  id: Scalars['Int']['output'];
};

export type CgsLineItemMetadataVariant = {
  __typename?: 'CgsLineItemMetadataVariant';
  product: Maybe<CgsLineItemMetadataVariantProduct>;
  type: CgsVariantType;
};

export type CgsLineItemMetadataVariantProduct = {
  __typename?: 'CgsLineItemMetadataVariantProduct';
  displayName: Maybe<Scalars['String']['output']>;
};

export type CgsLineItemVariant = {
  __typename?: 'CgsLineItemVariant';
  /** @deprecated We should only use SKU to identify a variant instead of product ID */
  id: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  type: CgsVariantType;
};

export type CgsOrderDetails = {
  __typename?: 'CgsOrderDetails';
  addOnsTotal: Maybe<Scalars['Int']['output']>;
  arrivalDate: Maybe<Scalars['Date']['output']>;
  billingAddress: CgsOrderDetailsAddress;
  cashTotal: Scalars['Int']['output'];
  chewsTotal: Scalars['Int']['output'];
  discountTotal: Scalars['Int']['output'];
  discounts: Array<CgsOrderDetailsDiscount>;
  earliestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  hasSurpriseFreeTreats: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isReschedulable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  latestDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  lineItems: Array<CgsOrderDetailsOrderLineItem>;
  mealsTotal: Scalars['Int']['output'];
  metadata: CgsOrderDetailsOrderMetadata;
  number: Scalars['String']['output'];
  paymentStatus: CgsPaymentStatus;
  /** The communicated cut off date and time for a user to reschedule the order. Time should be displayed as provided, ignore local timezone */
  rescheduleCutOffDate: Scalars['DateTime']['output'];
  shipDate: Scalars['Date']['output'];
  shipmentStatus: CgsShipmentStatus;
  shippingAddress: CgsOrderDetailsAddress;
  states: Array<CgsOrderState>;
  taxTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  trackingNumber: Maybe<Scalars['String']['output']>;
  trackingURL: Maybe<Scalars['String']['output']>;
  trackingURLs: Array<CgsOrderDetailsTrackingUrl>;
  treatsTotal: Scalars['Int']['output'];
  type: CgsOrderType;
};

export type CgsOrderDetailsAddress = {
  __typename?: 'CgsOrderDetailsAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  stateAbbreviation: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CgsOrderDetailsDiscount = {
  __typename?: 'CgsOrderDetailsDiscount';
  discountAmountWithUnit: CgsOrderDetailsDiscountAmountWithUnit;
  reason: CgsOrderDiscountReason;
  sku: Maybe<Scalars['String']['output']>;
  type: CgsOrderDiscountType;
};

export type CgsOrderDetailsDiscountAmountWithUnit = {
  __typename?: 'CgsOrderDetailsDiscountAmountWithUnit';
  amount: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

/** We should break this type into multiple types based on the product category */
export type CgsOrderDetailsOrderLineItem = {
  __typename?: 'CgsOrderDetailsOrderLineItem';
  /** Should be replaced with CgsChewLength */
  chewLength: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  metadata: CgsLineItemMetadata;
  quantity: Scalars['Int']['output'];
  /** Should be replaced with CgsChewSize and CgsTreatSize */
  size: Maybe<Scalars['String']['output']>;
  type: CgsVariantType;
  variant: CgsLineItemVariant;
};

export type CgsOrderDetailsOrderMetadata = {
  __typename?: 'CgsOrderDetailsOrderMetadata';
  pets: Maybe<Array<CgsOrderDetailsOrderMetadataPet>>;
  stats: CgsOrderDetailsOrderMetadataStats;
};

export type CgsOrderDetailsOrderMetadataPet = {
  __typename?: 'CgsOrderDetailsOrderMetadataPet';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CgsOrderDetailsOrderMetadataStats = {
  __typename?: 'CgsOrderDetailsOrderMetadataStats';
  plans: Maybe<Array<CgsOrderDetailsOrderMetadataStatsPlan>>;
};

export type CgsOrderDetailsOrderMetadataStatsPlan = {
  __typename?: 'CgsOrderDetailsOrderMetadataStatsPlan';
  days: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
};

export type CgsOrderDetailsTrackingUrl = {
  __typename?: 'CgsOrderDetailsTrackingURL';
  trackingNumber: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export enum CgsOrderDiscountReason {
  SecondBoxDiscount = 'SecondBoxDiscount',
  SubscriberFreeFirstTreats = 'SubscriberFreeFirstTreats',
  SubscriptionDiscount = 'SubscriptionDiscount',
  SurpriseFreeTreats = 'SurpriseFreeTreats',
  TreatsInCheckout = 'TreatsInCheckout',
  TrialDiscount = 'TrialDiscount',
}

export enum CgsOrderDiscountType {
  /** A discount applied to the entire order */
  Order = 'order',
  /** A discount applied to a specific SKU */
  Sku = 'sku',
}

export enum CgsOrderState {
  Cancelled = 'cancelled',
  Current = 'current',
  Delivered = 'delivered',
  Future = 'future',
  Invalid = 'invalid',
  Next = 'next',
}

export enum CgsOrderType {
  Regular = 'regular',
  Trial = 'trial',
}

export enum CgsPaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Free = 'free',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Processing = 'processing',
  Queued = 'queued',
  Refunded = 'refunded',
  Transferred = 'transferred',
  Unpaid = 'unpaid',
}

export type CgsPersonalityType = {
  __typename?: 'CgsPersonalityType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum CgsPetSex {
  Female = 'female',
  Male = 'male',
}

export enum CgsPetSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export type CgsPetsInput = {
  /** Pet that is labeled 'no contact' for marketing or other reasons */
  noContact?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the requested pet */
  petName?: InputMaybe<Scalars['String']['input']>;
};

export type CgsProduct = {
  __typename?: 'CgsProduct';
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

export type CgsRecipe = {
  __typename?: 'CgsRecipe';
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CgsRecipeDetails = {
  __typename?: 'CgsRecipeDetails';
  /** A summary of the recipe's calorie content */
  calorieContent: Scalars['String']['output'];
  /** A UI displayable name for the recipe */
  displayName: Scalars['String']['output'];
  /** A summary of the recipe's full ingredients */
  fullIngredients: Scalars['String']['output'];
  /** A recipe's guaranteed analysis */
  guaranteedAnalysis: Scalars['String']['output'];
  /** The recipe's id */
  id: Scalars['Int']['output'];
  /** A summary of the recipe's main ingredients */
  mainIngredients: Scalars['String']['output'];
  /** A human-readable unique identifer for the recipe */
  name: Scalars['String']['output'];
  /** A summary of the recipe's supplements */
  supplements: Scalars['String']['output'];
  /** A recipe's dry matter typical anlysis */
  typicalAnalysis: Scalars['String']['output'];
};

export type CgsRecipeDetailsInput = {
  /** Product Name to fetch */
  productName: Scalars['String']['input'];
};

export type CgsRecommendedChewsPackage = {
  __typename?: 'CgsRecommendedChewsPackage';
  approximateDays: Maybe<Scalars['Int']['output']>;
  chewLength: CgsChewLength;
  productName: Scalars['String']['output'];
  quantity: Maybe<Scalars['Int']['output']>;
  size: CgsChewSize;
};

export type CgsRecommendedChewsPortion = {
  __typename?: 'CgsRecommendedChewsPortion';
  petId: Scalars['Int']['output'];
  petName: Scalars['String']['output'];
  portion: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
};

export type CgsRecommendedSnapsTreatsPackage = {
  __typename?: 'CgsRecommendedSnapsTreatsPackage';
  approximateDays: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export type CgsRecommendedSnapsTreatsPortion = {
  __typename?: 'CgsRecommendedSnapsTreatsPortion';
  petId: Scalars['Int']['output'];
  petName: Scalars['String']['output'];
  portion: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
};

export type CgsSetAddOnsInput = {
  chews?: InputMaybe<Array<CgsChewAddOnInput>>;
  treats?: InputMaybe<Array<CgsTreatAddOnInput>>;
};

export enum CgsShipmentStatus {
  AcquisitionFailed = 'acquisition_failed',
  Cancelled = 'cancelled',
  /** No longer in use */
  Delayed = 'delayed',
  Delivered = 'delivered',
  DeliveryFailed = 'delivery_failed',
  /** No longer in use */
  Failed = 'failed',
  Processing = 'processing',
  Queued = 'queued',
  Scheduled = 'scheduled',
  /** No longer in use */
  Shipped = 'shipped',
  Transit = 'transit',
  Unscheduled = 'unscheduled',
}

export type CgsTreatAddOnInput = {
  productName: Scalars['String']['input'];
  productSize: SnapsTreatSize;
  quantity: Scalars['Int']['input'];
};

export enum CgsTreatSize {
  Large = 'large',
  Regular = 'regular',
}

export enum CgsVariantType {
  Chews = 'chews',
  Diy = 'diy',
  Food = 'food',
  FoodContainer = 'food_container',
  Leaflet = 'leaflet',
  Packaging = 'packaging',
  Supplement = 'supplement',
  Treat = 'treat',
}

export type Cgs_Order = {
  __typename?: 'Cgs_Order';
  /** Average days of food in the order, taking into account all pets */
  avgDaysOfFood: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

/** U.S. state or territory */
export type Cgs_State = {
  __typename?: 'Cgs_State';
  /** State's 2-letter abbreviation */
  abbreviation: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** State's full name */
  name: Scalars['String']['output'];
};

export enum Cgs_SubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Suspended = 'suspended',
}

export type ChangeFreshOrderSizeInput = {
  orderSize: Scalars['Int']['input'];
};

export type ChangeFreshOrderSizeQuote = {
  __typename?: 'ChangeFreshOrderSizeQuote';
  /** Daily consumption price of the quoted subscription, in cents */
  dailyConsumptionPrice: Scalars['Float']['output'];
  /** Quote's subscription frequency */
  frequency: Scalars['Int']['output'];
  /** Price of a regular box */
  regularOrderTotalConsumptionPrice: Scalars['Float']['output'];
  /** Yearly consumption price of the quoted subscription, in cents */
  yearlyConsumptionPrice: Scalars['Float']['output'];
};

export type ChangeFreshOrderSizeQuotes = {
  __typename?: 'ChangeFreshOrderSizeQuotes';
  /** Current subscription quote */
  current: ChangeFreshOrderSizeQuote;
  /** Maximum recommended frequency [legacy] */
  max: ChangeFreshOrderSizeQuote;
  /** Minimum recommended frequency [legacy] */
  min: Maybe<ChangeFreshOrderSizeQuote>;
};

export type ChangeFreshOrderSizeResponse = {
  __typename?: 'ChangeFreshOrderSizeResponse';
  customer: Customer;
};

export type ChangeFreshRecipesPlanForAllPetsQuoteInput = {
  /** Updated recipes to use as quote input */
  recipes: Array<ChangeFreshRecipesPlanQuoteRecipeInput>;
};

export type ChangeFreshRecipesPlanQuote = {
  __typename?: 'ChangeFreshRecipesPlanQuote';
  /** Daily price of the quoted plan */
  dailyConsumptionPrice: ChangeFreshRecipesPlanQuoteDailyConsumptionPrice;
  /** Regular order price for the quoted plan */
  regularOrderTotalConsumptionPrice: ChangeFreshRecipesPlanQuoteRegularOrderPrice;
  /** Recipes used to generate this quote */
  selectedRecipes: Array<ChangeFreshRecipesPlanQuoteRecipe>;
  /** Quote's subscription frequency */
  subscriptionFrequency: ChangeFreshRecipesPlanQuoteSubscriptionFrequency;
};

export type ChangeFreshRecipesPlanQuoteDailyConsumptionPrice = {
  __typename?: 'ChangeFreshRecipesPlanQuoteDailyConsumptionPrice';
  difference: Scalars['Float']['output'];
  original: Scalars['Float']['output'];
  updated: Scalars['Float']['output'];
};

export type ChangeFreshRecipesPlanQuoteInput = {
  petId: Scalars['Int']['input'];
  /** Updated recipes to use as quote input */
  recipes: Array<ChangeFreshRecipesPlanQuoteRecipeInput>;
};

export type ChangeFreshRecipesPlanQuoteRecipe = {
  __typename?: 'ChangeFreshRecipesPlanQuoteRecipe';
  /** Recipe display name */
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Recipe machine name */
  name: Scalars['String']['output'];
};

export type ChangeFreshRecipesPlanQuoteRecipeInput = {
  /** Recipe display name */
  displayName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  /** Recipe machine name */
  name: Scalars['String']['input'];
};

export type ChangeFreshRecipesPlanQuoteRegularOrderPrice = {
  __typename?: 'ChangeFreshRecipesPlanQuoteRegularOrderPrice';
  difference: Scalars['Float']['output'];
  original: Scalars['Float']['output'];
  updated: Scalars['Float']['output'];
};

export type ChangeFreshRecipesPlanQuoteSubscriptionFrequency = {
  __typename?: 'ChangeFreshRecipesPlanQuoteSubscriptionFrequency';
  original: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type ChannelPreference = {
  __typename?: 'ChannelPreference';
  email: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
};

export type ChewsProduct = {
  __typename?: 'ChewsProduct';
  calorieContent: Scalars['String']['output'];
  /** The length of the chew in the bag */
  chewLength: CgsChewLength;
  /** The quantity of chews in the bag */
  chewSize: CgsChewSize;
  /** The display name of the product */
  displayName: Scalars['String']['output'];
  guaranteedAnalysis: Array<GuaranteedAnalysisItem>;
  mainIngredients: Array<Scalars['String']['output']>;
  /** Price is in cents */
  price: Scalars['Int']['output'];
  /** The key of the product */
  productName: Scalars['String']['output'];
  quantitySubscribed: Scalars['Int']['output'];
};

export type Clinic = {
  __typename?: 'Clinic';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  source: ClinicDataSource;
  state: Scalars['String']['output'];
  streetAddress1: Scalars['String']['output'];
  streetAddress2: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ClinicComponent = {
  __typename?: 'ClinicComponent';
  clinic: Clinic;
  clinicEmail: Maybe<Scalars['String']['output']>;
  clinicPhone: Maybe<Scalars['String']['output']>;
  vetName: Maybe<Scalars['String']['output']>;
};

export enum ClinicDataSource {
  Google = 'Google',
  User = 'User',
}

export type ClinicInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  source: ClinicDataSource;
  state: Scalars['String']['input'];
  streetAddress1: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CreditCardBillingAddress = {
  __typename?: 'CreditCardBillingAddress';
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['output'];
  /** The second line of the customer's address, if present */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The state abbreviation */
  state: Scalars['String']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  accountInformation: AccountInformation;
  activatedAt: Maybe<Scalars['DateTime']['output']>;
  addOnLimits: AddOnLimits;
  billingAddress: BillingAddress;
  changeFreshOrderSizeQuotes: ChangeFreshOrderSizeQuotes;
  /** Price quote for a change in recipes for all pets */
  changeFreshRecipesPlanForAllPetsQuote: ChangeFreshRecipesPlanQuote;
  /** Price quote for a change in recipes for a single pet */
  changeFreshRecipesPlanQuote: ChangeFreshRecipesPlanQuote;
  chewsProducts: Array<ChewsProduct>;
  creditCard: CgsCreditCard;
  creditCards: Array<CgsCreditCard>;
  diySubscription: DiySubscription;
  eligibleForDownsell: EligibileForDownsell;
  eligibleForProductLine: EligibileForProductLine;
  experimentEligibility: ExperimentEligibility;
  firstUnlockedOrder: FirstUnlockedOrder;
  freshSubscription: FreshSubscription;
  /** get customer's eligible discounts */
  getEligibleDiscounts: Maybe<EligibleDiscounts>;
  /** Whether the user has treats in any order */
  hasTreatsInOrders: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  journeyStatus: JourneyStatus;
  nextOrderToBeDelivered: NextOrderToBeDelivered;
  notificationPreferences: NotificationPreferences;
  /** Orders associated with this customer */
  orders: Array<Cgs_Order>;
  paymentMethodChangedForOrder: Scalars['Boolean']['output'];
  pendingPet: Maybe<PendingPet>;
  pets: Array<Pet>;
  reactivationFreshQuote: ReactivationFreshQuote;
  /** Whether this Order is unlocked for the Payment Retry experience */
  retryPaymentIsUnlocked: Scalars['Boolean']['output'];
  shippingAddress: ShippingAddress;
  /** get all available snaps treat products for the customer & subscribed quantity */
  snapsTreatProducts: Array<SnapsTreatProduct>;
  /** Quote for an order size provided by client */
  suggestOrderSizeQuote: SuggestOrderSizeQuote;
  userCharacteristics: UserCharacteristics;
};

export type CustomerChangeFreshRecipesPlanForAllPetsQuoteArgs = {
  input: ChangeFreshRecipesPlanForAllPetsQuoteInput;
};

export type CustomerChangeFreshRecipesPlanQuoteArgs = {
  input: ChangeFreshRecipesPlanQuoteInput;
};

export type CustomerFreshSubscriptionArgs = {
  status: InputMaybe<Cgs_SubscriptionStatus>;
};

export type CustomerHasTreatsInOrdersArgs = {
  input: InputMaybe<HasTreatsInOrdersInput>;
};

export type CustomerPaymentMethodChangedForOrderArgs = {
  orderId: Scalars['Int']['input'];
};

export type CustomerPetsArgs = {
  input: InputMaybe<CgsPetsInput>;
};

export type CustomerSuggestOrderSizeQuoteArgs = {
  input: SuggestOrderSizeQuoteInput;
};

export type DiyDownsellInput = {
  cadence: Scalars['Int']['input'];
  nextOrderShipDate: Scalars['Date']['input'];
  numberOfBoxes: Scalars['Int']['input'];
};

export type DiyDownsellResponse = {
  __typename?: 'DiyDownsellResponse';
  success: Scalars['Boolean']['output'];
};

export type DiySubscription = {
  __typename?: 'DiySubscription';
  /** The available next arrival date for an order */
  availableNextDates: Array<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  /** Subscription's status */
  status: Cgs_SubscriptionStatus;
};

export type DiySubscriptionAvailableNextDatesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type EligibileForDownsell = {
  __typename?: 'EligibileForDownsell';
  /** Indicates if Customer is Eligible for Product Line 'LPF' for reason 'Downsell' */
  lpf: Scalars['Boolean']['output'];
};

export type EligibileForProductLine = {
  __typename?: 'EligibileForProductLine';
  /** Indicates if Customer is Eligible for Product Line 'LPF' */
  lpf: Scalars['Boolean']['output'];
};

export type EligibleDiscounts = {
  __typename?: 'EligibleDiscounts';
  freeFirstTreats: Maybe<FreeFirstTreatsDiscount>;
};

export type EligibleProduct = {
  __typename?: 'EligibleProduct';
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EnableLpfExperimentResponse = {
  __typename?: 'EnableLpfExperimentResponse';
  didEnableLpf: Scalars['Boolean']['output'];
};

export type ExperimentEligibility = {
  __typename?: 'ExperimentEligibility';
  hasOrderSizeUpNudgeEligibleCouponCode: Scalars['Boolean']['output'];
};

export type FirstUnlockedOrder = {
  __typename?: 'FirstUnlockedOrder';
  /** Estimated range of delivery dates */
  deliveryWindow: OrderDeliveryWindow;
  /** Order ID */
  id: Scalars['Int']['output'];
};

export type FoundClinic = {
  __typename?: 'FoundClinic';
  formattedAddress: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FreeFirstTreatsDiscount = {
  __typename?: 'FreeFirstTreatsDiscount';
  eligible: Maybe<Scalars['Boolean']['output']>;
};

export enum FreshFoodConfidence {
  /** Answered 'I already believe' that fresh food is the healthiest during signup */
  AlreadyBelieve = 'AlreadyBelieve',
  /** Answered 'I do not believe' that fresh food is the healthiest during signup */
  DoNotBelieve = 'DoNotBelieve',
  /** Answered 'I am not yet sure' that fresh food is the healthiest during signup */
  NotYetSure = 'NotYetSure',
  /** User did not answer the question */
  ValueNotAvailable = 'ValueNotAvailable',
}

export type FreshSubscription = {
  __typename?: 'FreshSubscription';
  /** Add on products */
  addOns: Array<CgsAddOn>;
  /** The available next arrival date for an order */
  availableNextDates: Array<Scalars['Date']['output']>;
  /** The available dates to resume a 'paused' subscription */
  availablePauseResumeDates: Array<Scalars['Date']['output']>;
  bufferDays: Maybe<Scalars['Int']['output']>;
  /** Subscription discount */
  discountPercentage: Scalars['Float']['output'];
  /** Subscription frequency */
  frequency: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastOrderPaymentFailed: Maybe<Scalars['Boolean']['output']>;
  lastOrderPaymentMethodChanged: Maybe<Scalars['Boolean']['output']>;
  /** The price of a shipment from this subscription */
  lastQuotedPrice: LastQuotedPrice;
  /** The date a customer’s next order will ship */
  nextDate: Maybe<Scalars['Date']['output']>;
  /** The customer’s desired arrival date for their next order */
  nextDesiredArrivalDate: Maybe<Scalars['Date']['output']>;
  recommendedChewsPackages: Array<CgsRecommendedChewsPackage>;
  recommendedChewsPortions: Array<CgsRecommendedChewsPortion>;
  recommendedSnapsTreatsPackages: Array<CgsRecommendedSnapsTreatsPackage>;
  recommendedSnapsTreatsPortions: Array<CgsRecommendedSnapsTreatsPortion>;
  /** Regular orders delivered count */
  regularOrdersDeliveredCount: Scalars['Int']['output'];
  /** Subscription's status */
  status: Cgs_SubscriptionStatus;
};

export type FreshSubscriptionAvailableNextDatesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type GuaranteedAnalysisItem = {
  __typename?: 'GuaranteedAnalysisItem';
  name: Scalars['String']['output'];
  percentage: Scalars['String']['output'];
};

export type HasTreatsInOrdersInput = {
  /** Whether to exclude the trial order when determining if user has treats in any orders */
  excludeTrialOrder?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum JourneyStatus {
  /** The customer has completed checkout and is awaiting their first box */
  AwaitingFirstBox = 'AwaitingFirstBox',
  /** The customer has received their first box and is awaiting their second box */
  ReceivedFirstBox = 'ReceivedFirstBox',
  /** The customer has received their second box */
  ReceivedSecondBox = 'ReceivedSecondBox',
}

export type LastQuotedPrice = {
  __typename?: 'LastQuotedPrice';
  /** Current daily consumption price for this subscription */
  dailyConsumptionPrice: Scalars['Int']['output'];
  /** Current consumption price of a regular order for this subscription */
  regularOrderTotalConsumptionPrice: Scalars['Int']['output'];
  shipmentPricePerWeek: Scalars['Int']['output'];
};

export type LineItemRecipe = {
  __typename?: 'LineItemRecipe';
  /** Recipe name to display on the customer portal */
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Recipe machine name */
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a clinic associated with the customer */
  addClinic: AddClinicResponse;
  /** Add a credit card to the customer's account */
  addCreditCard: AddCreditCardResponse;
  /** Add a pet to a customer's account */
  addPet: AddPetResponse;
  /** Immediately bill a Customer for an Order */
  billNow: BillNowResponse;
  /** Change the order size of a customer's fresh subscription */
  changeFreshOrderSize: ChangeFreshOrderSizeResponse;
  diyDownsell: DiyDownsellResponse;
  enableLpfExperiment: EnableLpfExperimentResponse;
  /** 'Pause' the fresh subscription by rescheduling the customer’s next order */
  pauseFreshSubscription: PauseFreshSubscriptionResponse;
  /** Reactivate the DIY subscription for a customer */
  reactivateDiySubscription: Maybe<ReactivateDiySubscriptionResponse>;
  /** Reactivate the fresh subscription for a customer */
  reactivateFreshSubscription: Maybe<ReactivateFreshSubscriptionResponse>;
  /** Remove an associated clinic from the customer */
  removeClinic: RemoveClinicResponse;
  /** Update the subscribed treats for the customer */
  setAddOns: SetAddOnsResponse;
  /** Set LPF product line eligibilty for reason downsell */
  setLPFDownsellEligibility: SetLpfDownsellEligibilityResponse;
  /** Suspend the fresh subscription for a customer */
  suspendFreshSubscription: SuspendFreshSubscriptionResponse;
  /** Update a credit card for the customer's account */
  updateCreditCard: UpdateCreditCardResponse;
  /** Update a customer's notification preferences */
  updateCustomerNotificationPreferences: UpdateCustomerNotificationPreferencesResponse;
  /** Update the customer information for a customer with a suspended subscription */
  updateCustomerWithSuspendedSubscription: UpdateCustomerWithSuspendedSubscriptionResponse;
  /** Update a food plan's recipes */
  updateFoodPlansRecipes: UpdateFoodPlansRecipesResponse;
  /** Update the current stock at home */
  updatePacksAtHome: UpdatePacksAtHomeResponse;
  updateSkipTreatsFromNextOrder: UpdateSkipTreatsFromNextOrderResponse;
};

export type MutationAddClinicArgs = {
  input: AddClinicInput;
};

export type MutationAddCreditCardArgs = {
  input: AddCreditCardInput;
};

export type MutationAddPetArgs = {
  input: AddPetInput;
};

export type MutationBillNowArgs = {
  input: BillNowInput;
};

export type MutationChangeFreshOrderSizeArgs = {
  input: ChangeFreshOrderSizeInput;
};

export type MutationDiyDownsellArgs = {
  input: DiyDownsellInput;
};

export type MutationPauseFreshSubscriptionArgs = {
  input: PauseFreshSubscriptionInput;
};

export type MutationReactivateDiySubscriptionArgs = {
  input: ReactivateDiySubscriptionInput;
};

export type MutationReactivateFreshSubscriptionArgs = {
  input: ReactivateFreshSubscriptionInput;
};

export type MutationRemoveClinicArgs = {
  input: RemoveClinicInput;
};

export type MutationSetAddOnsArgs = {
  input: CgsSetAddOnsInput;
};

export type MutationSuspendFreshSubscriptionArgs = {
  input: SuspendFreshSubscriptionInput;
};

export type MutationUpdateCreditCardArgs = {
  input: UpdateCreditCardInput;
};

export type MutationUpdateCustomerNotificationPreferencesArgs = {
  input: UpdateCustomerNotificationPreferencesInput;
};

export type MutationUpdateCustomerWithSuspendedSubscriptionArgs = {
  input: CgsUpdateCustomerWithSuspendedSubscriptionInput;
};

export type MutationUpdateFoodPlansRecipesArgs = {
  input: UpdateFoodPlansRecipesInput;
};

export type MutationUpdatePacksAtHomeArgs = {
  newPacksAtHome: Scalars['Int']['input'];
  planId: Scalars['Int']['input'];
};

export type MutationUpdateSkipTreatsFromNextOrderArgs = {
  skipTreatsFromNextOrder: Scalars['Boolean']['input'];
};

export type NextOrderToBeDelivered = {
  __typename?: 'NextOrderToBeDelivered';
  /** The total amount attributed to addOns only */
  addOnsTotal: Maybe<Scalars['Int']['output']>;
  /** The credit amount that can be applied to the order */
  applicableCredits: Scalars['Int']['output'];
  /** Order's creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The status of the order as it relates to delivery for the customer */
  deliveryStatus: OrderDeliveryStatus;
  /** Estimated range of delivery dates */
  deliveryWindow: OrderDeliveryWindow;
  /** The total amount attributed to the order's discount */
  discountTotal: Maybe<Scalars['Int']['output']>;
  /** Order ID */
  id: Scalars['Int']['output'];
  /** Whether this order is unlocked */
  isUnlocked: Scalars['Boolean']['output'];
  /** The total amount */
  itemTotal: Scalars['Int']['output'];
  key: Maybe<Scalars['String']['output']>;
  /** The total amount attributed to meals only */
  mealsTotal: Maybe<Scalars['Int']['output']>;
  /** Order number */
  number: Scalars['String']['output'];
  /** The payment status of this order */
  paymentStatus: Scalars['String']['output'];
  /** Breakdown per pet */
  pets: Array<OrderPet>;
  /** Daily price */
  pricePerDay: Scalars['Int']['output'];
  /** The latest date and time a customer can edit this order */
  rescheduleCutoffDate: Scalars['String']['output'];
  /** Order sequence number */
  sequenceNumber: Maybe<Scalars['String']['output']>;
  /** The estimated date the order will be shipped */
  shipDate: Scalars['Date']['output'];
  /** The total amount attributed to shipping costs */
  shippingTotal: Scalars['Int']['output'];
  /** The total amount attributed to tax */
  taxTotal: Scalars['Int']['output'];
  totalDays: Scalars['Int']['output'];
  /** Total price */
  totalOrderPrice: Scalars['Int']['output'];
  /** Total price with credits applied */
  totalOrderPriceWithCreditsApplied: Scalars['Int']['output'];
  trackingURL: Maybe<Scalars['String']['output']>;
  /** An order's tracking URL(s) provided by the shipping carrier */
  trackingUrls: Maybe<Array<Scalars['String']['output']>>;
};

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  id: Scalars['Int']['output'];
  /** Whether the user wants to receive marketing notifications */
  marketingNotifications: ChannelPreference;
  /** Whether the user wants to receive reminders about upcoming unfulfilled orders */
  orderReminders: ChannelPreference;
  /** Whether the user wants to receive updates about shipped order statuses */
  orderUpdates: ChannelPreference;
};

export enum OrderDeliveryStatus {
  /** When an order has reached its destination */
  Delivered = 'Delivered',
  /** When an order has failed shipment */
  Failed = 'Failed',
  /** When an order has failed payment */
  PaymentFailed = 'PaymentFailed',
  /** When an order is in production (shipment scheduled, billed, cooked, packed, etc) prior to leaving the facility */
  Preparing = 'Preparing',
  /** When no action has been taken on an order but is available in our system */
  Scheduled = 'Scheduled',
  /** When an order is out for delivery */
  Transit = 'Transit',
}

export type OrderDeliveryWindow = {
  __typename?: 'OrderDeliveryWindow';
  /** Earliest date this order is esimated to be delivered */
  earliestDesiredArrivalDate: Scalars['Date']['output'];
  /** The estimated delivery date as provided by the shipping carrier when an order enters a Transit status */
  estimatedDeliveryDate: Maybe<Scalars['Date']['output']>;
  /**
   * Earliest date this order is estimated to arrive
   * @deprecated Use earliestDesiredArrivalDate
   */
  estimatedEarliestArrivalDate: Scalars['Date']['output'];
  /**
   * Latest date this order is estimated to arrive
   * @deprecated Use latestDesiredArrivalDate
   */
  estimatedLatestArrivalDate: Scalars['Date']['output'];
  /** Latest date this order is esimated to be delivered */
  latestDesiredArrivalDate: Scalars['Date']['output'];
};

export type OrderPet = {
  __typename?: 'OrderPet';
  /** Amount of calories to feed per day (portion * pack calories) */
  dailyCalories: Scalars['Int']['output'];
  /** Pet’s ID */
  id: Scalars['Int']['output'];
  /** Pet’s name */
  name: Scalars['String']['output'];
  /** Pet's daily portion of a pack */
  portion: Scalars['Float']['output'];
  recipes: Array<CgsRecipe>;
  /** Quantity of days for this pet in this order */
  totalDays: Scalars['Int']['output'];
  /** Quantity of packs from this pet in this order */
  totalPacks: Scalars['Int']['output'];
};

export type PauseFreshSubscriptionInput = {
  resumeDate: Scalars['String']['input'];
};

export type PauseFreshSubscriptionResponse = {
  __typename?: 'PauseFreshSubscriptionResponse';
  customer: Customer;
};

export type PendingPet = {
  __typename?: 'PendingPet';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type Pet = {
  __typename?: 'Pet';
  /** A pet's activity level */
  activityLevel: Maybe<CgsActivityLevel>;
  /** The pet's age measured in weeks */
  ageInWeeks: Scalars['Int']['output'];
  /** The fresh food products available to this pet */
  availableFreshFoodProducts: Array<AvailableFreshFoodProduct>;
  /** A pet's body condition */
  bodyCondition: Maybe<CgsBodyCondition>;
  /** All of the breeds associated with this pet */
  breedsForDisplay: Array<Scalars['String']['output']>;
  /** The recipes currently assigned to the pet */
  foodRecipes: Array<PetFoodRecipe>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  noContact: Maybe<Scalars['Boolean']['output']>;
  /** A pet's personality type */
  personalityType: Maybe<CgsPersonalityType>;
  /** The plan information of the pet */
  plan: PetPlan;
  sex: Maybe<CgsPetSex>;
  /** The generic size of the pet based on their weight */
  size: CgsPetSize;
  /** The pet's weight in pounds */
  weightInPounds: Scalars['Float']['output'];
};

export type PetCharacteristics = {
  __typename?: 'PetCharacteristics';
  activityLevels: Array<Maybe<PetCharacteristicsActivityLevel>>;
  bodyConditions: Array<Maybe<PetCharacteristicsBodyCondition>>;
  breeds: Array<Maybe<PetCharacteristicsBreed>>;
  eatingStyles: Array<Maybe<PetCharacteristicsEatingStyle>>;
  healthIssues: Array<Maybe<PetCharacteristicsHealthIssue>>;
  personalityTypes: Array<Maybe<PetCharacteristicsPersonalityType>>;
  treatsUsageBuckets: Array<Maybe<PetCharacteristicsTreatsUsageBucket>>;
};

export type PetCharacteristicsActivityLevel = {
  __typename?: 'PetCharacteristicsActivityLevel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsBodyCondition = {
  __typename?: 'PetCharacteristicsBodyCondition';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsBreed = {
  __typename?: 'PetCharacteristicsBreed';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsEatingStyle = {
  __typename?: 'PetCharacteristicsEatingStyle';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsHealthIssue = {
  __typename?: 'PetCharacteristicsHealthIssue';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsPersonalityType = {
  __typename?: 'PetCharacteristicsPersonalityType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetCharacteristicsTreatsUsageBucket = {
  __typename?: 'PetCharacteristicsTreatsUsageBucket';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PetFoodRecipe = {
  __typename?: 'PetFoodRecipe';
  /** Recipe name to display on the customer portal */
  displayName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Recipe machine name */
  name: Scalars['String']['output'];
  /** The product id associated with the recipe */
  productId: Scalars['Int']['output'];
};

export type PetHealth = {
  __typename?: 'PetHealth';
  clinics: Array<ClinicComponent>;
  findClinic: Array<FoundClinic>;
  validateClinic: Maybe<ValidatedClinic>;
};

export type PetHealthFindClinicArgs = {
  searchTerm: Scalars['String']['input'];
  zipcode: InputMaybe<Scalars['String']['input']>;
};

export type PetHealthValidateClinicArgs = {
  input: ValidateVetInput;
};

export type PetPlan = {
  __typename?: 'PetPlan';
  /** The daily calories being fed from the last delivered order, taking into account any temporary portion overrides */
  currentDailyCalories: Scalars['Int']['output'];
  /** The amount of calories in each pack that is being fed since the last delivered order */
  currentPackCalories: Scalars['Float']['output'];
  /** The portion currently being fed, taking into account any temporary overrides */
  currentPortion: Scalars['Float']['output'];
  /** The price per day of a pet's subscription */
  dailyConsumptionPrice: Scalars['Float']['output'];
  /** Total calories consumed per day taking into account a pet's portion and mixing ratio */
  dailyFreshCalories: Scalars['Int']['output'];
  /** The portion of a pack consumed per day, taking into account mixing ratio */
  dailyPacksConsumed: Scalars['Float']['output'];
  /** How many days of food the customer has left in their current food stock */
  daysOfFood: Scalars['Float']['output'];
  /**
   * The estimated (rounded to the nearest second decimal place) amount of packs of food of the pet
   * @deprecated Use 'estimatedPacksOfFood' instead
   */
  estimatePacksOfFood: Scalars['Float']['output'];
  /** The estimated (rounded to the nearest second decimal place) amount of packs of food of the pet */
  estimatedPacksOfFood: Scalars['Float']['output'];
  /** What ratio of the dog's daily diet is TFD fresh - if less than 1, the dog mixes other food with TFD */
  freshFoodRatio: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** Whether or not the current feeding portion and pack size is different than the plan */
  isFeedingDifferentThanPlan: Scalars['Boolean']['output'];
  /** Determine if the next order the customer will receive is feeding the same daily calories as the plan */
  isNextOrderFeedingToPlan: Scalars['Boolean']['output'];
  /** The amount of calories in the food pack that will be delivered on the next order that follows the plan */
  planPackCalories: Scalars['Float']['output'];
};

export type PetQuote = {
  __typename?: 'PetQuote';
  dailyConsumptionPrice: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type PriceQuote = {
  __typename?: 'PriceQuote';
  mealsTotal: Scalars['Int']['output'];
  shipping: Scalars['String']['output'];
  tax: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  treatsTotal: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns information about the customer */
  customer: Customer;
  customerOrders: CgsCustomerOrders;
  /** List of avaiable food brands sorted alphabetically */
  foodBrands: Array<CgsFoodBrand>;
  orderDetailsByKey: CgsOrderDetails;
  /** Returns available pet characteristics */
  petCharacteristics: PetCharacteristics;
  petHealth: PetHealth;
  recipeDetails: CgsRecipeDetails;
  /** Base query for all queries to extend from */
  root: Maybe<Scalars['String']['output']>;
  /** List of U.S. states and territories */
  states: Array<Cgs_State>;
  /** Validate an address */
  validateAddress: ValidateAddressResponse;
};

export type QueryCustomerOrdersArgs = {
  futureOrderCount: Scalars['Int']['input'];
  pastOrderCount: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrderDetailsByKeyArgs = {
  key: Scalars['String']['input'];
};

export type QueryRecipeDetailsArgs = {
  input: CgsRecipeDetailsInput;
};

export type QueryValidateAddressArgs = {
  address: CgsAddressInput;
};

export type ReactivateDiySubscriptionInput = {
  nextDate: Scalars['Date']['input'];
};

export type ReactivateDiySubscriptionResponse = {
  __typename?: 'ReactivateDiySubscriptionResponse';
  customer: Customer;
};

export type ReactivateFreshSubscriptionInput = {
  nextDate: Scalars['Date']['input'];
};

export type ReactivateFreshSubscriptionResponse = {
  __typename?: 'ReactivateFreshSubscriptionResponse';
  customer: Customer;
};

export type ReactivationFreshQuote = {
  __typename?: 'ReactivationFreshQuote';
  pets: Array<PetQuote>;
  price: PriceQuote;
};

export type RemoveClinicInput = {
  clinicId: Scalars['String']['input'];
};

export type RemoveClinicResponse = {
  __typename?: 'RemoveClinicResponse';
  clinicId: Maybe<Scalars['String']['output']>;
};

export type SetAddOnsResponse = {
  __typename?: 'SetAddOnsResponse';
  customer: Customer;
};

export type SetLpfDownsellEligibilityResponse = {
  __typename?: 'SetLPFDownsellEligibilityResponse';
  customer: Customer;
  eligibleProduct: EligibleProduct;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  /** The first line of the customer's address */
  addressLine1: Scalars['String']['output'];
  /** The second line of the customer's address, if present */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The shipping address index value for caching */
  id: Scalars['Int']['output'];
  /** The state abbreviation */
  stateAbbreviation: Scalars['String']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

export type SnapsTreatProduct = {
  __typename?: 'SnapsTreatProduct';
  calorieContent: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  fullIngredients: Array<Scalars['String']['output']>;
  guaranteedAnalysis: Array<GuaranteedAnalysisItem>;
  ingredientsSubtext: Scalars['String']['output'];
  mainIngredients: Array<Scalars['String']['output']>;
  /** price is in cents */
  price: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  protein: SnapsTreatProtein;
  quantitySubscribed: Scalars['Int']['output'];
  size: SnapsTreatSize;
  /** @deprecated Field is available to maintain continuity with DSI segment events, will be removed once DSI has migrated to track behavior on product name and size */
  variantSku: Scalars['String']['output'];
};

export enum SnapsTreatProtein {
  Chicken = 'Chicken',
  Pork = 'Pork',
  Turkey = 'Turkey',
}

export enum SnapsTreatSize {
  Large = 'Large',
  Regular = 'Regular',
}

export enum StripeErrorCode {
  ContactBank = 'ContactBank',
  IncorrectInformation = 'IncorrectInformation',
  TemporarilyUnavailable = 'TemporarilyUnavailable',
}

export type SuggestOrderSizeQuote = {
  __typename?: 'SuggestOrderSizeQuote';
  /** Daily consumption price */
  dailyConsumptionPrice: Scalars['Float']['output'];
  /** Consumption price of entire regular order */
  regularOrderTotalConsumptionPrice: Scalars['Float']['output'];
};

export type SuggestOrderSizeQuoteInput = {
  /** Order size for which we want to generate a quote */
  orderSize: Scalars['Int']['input'];
};

export type SuspendFreshSubscriptionInput = {
  cancellationReasons: Array<Scalars['String']['input']>;
  cookAtHomeReplacementRecipe?: InputMaybe<Scalars['String']['input']>;
  freeResponse?: InputMaybe<Scalars['String']['input']>;
  prescriptionDiet?: InputMaybe<Scalars['String']['input']>;
  replacementFoodBrand?: InputMaybe<Scalars['String']['input']>;
  replacementFoodType?: InputMaybe<Scalars['String']['input']>;
};

export type SuspendFreshSubscriptionResponse = {
  __typename?: 'SuspendFreshSubscriptionResponse';
  customer: Customer;
};

export type UpdateCreditCardInput = {
  /** The first line of the billing address */
  addressLine1: Scalars['String']['input'];
  /** The second line of the billing address, if present */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The city of the address */
  city: Scalars['String']['input'];
  /** The country of the address */
  country: Scalars['String']['input'];
  /** id of the credit card to update */
  id: Scalars['String']['input'];
  /** The name on the credit card */
  name: Scalars['String']['input'];
  /** The state abbreviation */
  state: Scalars['String']['input'];
  /** The zip of the address */
  zip: Scalars['String']['input'];
};

export type UpdateCreditCardResponse = {
  __typename?: 'UpdateCreditCardResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateCustomerNotificationPreferencesInput = {
  marketingNotificationsSms: Scalars['Boolean']['input'];
  orderRemindersSms: Scalars['Boolean']['input'];
  orderUpdatesSms: Scalars['Boolean']['input'];
};

export type UpdateCustomerNotificationPreferencesResponse = {
  __typename?: 'UpdateCustomerNotificationPreferencesResponse';
  customer: Customer;
};

export type UpdateCustomerWithSuspendedSubscriptionResponse = {
  __typename?: 'UpdateCustomerWithSuspendedSubscriptionResponse';
  customer: Customer;
};

export type UpdateFoodPlanRecipesInput = {
  /** The plan id associated with this this update */
  id: Scalars['Int']['input'];
  selectedRecipes: Array<UpdateRecipeInput>;
};

export type UpdateFoodPlansRecipesInput = {
  foodPlans: Array<UpdateFoodPlanRecipesInput>;
  freeFormFeedback?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFoodPlansRecipesResponse = {
  __typename?: 'UpdateFoodPlansRecipesResponse';
  customer: Customer;
};

export type UpdatePacksAtHomeResponse = {
  __typename?: 'UpdatePacksAtHomeResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateRecipeInput = {
  /** The recipe id associated with this update */
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateSkipTreatsFromNextOrderResponse = {
  __typename?: 'UpdateSkipTreatsFromNextOrderResponse';
  success: Scalars['Boolean']['output'];
};

export type UserCharacteristics = {
  __typename?: 'UserCharacteristics';
  daysSinceCheckout: Scalars['Int']['output'];
  freshFoodConfidence: FreshFoodConfidence;
  /** Whether the user has a pet that is rounded or chunky */
  hasOverweightPet: Scalars['Boolean']['output'];
  hasPickyEater: Scalars['Boolean']['output'];
  /** Whether the user has a transition order in their subscription */
  hasTransitionOrder: Scalars['Boolean']['output'];
  isMultiPet: Scalars['Boolean']['output'];
};

export type ValidateAddressResponse = {
  __typename?: 'ValidateAddressResponse';
  submittedAddress: AddressFields;
  suggestedAddress: Maybe<AddressFields>;
};

export type ValidateVetInput = {
  address: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ValidatedClinic = {
  __typename?: 'ValidatedClinic';
  addressComponents: Maybe<AddressComponents>;
  formattedAddress: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
};
