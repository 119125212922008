import classNames from 'classnames';

import { PATH_WHY_FRESH } from '@farmersdog/constants/paths';
import {
  Mask,
  Wave,
  Nowrap,
  useViewport,
  Text,
  Card,
  Link,
} from '@farmersdog/corgi-x';
import { Carrot, DogFood, Heart } from '@farmersdog/corgi-x/icons';

import Picture, { LoadingOption } from 'src/components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import brettImages from './assets/brett-kitchen';
import jonathanImages from './assets/jonathan-van';
import bowlAnimatedImages from './assets/bowl-animated';
import styles from './Story.module.scss';

const SPOTIFY_PLAYLIST_LINK =
  'https://open.spotify.com/playlist/4hgHPv7yYVl2mdln7OYCwc?si=yX89bEz5RIOcQoPrEp4MwQ';

function RealFoodRealResults() {
  const variant = useViewport().lg ? 'heading-28' : 'heading-22';
  return (
    <>
      <div className={styles.iconWrapper}>
        <Carrot size={24} baseColor="Carrot2" />
      </div>
      <Text variant={variant} color="kale-3" as="h2" bold>
        Real food, real results
      </Text>
      <Text variant="article-20" color="charcoal-3" as="p">
        One vet suggested Brett try home cooking. He simply prepared ingredients
        pictured on a bag of traditional dog food — but what he made actually
        looked, smelled, and tasted like real food. Seemingly overnight, Jada’s
        problems disappeared.
      </Text>
    </>
  );
}

function AFreshRevelation() {
  const variant = useViewport().lg ? 'heading-28' : 'heading-22';
  return (
    <>
      <div className={styles.iconWrapper}>
        <DogFood size={24} baseColor="Carrot2" />
      </div>
      <Text variant={variant} color="kale-3" as="h2" bold>
        A fresh revelation
      </Text>
      <Text variant="article-20" color="charcoal-3" as="p">
        Brett realized that pet food marketing was masking{' '}
        <Link to={PATH_WHY_FRESH} target="_blank" rel="noopener noreferrer">
          an alarming reality
        </Link>
        , so he teamed up with Jonathan (and his pup, Buddy) to build the
        company they wished existed for their own dogs.
      </Text>
    </>
  );
}

function TheFeedback() {
  const variant = useViewport().lg ? 'heading-28' : 'heading-22';
  return (
    <>
      <div className={styles.iconWrapper}>
        <Heart size={24} baseColor="Carrot2" />
      </div>
      <Text variant={variant} color="kale-3" as="h2" bold>
        The feedback
      </Text>
      <Text variant="article-20" color="charcoal-3" as="p">
        Dogs of all shapes and sizes were thriving on the food and their humans
        had newfound peace of mind about what they were feeding. With a
        small-but-mighty team, they expanded delivery nationwide — without
        sacrificing a crumb of the care, love, and quality of their first{' '}
        <Nowrap>home-cooked batch.</Nowrap>
      </Text>
    </>
  );
}

function BrettCircleImage() {
  return (
    <figure className={styles.brettKitchen}>
      <Mask shape="circle" size="xl">
        <Picture
          sources={brettImages}
          alt="about-us-brett-kitchen"
          loading={LoadingOption.lazy}
        />
      </Mask>
      <Text
        variant="article-16"
        color="kale-3"
        fontStyle="italic"
        as="figcaption"
      >
        Things move much faster now that it isn’t just Brett packing orders{' '}
        <Nowrap>by hand.</Nowrap>
      </Text>
    </figure>
  );
}

function JonathanCircleImage() {
  return (
    <figure className={styles.jonathanVan}>
      <Mask shape="circle" size="xl">
        <Picture
          sources={jonathanImages}
          alt="about-us-jonathan-van"
          loading={LoadingOption.lazy}
        />
      </Mask>
      <Text
        variant="article-16"
        color="kale-3"
        fontStyle="italic"
        as="figcaption"
      >
        Delivering the first batch of orders in the wee hours of{' '}
        <Nowrap>the morning.</Nowrap>
      </Text>
    </figure>
  );
}

function FunFactDooWop() {
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <Card
      className={classNames(
        styles.funFact,
        styles.funFactDooWop,
        accessibilityThemeStyles.card
      )}
    >
      <Text variant="article-20" as="h3">
        Fun Fact:
      </Text>
      <Text variant="article-16" as="p">
        Brett and Jonathan loved listening to Doo-wop classics to stay energized
        and upbeat during late nights in the kitchen. Music — Doo-wop in
        particular — continues to be an <Nowrap>office staple!</Nowrap>
      </Text>
      <Text as="p" variant="heading-16">
        <Link
          href={SPOTIFY_PLAYLIST_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Listen on Spotify
        </Link>
      </Text>
    </Card>
  );
}

function FunFactName() {
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <Card
      className={classNames(
        styles.funFact,
        styles.funFactName,
        accessibilityThemeStyles.card
      )}
    >
      <Text variant="article-20" as="h3">
        Fun Fact:
      </Text>
      <Text variant="article-16" as="p">
        We get a lot of questions about our name, which was inspired by the fact
        that many of the longest-living dogs throughout history have been farm
        dogs.
      </Text>
      <Text variant="article-16" as="p">
        We think it might’ve had something to do with the simple, pure, and
        unfussy relationship between human, dog, and earth — a special bond that
        we try to capture in everything that <Nowrap>we do.</Nowrap>
      </Text>
    </Card>
  );
}

function FoodCompare() {
  return (
    <div className={styles.foodCompare}>
      <Mask shape="circle" size="xl">
        <Picture
          className={classNames(styles.bowlAnimated, styles.overlap)}
          sources={bowlAnimatedImages}
          alt="about-us-bowl-animated"
          loading={LoadingOption.lazy}
        />
      </Mask>
      <Text
        variant="article-16"
        color="kale-3"
        fontStyle="italic"
        as="figcaption"
      >
        Real, fresh food vs. kibble.
      </Text>
    </div>
  );
}

function MobileStory() {
  return (
    <>
      <div className={classNames(styles.overlap)} />
      <RealFoodRealResults />
      <FoodCompare />
      <div className={styles.sectionSpace} />
      <AFreshRevelation />
      <BrettCircleImage />
      <JonathanCircleImage />
      <FunFactDooWop />
      <div className={styles.sectionSpace} />
      <TheFeedback />
      <FunFactName />
      <div className={classNames(styles.overlap)} />
    </>
  );
}

function DesktopStory() {
  return (
    <>
      <div className={styles.column}>
        <div className={styles.endColumn}>
          <BrettCircleImage />
          <FunFactDooWop />
        </div>
      </div>
      <div className={styles.column}>
        <div
          className={classNames(styles.line, styles.lineTop, styles.overlap)}
        />
        <RealFoodRealResults />
        <div className={styles.line} />
        <AFreshRevelation />
        <div className={styles.line} />
        <TheFeedback />
        <div
          className={classNames(styles.line, styles.lineBottom, styles.overlap)}
        />
      </div>
      <div className={styles.column}>
        <div className={styles.endColumn}>
          <FoodCompare />
          <JonathanCircleImage />
          <FunFactName />
        </div>
      </div>
    </>
  );
}

function Story() {
  const isMobile = !useViewport().lg;
  const { isAccessibilityThemeOn, OysterBackgroundWrapper } =
    useAccessibilityThemeContext();

  const sectionContent = (
    <div
      className={classNames(styles.accessibilityExperimentContainer, {
        [styles.lg]: !isMobile,
      })}
    >
      {isMobile ? <MobileStory /> : <DesktopStory />}
    </div>
  );

  return isAccessibilityThemeOn ? (
    <OysterBackgroundWrapper addBackgroundPadding={isMobile}>
      {sectionContent}
    </OysterBackgroundWrapper>
  ) : (
    <Wave withBottom withTop>
      {sectionContent}
    </Wave>
  );
}

export default Story;
