import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import { CurrentDogHero, OptimizedHero, CurrentFormatHero } from './variants';
import { SubHero } from './components';

const heroVariantsMap: Partial<
  Record<HomepageEndToEndRedesignTreatments, JSX.Element>
> = {
  [HomepageEndToEndRedesignTreatments.CurrentHeroDog]: <CurrentDogHero />,
  [HomepageEndToEndRedesignTreatments.OptimizedHero]: <OptimizedHero />,
  [HomepageEndToEndRedesignTreatments.CurrentFormat]: <CurrentFormatHero />,
};

const defaultVariant = <CurrentDogHero />;

interface HeroSectionProps {
  ctaRevealElement: React.RefObject<HTMLDivElement>;
}

export const HeroSection = ({ ctaRevealElement }: HeroSectionProps) => {
  const { treatment } = useShowHomepageRedesign();

  return (
    <>
      {heroVariantsMap[treatment] ?? defaultVariant}
      <SubHero ctaRevealElement={ctaRevealElement} />
    </>
  );
};
