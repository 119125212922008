export function toggleElementInArray<E>(element: E, array: E[]): E[] {
  const newArray = [...array];

  if (newArray.includes(element)) {
    newArray.splice(newArray.indexOf(element), 1);
  } else {
    newArray.push(element);
  }

  return newArray;
}
