import { useId } from 'react';

import { Text } from '@farmersdog/corgi-x';

import styles from './NutritionFacts.module.css';

import type { NutritionFactsContent } from '../../types';

interface NutritionFactsProps {
  nutritionFacts: NutritionFactsContent;
}

export function NutritionFacts(props: NutritionFactsProps) {
  const id = useId();
  return (
    <section aria-labelledby={id} className={styles.nutritionFacts}>
      <Text id={id} as="h1" variant="article-40" bold className={styles.header}>
        Nutrition Facts
      </Text>
      <div className={styles.border} />
      <div className={styles.subSections}>
        <Text
          as="h2"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.subHeader}
        >
          Ingredients
        </Text>
        <Text as="p" variant="heading-16" className={styles.ingredients}>
          {props.nutritionFacts.ingredients}
        </Text>
        <Text as="p" variant="heading-12" className={styles.ingredientsSub}>
          {props.nutritionFacts.ingredientsSub}
        </Text>
      </div>
      <div className={styles.subSections}>
        <Text
          as="h2"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.subHeader}
        >
          Guaranteed Analysis
        </Text>
        {props.nutritionFacts.guaranteedAnalysis.map(({ item, percentage }) => (
          <div key={item} className={styles.analysis}>
            <Text variant="heading-16" className={styles.analysisItem}>
              {item}
            </Text>
            <Text variant="heading-16" className={styles.analysisPercentage}>
              {percentage}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.subSections}>
        <Text
          as="h2"
          variant="heading-16"
          color="kale-3"
          bold
          className={styles.subHeader}
        >
          Calculated Calorie Content
        </Text>
        {props.nutritionFacts.calculatedCalorieContent.map(content => (
          <Text
            key={content}
            as="p"
            variant="heading-16"
            className={styles.calorieContent}
          >
            {content}
          </Text>
        ))}
      </div>
      <div className={styles.border} />
    </section>
  );
}
