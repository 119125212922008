import { Logger } from '@farmersdog/logger/src/Logger';
import { browserOnly } from '@farmersdog/utils';

const log = new Logger('tosa:vendors:googleTagManager');

export interface SetGoogleTagManagerVarArgs {
  variableName: string;
  value: unknown;
}

interface WindowWithGTMObject extends Window {
  googleTagManagerVariables?: Record<string, unknown>;
}

export const gtmUndefinedDebugError =
  'Cannot set the variable because "window.googleTagManagerVariables" is not defined';

/**
 * Safely set a variable in the `googleTagManagerVariables` global window object.
 */

export function setGoogleTagManagerVar({
  variableName,
  value,
}: SetGoogleTagManagerVarArgs) {
  return browserOnly((window: WindowWithGTMObject) => {
    if (typeof window.googleTagManagerVariables !== 'object') {
      log.debug(gtmUndefinedDebugError);
      return;
    }
    window.googleTagManagerVariables[variableName] = value;
    log.debug('Set variable', { name: variableName, value });
  });
}
