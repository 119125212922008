/**
 * Return the closest fraction to the decimal - this is typically used for portions per day and packs remaining at home.
 *
 * We now support lots of odd fractions, like 2/7 or 3/5 or 1/8 - customers can't select those, but CX associates can.
 * Because of that, we need to support all the varieties of the denominator; e.g. for 2/7, we should allow 1/7, 2/7, 3/7, 4/7, 5/7, 6/7.
 * That's because, as you consume packs at that rate, we now have the remaining pack at different decimal values that don't
 * match the portions. For example, if you feed 2/7 of a pack, the remainder is 5/7; the next time it's 3/7; the next time it's 1/7; etc.
 *
 * In case we get even weirder in-between numbers (like you switched from feeding 1/7 of a pack to 1/6 of a pack or something, mid-way through
 * the last delivered stock), the fractions are chosen based on the halfway point between it and the next fraction. Once we go more than halfway,
 * the next fraction is chosen instead, etc.
 */
function decimalToFraction(decimal: number) {
  if (decimal <= 0.0625) {
    return '0';
  }

  // 1/8 = .125
  if (decimal > 0.0625 && decimal <= 0.134) {
    return '¹⁄₈';
  }

  // 1/7 = .142857143
  if (decimal > 0.134 && decimal <= 0.154) {
    return '¹⁄₇';
  }

  // 1/6 = .166666667
  if (decimal > 0.154 && decimal <= 0.183) {
    return '¹⁄₆';
  }

  // 1/5 = .2
  if (decimal > 0.183 && decimal <= 0.225) {
    return '¹⁄₅';
  }

  // 1/4 = .25
  if (decimal > 0.225 && decimal <= 0.268) {
    return '¹⁄₄';
  }

  // 2/7 = .285714286
  if (decimal > 0.268 && decimal <= 0.309) {
    return '²⁄₇';
  }

  // 1/3 = .333333
  if (decimal > 0.309 && decimal <= 0.354) {
    return '¹⁄₃';
  }

  // 3/8 = .375
  if (decimal > 0.354 && decimal <= 0.388) {
    return '³⁄₈';
  }

  // 2/5 = .4
  if (decimal > 0.388 && decimal <= 0.414) {
    return '²⁄₅';
  }

  // 3/7 = .428571429
  if (decimal > 0.414 && decimal <= 0.464) {
    return '³⁄₇';
  }

  // 1/2 = .5
  if (decimal > 0.464 && decimal <= 0.536) {
    return '¹⁄₂';
  }

  // 4/7 = .571428571
  if (decimal > 0.536 && decimal <= 0.586) {
    return '⁴⁄₇';
  }

  // 3/5 = .6
  if (decimal > 0.586 && decimal <= 0.613) {
    return '³⁄₅';
  }

  // 5/8 = .625
  if (decimal > 0.613 && decimal <= 0.646) {
    return '⁵⁄₈';
  }

  // 2/3 = .666667
  if (decimal > 0.646 && decimal <= 0.691) {
    return '²⁄₃';
  }

  // 5/7 = .714285714
  if (decimal > 0.691 && decimal <= 0.736) {
    return '⁵⁄₇';
  }

  // 3/4 = .75
  if (decimal > 0.736 && decimal <= 0.775) {
    return '³⁄₄';
  }

  // 4/5 = .8
  if (decimal > 0.775 && decimal <= 0.817) {
    return '⁴⁄₅';
  }

  // 5/6 = .833333333
  if (decimal > 0.817 && decimal <= 0.845) {
    return '⁵⁄₆';
  }

  // 6/7 = .857142857
  if (decimal > 0.845 && decimal <= 0.866) {
    return '⁶⁄₇';
  }

  // 7/8 = .875
  if (decimal > 0.866 && decimal <= 0.937) {
    return '⁷⁄₈';
  }

  if (decimal > 0.937 && decimal < 1) {
    return '1';
  } else {
    return '0';
  }
}

export function formatDecimalToFraction(
  portion: number | undefined = 0
): string {
  if (portion <= 0) {
    return '0';
  }

  const whole = Math.floor(portion);
  const decimal = portion % 1;
  const fraction = decimalToFraction(decimal);

  if (whole && decimal && fraction !== '0' && fraction !== '1') {
    return `${whole} ${fraction}`;
  }

  if (whole) {
    if (fraction === '1') {
      return (whole + 1).toString();
    } else {
      return whole.toString();
    }
  }

  if (decimal) {
    return fraction;
  }

  return '0';
}
