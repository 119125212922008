import { Picture } from '@farmersdog/corgi-x';

import styles from './FreshUpsell.module.scss';
import { generateSourcesForPicture } from 'src/pages/Home/components/AssetPreloader/assets/shared';
import { imageSources } from './assets';

export function CardImage() {
  const sourcesForPicture = generateSourcesForPicture(imageSources);

  return (
    <div>
      <Picture
        sources={sourcesForPicture}
        alt="dog next to bowl of food"
        className={styles.image}
      />
    </div>
  );
}
