import { Section, useViewport } from '@farmersdog/corgi-x';
import { PATH_WHY_FRESH } from '@farmersdog/constants/paths';
import { ExtendedButton, ExtendedText } from '../../components';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { useTrackedSectionRef } from 'src/hooks/useTrackedSections';

import SafetyFactsIcon from './assets/safety-facts-icon.svg';

import styles from './DidYouKnowSection.module.css';

export const DidYouKnowSection = () => {
  const isLarge = useViewport().lg;

  const sectionRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageRedesignSections.DidYouKnow
  );

  return (
    <div className={styles.wrapper} ref={sectionRef}>
      <Section
        aria-labelledby="did-you-know-section"
        variant="card"
        className={styles.factCard}
      >
        {isLarge && <SafetyFactsIcon aria-hidden className={styles.icon} />}
        <ExtendedText
          weight="semibold"
          as="h2"
          variant="spaced"
          topSpacing={{ xs: 'none', lg: 'md' }}
          color="Kale3"
          id="did-you-know-section"
          bottomSpacing="none"
        >
          SAFETY FACTS
        </ExtendedText>
        {isLarge ? <DesktopCopy /> : <MobileCopy />}
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.DidYouKnow}
        >
          <ExtendedButton
            className={styles.button}
            variant="bordered-kale"
            type="link"
            to={PATH_WHY_FRESH}
          >
            Learn More
          </ExtendedButton>
        </CtaTrackerV2>
      </Section>
    </div>
  );
};

function DesktopCopy() {
  return (
    <>
      <ExtendedText
        as="p"
        topSpacing="md"
        variant="article-28"
        bottomSpacing="md"
        color="Kale3"
      >
        Our human-grade pet food is always made
        <br />
        to the same high safety standards as the food you eat.
      </ExtendedText>
      <ExtendedText
        as="p"
        variant="article-28"
        topSpacing="none"
        bottomSpacing="xl"
        color="Kale3"
      >
        But kibble can be made with low-quality
        <br />
        meat to feed-grade standards.
      </ExtendedText>
    </>
  );
}

function MobileCopy() {
  return (
    <>
      <ExtendedText
        as="p"
        variant="article-24"
        topSpacing="sm"
        bottomSpacing="md"
        color="Kale3"
      >
        Our human-grade pet <br />
        food is always made to <br />
        the same high safety <br />
        standards as the food <br />
        you eat.
      </ExtendedText>
      <ExtendedText
        as="p"
        variant="article-24"
        topSpacing="none"
        bottomSpacing="md"
        color="Kale3"
      >
        But kibble can be made <br />
        with low-quality meat to <br />
        feed-grade standards.
      </ExtendedText>
    </>
  );
}
