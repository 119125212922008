import HeroCurrentDogVariantDesktopJpg from './hero-current-dog-desktop.jpg';
import HeroCurrentDogVariantDesktopWebp from './hero-current-dog-desktop.webp';
import HeroCurrentDogVariantMobileJpg from './hero-current-dog-mobile.jpg';
import HeroCurrentDogVariantMobileWebp from './hero-current-dog-mobile.webp';

export const heroCurrentDogVariantSources = {
  mobileSources: {
    webP: HeroCurrentDogVariantMobileWebp,
    jpg: HeroCurrentDogVariantMobileJpg,
  },
  desktopSources: {
    webP: HeroCurrentDogVariantDesktopWebp,
    jpg: HeroCurrentDogVariantDesktopJpg,
  },
};
