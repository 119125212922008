import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { paths } from '@farmersdog/constants';
import {
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
} from '@farmersdog/constants/paths';
import { Text } from '@farmersdog/corgi-x';
import { useSetCouponCookie } from '@farmersdog/coupons';

import { TosaLayout } from './components/layout';
import { NewTosaRouter } from './components/NewTosaRouter';
import { Node } from './components/schema/Node';
import { SignupHeader, DynamicPageTitle } from './components/shared';
import { CalculatingScreen } from './components/shared/CalculatingScreen/CalculatingScreen';
import { ErrorModal } from './components/shared/ErrorModal';
import { TosaDevTools } from './components/TosaDevTools';
import config from './config';
import { useGetLead } from './graphql/queries';
import {
  LightboxProvider,
  useRecoverSignupByUrl,
  RequestAttributesProvider,
  StaticDataFetchContextProvider,
  useReloadApp,
} from './hooks';
import {
  useFeaturePlaceholder,
  useAnonymousExperiments,
} from './hooks/experiments';
import { useDefaultDiscountExperiment } from './hooks/experiments/useDefaultDiscountExperiment';
import { useConfigureGa4 } from './hooks/useConfigureGa4';
import { useDebugTosa } from './hooks/useDebugTosa';
import { useFormNavigation } from './hooks/useFormNavigation';
import { useFormQueryUrl } from './hooks/useFormQueryUrl';
import { useFormSubmit } from './hooks/useFormSubmit';
import { useFormValidation } from './hooks/useFormValidation';
import { useHandleFormCompleted } from './hooks/useHandleFormCompleted';
import { useHandleMeCompleted } from './hooks/useHandleMeCompleted';
import { useHandlePageView } from './hooks/useHandlePageView';
import { useHandlePetsCompleted } from './hooks/useHandlePetsCompleted';
import { useIdentifyLead } from './hooks/useIdentifyLead';
import { useOverrideRoute } from './hooks/useOverrideRoute';
import { useProgress } from './hooks/useProgress';
import { useRestoreLeadProgress } from './hooks/useRestoreLeadProgress';
import { useRoute } from './hooks/useRoute';
import { useSchema } from './hooks/useSchema';
import { useScrollToTop } from './hooks/useScrollToTop';
import { TosaTokenProvider } from './hooks/useTosaToken';
import styles from './TOSA.css';
import { getIsFirstPetsPage } from './utils';
import { getBlueprintVersion } from './utils/getBlueprintVersion';
import { redirectIfNodeRemoved } from './utils/redirectIfNodeRemoved';

import type { ReloadApp } from './hooks';
import type {
  OnTosaCompleted,
  OnMeStepCompleted,
} from './hooks/useHandleFormCompleted';
import type { GetBlueprint } from './hooks/useSchema';
import type { FormFieldsType, UseFeatureHook } from './types';

export interface TOSAProps {
  getBlueprint: GetBlueprint;
  onTosaCompleted?: OnTosaCompleted;
  onMeCompleted?: OnMeStepCompleted;
  useFeature?: UseFeatureHook;
  clearSignupUser: () => void;
}

export interface TOSAComponentProps extends TOSAProps {
  reloadApp: ReloadApp;
}

const CALCULATING_SCREEN_PATHS: string[] = [
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
];

function TOSAComponent({
  reloadApp,
  getBlueprint,
  onMeCompleted: websiteOnMeCompleted,
  onTosaCompleted,
  useFeature: websiteUseFeature,
  clearSignupUser,
}: TOSAComponentProps) {
  const enableDevTools = config.get('features.tosaDevTools');
  const useFeature = websiteUseFeature ?? useFeaturePlaceholder;
  const [restoreComplete, setRestoreComplete] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<null | string>(
    null
  );

  useConfigureGa4();

  const anonymousExperiments = useAnonymousExperiments(useFeature);
  const blueprintVersion = getBlueprintVersion();

  const { schema, registry, formSteps, setFormSteps } = useSchema({
    getBlueprint,
    blueprintVersion,
    anonymousExperiments,
  });

  const onMeCompleted = useHandleMeCompleted(websiteOnMeCompleted);
  const onPetsCompleted = useHandlePetsCompleted();

  const urlEmail = useRecoverSignupByUrl();

  useSetCouponCookie();

  const formNavigation = useFormNavigation();
  const { current } = formNavigation;

  const { branchValidationSchema, formValidationSchema } = useFormValidation({
    current,
    root: schema?.root,
    registry,
  });

  const formMethods = useForm<FormFieldsType>({
    resolver: yupResolver(branchValidationSchema),
    defaultValues: {},
    mode: 'onChange',
  });

  useFormQueryUrl({
    formMethods,
    loaded: Boolean(schema && current && restoreComplete),
    schema,
    registry,
    setFormSteps,
  });

  useRoute({
    registry,
    current,
    blueprint: schema,
    setFormSteps,
    formNavigation,
    formMethods,
    formSteps,
    experiments: anonymousExperiments,
  });

  const { getValues, reset } = formMethods;

  const progress = useProgress({
    schema,
    formSteps,
    formMethods,
    formNavigation,
    useFeature,
  });

  const lead = useGetLead().data?.lead ?? null;

  redirectIfNodeRemoved({
    current,
    formSteps,
    formNavigation,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    anonymousExperiments,
    lead,
  });

  useScrollToTop({ formNavigation });
  useDebugTosa({ formMethods });

  useRestoreLeadProgress({
    formNavigation,
    urlEmail,
    formSteps,
    setFormSteps,
    blueprint: schema,
    reset,
    getNextIncompleteBranch: progress.getNextIncompleteBranch,
    getLastCompletedBranch: progress.getLastCompletedBranch,
    getFirstBlockedBranch: progress.getFirstBlockedBranch,
    handleRestoreComplete: () => setRestoreComplete(true),
    experiments: anonymousExperiments,
    useFeature,
  });

  useOverrideRoute({
    registry,
    getValues,
    formSteps,
    getBranchComplete: progress.getBranchComplete,
    formNavigation,
  });

  const handleFormCompleted = useHandleFormCompleted({
    formSteps,
    onMeCompleted,
    onPetsCompleted,
    onTosaCompleted,
    experiments: anonymousExperiments,
  });

  const isFirstPetsPage = getIsFirstPetsPage({ current, formSteps });

  useHandlePageView({
    isFirstPetsPage,
    email: lead?.email,
  });

  const [handleSubmit, formSubmitRequest] = useFormSubmit({
    formNavigation,
    formValidationSchema,
    formSteps,
    onFormCompleted: handleFormCompleted,
    setErrorModalMessage,
  });

  useDefaultDiscountExperiment({ experiments: anonymousExperiments });

  if (!schema || !registry || !current) {
    return null;
  }

  return (
    <div className={styles.root}>
      <DynamicPageTitle />

      <SignupHeader
        formNavigation={formNavigation}
        formSteps={formSteps}
        registry={registry}
        getValues={getValues}
        blueprint={schema}
        setFormSteps={setFormSteps}
        reset={reset}
        useFeature={useFeature}
        experiments={anonymousExperiments}
      />

      <ErrorModal
        errorMessage={errorModalMessage}
        setErrorModalMessage={setErrorModalMessage}
        reloadApp={reloadApp}
      />

      {enableDevTools && (
        <TosaDevTools
          formMethods={formMethods}
          schema={schema}
          registry={registry}
          formSteps={formSteps}
          setFormSteps={setFormSteps}
          goToStep={formNavigation.goToStep}
        />
      )}

      <TosaLayout>
        <Text variant="heading-28" color="charcoal-3" as="div">
          <Node
            onSubmit={handleSubmit}
            onFormCompleted={handleFormCompleted}
            formMethods={formMethods}
            formSubmitRequest={formSubmitRequest}
            schema={schema}
            registry={registry}
            setFormSteps={setFormSteps}
            formNavigation={formNavigation}
            node={schema.root}
            formSteps={formSteps}
            progress={progress}
            formValidationSchema={formValidationSchema}
            reloadApp={reloadApp}
            useFeature={useFeature}
            experiments={anonymousExperiments}
            setErrorModalMessage={setErrorModalMessage}
            clearSignupUser={clearSignupUser}
          />
        </Text>
      </TosaLayout>
    </div>
  );
}

export function TOSA(props: TOSAProps) {
  const [appKey, reloadApp] = useReloadApp();

  const NEW_TOSA_ROUTER_CONTROLLED_PATHS = [
    `${paths.PATH_SIGNUP_RECIPES}/:email?`,
    `${paths.PATH_SIGNUP_TREATS}/:email?`,
  ];

  const location = useLocation();

  const shouldRenderCalculatingScreen = !CALCULATING_SCREEN_PATHS.includes(
    location.pathname
  );

  return (
    <RequestAttributesProvider>
      <LightboxProvider>
        <TosaTokenProvider>
          <TOSAHooks />

          {shouldRenderCalculatingScreen && <CalculatingScreen />}

          <Switch>
            <Route path={NEW_TOSA_ROUTER_CONTROLLED_PATHS}>
              {props.useFeature && props.onTosaCompleted && (
                <NewTosaRouter
                  useFeature={props.useFeature}
                  onTosaCompleted={props.onTosaCompleted}
                />
              )}
            </Route>

            <Route path={`${paths.PATH_SIGNUP}/:pageOrEmail?/:email?`}>
              <StaticDataFetchContextProvider key={appKey}>
                <TOSAComponent reloadApp={reloadApp} {...props} />
              </StaticDataFetchContextProvider>
            </Route>
            <Route>
              <Redirect to={paths.PATH_SIGNUP} />
            </Route>
          </Switch>
        </TosaTokenProvider>
      </LightboxProvider>
    </RequestAttributesProvider>
  );
}

// This just exists to run hooks that we want to run in both the old and new
// TOSA architecture. Once we switch fully over to NewTOSARouter and remove
// TOSAComponent, we can migrate its hook calls into NewTOSARouter and delete
// it.
function TOSAHooks() {
  useIdentifyLead();

  return null;
}
