export enum RedditMethod {
  Init = 'init',
  Track = 'track',
}

export enum RedditEvent {
  PageVisit = 'PageVisit',
  AddToCart = 'AddToCart',
  Purchase = 'Purchase',
  Lead = 'Lead',
}

export type RedditTagMounter = (
  method: RedditMethod,
  eventName: RedditEvent,
  properties: Record<string, unknown>
) => void;

/**
 * Unique ID used for deduplication between client and server side conversion events
 */
export interface RedditEventBaseProperties {
  eventId: string;
}
