export enum SnapchatMethod {
  Init = 'init',
  Track = 'track',
}

export enum SnapchatEvent {
  PageView = 'PAGE_VIEW',
  SignUp = 'SIGN_UP',
  AddCart = 'ADD_CART',
  Purchase = 'PURCHASE',
}

export type SnapchatTagMounter = (
  method: SnapchatMethod,
  eventName: SnapchatEvent,
  properties?: Record<string, unknown>
) => void;
