import { getRedditScript } from '@farmersdog/pixels';

import { SdkLoaderWithFallbackScript } from 'src/components/Script/SdkLoaderWithFallbackScript';
import { PIXEL_ID } from './settings';

const { sdkLoader, fallbackScript } = getRedditScript(PIXEL_ID);

export const RedditScript = () => {
  return (
    <SdkLoaderWithFallbackScript
      sdkLoader={sdkLoader}
      fallbackScript={fallbackScript}
    />
  );
};
