export enum DisplaySaving {
  TwentyPercentSavings = 'Save 20% per oz',
}

export enum TreatSku {
  ChickenSnapsLarge = 'SNAPS_CHICKEN_L',
  ChickenSnapsRegular = 'SNAPS_CHICKEN_M',
  ChickenSnapsV2Large = 'SNPS_CHKNV2_L',
  ChickenSnapsV2Regular = 'SNPS_CHKNV2_M',
  PorkSnapsLarge = 'SNPPOPE08000',
  PorkSnapsRegular = 'SNPPOPE03000',
  TurkeySnapsLarge = 'SNAPS_TURKEY_L',
  TurkeySnapsRegular = 'SNAPS_TURKEY_M',
  TurkeySnapsV2Large = 'SNPTKPE08000',
  TurkeySnapsV2Regular = 'SNPTKPE03000',
}

export enum SnapSticksRecipeDisplayName {
  Chicken = 'Chicken',
  Pork = 'Pork',
  Turkey = 'Turkey',
}

export enum TreatPackageSize {
  Regular = 'Regular',
  Large = 'Large',
}

const CHEW_LENGTH_QUERY_PARAM = 'length';
export const ChewQueryParams = {
  chewLength: CHEW_LENGTH_QUERY_PARAM,
};

export interface TreatContent {
  /** The treat's sku */
  sku: TreatSku;
  /** What is the size of the treat packaging */
  packageSize: TreatPackageSize;
  /** Treat recipe (display name) */
  recipeDisplayName: SnapSticksRecipeDisplayName;
}

const chickenSnapsRegular: TreatContent = {
  sku: TreatSku.ChickenSnapsRegular,
  recipeDisplayName: SnapSticksRecipeDisplayName.Chicken,
  packageSize: TreatPackageSize.Regular,
};

const chickenSnapsLarge: TreatContent = {
  ...chickenSnapsRegular,
  sku: TreatSku.ChickenSnapsLarge,
  packageSize: TreatPackageSize.Large,
};

const chickenSnapsV2Regular: TreatContent = {
  sku: TreatSku.ChickenSnapsV2Regular,
  recipeDisplayName: SnapSticksRecipeDisplayName.Chicken,
  packageSize: TreatPackageSize.Regular,
};

const chickenSnapsV2Large: TreatContent = {
  ...chickenSnapsRegular,
  sku: TreatSku.ChickenSnapsV2Large,
  packageSize: TreatPackageSize.Large,
};

const porkSnapsRegular: TreatContent = {
  sku: TreatSku.PorkSnapsRegular,
  packageSize: TreatPackageSize.Regular,
  recipeDisplayName: SnapSticksRecipeDisplayName.Pork,
};

const porkSnapsLarge: TreatContent = {
  ...porkSnapsRegular,
  sku: TreatSku.PorkSnapsLarge,
  packageSize: TreatPackageSize.Large,
};

const turkeySnapsRegular: TreatContent = {
  sku: TreatSku.TurkeySnapsRegular,
  packageSize: TreatPackageSize.Regular,
  recipeDisplayName: SnapSticksRecipeDisplayName.Turkey,
};

const turkeySnapsLarge: TreatContent = {
  ...turkeySnapsRegular,
  sku: TreatSku.TurkeySnapsLarge,
  packageSize: TreatPackageSize.Large,
};

const turkeySnapsV2Regular: TreatContent = {
  sku: TreatSku.TurkeySnapsV2Regular,
  packageSize: TreatPackageSize.Regular,
  recipeDisplayName: SnapSticksRecipeDisplayName.Turkey,
};

const turkeySnapsV2Large: TreatContent = {
  ...turkeySnapsRegular,
  sku: TreatSku.TurkeySnapsV2Large,
  packageSize: TreatPackageSize.Large,
};

export type TreatsContent = Record<TreatSku, TreatContent>;
export const TreatsProductInfo: TreatsContent = {
  [TreatSku.ChickenSnapsLarge]: chickenSnapsLarge,
  [TreatSku.ChickenSnapsRegular]: chickenSnapsRegular,
  [TreatSku.ChickenSnapsV2Large]: chickenSnapsV2Large,
  [TreatSku.ChickenSnapsV2Regular]: chickenSnapsV2Regular,
  [TreatSku.PorkSnapsLarge]: porkSnapsLarge,
  [TreatSku.PorkSnapsRegular]: porkSnapsRegular,
  [TreatSku.TurkeySnapsLarge]: turkeySnapsLarge,
  [TreatSku.TurkeySnapsRegular]: turkeySnapsRegular,
  [TreatSku.TurkeySnapsV2Large]: turkeySnapsV2Large,
  [TreatSku.TurkeySnapsV2Regular]: turkeySnapsV2Regular,
};
