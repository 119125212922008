import type { MouseEvent, ReactElement } from 'react';
import { cloneElement, useCallback } from 'react';
import { trackCtaClick } from '../../utils/trackCtaClick';

export enum HomepageSections {
  Hero = 'hero',
  Bowl = 'bowl',
  Food = 'food',
  Package = 'package',
  HowItWorks = 'how_it_works',
  Vet = 'vet',
  Reviews = 'reviews',
  Recommend = 'recommend',
}

export enum HomepageRedesignSections {
  Banner = 'banner',
  Hero = 'hero',
  BowlPack = 'bowl_pack',
  DidYouKnow = 'did_you_know',
  LifetimeOfBenefits = 'lifetime_of_benefits',
  PressBar = 'press_bar',
  BetterForThem = 'better_for_them',
  Testimonials = 'testimonials',
  LetsGetStarted = 'lets_get_started',
  VetKnowsBestVideo = 'vet_knows_best_video',
  VetKnowsBestContent = 'vet_knows_best_content',
  Faq = 'faq',
  ContactUs = 'contact_us',
}

interface TrackCtaClickProps {
  type: 'exit' | 'select';
  moduleLevel: 1 | 2;
  moduleName: HomepageRedesignSections | HomepageSections;
}

interface CtaTrackerV2Props extends TrackCtaClickProps {
  children: ReactElement<{ onClick?: (event: MouseEvent) => void }>;
}

export function CtaTrackerV2({
  type,
  moduleLevel,
  moduleName,
  children,
}: CtaTrackerV2Props) {
  const onClick = useCallback(
    (e: MouseEvent) => {
      trackCtaClick(e, { type, moduleLevel, moduleName });
    },
    [moduleLevel, moduleName, type]
  );

  return cloneElement(children, {
    onClick: (e: MouseEvent) => {
      onClick(e);
      children.props.onClick?.(e);
    },
  });
}
