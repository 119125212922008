import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  PATH_REVIEWS,
  PATH_ABOUT_US,
  PATH_FAQ,
  PATH_LOGIN,
  PATH_WHY_FRESH,
  PATH_DIY,
} from '@farmersdog/constants/paths';

import config from 'src/config';

import { DIY_FAQ } from 'src/pages/DIY/components/FAQ/constants/ids';

import * as whyFreshData from 'src/pages/WhyFresh/data';
import type { AppNavItem } from '@farmersdog/corgi-x';
import { HomepageRedesignSections } from '../../../pages/HomepageRedesign/components/CtaTrackerV2';
import { trackCtaClick } from '../../../pages/HomepageRedesign/utils/trackCtaClick';

interface useNavigationItemsProps {
  recommendVetPortal: boolean;
}

const VET_PORTAL_LINK_APP_NAV_ITEM = {
  label: 'For Vet Professionals',
  href: config('app.vetPortalUrl'),
  type: 'anchor',
  target: '_blank',
  rel: 'noopener',
} satisfies AppNavItem;

const rightItems = [
  {
    label: 'Log In',
    to: PATH_LOGIN,
    alwaysVisible: true,
    onClick: (e: MouseEvent) => {
      trackCtaClick(e, {
        type: 'select',
        moduleLevel: 1,
        moduleName: HomepageRedesignSections.Hero,
      });
    },
  },
];

function useNavigationItems({ recommendVetPortal }: useNavigationItemsProps) {
  const location = useLocation();

  const leftItems = useMemo(() => {
    const defaultLeftItems = [
      {
        label: 'Reviews',
        to: PATH_REVIEWS,
      },
      {
        label: 'About Us',
        to: PATH_ABOUT_US,
      },
      {
        label: whyFreshData.pageTitle,
        to: PATH_WHY_FRESH,
      },
      {
        label: 'FAQ',
        to: location.pathname === PATH_DIY ? `#${DIY_FAQ}` : PATH_FAQ,
        // overrides react-router-dom NavLink default active class to consider complete match of path + hash as active
        isActive: () =>
          location.pathname === PATH_DIY && location.hash === `#${DIY_FAQ}`,
      },
    ];

    return recommendVetPortal
      ? [...defaultLeftItems, VET_PORTAL_LINK_APP_NAV_ITEM]
      : defaultLeftItems;
  }, [recommendVetPortal, location.pathname, location.hash]);

  return {
    leftItems,
    rightItems,
  };
}

export default useNavigationItems;
