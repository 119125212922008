import { useShowHomepageRedesign } from 'src/hooks/useHomepageRedesign';
import { TrackedSectionsProvider } from 'src/hooks/useTrackedSections';
import { ExitIntent } from 'src/pages/Home/components/ExitIntent/ExitIntent';
import Home from 'src/pages/Home';
import { HomepageRedesign } from 'src/pages/HomepageRedesign';

import { AssetPreloader } from './AssetPreloader';

export function Homepage() {
  const { shouldShowHomepageRedesign } = useShowHomepageRedesign();
  return (
    <TrackedSectionsProvider>
      <AssetPreloader />
      {shouldShowHomepageRedesign ? <HomepageRedesign /> : <Home />}
      <ExitIntent />
    </TrackedSectionsProvider>
  );
}
