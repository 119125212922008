export { default as berneMobilejpg } from './berne_9x16.jpg';
export { default as berneMobileWebp } from './berne_9x16.webp';
export { default as berneDesktopjpg } from './berne_16x9.jpg';
export { default as berneDesktopWebp } from './berne_16x9.webp';

export { default as kimchiMobilejpg } from './kimchi_9x16.jpg';
export { default as kimchiMobileWebp } from './kimchi_9x16.webp';
export { default as kimchiDesktopjpg } from './kimchi_16x9.jpg';
export { default as kimchiDesktopWebp } from './kimchi_16x9.webp';

export { default as konavandiMobilejpg } from './konavandi_9x16.jpg';
export { default as konavandiMobileWebp } from './konavandi_9x16.webp';
export { default as konavandiDesktopjpg } from './konavandi_16x9.jpg';
export { default as konavandiDesktopWebp } from './konavandi_16x9.webp';

export { default as luluMobilejpg } from './lulu_9x16.jpg';
export { default as luluMobileWebp } from './lulu_9x16.webp';
export { default as luluDesktopjpg } from './lulu_16x9.jpg';
export { default as luluDesktopWebp } from './lulu_16x9.webp';

export { default as zboMobilejpg } from './zbo_9x16.jpg';
export { default as zboMobileWebp } from './zbo_9x16.webp';
export { default as zboDesktopjpg } from './zbo_16x9.jpg';
export { default as zboDesktopWebp } from './zbo_16x9.webp';
