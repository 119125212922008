import { useState } from 'react';
import {
  Accordion,
  AccordionPanel,
  Grid,
  GridItem,
  Text,
} from '@farmersdog/corgi-x';
import { faqItems } from './data';
import { ImageCard } from '../ImageCard';
import { Container } from '../Container';

import styles from './FAQ.module.css';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';
import { track } from 'src/analytics';
import eventNames from '../../../../analytics/eventNames';
import { HomepageRedesignSections } from '../CtaTrackerV2';

export const FAQ = () => {
  const [expandedPanel, setExpandedPanel] = useState<string | null>(null);

  const handleToggle = (id: string, isExpanded: boolean) => {
    track(eventNames.clicked, {
      component: 'button',
      type: isExpanded ? 'exit' : 'select',
      moduleLevel: 1,
      moduleName: HomepageRedesignSections.Faq,
      text: id,
    });

    setExpandedPanel(isExpanded ? null : id);
  };

  const sectionRef = useTrackedSectionRef(HomepageRedesignSections.Faq);

  return (
    <section className={styles.section} ref={sectionRef}>
      <Container>
        <Grid
          columnGap={{
            xs: 'none',
            lg: 'lg',
          }}
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          flexDirection={{
            xs: 'column',
            lg: 'row',
          }}
        >
          <GridItem xs={12} lg={6}>
            <ImageCard />
          </GridItem>
          <GridItem xs={12} lg={6}>
            <div className={styles.container}>
              <Text
                bold
                as="h3"
                color="kale-3"
                bottomSpacing={{ xs: 'md', lg: 'lg' }}
                variant="heading-28"
                className={styles.title}
                align={{
                  xs: 'center',
                  lg: 'left',
                }}
              >
                Still have questions?
              </Text>
              <Accordion className={styles.accordion}>
                {faqItems.map(({ question, answer }) => {
                  const isExpanded = expandedPanel === question;
                  const sanitizedId = question
                    .toLowerCase()
                    .replace(/[^a-z0-9]/g, '-');

                  return (
                    <AccordionPanel
                      key={question}
                      id={sanitizedId}
                      heading={question}
                      expanded={isExpanded}
                      onToggle={() => handleToggle(question, isExpanded)}
                    >
                      <Text
                        as="p"
                        align="left"
                        vSpacing="none"
                        leftSpacing="sm"
                        color="Kale3"
                        variant="article-16"
                      >
                        {answer}
                      </Text>
                    </AccordionPanel>
                  );
                })}
              </Accordion>
            </div>
          </GridItem>
        </Grid>
      </Container>
    </section>
  );
};
