import { reporter } from 'src/services/reporter';
import { trackPinterestLead } from 'src/vendors/pinterest';
import {
  TIKTOK_REGISTRATION_EVENT,
  trackTikTokEvent,
} from 'src/vendors/tiktok';

import {
  mountRedditLead,
  mountSnapchatSignup,
  generateDeduplicationId,
} from '@farmersdog/pixels';

import track from 'src/analytics/track';
import events from 'src/analytics/eventNames';
import {
  getCurrentSignupSubscriptionType,
  cookie,
} from '@farmersdog/lead-browser-storage';
import { PEACOCK_EVENTS, trackPeacockEvent } from 'src/vendors/peacock';

interface AddressData {
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

interface TrackMeSuccessArgs {
  /** RequestAttributes from the redux store, includes things like IP address and utm_source */
  requestAttributes: Record<string, unknown>;
  /** The registered user object (as opposed to from Redux) */
  user: Reducer.User;
  /** The user's human id */
  humanId: string | null;
  /** The discount override amount when cvr_globaldiscount_override is on */
  discountOverride: number | null;
  /** selected address data for track events */
  addressData?: AddressData;
}

/**
 * @deprecated This function is in the process of being migrated to `useHandleMeCompleted` in TOSA.
 * For new features or modifications, it is reccomended to implement them directly in the new hook.
 */
export function trackMeSuccess({
  requestAttributes,
  humanId,
  discountOverride,
  user,
  addressData,
}: TrackMeSuccessArgs) {
  if (!user) {
    reporter.error(
      `Segment event "${events.me_success}" has missing parameter "user"`
    );
  }

  const additionalUserAttributes = {
    petsCount: user?.pets?.length,

    freshFoodConfidence: user?.freshFoodConfidence,
    email: user?.email,
    firstName: user?.firstName,
    fbp: cookie.getFacebookPixelCookie(),
    fcp: cookie.getFacebookClickIdCookie(),
    humanId,
    city: addressData?.city?.toLowerCase(),
    state: addressData?.state?.toLowerCase(),
    zip: addressData?.zip,
  };

  const currentSignupSubscriptionType = getCurrentSignupSubscriptionType();
  const eventId = generateDeduplicationId();

  // If you update the payload here, please update the tracking plan in Segment
  // for this event as well:
  // https://app.segment.com/farmersdog/protocols/tracking-plans/tp_1eST2xLpVi5ut60vM5sYE9MsYa1/draft?name=Me+-+Success
  track(events.me_success, {
    ...requestAttributes,
    ...additionalUserAttributes,
    product_line: currentSignupSubscriptionType,
    label: currentSignupSubscriptionType,
    discountOverride,
    eventId: eventId,
  });

  void trackPinterestLead(user?.email);

  mountRedditLead({ eventId });
  void mountSnapchatSignup({ email: user?.email, eventId });
  void trackTikTokEvent(TIKTOK_REGISTRATION_EVENT, {
    email: user?.email,
    event_id: eventId,
  });

  trackPeacockEvent({ eventName: PEACOCK_EVENTS.SIGN_UP });
}
