import config from 'src/config';
import { Logger } from '@farmersdog/logger';

import cookie from 'js-cookie';
import {
  SIGNUP_EXIT_INTENT_CHECKOUT_COOKIE,
  SIGNUP_EXIT_INTENT_ME_COOKIE,
  SIGNUP_EXIT_INTENT_PETS_COOKIE,
  SIGNUP_EXIT_INTENT_PLANS_COOKIE,
  SIGNUP_EXIT_INTENT_RECIPES_COOKIE,
  SIGNUP_PLAN_PAGE_COOKIE,
  REFERRAL_COOKIE,
} from 'src/constants/cookie';

const log = new Logger('app:utils:cookies');

const exitCookieByPage: Record<string, string> = {
  checkout: SIGNUP_EXIT_INTENT_CHECKOUT_COOKIE,
  me: SIGNUP_EXIT_INTENT_ME_COOKIE,
  pets: SIGNUP_EXIT_INTENT_PETS_COOKIE,
  plans: SIGNUP_EXIT_INTENT_PLANS_COOKIE,
  recipes: SIGNUP_EXIT_INTENT_RECIPES_COOKIE,
};

/**
 * Set a cookie to mark when the exit intent modal was last shown.
 */
export function setExitIntentCookie(page: string): void {
  if (!process.env.BROWSER) {
    return;
  }

  cookie.set(exitCookieByPage[page], 'true', {
    expires: config('features.exitIntentExpiration'),
  });
  log.debug('Set exit intent cookie', { page });
}

/**
 * Returns `true` if we should show the exit intent modal.
 */
export function showExitIntentCookie(page: string): boolean {
  return !cookie.get(exitCookieByPage[page]);
}

/**
 * Set a cookie to remember to display the /plan page.
 */
export function setPlanPageCookie(): void {
  if (!process.env.BROWSER) {
    return;
  }
  log.debug('Set plan page cookie');
  cookie.set(SIGNUP_PLAN_PAGE_COOKIE, 'true');
}

/**
 * Returns `true` if we should show the /plan page.
 */
export function showPlanPageCookie(): boolean {
  return !!cookie.get(SIGNUP_PLAN_PAGE_COOKIE);
}

export interface ReferrerCookie {
  referrerCode: string;
  referrerType: string;
  referrerAssociatedAt: Date;
  [k: string | symbol]: unknown;
}

/**
 * Returns the referrer user data coming from the referral cookie.
 */
export function getReferrerCookie(): ReferrerCookie | void {
  const cookieValue = cookie.get(REFERRAL_COOKIE);
  if (!cookieValue) {
    return;
  }
  log.debug('Get referrer cookie...');
  try {
    const parsed = JSON.parse(cookieValue) as ReferrerCookie;
    if (
      !parsed.referrerAssociatedAt ||
      !parsed.referrerType ||
      !parsed.referrerCode
    ) {
      throw new Error('Invalid cookie format');
    }
    log.debug('Referrer cookie valid', parsed);

    return parsed;
  } catch (err) {
    log.debug('Error parsing cookie', { err });
  }
}
