import { Logger } from '@farmersdog/logger';

import {
  getHasSSRAnonymousExperiment,
  useSSRAnonymousExperiments,
} from 'src/server/context/experimentsContext';
import type {
  AnonymousExperimentFlag,
  AnonymousExperimentFlags,
} from 'src/server/middlewares/experimentsMiddleware';
import { useFeature, type UseFeatureReturn } from '../useFeature';

import { useCheckForTreatmentMismatch } from './useCheckForTreatmentMismatch';

interface UseSsrFeatureResult<TreatmentType extends string> {
  treatment: TreatmentType;
  config:
    | AnonymousExperimentFlags[AnonymousExperimentFlag]['config']
    | UseFeatureReturn<TreatmentType, Record<string, unknown>>['config'];
}

export type UseSsrFeatureArgs<Flag extends AnonymousExperimentFlag> =
  AnonymousExperimentFlags[Flag]['attributes'] extends undefined
    ? { featureKey: Flag; attributes?: undefined }
    : {
        featureKey: Flag;
        attributes: AnonymousExperimentFlags[Flag]['attributes'];
      };

export const useSsrFeature = <
  Flag extends AnonymousExperimentFlag,
  TreatmentType extends string,
>({
  featureKey,
  attributes,
}: UseSsrFeatureArgs<Flag>): UseSsrFeatureResult<TreatmentType> => {
  const experiments = useSSRAnonymousExperiments();

  // Server Side Evaluation
  const { serverTreatment, serverConfig } = getServerTreatmentAndConfig({
    experiments,
    featureKey,
  });

  // Client Side Evaluation
  const {
    config: clientConfig,
    treatment: clientTreatment,
    isReady,
  } = useFeature(featureKey, { attributes });

  const treatment = (serverTreatment ?? clientTreatment) as TreatmentType;
  const config = serverConfig ?? clientConfig;

  useCheckForTreatmentMismatch({
    featureKey,
    serverTreatment: serverTreatment as string,
    clientTreatment,
    isReady,
    config,
  });

  return { treatment, config };
};

interface GetServerTreatmentAndConfigArgs {
  experiments: ReturnType<typeof useSSRAnonymousExperiments>;
  featureKey: AnonymousExperimentFlag;
}
function getServerTreatmentAndConfig({
  experiments,
  featureKey,
}: GetServerTreatmentAndConfigArgs) {
  const hasTreatment = getHasSSRAnonymousExperiment(experiments, featureKey);
  if (!hasTreatment) {
    return { serverTreatment: undefined, serverConfig: undefined };
  }

  const serverTreatment = experiments[featureKey].treatment;
  const serverConfig = experiments[featureKey].config;

  if (!serverTreatment) {
    const log = new Logger(`website:useSsrFeature`);
    log.error(`No server treatment found for SSR Anonymous Experiment`, {
      experiment: experiments[featureKey],
      featureKey,
      serverTreatment,
    });
  }

  return { serverTreatment, serverConfig };
}
