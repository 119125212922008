import Bowser from 'bowser';
import noop from 'lodash/noop';
import type React from 'react';
import { Children, Fragment, useLayoutEffect, useState } from 'react';

import { browserOnly } from '@farmersdog/utils';

/**
 * Device type information
 *
 * @param osname - The name of the operating system.
 * @param browser - The name of the browser.
 * @param platform - The name of the platform.
 * @param engine - The name of the browser rendering engine.
 * @param touchable - Whether the device supports touch events.
 * @param passiveSupported - Whether the device supports passive event listeners.
 *
 * @param initialize - Initialize the deviceType object.
 * @param setUserAgent - Set the userAgent string.
 * @param setPassiveSupported - Set the passiveSupported param.
 * @param setTouchSupported - Set up the subscription for touch event notifier.
 *
 * This code is run both in node and in the browser. Please update carefully!
 */
export class DeviceType {
  osname: string;
  browser: string;
  platform: string;
  engine: string;
  touchable: boolean;
  passiveSupported: boolean;

  constructor() {
    this.osname = '';
    this.browser = '';
    this.platform = '';
    this.engine = '';
    this.touchable = false;
    this.passiveSupported = false;
  }

  /**
   * Initialize the deviceType object in both node and browser environments.
   * Some attributes of deviceType will not be accessible in browser
   * environments. You must call this method before rendering the react tree.
   *
   * @param userAgent - The user agent of the device.
   */
  initialize(userAgent: string) {
    this.setUserAgent(userAgent);
    this.setPassiveSupported();
  }

  /**
   * Set the deviceType attributes that are determined by the user agent.
   *
   * @param userAgent - The user agent of the device.
   */
  setUserAgent(userAgent: string) {
    if (userAgent) {
      const deviceInfo = Bowser.getParser(userAgent);

      this.osname = deviceInfo.getOSName().toLowerCase();
      this.browser = deviceInfo.getBrowserName().toLowerCase();
      this.platform = deviceInfo.getPlatformType().toLowerCase();
      this.engine = deviceInfo.getEngineName().toLowerCase();
    }
  }

  /**
   * Method for detecting support for third options object argument when
   * setting up event listeners. Recommended by mozilla :)
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
   */
  setPassiveSupported() {
    browserOnly(window => {
      let passiveSupported = this.passiveSupported;

      try {
        const options = {
          get passive() {
            // This function will be called when the browser attempts to access
            // the passive property.
            passiveSupported = true;
            return undefined;
          },
        };
        window.addEventListener(
          'test' as keyof WindowEventMap,
          options as unknown as EventListenerOrEventListenerObject,
          options
        );
        window.removeEventListener(
          'test' as keyof WindowEventMap,
          options as unknown as EventListenerObject,
          options as unknown as EventListenerOptions
        );
      } catch {
        passiveSupported = false;
      }

      this.passiveSupported = passiveSupported;
    });
  }

  /**
   * Method for detecting touch support on devices. There are a few methods for
   * detection however we cannot be absolutely sure that we wil get this
   * synchronously.
   *
   * @param callback - Function to call when touch support is detected.
   */
  setTouchSupported(callback: () => void) {
    return browserOnly(
      window => {
        if ('PointerEvent' in window && window.navigator.maxTouchPoints) {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }

          return noop;
        }

        if (
          window.matchMedia &&
          window.matchMedia('(any-pointer:coarse)').matches
        ) {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }

          return noop;
        }

        // last resort
        const handleTouchStart = () => {
          this.touchable = true;

          if (typeof callback === 'function') {
            callback();
          }
        };

        window.addEventListener(
          'touchstart',
          handleTouchStart,
          this.passiveSupported ? { passive: true } : false
        );

        return () => window.removeEventListener('touchstart', handleTouchStart);
      },
      () => noop
    );
  }
}

const deviceType = Object.seal(new DeviceType());

export default deviceType;

/**
 * Unfortunately this component is quite dumb and also quite needed. Due to how
 * touchevents are detected we have to use a keyed fragment to force a render
 * cycle when touch is detected.
 */
export function DeviceTypeInjector({ children }: React.PropsWithChildren) {
  const [hasTouchable, setHasTouchable] = useState(false);

  useLayoutEffect(() => {
    return deviceType.setTouchSupported(() => setHasTouchable(true));
  }, []);

  return (
    <Fragment key={`has-touchable-${hasTouchable}`}>
      {Children.only(children)}
    </Fragment>
  );
}
