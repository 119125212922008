import classNames from 'classnames';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { Wave, Nowrap, Text, Button, useViewport } from '@farmersdog/corgi-x';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';

import { trackGoToSignup } from 'src/analytics';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './GuidedByScience.module.scss';

const TRACKING_DATA = {
  page: 'About Us',
  location: 'Guided by Science. Driven by love.',
  ctaCopy: 'Build My Plan',
};

function GuidedByScience() {
  const { lg } = useViewport();
  const isMobile = !lg;

  const {
    isAccessibilityThemeOn,
    KaleBackgroundWrapper,
    accessibilityThemeStyles,
  } = useAccessibilityThemeContext();

  const sectionContent = (
    <>
      <Text
        variant={isMobile ? 'heading-40' : 'heading-52'}
        color="kale-3"
        as="h1"
        bold
      >
        Guided by
        {isMobile ? <br /> : ' '}
        science.
        <br />
        Driven by love.
      </Text>
      <Text variant="article-20" color="charcoal-3" as="p">
        Using decades of pet nutrition research (and a little common sense), our
        founders worked with top vet nutritionists to create a fresh, convenient
        pet <Nowrap>food service</Nowrap>.
        <br />
        <br />
        We’re using quality and safety standards never before seen in the
        industry — and the result is fresh food that’s safe enough for humans to
        eat, but that’s nutritionally complete and balanced{' '}
        <Nowrap>for dogs</Nowrap>.
      </Text>
      <CtaTracker
        event={TrackingEvent.goToSignup}
        trackingFunction={trackGoToSignup}
        trackingData={TRACKING_DATA}
      >
        <Button
          variant="primary"
          type="link"
          to={PATH_SIGNUP_ME}
          className={accessibilityThemeStyles.responsiveCta}
        >
          Build My Plan
        </Button>
      </CtaTracker>
    </>
  );
  return isAccessibilityThemeOn ? (
    <KaleBackgroundWrapper>
      <div
        className={classNames(styles.accessibilityThemeContainer, {
          [styles.lg]: !isMobile,
        })}
      >
        {sectionContent}
      </div>
    </KaleBackgroundWrapper>
  ) : (
    <Wave withTop withBottom>
      <div className={classNames(styles.container, { [styles.lg]: !isMobile })}>
        <div className={styles.column}>{sectionContent}</div>
      </div>
    </Wave>
  );
}

export default GuidedByScience;
