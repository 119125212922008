import type { FetchResult } from '@apollo/client';
import { ApolloLink } from '@apollo/client';

import { tosaToken } from '@farmersdog/lead-browser-storage';
import { GraphQLEndpoints } from './constants';
import { prepareGlobalRequestHeaders } from '../utils/prepareGlobalRequestHeaders';
import type { CreateLeadMutation } from '../tosa.types';

/**
 * Authorization middleware to be used with the TOSA endpoint
 *
 * Automatically handles setting authorization tokens in outgoing network
 * requests and setting authorization tokens in incoming responses.
 */
export function createTosaAuthMiddleware(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    // Forwards the operation to the next link in the chain if the endpoint is not tosa
    if (operation.getContext().endpoint === GraphQLEndpoints.TOSA) {
      const currentToken = tosaToken.get();
      // set tosa token authorization to the headers when the endpoint is tosa
      operation.setContext({
        ...operation.getContext(),
        headers: {
          ...(currentToken
            ? { authorization: `Bearer ${currentToken}` }
            : undefined),
          ...prepareGlobalRequestHeaders(),
          'x-requester-path': window.location.pathname,
        },
        fetch,
      });

      // Set the tosa token in browser storage after a successful createLead mutation
      if (operation.operationName === 'CreateLead') {
        return forward(operation).map(
          // @ts-expect-error We are checking for this result above
          (result: FetchResult<CreateLeadMutation>) => {
            const newToken = result?.data?.createLead.token;

            if (!newToken) {
              return result;
            }
            tosaToken.set(newToken);
            return result;
          }
        );
      }
    }

    return forward(operation);
  });
}
