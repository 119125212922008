import CnetMobilePng from './cnet-mobile.png';
import CnetMobileWebp from './cnet-mobile.webp';
import CnetDesktopPng from './cnet-desktop.png';
import CnetDesktopWebp from './cnet-desktop.webp';
import GoodHousekeepingMobilePng from './good-housekeeping-mobile.png';
import GoodHousekeepingMobileWebp from './good-housekeeping-mobile.webp';
import GoodHousekeepingDesktopPng from './good-housekeeping-desktop.png';
import GoodHousekeepingDesktopWebp from './good-housekeeping-desktop.webp';
import GoogleMobilePng from './google-mobile.png';
import GoogleMobileWebp from './google-mobile.webp';
import GoogleDesktopPng from './google-desktop.png';
import GoogleDesktopWebp from './google-desktop.webp';
import NewYorkMagazineMobilePng from './new-york-magazine-mobile.png';
import NewYorkMagazineMobileWebp from './new-york-magazine-mobile.webp';
import NewYorkMagazineDesktopPng from './new-york-magazine-desktop.png';
import NewYorkMagazineDesktopWebp from './new-york-magazine-desktop.webp';
import PopularScienceMobilePng from './popular-science-mobile.png';
import PopularScienceMobileWebp from './popular-science-mobile.webp';
import PopularScienceDesktopPng from './popular-science-desktop.png';
import PopularScienceDesktopWebp from './popular-science-desktop.webp';

export const mobileSources = {
  popularScience: [PopularScienceMobileWebp, PopularScienceMobilePng],
  goodHousekeeping: [GoodHousekeepingMobileWebp, GoodHousekeepingMobilePng],
  google: [GoogleMobileWebp, GoogleMobilePng],
  newYorkMagazine: [NewYorkMagazineMobileWebp, NewYorkMagazineMobilePng],
  cnet: [CnetMobileWebp, CnetMobilePng],
};

export const desktopSources = {
  popularScience: [PopularScienceDesktopWebp, PopularScienceDesktopPng],
  goodHousekeeping: [GoodHousekeepingDesktopWebp, GoodHousekeepingDesktopPng],
  google: [GoogleDesktopWebp, GoogleDesktopPng],
  newYorkMagazine: [NewYorkMagazineDesktopWebp, NewYorkMagazineDesktopPng],
  cnet: [CnetDesktopWebp, CnetDesktopPng],
};
