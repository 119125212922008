import type { Request } from 'express';

import { flagRouteMap } from './flagRouteMap';
import type { AnonymousExperimentFlag } from './flags';

export function getExperimentFlagsForRequest({
  requestPath,
}: {
  requestPath: Request['path'];
}) {
  return Object.entries(flagRouteMap)
    .filter(([, flagRoutes]) =>
      flagRoutes.some(flagRoute => isMatchingRoute(flagRoute, requestPath))
    )
    .map(([flag]) => flag as AnonymousExperimentFlag);
}

export function isMatchingRoute(flagRoute: string, requestPath: string) {
  if (flagRoute === requestPath) {
    return true;
  }

  const flagRouteParts = flagRoute.split('/');
  const requestPathParts = requestPath.split('/');

  return flagRouteParts.every((flagRoutePart, index) => {
    if (flagRoutePart.startsWith(':')) {
      return true;
    }

    return flagRoutePart === requestPathParts[index];
  });
}
