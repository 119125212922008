import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import beefAndSweetPotatoWebp from '../assets/beef-and-sweet-potato.webp';

export const beefAndSweetPotato: DiyRecipe = {
  name: 'Beef & Sweet Potato',
  primaryProtein: PrimaryProtein.Beef,
  ingredients: [
    {
      name: '90% lean ground beef',
      quantity: 1208,
      unit: 'g',
      alternativeQuantity: 2.7,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 260,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'carrots, shredded or cut into 1/4 inch dice',
      quantity: 260,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, cut into 1/4 inch dice',
      quantity: 260,
      unit: 'g',
      alternativeQuantity: 3,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmer’s Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 14,
      unit: 'g',
      alternativeQuantity: 1,
      alternativeUnit: 'tablespoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  ingredientSummary:
    'Ground Beef, Sweet Potato, Carrots, Broccoli, DIY Nutrient Mix, Sunflower Oil',
  id: 'beef-and-sweet-potato',
  cookTime: '35 mins',
  calories: 2430,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale',
    'food safe thermometer  ',
  ],
  image: beefAndSweetPotatoWebp,
  instructions: [
    {
      name: 'Step 1',
      description: `Place pot/skillet/dutch oven oven over medium to medium-high heat on the stovetop.
        Pour the sunflower oil in and spread it to evenly coat the bottom.`,
    },
    {
      name: 'Step 2',
      description: `Add the ground beef. Break apart and stir until the meat is no longer pink and is broken into small pieces.`,
    },
    {
      name: 'Step 3',
      description: `Add the sweet potato, carrots, and broccoli. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.`,
      note: `Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards.
      Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.`,
    },
    {
      name: 'Step 4',
      description: `Using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.`,
      note: `In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.`,
    },
    {
      name: 'Step 5',
      description: `Remove the pot from the heat and sprinkle {numberOfPackets} of The Farmer’s Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.`,
    },
  ],
};
