import WeightManagementWebp from './weight-management.webp';
import WeightManagementJpg from './weight-management.jpg';

import PickyEatingWebp from './picky-eating.webp';
import PickyEatingJpg from './picky-eating.jpg';

import SeniorCareWebp from './senior-care.webp';
import SeniorCareJpg from './senior-care.jpg';

import PuppyCareWebp from './puppy-care.webp';
import PuppyCareJpg from './puppy-care.jpg';

export const WeightManagementSources = {
  webP: WeightManagementWebp,
  jpg: WeightManagementJpg,
};

export const PickyEatingSources = {
  webP: PickyEatingWebp,
  jpg: PickyEatingJpg,
};

export const SeniorCareSources = {
  webP: SeniorCareWebp,
  jpg: SeniorCareJpg,
};

export const PuppyCareSources = {
  webP: PuppyCareWebp,
  jpg: PuppyCareJpg,
};
