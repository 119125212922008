import { useViewport } from '@farmersdog/corgi-x';

import { PressBarMobile } from './PressBarMobile';
import { PressBarDesktop } from './PressBarDesktop';

export const PressBarSection = () => {
  const viewport = useViewport();

  if (!viewport?.xs) {
    return;
  }

  return !viewport.lg ? <PressBarMobile /> : <PressBarDesktop />;
};
