import { SnapchatMethod } from './types';

import type { ScriptFunctionWithFallbackReturn } from '../../types';

export function getSnapchatScript(
  ids: Array<string>
): ScriptFunctionWithFallbackReturn {
  const fallbackScript = `
  (function(e, t, n) {
      if (e.snaptr) return;
      var a = e.snaptr = function() {
          a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments)
      };
      a.queue = [];
      ${generateInitCalls(ids)}
  })(window);`;

  const sdkLoader = `
  (function(t, n) {
      var id = 'snapchat-pixel';
      if (!t.getElementById(id)) {
        var s = 'script';
        r = t.createElement(s);
        r.async = !0;
        r.id = id;
        r.src = n;
        var u = t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r, u);
      }
  })(document, 'https://sc-static.net/scevent.min.js');`;

  return { fallbackScript, sdkLoader };
}

function generateInitCalls(ids: Array<string>) {
  return ids.map(id => `snaptr('${SnapchatMethod.Init}', '${id}');`).join('\n');
}
