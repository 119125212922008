import fastdom from 'fastdom';
import { browserOnly } from '@farmersdog/corgi';

import type {
  Viewport,
  ViewportName,
  Orientation,
  OrientationName,
  DefaultViewPort,
} from 'src/screen/types';

interface ScreenConstructor {
  viewports: Viewport[];
  orientations: Orientation[];
}

/** All of the relevant screen object properties */
export interface ScreenState {
  /** name of the matched screen */
  matchedViewport: ViewportName;
  /** array of matched screens */
  matchedViewports: ViewportName[];
  /** orientation of the viewport */
  orientation: OrientationName;
}

/**
 * This class contains helper methods to assit with js screen calculations
 */
class Screen {
  private viewports: Viewport[];
  private orientations: Orientation[];
  private viewportQueries: Map<ViewportName, MediaQueryList>;
  private orientationQueries: Map<OrientationName, MediaQueryList>;

  /**
   * Constructor for the Screen class. Use this to pass in your screen and
   * orientation configurations.
   */
  constructor({ viewports, orientations }: ScreenConstructor) {
    this.viewports = viewports;
    this.orientations = orientations;

    this.viewportQueries = new Map<ViewportName, MediaQueryList>();
    this.orientationQueries = new Map<OrientationName, MediaQueryList>();

    this.setMatchedMediaQueries();
  }

  /**
   * Cancellable, batched & server safe measurements of the global window object.
   *
   * @param callback - function to call with the measured values
   */
  safeMeasureWindow(
    callback: (params: { innerHeight: number; innerWidth: number }) => void
  ) {
    return browserOnly<void>(
      () => {
        const read = fastdom.measure(() => {
          const { innerHeight, innerWidth } = window;

          callback({
            innerHeight,
            innerWidth,
          });
        });

        // this doesn't seem to do anything?
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        () => fastdom.clear(read);
      },
      () => {
        callback({
          innerHeight: 0,
          innerWidth: 0,
        });
      }
    );
  }

  /**
   * Get an ordered array of breakpoints that match the given width
   *
   * @param width - the width of the viewport
   *
   * @example
   * ```ts
   * const matchedViewports = getMatchedViewports(413);
   * console.log(matchedViewports) // ['xs']
   * ```
   */
  getMatchedViewports(width: number): ViewportName[] {
    return this.viewports
      .filter(viewport => width >= viewport.minWidth)
      .map(viewport => viewport.name)
      .reverse();
  }

  /**
   * Get the name of the breakpoint that matches the width
   *
   * @param width - the width of the viewport
   *
   * @example
   * ```ts
   * const screenName = getViewportName(413)
   * console.log(screenName) // 'xs'
   * ```
   */
  getViewportName(width: number): ViewportName {
    return this.getMatchedViewports(width)[0] || this.viewports[0].name;
  }

  /**
   * Get the orientations that match the given window object
   *
   * @param window - the injected window object
   *
   * @example
   * ```ts
   * const orientations = getMatchedOrientations({ innerWidth: 414, innerHeight: 736 });
   * console.log(orientations) // ['portrait']
   * ```
   */
  getMatchedOrientations(window?: Window | DefaultViewPort): OrientationName[] {
    const innerWidth = window?.innerWidth ?? 0;
    const innerHeight = window?.innerHeight ?? 0;

    const aspectRatio = innerWidth / innerHeight;

    return this.orientations
      .filter(orientation => aspectRatio >= orientation.minAspectRatio)
      .map(orientation => orientation.name)
      .reverse();
  }

  /**
   * Get the name of the orientation that matches the window object
   *
   * @param window - the injected window object
   *
   * @example
   * ```ts
   * const orientations = getOrientationName({ innerWidth: 414, innerHeight: 736 });
   * console.log(orientations) // 'portrait'
   * ```
   */
  getOrientationName(window?: Window | DefaultViewPort): OrientationName {
    return this.getMatchedOrientations(window)[0] || this.orientations[0].name;
  }

  /**
   * Get the screen characteristics that match our configuration
   *
   * @param window - the injected window object
   */
  getScreen(window?: Window | DefaultViewPort): ScreenState {
    const innerWidth = window?.innerWidth ?? 0;

    return {
      matchedViewport: this.getViewportName(innerWidth),
      matchedViewports: this.getMatchedViewports(innerWidth),
      orientation: this.getOrientationName(window),
    };
  }

  /**
   * Set the window match media queries for the screen. This older syntax is
   * used for compatibility with IE11.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
   */
  setMatchedMediaQueries() {
    browserOnly(window => {
      if (!window.matchMedia) {
        return;
      }

      this.viewports.forEach(viewport => {
        this.viewportQueries.set(
          viewport.name,
          window.matchMedia(`(min-width: ${viewport.minWidth}px)`)
        );
      });

      this.orientations.forEach(orientation => {
        this.orientationQueries.set(
          orientation.name,
          window.matchMedia(`(orientation: ${orientation.cssQueryString})`)
        );
      });
    });
  }

  /**
   * Subscribe a callback function to all of the viewport media queries.
   *
   * @param callback - A function to call when a media query is triggered.
   * @returns A function that when called will cancel the subscription.
   */
  subscribeViewportChange(
    callback: (viewportQueries: Map<ViewportName, MediaQueryList>) => void
  ) {
    const handleViewportChange = () => callback(this.viewportQueries);

    this.viewportQueries.forEach(mediaQuery => {
      mediaQuery.addListener(handleViewportChange);
    });

    // must call initially after setting subscription
    handleViewportChange();

    return () =>
      this.viewportQueries.forEach(mediaQuery =>
        mediaQuery.removeListener(handleViewportChange)
      );
  }

  /**
   * Subscribe a callback function to all of the orientation media queries.
   *
   * @param callback - A function to call when a media query is triggered.
   * @returns A function that when called will cancel the subscription.
   */
  subscribeOrientationChange(
    callback: (orientationQueries: Map<OrientationName, MediaQueryList>) => void
  ) {
    const handleOrientationChange = () => callback(this.orientationQueries);

    this.orientationQueries.forEach(mediaQuery => {
      mediaQuery.addListener(handleOrientationChange);
    });

    // must call initially after setting subscription
    handleOrientationChange();

    return () =>
      this.orientationQueries.forEach(mediaQuery =>
        mediaQuery.removeListener(handleOrientationChange)
      );
  }
}

export default Screen;
