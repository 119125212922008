import { formatCurrency } from 'src/utils/formatters';
import type { Recipe } from 'src/graphql/types';

import type { LpfRecipes, RecipeNames } from '@farmersdog/constants';
import { LPF_RECIPES } from '@farmersdog/constants';
import type { PetWithRecipesAndRatio } from '../hooks/state/usePetsWithRecipesAndRatio';
import type { RatioBuckets } from '../hooks/useConsumptionPricing/utils/getPlanRatio';
import { getPlanRatio } from '../hooks/useConsumptionPricing/utils/getPlanRatio';
import type { QuotePet } from '../hooks/useFreshCheckoutQuote';

type UnsortedRecipe = Pick<Recipe, 'displayName'> & {
  name: RecipeNames;
};

/**
 * Return whether the tax is a non-zero tax amount
 *
 */
export function getHasSalesTax(taxAmount?: number | null) {
  return (
    typeof taxAmount === 'number' && Number.isFinite(taxAmount) && taxAmount > 0
  );
}

interface GetDisplayTrialTotalArgs {
  hasSalesTaxApplied: boolean;
  totalAmount: number;
  subtotalAmount: number;
}

/**
 * Return the total trial price or free, plus language about tax being included or not included
 *
 * @param hasSalesTaxApplied - Whether the order has sales tax applied or not
 * @param totalAmount - The total amount of the order
 * @param subtotalAmount - The subtotal amount of the order
 */

export function getDisplayTrialTotal({
  hasSalesTaxApplied,
  totalAmount,
  subtotalAmount,
}: GetDisplayTrialTotalArgs) {
  if (!totalAmount) {
    return 'free';
  }

  const formattedAmount = formatCurrency(
    hasSalesTaxApplied ? totalAmount : subtotalAmount
  );
  const plusTax = hasSalesTaxApplied ? '' : ' + tax';

  return formattedAmount ? `${formattedAmount}${plusTax}` : '';
}

export interface PetOrderSummaryMap {
  [petName: string]: {
    subtotal: string;
    regularDailyPrice: string;
    trialDailyPrice: string;
    daysOfFood: number;
    recipes: (string | undefined)[];
    hasSelectedLpf: boolean;
    planRatio: RatioBuckets | undefined;
  };
}

export const getOrderSummaryPerPetMap = (
  {
    trialOrderDaysOfFood,
    pets,
  }: {
    trialOrderDaysOfFood: number;
    pets: QuotePet[];
  },
  allRecipes: { name: string; displayName: string }[] | undefined,
  foodPlans: PetWithRecipesAndRatio[]
): PetOrderSummaryMap => {
  const petOrderDetailsMap: PetOrderSummaryMap = {};
  const anyPetHasMixingPlan = foodPlans.some(foodPlan => foodPlan.ratio < 1);

  pets.forEach(pet => {
    const petFoodPlan = foodPlans.find(
      foodPlan => foodPlan.petName === pet.name
    );

    petOrderDetailsMap[pet.name] = {
      subtotal: formatCurrency(pet.subtotal) || '',
      regularDailyPrice: formatCurrency(pet.regularDailyPrice) || '',
      trialDailyPrice: formatCurrency(pet.trialDailyPrice) || '',
      daysOfFood: trialOrderDaysOfFood,

      recipes: (
        (petFoodPlan?.recipes.map(recipe => {
          return allRecipes?.find(r => r.name === recipe) ?? {};
        }) as UnsortedRecipe[]) ?? []
      ).map(recipe => recipe.displayName),

      hasSelectedLpf:
        petFoodPlan?.recipes.some(
          recipe => recipe && LPF_RECIPES.includes(recipe as LpfRecipes)
        ) ?? false,

      planRatio:
        anyPetHasMixingPlan && petFoodPlan
          ? getPlanRatio(petFoodPlan.ratio)
          : undefined,
    };
  });

  return petOrderDetailsMap;
};
