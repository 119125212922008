import { Text, Nowrap, Grid } from '@farmersdog/corgi-x';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { ExtendedText } from '../../../ExtendedText';
import { ExtendedButton } from '../../../ExtendedButton';

import styles from './HeroTitle.module.css';
import { CtaTrackerV2, HomepageRedesignSections } from '../../../CtaTrackerV2';

const ctaLabel = 'See Plans and Pricing';

export const HeroTitle = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  return (
    <Grid className={styles.heroTitleContainer}>
      <div>
        <ExtendedText color="Kale3" as="h1" className={styles.heroTitle}>
          The recipe for a
          <br className={styles.titeBreakline} /> long,{' '}
          <Nowrap>healthy life.</Nowrap>
        </ExtendedText>
        <Text
          as="p"
          className={styles.heroSubtitle}
          color="Kale3"
          align="center"
          vSpacing="none"
          weight="semibold"
        >
          Made by Board-Certified Nutritionists.{' '}
          <br className={styles.subtitleBreakline} />
          Plans tailored just <Nowrap>for your dog.</Nowrap>
        </Text>
      </div>
      <div>
        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.Hero}
        >
          <ExtendedButton
            variant="solid-carrot"
            type="link"
            to={linkToFreshSignup}
            className={styles.heroCta}
          >
            {ctaLabel}
          </ExtendedButton>
        </CtaTrackerV2>
      </div>
    </Grid>
  );
};
