import { ButtonBase, Text } from '@farmersdog/corgi-x';
import { CheckCircle } from '@farmersdog/corgi-x/icons';

import { formatCurrency } from '@/account/utils';

import {
  DISCOUNT_DISCLAIMER_BOLD,
  DISCOUNT_DISCLAIMER_CTA,
  DISCOUNT_DISCLAIMER_REGULAR,
} from './DiscountDisclaimer.copy';
import styles from './DiscountDisclaimer.module.css';

interface DiscountDisclaimerProps {
  addOnTotalPrice?: number;
  seeTermsHandler: () => void;
}

export const DiscountDisclaimer = ({
  seeTermsHandler,
  addOnTotalPrice,
}: DiscountDisclaimerProps) => {
  return (
    <section
      className={styles.root}
      data-testid="treats-discount-applied-disclaimer"
      role="note"
      aria-label="Treats discounting applied terms"
    >
      <div className={styles.icon}>
        <CheckCircle baseColor="Kale3" size="22px" />
      </div>

      <Text
        as="p"
        variant="heading-16"
        vSpacing="none"
        align="left"
        color="Kale3"
      >
        <Text bold>{DISCOUNT_DISCLAIMER_BOLD}</Text>{' '}
        {addOnTotalPrice === undefined
          ? DISCOUNT_DISCLAIMER_REGULAR
          : `Your first regular-sized bag is FREE. Recurring order treats total is ${formatCurrency(addOnTotalPrice)}.`}{' '}
        <ButtonBase
          aria-label="See treats discounting terms and conditions"
          type="button"
          onClick={seeTermsHandler}
        >
          <Text underline>{DISCOUNT_DISCLAIMER_CTA}</Text>.
        </ButtonBase>
      </Text>
    </section>
  );
};
