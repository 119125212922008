import { useCallback, useEffect, useRef } from 'react';

export function useSetTimeout(fn: () => void, timeout: number) {
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const runFn = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(fn, timeout);
  }, [fn, timeout]);

  return runFn;
}
