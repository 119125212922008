import {
  PATH_HOME,
  PATH_REFERRAL_CODE_MATCH,
} from '@farmersdog/constants/paths';

import {
  AC_HOMEPAGE_END_TO_END_REDESIGN,
  CVR_HOMEPAGE_HERO_IMAGE,
} from 'src/abTesting/features';

import type { AnonymousExperimentFlag } from './flags';

export const flagRouteMap = {
  [CVR_HOMEPAGE_HERO_IMAGE]: [PATH_HOME, PATH_REFERRAL_CODE_MATCH],
  [AC_HOMEPAGE_END_TO_END_REDESIGN]: [PATH_HOME, PATH_REFERRAL_CODE_MATCH],
} as Record<AnonymousExperimentFlag, string[]>;
