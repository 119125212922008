import type { ApolloError } from '@apollo/client';

const handledErrorCodes = new Set(['TAX_FETCHING_FAILURE']);

export function isHandledQuoteTaxError(error: ApolloError | undefined) {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length) {
    return false;
  }

  const errorCode = error?.graphQLErrors[0].extensions?.code as string;

  if (handledErrorCodes.has(errorCode)) {
    return true;
  }

  return false;
}
