import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import { AmazonTagMethod } from './types';

import type { AmazonTagEvent } from './types';

export interface TrackAmazonTagEventArgs {
  name: AmazonTagEvent;
  properties?: Record<string, unknown>;
}

function _mountAmazonTagEvent(
  w: Window,
  { name, properties = {} }: TrackAmazonTagEventArgs
) {
  w.amzn?.(AmazonTagMethod.TrackEvent, name, properties);
}

export const mountAmazonTagEvent = mountVendorWindowEvent(
  'Amazon',
  _mountAmazonTagEvent
);
