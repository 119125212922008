import { mobileSources, desktopSources } from './assets';

export const getPressBarItems = ({ isMobile }: { isMobile: boolean }) => {
  const sources = isMobile ? mobileSources : desktopSources;

  return [
    {
      sources: sources.popularScience,
      alt: 'Popular Science Logo',
      height: {
        mobile: 28,
        desktop: 48,
      },
      quote:
        'The Farmer’s Dog is working to combat poor health with fresh, human-grade food',
    },
    {
      sources: sources.goodHousekeeping,
      alt: 'Good Housekeeping Logo',
      height: {
        mobile: 28,
        desktop: 48,
      },
      quote:
        'Helps you zero in on everything you need to consider for your dog',
    },
    {
      sources: sources.google,
      alt: 'Google customer reviews five stars',
      height: {
        mobile: 28,
        desktop: 48,
      },
      quote:
        'My dog LOVES the food and I love the convenience and nutritional value!',
    },
    {
      sources: sources.newYorkMagazine,
      alt: 'New York Magazine Logo',
      height: {
        mobile: 28,
        desktop: 48,
      },
      quote:
        'Long-term feeding trials show that the nutritional value exceeds AAFCO recommendations',
    },
    {
      sources: sources.cnet,
      alt: 'CNET Logo',
      height: {
        mobile: 20,
        desktop: 32,
      },
      quote:
        'A personalized profile and diet for your dog based on breed, age, activity level, ideal weight',
    },
  ];
};
