/**
 * Note: This is an extension of the corgi-x component `Gallery` but with the ability to loop through the slides.
 */
import classNames from 'classnames';
import fastdom from 'fastdom';
import noop from 'lodash/noop';
import {
  Children,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useState,
  type MouseEventHandler,
  type ReactNode,
} from 'react';

import {
  useHtmlElement,
  Text,
  ScrollSnap,
  IndexButton,
  StatusMessage,
} from '@farmersdog/corgi-x';
import { Chevron } from '@farmersdog/corgi-x/icons';

import styles from './ExtendedGallery.module.css';
import { useScrollEnd } from './hooks/useScrollEnd';
import { useSlideshow } from './hooks/useSlideshow';
import { getIsActiveSlide } from './helpers/getIsActiveSlide';
import { mapSlideToChild } from './helpers/mapSlideToChild';
import { Slide } from './Slide';

/** Which contrast mode to use */
export type GalleryMode = 'light' | 'dark' | 'darker';

export interface GalleryProps {
  id?: string;
  /** The label used for this gallery. Required for accessibility. */
  label: string;
  /** Boolean value to toggle the display of index dots indicators */
  withDots?: boolean;
  /** Callback when the currently active slide is clicked */
  onActiveSlideClick?: MouseEventHandler;
  /** Callback when the current slide changes from a touch gestures */
  onSlideChange?: (nextIndex: number) => void;
  /** The currently active index when used in a controlled state pattern */
  activeIndex?: number;
  /** Content to render beneath the active slide */
  footnote?: ReactNode;
  /** Which contrast mode to use */
  mode?: GalleryMode;
  /** Whether to toggle the wide view */
  isWide?: boolean;
  /** Whether to toggle the debug code to visualize the intersections */
  isDebug?: boolean;
  /** Toggle looping from last to first and first to last */
  withLoop?: boolean;
  /** Optional number of slides that overlap when looping
   * default is 1 -- which means the last slide will be shown partially to the left of the first slide and vice versa
   **/
  overlap?: number;
  /** Optional container class name */
  className?: string;
  /** Optional slide class name */
  slideClassname?: string;
  /** Whether to grayscale inactive items */
  grayscaleInactiveItems?: boolean;
  /** Disables user scrolling*/
  disableUserScrolling?: boolean;
  /** Disables scale animation on slide change */
  disableScaleAnimation?: boolean;
  children?: ReactNode;
}
const initialRatios: number[] = [];

// The number of slides that overlap when looping
const DEFAULT_OVERLAPPING_SLIDES = 1;

// prepends the last N (overlap) slides to the beginning and appends the first N slides to the end
const getSlidesWithOverlap = (
  childrenArray: Array<Exclude<ReactNode, boolean | null | undefined>>,
  overlap: number
) => {
  const slides = childrenArray.slice();
  const lastNSlides = slides.slice(-overlap);
  const firstNSlides = slides.slice(0, overlap);
  return [...lastNSlides, ...slides, ...firstNSlides];
};

/**
 * This is an extension of the corgi-x component `Gallery` but with the ability to loop through the slides.
 *
 * Render a gallery component. This component accepts any number of children
 * that will each be rendered as a slide for the gallery.
 *
 * @see https://corgi-x.tfd.engineering/components/gallery
 */
export const ExtendedGallery = forwardRef<HTMLElement, GalleryProps>(
  (
    {
      id: idFromProps,
      mode = 'dark',
      label,
      onSlideChange,
      withDots = false,
      onActiveSlideClick,
      footnote,
      activeIndex: activeIndexProp = 0,
      isWide = false,
      withLoop = false,
      className,
      slideClassname,
      overlap = DEFAULT_OVERLAPPING_SLIDES,
      grayscaleInactiveItems = false,
      disableUserScrolling = false,
      disableScaleAnimation = false,
      isDebug = false,
      children,
    },
    ref
  ) => {
    const reactId = useId();
    const id = idFromProps || reactId;

    const messageReactId = useId();
    const messageId = idFromProps ? idFromProps + 'message' : messageReactId;

    const numberOfSlides = Children.count(children);
    const [containerEl, containerRef] = useHtmlElement();
    const [intersectionRatios, setIntersectionRatios] = useState(initialRatios);
    const waitForScrollEnd = useScrollEnd(containerEl);

    const childrenArray = Children.toArray(children);

    const slidesToRender = withLoop
      ? getSlidesWithOverlap(childrenArray, overlap)
      : childrenArray;

    const mapSlideIndex = (index: number): number =>
      mapSlideToChild(index, numberOfSlides);

    const slideIsActive = (index: number): boolean =>
      mapSlideIndex(index) === mapSlideIndex(activeIndex);

    const shouldOmitAnimation = (index: number): boolean => {
      const isLoopingSlide =
        index === activeIndex + numberOfSlides ||
        index === activeIndex - numberOfSlides;
      return disableScaleAnimation || (withLoop && isLoopingSlide);
    };

    // it keeps the active index consistent with the parent component, even though we prepend and append slides
    const shiftedOnSlideChange = withLoop
      ? (index: number): void => onSlideChange?.(mapSlideIndex(index - overlap))
      : onSlideChange;

    const { activeIndex, goToSlide } = useSlideshow({
      scrollableEl: containerEl,
      scrollBehavior: 'smooth',
      getIsActiveSlide,
      onSlideChange: shiftedOnSlideChange,
      activeIndex: withLoop ? activeIndexProp + overlap : activeIndexProp,
    });

    useEffect(() => {
      if (!containerEl) {
        return;
      }

      const adjustLoop = async () => {
        if (activeIndex === 0) {
          await waitForScrollEnd();
          goToSlide(numberOfSlides, 'instant');
        } else if (activeIndex === numberOfSlides + overlap) {
          await waitForScrollEnd();
          goToSlide(overlap, 'instant');
        }
      };

      void adjustLoop();
    }, [
      activeIndex,
      containerEl,
      goToSlide,
      numberOfSlides,
      waitForScrollEnd,
      overlap,
    ]);

    const handleScroll = useCallback((): void => {
      let nextIntersectionRatios: number[];

      fastdom.measure(() => {
        if (!containerEl) {
          return;
        }

        const scrollableRect = containerEl.getBoundingClientRect();
        const scrollableLeft = scrollableRect.left;
        const scrollableRight = scrollableRect.right;
        const scrollableWidth = scrollableRect.width;
        const slides = Array.from(containerEl.children);

        nextIntersectionRatios = slides.map(slide => {
          const slideRect = slide.getBoundingClientRect();
          const slideCenter = slideRect.width / 2 + slideRect.left;
          const animationReferenceRight =
            slideCenter + scrollableRect.width / 2;
          const animationReferenceLeft = slideCenter - scrollableRect.width / 2;

          const isIntersectingLeft =
            animationReferenceLeft <= scrollableLeft &&
            animationReferenceRight > scrollableLeft;

          const isIntersectingRight =
            animationReferenceRight >= scrollableRight &&
            animationReferenceLeft < scrollableRight;

          if (isIntersectingRight) {
            return (
              (scrollableWidth - (animationReferenceRight - scrollableRight)) /
              scrollableWidth
            );
          }

          if (isIntersectingLeft) {
            return (animationReferenceRight - scrollableLeft) / scrollableWidth;
          }

          return 0;
        });

        fastdom.mutate(() => {
          setIntersectionRatios(nextIntersectionRatios);
        });
      });
    }, [containerEl]);

    return (
      <section
        aria-label={label}
        className={classNames(className, {
          [styles.isWide]: isWide,
          [styles.isNotWide]: !isWide,
        })}
        data-active-slide={activeIndex}
        ref={ref}
      >
        <div className={styles.container}>
          {isWide && (
            <>
              <div className={styles.backwardButtonContainer}>
                <button
                  onClick={(): void => goToSlide(activeIndex - 1)}
                  className={classNames(styles.arrowButton)}
                  disabled={!withLoop && activeIndex === 0}
                  aria-label="Previous Slide"
                >
                  <Chevron
                    fill={mode === 'light' ? 'white' : 'kale-3'}
                    size={72}
                    orientation={'left'}
                  />
                </button>
              </div>
              <div className={styles.forwardButtonContainer}>
                <button
                  onClick={(): void => goToSlide(activeIndex + 1)}
                  className={classNames(styles.arrowButton)}
                  disabled={!withLoop && activeIndex === numberOfSlides - 1}
                  aria-label="Next Slide"
                >
                  <Chevron
                    fill={mode === 'light' ? 'white' : 'kale-3'}
                    size={72}
                  />
                </button>
              </div>
            </>
          )}
          <ScrollSnap
            as="ul"
            id={id}
            containerRef={containerRef}
            className={classNames(styles.horizontalSnap, {
              [styles.disableUserScroll]: disableUserScrolling,
            })}
            onScroll={handleScroll}
          >
            {slidesToRender.map((child, index) => {
              const handleClick =
                index === activeIndex
                  ? onActiveSlideClick
                  : (): void => goToSlide(index);

              return (
                <Slide
                  key={index}
                  aria-label={`${index + 1} of ${numberOfSlides}`}
                  aria-describedby={messageId}
                  className={slideClassname}
                  onClick={disableUserScrolling ? noop : handleClick}
                  isDebug={isDebug}
                  isWide={isWide}
                  index={index}
                  intersectionRatio={intersectionRatios[index]}
                  active={slideIsActive(index)}
                  grayscaleInactiveItem={grayscaleInactiveItems}
                  omitAnimation={shouldOmitAnimation(index)}
                  disableUserScrolling={disableUserScrolling}
                >
                  {child}
                </Slide>
              );
            })}
          </ScrollSnap>
        </div>
        <StatusMessage
          politeness="polite"
          id={messageId}
          className={styles.message}
          key={activeIndex}
        >
          <Text color={['dark', 'darker'].includes(mode) ? 'Kale3' : 'White'}>
            {footnote}
          </Text>
        </StatusMessage>
        {withDots && (
          <div className={styles.dots}>
            {Children.map(children, (_, childIndex) => {
              return (
                <IndexButton
                  variant="kale"
                  aria-label={`Go to slide ${withLoop ? childIndex + 1 : childIndex}`}
                  aria-controls={id}
                  active={
                    withLoop
                      ? childIndex + 1 === activeIndex
                      : childIndex === activeIndex
                  }
                  onClick={(): void =>
                    goToSlide(withLoop ? childIndex + 1 : childIndex)
                  }
                />
              );
            })}
          </div>
        )}
      </section>
    );
  }
);
