/* CH#49502: Used by RET and CVR */
import { Helmet } from 'react-helmet-async';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { AmazonTagScript } from 'src/vendors/amazon';
import { AspireIqScript } from 'src/vendors/aspireIq';
import { Ga4Script } from 'src/vendors/ga4';
import { TikTokScript } from 'src/vendors/tiktok';
import { SegmentScript } from 'src/vendors/segment';
import { DatadogScript } from 'src/vendors/datadog/DatadogScript';
import { PowerInboxScript } from 'src/vendors/powerInbox';
import { LiveIntentScript } from 'src/vendors/liveintent';
import { VWAScript } from 'src/vendors/vwa';
import { XScript } from 'src/vendors/x';
import { PostieScript } from 'src/vendors/postie';
import { SnapchatScript, SpotifyScript, RedditScript } from 'src/vendors';

import {
  description,
  image,
  ogImage,
  siteName,
  titleTemplate,
  defaultTitle,
} from 'src/constants/helmet';

import config from 'src/config';
import {
  PATH_REFERRAL_CODE,
  PATH_SIGNUP_SUCCESS,
  PATH_CUSTOMER_ACCOUNT,
  PATH_SIGNUP,
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_CANONICAL_ROUTE,
} from '@farmersdog/constants/paths';
import { NextDoorScript } from 'src/vendors/nextDoor';
import { isFreshSignup } from '@farmersdog/lead-browser-storage';
import { PeacockScript } from 'src/vendors/peacock/PeacockScript';
import { PrismicPreviewToolbarScript } from '@/account/content';

const publicUrl = config('app.publicUrl');

function stripTrailingSlash(path: string) {
  return path.replace(/\/+$/, '');
}

function Head() {
  const { pathname } = useLocation();

  const isProduction = config('app.env') === 'production';
  const isLiveIntentTrackingEnabled = config('features.liveIntentTracking');

  const isHomepage = Boolean(useRouteMatch({ path: PATH_HOME, exact: true }));
  const isReferrerPath = Boolean(useRouteMatch(PATH_REFERRAL_CODE));
  const isCheckoutSuccessPath = Boolean(useRouteMatch(PATH_SIGNUP_SUCCESS));
  const isSignupPath = Boolean(useRouteMatch(PATH_SIGNUP));
  const isSignupMePath = Boolean(useRouteMatch(PATH_SIGNUP_ME));
  const isAccountPath = Boolean(useRouteMatch(PATH_CUSTOMER_ACCOUNT));

  const shouldSetHomepageCanonical =
    isReferrerPath || isAccountPath || isHomepage;

  const pathWithoutTrailingSlash = stripTrailingSlash(pathname);

  const canonicalUrl = `${publicUrl}${
    shouldSetHomepageCanonical ? PATH_HOME : pathWithoutTrailingSlash
  }`;
  const signupCanonicalUrl = `${publicUrl}${PATH_SIGNUP_CANONICAL_ROUTE}`;

  const powerInboxScript = isCheckoutSuccessPath ? <PowerInboxScript /> : null;

  const noIndex = !isProduction || isReferrerPath;

  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
        <link
          rel="canonical"
          href={isSignupPath ? signupCanonicalUrl : canonicalUrl}
        />
        <meta itemProp="name" content={siteName} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />
        <meta itemProp="thumbnailUrl" content={image} />
        <meta itemProp="url" content={publicUrl} />
        {noIndex && <meta name="robots" content="noindex" />}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:title" content={defaultTitle} />
        <meta name="twitter:url" content={publicUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={publicUrl} />
        <meta property="fb:app_id" content={config('facebook.appId')} />
      </Helmet>
      <SegmentScript />
      <Ga4Script />
      <PrismicPreviewToolbarScript />
      {isFreshSignup() && (
        <>
          <TikTokScript />
          <PeacockScript />
          <NextDoorScript />
          {isSignupMePath && <VWAScript />}
          {powerInboxScript}
          <AmazonTagScript />
          <AspireIqScript />
          <XScript />
          <PostieScript />
          <SpotifyScript />
          <SnapchatScript />
          <RedditScript />
        </>
      )}
      {isLiveIntentTrackingEnabled && <LiveIntentScript />}
      <DatadogScript />
    </>
  );
}

export default Head;
