import { browserOnly } from '@farmersdog/utils';
import { useEffect, useState } from 'react';

export function useIsPageLoaded() {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    browserOnly((window, document) => {
      if (document.readyState === 'complete') {
        setIsPageLoaded(true);
        return;
      }

      const onPageLoad = () => {
        setIsPageLoaded(true);
      };

      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    });
  }, []);

  return isPageLoaded;
}
