import { isbot } from 'isbot';

import type { HomepageEndToEndRedesignTreatments } from 'src/abTesting';
import { useSsrFeature } from 'src/abTesting';
import { AC_HOMEPAGE_END_TO_END_REDESIGN } from 'src/abTesting/features';

import {
  activeHomepageRedesignTreatments,
  type ActiveHomepageRedesignTreatment,
} from './types';

export interface UseShowHomePageE2ERedesignReturnType {
  shouldShowHomepageRedesign: boolean;
  treatment: HomepageEndToEndRedesignTreatments;
}

export const useShowHomepageRedesign =
  (): UseShowHomePageE2ERedesignReturnType => {
    const isBot = isbot(navigator.userAgent);
    const { treatment } = useSsrFeature<
      typeof AC_HOMEPAGE_END_TO_END_REDESIGN,
      HomepageEndToEndRedesignTreatments
    >({ featureKey: AC_HOMEPAGE_END_TO_END_REDESIGN, attributes: { isBot } });

    const shouldShowHomepageRedesign = treatment
      ? activeHomepageRedesignTreatments.includes(
          treatment as ActiveHomepageRedesignTreatment
        )
      : false;

    return {
      shouldShowHomepageRedesign,
      treatment,
    };
  };
