import { useViewport } from '@farmersdog/corgi-x';
import {
  LifetimeOfBenefitsMobile,
  LifetimeBenefitsDesktop,
} from './components';
import type { BenefitCardVariant } from './data';

interface LifetimeOfBenefitsProps {
  onTileClick: (key: BenefitCardVariant) => void;
}

export const LifetimeOfBenefits = ({
  onTileClick,
}: LifetimeOfBenefitsProps) => {
  const viewport = useViewport();
  const isMobile = !viewport?.lg;

  return isMobile ? (
    <LifetimeOfBenefitsMobile onTileClick={onTileClick} />
  ) : (
    <LifetimeBenefitsDesktop />
  );
};
