import { useState } from 'react';
import { BowlPackSection, OrderNowMarquee } from '../../../../components';
import { HeroIconItemsDesktop } from '../../components';

interface SubHeroProps {
  ctaRevealElement: React.RefObject<HTMLDivElement>;
}

export const SubHero = ({ ctaRevealElement }: SubHeroProps) => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  return (
    <div ref={ctaRevealElement}>
      <HeroIconItemsDesktop animationComplete={animationComplete} />
      <OrderNowMarquee />
      <BowlPackSection onAnimationComplete={handleAnimationComplete} />
    </div>
  );
};
