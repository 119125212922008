import { motion } from 'motion/react';
import { Nowrap, Section, useViewport } from '@farmersdog/corgi-x';

import { getDeliveryEstimateText } from 'src/pages/HomepageRedesign/utils';
import {
  SaucepanIconSvg,
  IngredientsIconSvg,
  DeliveredIconSvg,
} from './assets';
import { makeRoom } from './animations';

import styles from './HeroIconItemsDesktop.module.css';
import { ExtendedText } from '../../../ExtendedText';

const heroIconItems = [
  {
    id: 'human-grade-safety',
    icon: IngredientsIconSvg,
    iconLabel: 'Dog food ingredients',
    text: (
      <>
        <Nowrap>Human-grade</Nowrap> <Nowrap>safety standards</Nowrap>
      </>
    ),
  },
  {
    id: 'delivered-to-your-door',
    icon: SaucepanIconSvg,
    iconLabel: 'Ingredients in Saucepan',
    text: (
      <>
        <Nowrap>Real food,</Nowrap> <Nowrap>gently cooked</Nowrap>
      </>
    ),
  },
  {
    id: 'loved-by-millions',
    icon: DeliveredIconSvg,
    iconLabel: 'Dog food delivery box',
    text: getDeliveryEstimateText('desktop') as JSX.Element,
  },
];

interface HeroIconItemsDesktopProps {
  animationComplete: boolean;
}

export const HeroIconItemsDesktop = ({
  animationComplete = false,
}: HeroIconItemsDesktopProps) => {
  const viewport = useViewport();

  return (
    <Section as="div" className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.itemsWrapper}>
          <motion.div
            layout
            variants={makeRoom.variants}
            initial="initial"
            animate={viewport.xl ? 'animateXL' : 'animateLG'}
            transition={animationComplete ? undefined : makeRoom.transition}
            className={styles.itemsContainer}
          >
            {heroIconItems.map(item => (
              <div key={item.id} className={styles.item}>
                <item.icon
                  role="img"
                  aria-label={item.iconLabel}
                  className={styles.icon}
                />
                <ExtendedText
                  variant="heading-18"
                  weight="semibold"
                  color="Kale3"
                >
                  {item.text}
                </ExtendedText>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </Section>
  );
};
