export enum X_Events {
  Lead = 'tw-o28pe-odnpi',
  Add_To_Cart = 'tw-o28pe-odnpn',
  Purchase = 'tw-o28pe-odnog',
}

export type XSdk = (
  command: 'event',
  eventName: X_Events,
  properties?: Record<string, unknown>
) => void;
