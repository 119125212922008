import { useEffect, useRef } from 'react';

interface useIntervalArgs {
  durationSeconds: number;
  onTimeLapsed: () => void;
  enabled?: boolean;
}

export const useInterval = ({
  durationSeconds,
  onTimeLapsed,
  enabled = true,
}: useIntervalArgs) => {
  const intervalRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!enabled) {
      clearInterval(intervalRef.current);
      return;
    }

    intervalRef.current = setInterval(() => {
      onTimeLapsed();
    }, durationSeconds * 1000);
    return () => clearInterval(intervalRef.current);
  }, [durationSeconds, onTimeLapsed, enabled]);

  const reset = () => {
    clearInterval(intervalRef.current);
    if (!enabled) {
      return;
    }

    intervalRef.current = setInterval(() => {
      onTimeLapsed();
    }, durationSeconds * 1000);
  };
  return { reset };
};
