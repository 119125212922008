/**
 * Uses a RegExp to check if a string is a valid phone number
 */
export function isValidPhoneNumber(phone: string) {
  if (!phone) {
    return false;
  }

  const phoneRegExp = new RegExp(/^\d{3}-?\d{3}-?\d{4}$/);
  if (phoneRegExp.test(phone)) {
    return true;
  }

  return false;
}
