import { usePrismicContext } from '@prismicio/react';
import { useEffect, useState } from 'react';

import { reporter } from '@/account/errors/services';

import type * as Repository from './types.generated';
import type { Client, PrismicDocument } from '@prismicio/client';

export interface UseExperimentDocumentsArgs<TRelation> {
  featureName: string;
  treatmentName: string;
  fallbackContent: TRelation;
}

export interface UseExperimentDocuments<TRelation> {
  /** The specific content treatment for this user. A named subsection of the parent Prismic Document.
  If Prismic is down, fallbackContent will be returned. */
  content?: TRelation;
  /** loading=true until Prismic request is completed. When loading=false, content is populated. */
  loading: boolean;
  /** error contains any errors received from constructing client request for Prismic */
  error?: Error;
}

export type ExperimentDocument = Repository.SplitExperimentDocument;
export type Experiment = ExperimentDocument['data'];
export type EOmitTreatments = Omit<Experiment, 'treatments'>;

export type Treatment = Repository.SplitExperimentDocumentDataTreatmentsItem;
export type TOmitContent = Omit<Treatment, 'content'>;

export interface TreatmentWithRelation<TRelation> extends TOmitContent {
  content: TRelation;
}

export interface ExperimentWithRelations<TRelation> extends EOmitTreatments {
  treatments: TreatmentWithRelation<TRelation>[];
}

/**
 * Fetches a document from Prismic CMS by `featureName` and filters to a sub-document using `treatmentName`.
 *
 * @param featureName - id of a Document to pull from Prismic CMS, associated with a split experiment.
 * @param treatmentName - A named sub-section of the parent Document from Prismic,
 *      defined by the split treatments associated with this feature.
 * @param fallbackContent - The content to render if Prismic CMS is down.
 * @returns The content pulled from Prismic, else fallbackContent if the request errors.
 *
 */
export function useExperimentDocuments<TRelation extends PrismicDocument>({
  featureName,
  treatmentName,
  fallbackContent,
}: UseExperimentDocumentsArgs<TRelation>): UseExperimentDocuments<TRelation> {
  const prismicContext = usePrismicContext();
  const [content, setContent] = useState<TRelation>(fallbackContent);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const fetchDocument = async () => {
      const client = prismicContext.client;

      try {
        if (!client) {
          throw new Error('Could not find client');
        }

        const data: ExperimentWithRelations<TRelation> =
          await getExperimentDocumentsByFeature<TRelation>(client, featureName);

        const treatment: TRelation = data?.treatments.find(
          value => value.treatment_name === treatmentName
        )?.content.data as TRelation;

        if (treatment) {
          setContent(treatment);
        } else {
          const errorMessage =
            'Error retrieving treatment from returned prismic content';
          const e = new Error(errorMessage);
          reporter.warn(errorMessage, {
            featureName,
            treatmentName,
          });
          setError(e);
        }
      } catch (e: unknown) {
        reporter.warn('Error loading prismic content', {
          featureName,
          sourceError: e,
        });
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    };
    // 'control' is a reserved keyword in split.io
    // Treat the same as loading=true
    // https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
    if (treatmentName !== 'control') {
      setLoading(true);
      void fetchDocument();
    }
  }, [
    featureName,
    prismicContext.client,
    treatmentName,
    fallbackContent,
    error?.message,
  ]);
  return {
    content,
    loading,
    error,
  };
}

async function getExperimentDocumentsByFeature<T extends PrismicDocument>(
  client: Client,
  featureName: string
): Promise<ExperimentWithRelations<T>> {
  const experiment: Repository.SplitExperimentDocument =
    await client.getByUID<Repository.SplitExperimentDocument>(
      'split_experiment',
      featureName
    );
  // Grab contentID of all sub-documents
  const contentIds = experiment.data.treatments.map(treatment => {
    const content = treatment.content;

    // @ts-expect-error This type seems incorrect
    return content.id as string;
  });

  // Download content of all sub-documents
  const contentDocuments = await client.getAllByIDs<T>(contentIds);

  // Attach content back to original document
  const updatedTreatments = updateTreatmentsWithContainedDocuments<T>(
    experiment.data.treatments as TreatmentWithRelation<T>[],
    contentDocuments
  );

  return {
    ...experiment.data,
    treatments: updatedTreatments,
  };
}

function updateTreatmentsWithContainedDocuments<T extends PrismicDocument>(
  treatments: TreatmentWithRelation<T>[],
  relatedDocuments: T[]
): TreatmentWithRelation<T>[] {
  return treatments.map(treatment => {
    const relatedDocument = relatedDocuments.find(contentDocument => {
      return contentDocument.id === treatment.content.id;
    });

    if (!relatedDocument) {
      throw new Error('Could not find related document');
    }

    return {
      ...treatment,
      content: relatedDocument,
    };
  });
}
