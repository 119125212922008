import { useState } from 'react';

/**
 * Utility hook to help manage the state of the SkipAddOnsModal
 */
export function useSkipAddOnsModal() {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return {
    isOpen,
    open,
    close,
  };
}
