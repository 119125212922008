import desktopCurrentFormatHeroJPG from './desktop-current-format-hero.jpg';
import desktopCurrentFormatHeroWebp from './desktop-current-format-hero.webp';

import mobileCurrentFormatHeroJPG from './mobile-current-format-hero.jpg';
import mobileCurrentFormatHeroWebp from './mobile-current-format-hero.webp';

export const currentFormatHeroVariantSources = {
  mobileSources: {
    webP: mobileCurrentFormatHeroWebp,
    jpg: mobileCurrentFormatHeroJPG,
  },
  desktopSources: {
    webP: desktopCurrentFormatHeroWebp,
    jpg: desktopCurrentFormatHeroJPG,
  },
};
