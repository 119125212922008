import { PATH_SIGNUP_CHECKOUT } from '@farmersdog/constants/paths';
import { Divider, Button, Text, useViewport, Modal } from '@farmersdog/corgi-x';
import { isValidEmail } from '@farmersdog/utils';

import useGlobalLightbox from 'src/utils/useGlobalLightbox';

import {
  MODAL_TITLE,
  NOT_NOW_BUTTON_COPY,
  LOGIN_BUTTON_COPY,
} from './FinishCheckoutModal.copy';
import styles from './FinishCheckoutModal.module.css';

export interface FinishCheckoutModalProps {
  email: string;
  onCancelClick?: () => void;
}

export const FINISH_CHECKOUT_MODAL_ID = 'FinishCheckoutModalId';

export function FinishCheckoutModal({
  email = '',
  onCancelClick,
}: FinishCheckoutModalProps) {
  const { rootProps, labelProps, close } = useGlobalLightbox({
    id: FINISH_CHECKOUT_MODAL_ID,
  });

  const isMobile = !useViewport().md;
  const titleTextVariant = isMobile ? 'heading-28' : 'heading-40';
  const subtitleTextVariant = isMobile ? 'heading-16' : 'heading-22';

  const handleClickNotNow = () => {
    onCancelClick?.();
    close();
  };

  const signupRecoveryPath = getSignupRecoveryPath(email);

  return (
    <Modal withCloseButton {...rootProps}>
      <Text as="h2" variant={titleTextVariant} bold {...labelProps}>
        {MODAL_TITLE}
      </Text>
      <Text as="p" variant={subtitleTextVariant} className={styles.text}>
        Please finish checking out to login into your account.
      </Text>
      <Divider orientation="horizontal" color="Oyster0" margin={0} />
      <div className={styles.buttonContainer}>
        <Button variant="plain-text" onClick={handleClickNotNow}>
          <Text bold variant="heading-16">
            {NOT_NOW_BUTTON_COPY}
          </Text>
        </Button>
        <Button type="link" onClick={close} to={signupRecoveryPath}>
          {LOGIN_BUTTON_COPY}
        </Button>
      </div>
    </Modal>
  );
}

function getSignupRecoveryPath(email: string): string {
  const recoveryEmail = isValidEmail(email) ? email : '';
  return `${PATH_SIGNUP_CHECKOUT}/${recoveryEmail}`;
}
