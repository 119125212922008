import { Logger } from '@farmersdog/logger';

import {
  useSSRAnonymousExperiments,
  getHasSSRAnonymousExperiment,
} from '../../server/context/experimentsContext';

import { HomepageHeroImageTreatments } from 'src/abTesting';
import { CVR_HOMEPAGE_HERO_IMAGE } from 'src/abTesting/features';

interface UseHomepageHeroExperimentReturn {
  treatment: HomepageHeroImageTreatments;
}

export function useHomepageHeroExperiment(): UseHomepageHeroExperimentReturn {
  const experiments = useSSRAnonymousExperiments();

  const hasHomepageHeroExperiment = getHasSSRAnonymousExperiment(
    experiments,
    CVR_HOMEPAGE_HERO_IMAGE
  );

  if (!hasHomepageHeroExperiment) {
    return {
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  const homepageHeroExperiment = experiments.cvr_homepage_hero_image;
  if (!homepageHeroExperiment?.treatment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error('No treatment found for homepage hero experiment', {
      experiment: homepageHeroExperiment,
    });

    return {
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  return {
    treatment: homepageHeroExperiment.treatment,
  };
}
