import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { FullFooter } from 'src/components/Footer';
import Page from 'src/components/Page';

import HomepageHero from './components/HomepageHero';
import SectionLogos from './components/SectionLogos';
import SectionBowl from './components/SectionBowl';
import SectionFood from './components/SectionFood';
import SectionPackage from './components/SectionPackage';
import SectionDog from './components/SectionDog';
import SectionHowItWorks from './components/SectionHowItWorks';
import SectionReviews from './components/SectionReviews';
import { SectionVet } from './components/SectionVet/SectionVet';
import { SectionRecommended } from './components/SectionRecommended';

import { Navigation } from 'src/components/Navigation';
import schema from './schema.json';
import { HomepageSections } from '../HomepageRedesign/components/CtaTrackerV2';
import { useTrackedSectionRef } from '../../hooks/useTrackedSections';

function Home() {
  const ctaReveal = useRef(null);

  const howItWorksSectionRef = useTrackedSectionRef<HTMLDivElement>(
    HomepageSections.HowItWorks
  );

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <HomepageHero />
      <div ref={ctaReveal}>
        <SectionLogos />
        <Page
          className="Home-sections"
          style={{ overflowX: 'hidden' }}
          description={schema.description}
        >
          <SectionBowl />
          <SectionFood />
          <SectionPackage />
          <div ref={howItWorksSectionRef}>
            <SectionDog />
            <SectionHowItWorks />
          </div>
          <SectionVet />
          <SectionReviews />
          <SectionRecommended />
        </Page>
        <FullFooter />
      </div>
    </>
  );
}

export default Home;
