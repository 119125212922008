import classNames from 'classnames';
import { useId } from 'react';

import { PATH_TREAT_PRODUCTS } from '@farmersdog/constants/paths';
import { Picture, Text, Link, Card, useViewport } from '@farmersdog/corgi-x';

import { treatsDogDesktop, treatsDogMobile } from '@/account/treats/assets';

import styles from './InactiveEmptyTreats.module.css';

export function InactiveEmptyTreats({ className }: { className?: string }) {
  const isLarge = useViewport().lg;
  const id = useId();
  return (
    <Card className={classNames(className, styles.container)}>
      <Picture
        alt="treats dog"
        sources={isLarge ? treatsDogDesktop : treatsDogMobile}
        className={styles.image}
      />
      <section aria-labelledby={id} className={styles.content}>
        <Text variant="article-20" color="kale-3" id={id}>
          Add our <strong>healthy, tasty treats</strong> to your plan when you
          reactivate!
        </Text>
        <Text bold variant="heading-16">
          <Link to={PATH_TREAT_PRODUCTS}>Learn More</Link>
        </Text>
      </section>
    </Card>
  );
}
