import { events, track } from 'src/analytics';

import type { RecommendedChewsPackage } from '@/account/treats/ChewsDetails/hooks/useChewsDetailsData';

export function trackChewsRecommendation(
  recommendedChewsPackages: RecommendedChewsPackage[]
) {
  track(events.chews_how_to_chew_recommendation, {
    recommendedChewsPackages,
  });
}
