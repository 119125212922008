import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import type { RefCallback } from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';

import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { paths } from '@farmersdog/constants';

import { ExtendedButton } from '../ExtendedButton';
import { useHasScrolled } from 'src/hooks/useHasScrolled';
import { useTrackedSectionRef } from '../../../../hooks/useTrackedSections';

import styles from './BetterForThem.module.css';
import { CtaTrackerV2, HomepageRedesignSections } from '../CtaTrackerV2';
import { betterForThemItems } from './data';

export const BetterForThem = () => {
  const hasScrolled = useHasScrolled();
  const [activeAnimationIndex, setActiveAnimationIndex] = useState(-1);

  const registerSectionRef = useTrackedSectionRef(
    HomepageRedesignSections.BetterForThem
  );

  const intersectionObserver = useMemo(() => {
    // This component is server-rendered, and IntersectionObserver is not available on the server,
    // so we should only create it on the client to avoid throwing an error.
    if (typeof IntersectionObserver === 'undefined') {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveAnimationIndex(0);
          observer.disconnect();
        }
      },
      {
        threshold: 0.2,
      }
    );

    return observer;
  }, []);

  // See https://18.react.dev/reference/react-dom/components/common#ref-callback
  // for more information on RefCallbacks.
  const sectionRef: RefCallback<HTMLElement> = useCallback(
    node => {
      registerSectionRef(node);

      if (node) {
        intersectionObserver?.observe(node);
      } else {
        intersectionObserver?.disconnect();
      }
    },
    [registerSectionRef, intersectionObserver]
  );

  useEffect(() => {
    return () => {
      intersectionObserver?.disconnect();
    };
  }, [intersectionObserver]);

  return (
    <section ref={sectionRef} className={styles.wrapper}>
      <div className={styles.container}>
        <Text
          bold
          as="h2"
          color="kale-3"
          align="center"
          bottomSpacing="lg"
          variant="heading-28"
          className={styles.heading}
        >
          Better for them. <br /> Easier for you.
        </Text>
        <Grid
          alignItems="center"
          justifyContent="center"
          className={styles.grid}
          flexDirection={{
            xs: 'column',
            lg: 'row',
          }}
        >
          {betterForThemItems.map(({ id, animation, title }, index) => {
            const isCurrentAnimationPlaying = index === activeAnimationIndex;

            return (
              <GridItem lg={4} key={id} className={styles.item}>
                {hasScrolled && (
                  <DotLottieReact
                    key={`${index}-${activeAnimationIndex}`}
                    data={JSON.stringify(animation)}
                    className={styles.player}
                    autoplay={isCurrentAnimationPlaying}
                    loop={false}
                    dotLottieRefCallback={player => {
                      player?.addEventListener('complete', () => {
                        if (isCurrentAnimationPlaying) {
                          setActiveAnimationIndex(
                            prevIndex =>
                              (prevIndex + 1) % betterForThemItems.length
                          );
                        }
                      });
                    }}
                  />
                )}
                <Text
                  weight="semibold"
                  topSpacing={{
                    xs: 'xxs',
                    lg: 'md',
                  }}
                  align="center"
                  color="kale-3"
                  variant="heading-16"
                  as="h3"
                  className={styles.title}
                >
                  {title}
                </Text>
              </GridItem>
            );
          })}
        </Grid>

        <CtaTrackerV2
          type="select"
          moduleLevel={1}
          moduleName={HomepageRedesignSections.BetterForThem}
        >
          <ExtendedButton
            type="link"
            to={paths.PATH_SIGNUP}
            variant="bordered-kale"
            className={styles.button}
          >
            Make the Switch
          </ExtendedButton>
        </CtaTrackerV2>
      </div>
    </section>
  );
};
