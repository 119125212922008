import { Grid, GridItem, Link, Picture, Text } from '@farmersdog/corgi-x';
import { vet } from '../assets';
import styles from './CheckMarksSection.module.scss';
import CheckIcon from './assets/check.svg';
import { Testimonials } from './Testimonials/Testimonials';
import config from 'src/config';
import { HomepageTitle1 } from '../../HomepageTitle';
import {
  trackHomeVetClickedVetPortal,
  trackHomeVetSeen,
} from 'src/analytics/events';
import { useOnEnterView } from 'src/hooks/useOnEnterView';
import { useRef } from 'react';
import {
  CtaTrackerV2,
  HomepageSections,
} from '../../../../HomepageRedesign/components/CtaTrackerV2';

export const informationList = [
  'On-Staff, Board-Certified Nutritionists',
  'On-Staff Veterinarians',
  'AAFCO Complete & Balanced',
  'Clinically Proven Nutrition',
];

interface CheckMarksSectionProps {
  isMobile: boolean;
}

export const CheckMarksSection = ({ isMobile }: CheckMarksSectionProps) => {
  const ref = useRef(null);
  useOnEnterView({
    ref,
    onEnter: trackHomeVetSeen,
  });
  return (
    <section aria-label="vet section" className={styles.container}>
      <Grid gap="sm">
        <GridItem>
          <Grid rowGap="sm" flexDirection="row" className={styles.topSection}>
            <div>
              <Picture
                sources={vet}
                alt="vet"
                loading="lazy"
                className={styles.vetImage}
              />
            </div>
            <GridItem className={styles.information}>
              <HomepageTitle1 center={false} bottomSpacing="md">
                Driven by science.
              </HomepageTitle1>
              <div ref={ref}>
                <Text
                  variant="article-20"
                  color="charcoal-3"
                  className={styles.subtitle}
                  bottomSpacing="lg"
                >
                  We combine decades of dog nutrition research with the clinical
                  insights of experienced vet professionals.
                </Text>
              </div>
              <ul className={styles.informationList}>
                {informationList.map(info => (
                  <li key={info}>
                    <CheckIcon />
                    <Text
                      variant={isMobile ? 'heading-16' : 'heading-22'}
                      bold
                      leftSpacing="sm"
                    >
                      {info}
                    </Text>
                  </li>
                ))}
              </ul>

              <Text
                topSpacing="lg"
                variant="article-16"
                bottomSpacing="lg"
                className={styles.linkContainer}
              >
                Are you a veterinary professional? Check out our{' '}
                <CtaTrackerV2
                  moduleLevel={1}
                  moduleName={HomepageSections.Vet}
                  type="select"
                >
                  <Link
                    href={`${config('app.vetPortalUrl')}`}
                    className={styles.link}
                    role="link"
                    onClick={trackHomeVetClickedVetPortal}
                    target="_blank"
                  >
                    Vet Team Portal.
                  </Link>
                </CtaTrackerV2>
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
        <Testimonials isMobile={isMobile} />
      </Grid>
    </section>
  );
};
