import classNames from 'classnames';
import { useId } from 'react';

import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import { Picture } from '../../../../shared';

import styles from './Card.module.css';

import type { SelectionOption, Value } from '../CardRadioGroup';

interface CardProps<ValueType extends Value>
  extends SelectionOption<ValueType> {
  name: string;
  imageSources: string[];
  imageAlt: string;
  heading: string;
  description?: string;
  isSelected: boolean;
  value: ValueType;
  onChange: () => void;
}

export function Card<ValueType extends Value>({
  name,
  heading,
  description,
  imageSources,
  imageAlt,
  value,
  isSelected,
  onChange,
}: CardProps<ValueType>) {
  const headingId = useId();
  const descriptionId = useId();

  return (
    <label
      className={classNames(styles.card, {
        [styles.selected]: isSelected,
      })}
    >
      <input
        type="radio"
        name={name}
        aria-labelledby={headingId}
        aria-describedby={descriptionId}
        className={styles.radioInput}
        onChange={onChange}
        value={value as string | number}
        checked={isSelected}
      />
      <Grid
        flexDirection={{ xs: 'row', lg: 'column' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <GridItem xl={12} xs={5} justifyContent="center">
          <Picture
            sources={imageSources}
            alt={imageAlt}
            className={styles.image}
          />
        </GridItem>
        <GridItem
          xl={12}
          xs={7}
          flexDirection="column"
          justifyContent="center"
          alignItems={{ xs: 'flex-start', lg: 'center' }}
        >
          <Text
            id={headingId}
            variant="heading-16"
            bold
            color="Charcoal2"
            as="h5"
            className={styles.heading}
          >
            {heading}
          </Text>
          {description && (
            <Text
              id={descriptionId}
              variant="heading-12"
              as="p"
              color="Charcoal2"
              className={styles.description}
            >
              {description}
            </Text>
          )}
        </GridItem>
      </Grid>
    </label>
  );
}
