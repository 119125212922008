import { getSnapchatPii } from './getSnapchatPii';
import { mountSnapchatEvent } from './mountSnapchatEvent';
import { SnapchatEvent } from './types';

interface MountSnapchatSignupArgs {
  email?: string;
  eventId: string;
}

export async function mountSnapchatSignup({
  email,
  eventId,
}: MountSnapchatSignupArgs) {
  const properties = {
    ...(await getSnapchatPii(email)),
    event_id: eventId,
  };

  mountSnapchatEvent({ name: SnapchatEvent.SignUp, properties });
}
