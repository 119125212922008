import { AcMe3PageTreatments, FeatureName } from '../../../utils';

import type { UseFeatureHook } from '../../../types';

interface useAcMe3PageExperimentProps {
  useFeature: UseFeatureHook;
}

const ACTIVE_TREATMENTS = [
  AcMe3PageTreatments.me3_page_only_copy_change,
] as const;

export type ActiveTreatment = (typeof ACTIVE_TREATMENTS)[number];

const isActiveTreatment = (
  treatment: AcMe3PageTreatments
): treatment is ActiveTreatment =>
  ACTIVE_TREATMENTS.includes(treatment as ActiveTreatment);

export function useAcMe3PageExperiment({
  useFeature,
}: useAcMe3PageExperimentProps) {
  const { treatment } = useFeature(FeatureName.AC_ME3_PAGE);

  const isExperimentOn = isActiveTreatment(treatment as AcMe3PageTreatments);

  return {
    isExperimentOn,
  };
}
