import { useHistory } from 'react-router';

import { RecipesPage } from '../../RecipesPage';
import { useQueryParams } from '../hooks/useQueryParams';
import { getRecipesRouteForPet } from '../utils/getRecipesRouteForPet';

import type { OnTosaCompleted } from '../../../exports';
import type { FetchLeadQuery } from '../../../graphql/types';
import type { UseFeatureHook } from '../../../types';

interface RecipesPageRouteArgs {
  useFeature: UseFeatureHook;
  lead: FetchLeadQuery['lead'];
  onTosaCompleted: OnTosaCompleted;
  recipesRoutes: string[];
  routeBeforeRecipes: string;
  routeAfterRecipes: string | undefined;
}

export function RecipesPageRoute({
  useFeature,
  lead,
  onTosaCompleted,
  recipesRoutes,
  routeBeforeRecipes,
  routeAfterRecipes,
}: RecipesPageRouteArgs) {
  const history = useHistory();
  function redirectToLastAvailableRecipesRoute() {
    const lastRecipesRoute = recipesRoutes.at(-1)!;
    history.replace(lastRecipesRoute);
    return null;
  }

  const queryParams = useQueryParams();
  const petName = queryParams.get('pet');

  if (!petName) {
    return redirectToLastAvailableRecipesRoute();
  }

  const currentRecipesRoute = getRecipesRouteForPet(petName);

  if (!recipesRoutes.includes(currentRecipesRoute)) {
    return redirectToLastAvailableRecipesRoute();
  }

  const currentRecipesRouteIdx = recipesRoutes.indexOf(currentRecipesRoute);
  const previousRoute =
    recipesRoutes[currentRecipesRouteIdx - 1] ?? routeBeforeRecipes;
  const nextRoute =
    recipesRoutes[currentRecipesRouteIdx + 1] ?? routeAfterRecipes;

  return (
    <RecipesPage
      // We include a key so that the component remounts every time the pet in
      // the query string changes, since this should be treated as a navigation.
      key={petName}
      petName={petName}
      lead={lead}
      useFeature={useFeature}
      onTosaCompleted={onTosaCompleted}
      previousRoute={previousRoute}
      nextRoute={nextRoute}
    />
  );
}
