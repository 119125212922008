import { useState, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router';

import { ContentReveal, useViewport } from '@farmersdog/corgi-x';

import {
  trackSignupFreeTreatAddTreatToCart,
  trackSignupFreeTreatsRemovedFromCart,
  trackSignupFreeTreatsSkipped,
  trackSignupFreeTreatsSuccess,
  trackSignupFreeTreatsShown,
} from '../../analytics';
import { standardBlueprintVersion } from '../../blueprint';
import { useUpdateLead } from '../../graphql/mutations';
import { useFetchAvailableProducts, useGetLead } from '../../graphql/queries';
import { useThrowToErrorBoundary } from '../../hooks';
import TosaRootStyles from '../../TOSA.css';
import { TosaLayout } from '../layout';
import { DynamicPageTitle } from '../shared';
import { SignUpHeaderNonTosaPage } from '../shared/SignUpHeaderNonTosaPage';

import { Hero, Cta, HumanGradeTreats, FAQ } from './components';
import styles from './SignupTreatsPage.module.css';

import type {
  Treat,
  LeadSelectionInput,
  FetchLeadQuery,
} from '../../graphql/types';
import type { RouteProps } from '../NewTosaRouter/types';

interface SignupTreatsPageArgs extends RouteProps {
  lead: FetchLeadQuery['lead'];
}

export function SignupTreatsPage({
  lead,
  previousRoute,
  nextRoute,
}: SignupTreatsPageArgs) {
  const viewport = useViewport();

  const [updateLead, updateLeadState] = useUpdateLead();

  const throwToErrorBoundary = useThrowToErrorBoundary();

  const { refetch } = useGetLead();

  useEffect(() => {
    // We need to ensure when a user navigates back to the treats page, we have the most up-to-date lead data
    // Since their treat selections can be removed in the website
    /// This will be obsolete once the entire signup flow shares the same Apollo state
    void refetch();
  }, [refetch]);

  const {
    data: availableProductsData,
    loading: isFetchAvailableProductsLoading,
  } = useFetchAvailableProducts();
  const availableTreats = useMemo(
    () => availableProductsData?.availableProducts?.treats ?? [],
    [availableProductsData?.availableProducts?.treats]
  );
  const [selectedTreat, setSelectedTreat] = useState<Treat | null>(null);
  const [currentlyLoadingButton, setCurrentlyLoadingButton] = useState<
    'primary' | 'secondary'
  >();

  const persistedTreats = useMemo(
    () => lead.selection?.treats ?? [],
    [lead.selection?.treats]
  );

  useEffect(() => {
    if (availableTreats.length > 0) {
      const treatNames = availableTreats.map(treat => treat.displayName);
      trackSignupFreeTreatsShown({ treats: treatNames, page: 'treats' });
    }
  }, [availableTreats]);

  // Reset the current selection based on the lead data & available treats
  useEffect(() => {
    const preSelectedTreat =
      availableTreats.find(treat => treat.name === persistedTreats[0]?.name) ??
      availableTreats[0];

    setSelectedTreat(preSelectedTreat);
  }, [availableTreats, persistedTreats]);

  const updateLeadSelection = useCallback(
    async (selection: LeadSelectionInput) => {
      await updateLead({
        variables: {
          lead: {
            blueprintVersion: standardBlueprintVersion,
            selection,
          },
        },
      });
    },
    [updateLead]
  );

  const handleAddTreat = useCallback(() => {
    if (!selectedTreat) {
      return;
    }

    setCurrentlyLoadingButton('primary');

    const selection: LeadSelectionInput = {
      treats: [
        {
          name: selectedTreat.name,
          size: selectedTreat.size,
          quantity: 1, // hardcoded to only allow one treat bag at checkout
        },
      ],
    };

    void updateLeadSelection(selection);

    trackSignupFreeTreatAddTreatToCart(selectedTreat);
    trackSignupFreeTreatsSuccess();
  }, [selectedTreat, updateLeadSelection]);

  const onSkip = useCallback(() => {
    setCurrentlyLoadingButton('secondary');
    const selection: LeadSelectionInput = {
      treats: [],
    };

    void updateLeadSelection(selection);

    const preSelectedTreat = persistedTreats?.[0];
    if (preSelectedTreat) {
      trackSignupFreeTreatsRemovedFromCart(preSelectedTreat);
    } else {
      trackSignupFreeTreatsSkipped();
    }

    trackSignupFreeTreatsSuccess();
  }, [persistedTreats, updateLeadSelection]);

  const ctaElement = useMemo(
    () => (
      <Cta
        hasSelectedTreats={Boolean(selectedTreat)}
        areAnyAddedTreats={!!persistedTreats?.length}
        onAddToPlan={handleAddTreat}
        onSkip={onSkip}
        currentlyLoadingButton={currentlyLoadingButton}
      />
    ),
    [
      selectedTreat,
      currentlyLoadingButton,
      handleAddTreat,
      onSkip,
      persistedTreats,
    ]
  );

  if (updateLeadState.error) {
    throwToErrorBoundary(updateLeadState.error);
  }

  if (updateLeadState.data && nextRoute) {
    return <Redirect to={nextRoute} push />;
  }

  return (
    <ContentReveal inAnimation="fade" in={!isFetchAvailableProductsLoading}>
      <div className={TosaRootStyles.root}>
        <DynamicPageTitle />
        <SignUpHeaderNonTosaPage previousSignupRoute={previousRoute} />

        <TosaLayout>
          <div className={styles.container}>
            <Hero
              availableTreats={availableTreats}
              selectedTreat={selectedTreat}
              onSelectTreat={setSelectedTreat}
              ctaElement={ctaElement}
              alreadyAddedTreats={persistedTreats}
              petNames={lead.pets.map(pet => pet.name)}
            />
            <HumanGradeTreats />
            <FAQ />
            {!viewport.md && ctaElement}
          </div>
        </TosaLayout>
      </div>
    </ContentReveal>
  );
}
