import classNames from 'classnames';

import styles from './ExtendedWistiaElement.module.css';

import { Script } from '../../../../components/Script';
import { useHasScrolled } from 'src/hooks/useHasScrolled';

import type { NativeWistiaOptions } from './types';

type IntrinsicDivProps = JSX.IntrinsicElements['div'];

export interface WistiaElementProps extends IntrinsicDivProps {
  id: string;
  options?: NativeWistiaOptions;
}

/** Render a DIV element used by the wistia script to host the video. */
export function WistiaElement(props: WistiaElementProps) {
  const hasScrolled = useHasScrolled();
  if (!hasScrolled) {
    return null;
  }

  const { options = { popover: false }, id, className, ...divProps } = props;
  const cssClass = classNames(
    {
      [styles.wistiaElement]: true,
      [styles.popover]: options.popover,

      // These classes are used by Wistia's script to find and modify the element in the DOM
      // (see https://docs.wistia.com/docs/popover-embed-customization#popover-embeds)
      wistia_embed: true,
      [`wistia_async_${id}`]: true,
      'popover=true': options.popover,
    },
    className
  );

  return (
    <>
      <Script src="//fast.wistia.com/assets/external/E-v1.js" />
      <div
        {...divProps}
        // We need to add a `key` so that the Wistia script detects when the `id` changes
        key={id}
        className={cssClass}
      />
    </>
  );
}
