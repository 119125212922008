import { events, track } from 'src/analytics';

interface trackTreatsFinalSkuRemovedProps {
  path: string;
  category: 'treats' | 'chews';
}

export function trackTreatsFinalSkuRemoved({
  path,
  category,
}: trackTreatsFinalSkuRemovedProps) {
  return track(events.treats_final_sku_removed, {
    path,
    category,
  });
}
