import noop from 'lodash/noop';

/**
 * Safely execute browser only code.
 *
 * @param {Function} fn The function to execute.
 * @param {Any} fallback The default return value.
 *
 */
function browserOnly(fn = noop, fallback = noop) {
  if (typeof window === 'undefined') {
    return fallback();
  }

  return fn(window, document);
}

export default browserOnly;
